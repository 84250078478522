import { useCallback, useMemo } from 'react'
import { useAppSelector } from '../redux/useAppSelector'

export const useActions = () => {
    const commandsList = useAppSelector(({ actions }) => actions.commandsList)
    const formsList = useAppSelector(({ actions }) => actions.formsList)
    const widgetActionID = 4
    const widgetCommands = useMemo(() => {
        return commandsList?.filter(elem => elem.commandType.id === widgetActionID)
    }, [commandsList])
    const widgetForms = useMemo(() => {
        return formsList?.filter(elem => elem.formType.id === widgetActionID)
    }, [formsList])
    const getCommandsListByWidgetType = useCallback(
        (widgetTypeID: number) => {
            return widgetCommands?.filter(elem => elem.widgetType.id === widgetTypeID) ?? []
        },
        [widgetCommands],
    )
    const getFormsListByWidgetType = useCallback(
        (widgetTypeID: number) => {
            return widgetForms?.filter(elem => elem.widgetType.id === widgetTypeID) ?? []
        },
        [widgetForms],
    )
    return {
        commandsList,
        widgetCommands,
        getCommandsListByWidgetType,
        getFormsListByWidgetType,
    }
}
