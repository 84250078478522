import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { FetchUpdateUISettings, AppStateInitialState } from './appState.types'
import type { MainMenuSettingsResponse, UISettingsResponse } from '@src/api/userinterface/userinterface.types'

const initialState: AppStateInitialState = {
    UISettings: null,
    mainMenuList: null,
    isLoadingUISettings: false,
    isErrorUISettings: false,
    isLoadingMainMenuSettings: false,
    isErrorMainMenuSettings: false,
    isLoadingUpdateUISettings: false,
    isErrorUpdateUISettings: false,
}

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setLocalUISettings: (state, { payload }: PayloadAction<NonNullable<AppStateInitialState['UISettings']>>) => {
            state.UISettings = { ...state.UISettings, ...payload }
        },

        fetchUISettings: state => {
            state.isLoadingUISettings = true
        },
        setUISettings: (state, { payload }: PayloadAction<UISettingsResponse>) => {
            state.UISettings = { ...state.UISettings, ...payload }
            state.isLoadingUISettings = false
        },
        errorUISettings: state => {
            state.isLoadingUISettings = false
            state.isErrorUISettings = true
        },
        fetchUpdateUISettings: (state, { payload }: PayloadAction<FetchUpdateUISettings>) => {
            state.isLoadingUpdateUISettings = true
        },
        setUpdateUISettings: (state, { payload }: PayloadAction<FetchUpdateUISettings>) => {
            state.isLoadingUpdateUISettings = false
            state.UISettings = {
                ...state.UISettings,
                ...payload,
            }
        },
        errorUpdateUISettings: state => {
            state.isLoadingUpdateUISettings = false
            state.isErrorUpdateUISettings = true
        },
        fetchMainMenuSettings: state => {
            state.isLoadingMainMenuSettings = true
        },
        setMainMenuSettings: (state, { payload }: PayloadAction<MainMenuSettingsResponse>) => {
            state.mainMenuList = payload.elements
            state.isLoadingMainMenuSettings = false
        },
        errorMainMenuSettings: state => {
            state.isLoadingMainMenuSettings = false
            state.isErrorMainMenuSettings = true
        },
    },
})

export const { actions: appStateActions, reducer: appState } = appStateSlice
