import React, { type FC, useState, useEffect, type ReactNode } from 'react'
import styles from './styles.module.scss'
import { CheckBox, WarningIcon } from '@src/components'

interface ISharedUserItem {
    disabled?: boolean
    userName: ReactNode
    isShared: boolean
    hasNotRight: boolean
    onChange: (value: boolean) => void
}

export const SharedUserItem: FC<ISharedUserItem> = ({ userName, isShared, hasNotRight, disabled, onChange }) => {
    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        setIsChecked(isShared)
    }, [isShared])

    const handleChange = (status: boolean) => {
        setIsChecked(status)
        onChange(status)
    }

    return (
        <div
            style={{ filter: disabled ? 'grayscale(1)' : 'none', pointerEvents: disabled ? 'none' : 'auto' }}
            className={`${styles.root} ${hasNotRight ? styles.warning : ''} ${isChecked ? styles.checked : ''}`}
        >
            <CheckBox isChecked={isChecked} onChange={handleChange} label={userName} />
            {hasNotRight && <WarningIcon />}
        </div>
    )
}
