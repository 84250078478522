import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { FilterIcon, Switcher } from '@src/components'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { WidgetFilterGroup } from '@src/api/widgets/widgets.types'
import { FiltersGroup } from './components/FiltersGroup/FiltersGroup'
import { createEmptyFilterGroup, createEmptyFilterItem, getCleanFilters, sortFilters } from './FilterPanel.functions'
import type { LogicOperators } from './FilterPanel.types'
import { useLogicOperators } from './hooks/useLogicOperators'

export const FilterPanel = () => {
    const { t } = useTranslation()
    const [isFilterActive, setIsFilterActive] = useState(false)
    const [advancedMode, setAdvancedMode] = useState(false)
    const [filters, setFilters] = useState<WidgetFilterGroup[] | undefined>()
    const { currentWidget, updateWidget, setupWidgetPage, widgetID } = useWidget()
    const logicOperatorsOptions = useLogicOperators()

    useEffect(() => {
        if (typeof currentWidget?.widget?.isDisabledFiltering === 'boolean')
            setIsFilterActive(!currentWidget?.widget?.isDisabledFiltering)
        else setIsFilterActive(false)
    }, [currentWidget?.widget?.isDisabledFiltering])

    useEffect(() => {
        setAdvancedMode(!!currentWidget?.widget?.isExtendedFiltering)
    }, [currentWidget?.widget?.isExtendedFiltering])
    useEffect(() => {
        if (currentWidget?.widget?.filterGroups) {
            const filterGroups = currentWidget.widget.filterGroups
            const addFirstEmptyRow = filterGroups.map(elem => ({
                ...elem,
                filters: elem.filters.length === 0 ? [createEmptyFilterItem()] : elem.filters,
            }))
            const sortedFilters = [...addFirstEmptyRow]
                .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
                .map(elem => ({
                    ...elem,
                    filters: [...elem.filters].sort((f, s) => f.sequenceNumber - s.sequenceNumber),
                }))
            setFilters(sortedFilters)
        }
    }, [currentWidget?.widget?.filterGroups, widgetID])

    useEffect(() => {
        if (widgetID && currentWidget?.widget?.filterGroups.length === 0) {
            updateWidget({
                widgetType: setupWidgetPage.widgetType,
                filterGroups: [createEmptyFilterGroup(widgetID)],
            })
        }
    }, [widgetID, currentWidget?.widget?.filterGroups])

    const handleFilters = (status: boolean) => {
        setIsFilterActive(status)
        updateWidget({ isDisabledFiltering: !status })
    }

    const updateFilters = (newFilterGroup: WidgetFilterGroup) => {
        return filters?.map(filter => (filter.id === newFilterGroup.id ? newFilterGroup : filter)) ?? []
    }

    const handleUpdateFiltersState = (newFilter: WidgetFilterGroup) => {
        const newFilters = updateFilters(newFilter)
        const sortedFilters = sortFilters(newFilters)
        setFilters(sortedFilters)
    }
    const handleUpdateFilters = (newFilter: WidgetFilterGroup) => {
        const newFilters = updateFilters(newFilter)
        const sortedFilters = sortFilters(newFilters)
        const cleanFilters = getCleanFilters(sortedFilters)
        updateWidget({ filterGroups: cleanFilters })
    }
    const handleUpdateLogicOperator = (value: LogicOperators) => {
        if (filters?.[0]?.id) {
            if ((value === 'any') !== filters[0].isUseOperatorOR) {
                const currentFilterGroup: WidgetFilterGroup = { ...filters[0], isUseOperatorOR: value === 'any' }
                handleUpdateFiltersState(currentFilterGroup)
                handleUpdateFilters(currentFilterGroup)
            }
        }
    }
    const handleAddFiltersGroup = (index: number) => {
        const copyFiltersGroups = [...(filters ?? [])]
        copyFiltersGroups.splice(index + 1, 0, createEmptyFilterGroup(widgetID))
        const sortedFilters = sortFilters(copyFiltersGroups)
        const cleanFilters = getCleanFilters(sortedFilters)
        setFilters(sortedFilters)
        updateWidget({ filterGroups: cleanFilters })
    }
    const handleAdvancedMode = (status: boolean) => {
        updateWidget({ isExtendedFiltering: status })
        setAdvancedMode(status)
    }

    const handleDeleteFilterGroup = (filterGroupID: number) => {
        const filteredFilterGroups = filters?.filter(elem => elem.id !== filterGroupID)
        const sortFilteredFilters = filteredFilterGroups?.map((elem, i) => ({ ...elem, sequenceNumber: i + 1 }))
        setFilters(sortFilteredFilters)
        updateWidget({ filterGroups: sortFilteredFilters })
    }

    return (
        <div className={styles.root}>
            <div className={styles.panel}>
                <div className={styles.titleWrap}>
                    <div className={styles.icon}>
                        <FilterIcon />
                    </div>
                    <p className={styles.title}>{t('create_widget.settings.filter.title')}</p>
                    <Switcher value={isFilterActive} onChange={handleFilters} />
                </div>
                {isFilterActive && (
                    <div className={styles.condition}>
                        <div className={styles.conditionWrap}>
                            <div className={`divider ${styles.divider}`} />
                            <span className={styles.conditionTitle}>
                                {t('create_widget.settings.filter.condition_1')}
                            </span>
                            <SelectList
                                className={styles.select}
                                options={logicOperatorsOptions}
                                onChange={(value: string | number) =>
                                    handleUpdateLogicOperator(value as LogicOperators)
                                }
                                selectedValue={filters?.[0]?.isUseOperatorOR ? 'any' : 'all'}
                                isFilter
                            />
                            <span className={styles.conditionTitle}>
                                {t('create_widget.settings.filter.condition_2')}
                            </span>
                        </div>
                        <div className={styles.advancedMode}>
                            <span className={styles.conditionTitle}>
                                {t('create_widget.settings.filter.advanced_mode')}
                            </span>
                            <Switcher value={advancedMode} onChange={handleAdvancedMode} />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.filtersWrapper}>
                {(isFilterActive &&
                    filters?.map((filter, index) => (
                        <FiltersGroup
                            handleDeleteFilterGroup={handleDeleteFilterGroup}
                            key={filter.id}
                            filterGroupItem={filter}
                            index={index}
                            advancedMode={advancedMode}
                            onFilterUpdate={handleUpdateFilters}
                            onFilterUpdateState={handleUpdateFiltersState}
                            onAddFilterGroup={() => handleAddFiltersGroup(index)}
                        />
                    ))) ??
                    null}
            </div>
        </div>
    )
}
