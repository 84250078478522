import { useEffect, useState } from 'react'
import { type Layout } from 'react-grid-layout'
import type { Coordinates, UseDragNDropProps } from '../GridLayout.types'
import { getWidgetPositions } from '../GridLayout.functions'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { fetchUpdateWidgetByIdAllRequests } from '@src/store/widgets/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const useDragNDrop = ({
    rowsNumber = 1,
    widgetsGrid,
    emptyPlace,
    setWidgetsGrid,
    setIsOpenNotEnoughtFreeSpace,
    setActiveGridItemID,
}: UseDragNDropProps) => {
    const dispatch = useAppDispatch()
    const [replaceWidgetID, setReplaceWidgetID] = useState(0) // ІД комірки на яку перетягують іншу комірку. По цьому ІД комірці встановлюється значення (x, y) старої позиції комірки, яку перетягнули
    const [isDrag, setIsDrag] = useState(false)
    const [maxRows, setMaxRows] = useState(rowsNumber)
    const isShowModal = useAppSelector(({ appState }) => appState.UISettings?.dontAskAgainReduceWidgetsSizeAttention)

    useEffect(() => {
        setMaxRows(rowsNumber)
    }, [rowsNumber])
    const { dashboardID } = useDashboard()
    const { currentDashboardWidgets, findWidgetTypeByWidgetID } = useWidget({ dashboardID })

    const updateWidgetsGrid = (newWidget: Layout) => {
        const { x, y, w, h, i: id } = newWidget
        setWidgetsGrid(prev =>
            prev.map(elem =>
                elem.id.toString() === id ? { ...elem, x, y, w, h, positions: getWidgetPositions(x, y, w, h) } : elem,
            ),
        )
    }

    const updateWidget = (widget: Layout) => {
        const { x, y, w, h, i } = widget
        const widgetType = findWidgetTypeByWidgetID(Number(i))
        if (dashboardID && widgetType)
            dispatch(
                fetchUpdateWidgetByIdAllRequests({
                    id: Number(i),
                    dashboardID,
                    widgetType,
                    positionX: x,
                    positionY: y,
                    cellsX: w,
                    cellsY: h,
                }),
            )
    }

    const getAvailableCellsForWidget = (oldWidget: Layout, newWidget: Layout) => {
        const { x, y, w, h } = newWidget
        const newPositions = getWidgetPositions(x, y, w, h)
        const getOldWidgetPositions = getWidgetPositions(oldWidget.x, oldWidget.y, oldWidget.w, oldWidget.h)
        const fullEmptyPlace = [...emptyPlace, ...getOldWidgetPositions]
        return fullEmptyPlace.filter(([emptyY, emptyX]) => {
            return newPositions.some(pos => pos[0] === emptyY && pos[1] === emptyX)
        })
    }

    const calculateWidgetSideProperties = (cells: Coordinates) => {
        const availableHeight = cells.map(item => item[0])
        const availableWidth = cells.map(item => item[1])
        const newWidth = new Set(availableWidth).size || 1
        const newHeight = new Set(availableHeight).size || 1
        return {
            newWidth,
            newHeight,
        }
    }

    const moveSingleWidget = (oldWidget: Layout, newWidget: Layout) => {
        const { x, y, w, h } = newWidget
        if (oldWidget.x !== x || oldWidget.y !== y || oldWidget.w !== w || oldWidget.h !== h)
            updateWidgetsGrid(newWidget)
    }
    const resizeSingleWidget = (oldWidget: Layout, newWidget: Layout) => {
        const { w, h } = newWidget
        const availableCells = getAvailableCellsForWidget(oldWidget, newWidget)
        const { newWidth, newHeight } = calculateWidgetSideProperties(availableCells)
        if ((newWidth && newWidth !== w) || (newHeight && newHeight !== h)) {
            newWidget.w = newWidth
            newWidget.h = newHeight
            updateWidgetsGrid(newWidget)
        }
    }
    const resizeSwapableWidget = (newWidget: Layout, oldAnotherWidget: Layout) => {
        const newWidgetPositions = getWidgetPositions(newWidget.x, newWidget.y, newWidget.w, newWidget.h)
        const oldAnotherWidgetPositions = getWidgetPositions(
            oldAnotherWidget.x,
            oldAnotherWidget.y,
            oldAnotherWidget.w,
            oldAnotherWidget.h,
        )
        const fullEmptyPlace = [...emptyPlace, ...oldAnotherWidgetPositions]
        const availableCells = fullEmptyPlace.filter(([emptyY, emptyX]) => {
            return newWidgetPositions.some(pos => pos[0] === emptyY && pos[1] === emptyX)
        })
        const { newWidth, newHeight } = calculateWidgetSideProperties(availableCells)
        updateWidgetsGrid({ ...newWidget, h: newHeight, w: newWidth })
        return {
            ...newWidget,
            h: newHeight,
            w: newWidth,
        }
    }
    const swapWidgets = (newLayout: Layout[], oldWidgetVersion: Layout, newWidgetVersion: Layout) => {
        if (replaceWidgetID) {
            const oldReplacedWidgetVersion = newLayout.find(elem => Number(elem.i) === replaceWidgetID)
            if (oldReplacedWidgetVersion) {
                const newReplacedWidgetVersion = {
                    ...oldReplacedWidgetVersion,
                    x: oldWidgetVersion.x,
                    y: oldWidgetVersion.y,
                }
                const updatedNewWidgetVersion = resizeSwapableWidget(newWidgetVersion, oldReplacedWidgetVersion)
                const updatedNewReplacedWidgetVersion = resizeSwapableWidget(newReplacedWidgetVersion, oldWidgetVersion)
                if (
                    newWidgetVersion.h !== updatedNewWidgetVersion.h ||
                    newWidgetVersion.w !== updatedNewWidgetVersion.w ||
                    newReplacedWidgetVersion.h !== updatedNewReplacedWidgetVersion.h ||
                    newReplacedWidgetVersion.w !== updatedNewReplacedWidgetVersion.w
                ) {
                    if (isShowModal) {
                        updateWidget(updatedNewWidgetVersion)
                        updateWidget(updatedNewReplacedWidgetVersion)
                    } else setIsOpenNotEnoughtFreeSpace(true)
                }
                if (
                    newWidgetVersion.h === updatedNewWidgetVersion.h &&
                    newWidgetVersion.w === updatedNewWidgetVersion.w &&
                    newReplacedWidgetVersion.h === updatedNewReplacedWidgetVersion.h &&
                    newReplacedWidgetVersion.w === updatedNewReplacedWidgetVersion.w
                ) {
                    updateWidget(updatedNewWidgetVersion)
                    updateWidget(updatedNewReplacedWidgetVersion)
                }
            }
        }
    }
    const moveWidget = (oldWidget: Layout, newWidget: Layout) => {
        moveSingleWidget(oldWidget, newWidget)
        resizeSingleWidget(oldWidget, newWidget)
    }

    const dragWidget = (oldWidget: Layout, newWidget: Layout) => {
        const { x, y, w, h, i: id } = newWidget
        const widgetSize = w * h
        const newPositions = getWidgetPositions(x, y, w, h)
        const oldPositions = getWidgetPositions(oldWidget.x, oldWidget.y, oldWidget.w, oldWidget.h)
        const isAbleReplace = newPositions.every(([posY, posX]) => {
            return [...emptyPlace, ...oldPositions].some(pos => pos[0] === posY && pos[1] === posX)
        })
        const availableCells = getAvailableCellsForWidget(oldWidget, newWidget)
        const { newWidth, newHeight } = calculateWidgetSideProperties(availableCells)
        const availableSize = newWidth * newHeight
        const widgetType = currentDashboardWidgets?.find(elem => elem.id === Number(id))?.listData.widgetType
        if (isAbleReplace && dashboardID && widgetType && (oldWidget.x !== x || oldWidget.y !== y)) {
            moveWidget(oldWidget, newWidget)
            updateWidget(newWidget)
        }
        if (widgetSize !== availableCells.length && availableSize === availableCells.length && !replaceWidgetID) {
            moveWidget(oldWidget, {
                ...newWidget,
                y: availableCells[0][0],
                x: availableCells[0][1],
                w: newWidth,
                h: newHeight,
            })

            if (isShowModal)
                updateWidget({
                    ...newWidget,
                    y: availableCells[0][0],
                    x: availableCells[0][1],
                    w: newWidth,
                    h: newHeight,
                })
            else setIsOpenNotEnoughtFreeSpace(true)
        }
        if (!isAbleReplace && availableSize !== availableCells.length) {
            newWidget.x = oldWidget.x
            newWidget.y = oldWidget.y
        }
    }

    const handleOnDragStart = (newLayout: Layout[], oldItem: Layout, newItem: Layout) => {
        setMaxRows(maxRows + newItem.h - 1)
        setActiveGridItemID(Number(newItem.i))
        setIsDrag(true)
    }

    const handleOnDrag = (newLayout: Layout[], oldItem: Layout, newItem: Layout) => {
        const { x, y } = newItem
        const replaceLayoutID =
            widgetsGrid.find(layout => layout.x === x && layout.y === y && layout.id !== Number(newItem.i))?.id ?? 0
        setReplaceWidgetID(replaceLayoutID)
    }

    const handleOnDragStop = (newLayout: Layout[], oldItem: Layout, newItem: Layout) => {
        setMaxRows(rowsNumber)
        if (!replaceWidgetID) dragWidget(oldItem, newItem)
        swapWidgets(newLayout, oldItem, newItem)
        setIsDrag(false)
    }

    return {
        maxRows,
        isDrag,
        handleOnDragStart,
        handleOnDrag,
        handleOnDragStop,
    }
}
