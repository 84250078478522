import React, { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary, CalendarCloseIcon, CancelIcon, CheckBox, InfoIcon } from '@src/components/StyleGuide'
import { Title } from './Title'
import styles from './styles.module.scss'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import type { AntModalProps } from '@src/layouts/AntModalLayout/AntModalLayout.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'

export const ChangeAllWidgetsDateModal: FC<AntModalProps> = ({ onCancel, isOpen, onApply }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [dontAskAgain, setDontAskAgain] = useState(false)

    const handleClose = () => {
        onCancel()
    }

    const onAccept = () => {
        dispatch(appStateActions.setLocalUISettings({ dontAskAgainPickTemporaryDatesForWidgets: dontAskAgain }))
        onApply?.()
    }

    return (
        <AntModalLayout
            isOpen={isOpen}
            onCancel={handleClose}
            title={<Title title={t('change_all_widgets_date_modal.title')} />}
        >
            <div className={styles.bodyWrapper}>
                <p className={styles.modalWarning}>{t('change_all_widgets_date_modal.warning')}</p>
                <div className={styles.modalDescriptionContainer}>
                    <InfoIcon />
                    <p className={styles.modalDescription}>
                        {t('change_all_widgets_date_modal.hint_1')} -{' '}
                        <span>{t('change_all_widgets_date_modal.hint_2')}</span>
                    </p>
                    <div className={styles.buttonPlaceholder}>
                        <CalendarCloseIcon />
                    </div>
                </div>

                <CheckBox
                    isChecked={dontAskAgain}
                    onChange={() => setDontAskAgain(!dontAskAgain)}
                    label={t('reduce_grid_warning_modal.dont_show_again')}
                />
                <p className={styles.question}>{t('change_all_widgets_date_modal.question')}</p>
                <div className={styles.buttonsWrapper}>
                    <ButtonPrimary
                        icon={<ApplyIcon stroke='white' />}
                        title={t('buttons.yes')}
                        type='primary'
                        onClick={onAccept}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('buttons.no')}
                        type='transparent'
                        onClick={handleClose}
                    />
                </div>
            </div>
        </AntModalLayout>
    )
}
