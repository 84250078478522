import React, { useEffect } from 'react'
import { ButtonIcon, ReturnIcon } from '@src/components/StyleGuide'
import styles from '../styles.module.scss'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useNavigate } from 'react-router-dom'
import { deleteSetupWidgetPageData } from '@src/store/widgets/slice'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useTranslation } from 'react-i18next'

export const BackButton = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { setupWidgetPage } = useWidget()

    const handleBack = () => {
        navigate('/')
        dispatch(deleteSetupWidgetPageData())
    }
    useEffect(() => {
        if (!setupWidgetPage.dashboardID) handleBack()
    }, [setupWidgetPage.dashboardID])
    return (
        <ButtonIcon className={styles.btnIcon} icon={<ReturnIcon />} title={t('buttons.back')} onClick={handleBack} />
    )
}
