import { useCallback, useEffect, useState } from 'react'
import type { Coordinates, WidgetGridType } from '../GridLayout.types'
import { type Layout } from 'react-grid-layout'
import { createWidgetObjForGrid } from '../GridLayout.functions'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchUpdateWidgetByIdAllRequests } from '@src/store/widgets/slice'
import type { WidgetListItem } from '@src/api/widgets/widgets.types'
import { gridActions } from '@src/store/grid/slice'

export const useGridData = (id: number | null) => {
    const dispatch = useAppDispatch()
    const [emptyPlace, setEmptyPlace] = useState<Coordinates>([])
    const [widgetsGrid, setWidgetsGrid] = useState<WidgetGridType[]>([])
    const [currentsWidgetsList, setCurrentsWidgetsList] = useState<WidgetListItem[]>([])
    const [layout, setLayout] = useState<Layout[]>([])

    const { currentDashboard } = useDashboard({ id })
    const { currentDashboardWidgets } = useWidget({ dashboardID: id })
    const colsNumber = currentDashboard?.cellsX
    const rowsNumber = currentDashboard?.cellsY
    const gridSize = (currentDashboard?.cellsY ?? 1) * (currentDashboard?.cellsX ?? 1)

    const isWidgetOnCell = ({ x, y }: { x: number; y: number }) => {
        return widgetsGrid.some(widget => widget.positions.find(pos => pos[0] === y && pos[1] === x))
    }

    const createFullEmptyGrid = (rows?: number, cols?: number) => {
        const emptyGrid: Coordinates = []
        if (typeof rows === 'number' && typeof rows === 'number') {
            for (let i = 0; i < rows; i += 1) {
                for (let j = 0; j < rows; j += 1) {
                    emptyGrid.push([i, j])
                }
            }
        }
        return emptyGrid
    }

    const createWidgetsGrid = useCallback(() => {
        if (typeof rowsNumber === 'number' && typeof colsNumber === 'number') {
            const newWidgetsGrid: WidgetGridType[] = currentsWidgetsList.map(elem =>
                createWidgetObjForGrid(elem, colsNumber, rowsNumber),
            )
            setWidgetsGrid(newWidgetsGrid)
        }
    }, [currentsWidgetsList])

    const sendChangedWidgets = (widgets?: WidgetGridType[]) => {
        const widgetsArray = widgets ?? widgetsGrid
        const changedWidgets = currentsWidgetsList.filter(elem => {
            const newWidgetVersion = widgetsArray.find(item => item.id === elem.id)

            const { positionX, positionY, cellsX, cellsY } = elem
            return (
                newWidgetVersion?.x !== positionX ||
                newWidgetVersion?.y !== positionY ||
                newWidgetVersion?.w !== cellsX ||
                newWidgetVersion?.h !== cellsY
            )
        })
        changedWidgets.forEach(elem => {
            const newWidgetVersion = widgetsArray.find(item => item.id === elem.id)
            if (newWidgetVersion)
                dispatch(
                    fetchUpdateWidgetByIdAllRequests({
                        id: elem.id,
                        dashboardID: elem.dashboardID,
                        widgetType: elem.widgetType,
                        positionX: newWidgetVersion.x,
                        positionY: newWidgetVersion.y,
                        cellsX: newWidgetVersion.w,
                        cellsY: newWidgetVersion.h,
                    }),
                )
        })
    }

    useEffect(() => {
        dispatch(gridActions.setWidgetsGrid({ dashboardID: id, widgetsGrid }))
    }, [widgetsGrid])
    useEffect(() => {
        dispatch(gridActions.setLayout({ dashboardID: id, layout }))
    }, [layout])
    useEffect(() => {
        dispatch(gridActions.setEmptySpaces({ dashboardID: id, emptySpaces: emptyPlace }))
    }, [emptyPlace])

    useEffect(() => {
        const isLoading = currentDashboardWidgets?.some(elem => elem.isLoadingUpdateWidget)
        if (!isLoading) {
            setCurrentsWidgetsList(currentDashboardWidgets?.map(widget => widget.listData) ?? [])
        }
    }, [currentDashboardWidgets, colsNumber, rowsNumber])

    useEffect(() => {
        createWidgetsGrid()
    }, [createWidgetsGrid])

    useEffect(() => {
        if (typeof rowsNumber === 'number' && typeof colsNumber === 'number') {
            const fullLayout: Array<Layout | null> = new Array(gridSize).fill(null).map((elem: null, index) => {
                const y = Math.floor(index / colsNumber)
                const x = index % colsNumber
                const findWidget = widgetsGrid.find(widget => widget.x === x && widget.y === y)
                const isCellTaken = isWidgetOnCell({ x, y })
                return !isCellTaken || (isCellTaken && findWidget)
                    ? {
                          i: findWidget?.id?.toString() ?? `empty_cell_${Math.random()}`,
                          x,
                          y,
                          w: findWidget?.w ?? 1,
                          h: findWidget?.h ?? 1,
                          static: !isCellTaken,
                          resizeHandles: ['s', 'se', 'w', 'e'],
                      }
                    : elem
            })
            const clearLayout = fullLayout.filter(item => !!item) as Layout[]

            setLayout(clearLayout)
        }
    }, [widgetsGrid])

    useEffect(() => {
        const emptyCell: Array<[number, number]> = layout
            .filter(elem => elem.i.includes('empty_cell'))
            .map(elem => [elem.y, elem.x])
        setEmptyPlace(emptyCell)
    }, [layout])

    useEffect(() => {
        const replaceOutsideRequired = widgetsGrid.filter(elem => elem.isOutside)
        const isResizeOutborderRequired = widgetsGrid.some(elem => elem.isOutborder && !elem.isOutside)
        if (
            (replaceOutsideRequired.length || isResizeOutborderRequired) &&
            typeof rowsNumber === 'number' &&
            typeof colsNumber === 'number'
        ) {
            const emptyGrid = createFullEmptyGrid(rowsNumber, colsNumber).filter(
                elem => !widgetsGrid.some(item => item.y === elem[0] && item.x === elem[1]),
            )
            const shouldWidgetsReduceSize =
                !!replaceOutsideRequired.length && replaceOutsideRequired.length > emptyPlace.length

            const emptyGridCells = shouldWidgetsReduceSize ? emptyGrid : emptyPlace
            const newWidgetsGrid = widgetsGrid.map(elem => {
                if (shouldWidgetsReduceSize && !elem.isOutside && !elem.isOutborder)
                    return { ...elem, w: 1, h: 1, positions: [[elem.y, elem.x]] as Coordinates }
                if (elem.isOutside) {
                    const outsidedWidgetIndex = replaceOutsideRequired.findIndex(item => item.id === elem.id)
                    return outsidedWidgetIndex !== -1
                        ? {
                              ...elem,
                              x: emptyGridCells[outsidedWidgetIndex]?.[1],
                              y: emptyGridCells[outsidedWidgetIndex]?.[0],
                              h: 1,
                              w: 1,
                              positions: [
                                  [emptyGridCells[outsidedWidgetIndex]?.[0], emptyGridCells[outsidedWidgetIndex]?.[1]],
                              ] as Coordinates,
                              isOutside:
                                  emptyGridCells[outsidedWidgetIndex]?.[1] + 1 > colsNumber ||
                                  emptyGridCells[outsidedWidgetIndex]?.[0] + 1 > rowsNumber,
                              isOutborder: false,
                          }
                        : elem
                }
                if (elem.isOutborder) {
                    const newPositions = elem.positions.filter(
                        pos => pos[0] + 1 <= rowsNumber && pos[1] + 1 <= colsNumber,
                    )
                    const newHeight = new Set(newPositions.map(pos => pos[0])).size
                    const newWidth = new Set(newPositions.map(pos => pos[1])).size
                    return {
                        ...elem,
                        h: newHeight || 1,
                        w: newWidth || 1,
                        positions: newPositions,
                        isOutborder: false,
                    }
                }
                return elem
            })
            setWidgetsGrid(newWidgetsGrid)
            sendChangedWidgets(newWidgetsGrid)
        }
    }, [emptyPlace])
    return {
        emptyPlace,
        widgetsGrid,
        setWidgetsGrid,
        createWidgetsGrid,
        sendChangedWidgets,
        layout,
        setLayout,
        colsNumber,
        rowsNumber,
        gridSize,
        currentsWidgetsList,
        createWidgetObjForGrid,
    }
}
