import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import {
    ButtonWithIcon,
    ButtonIcon,
    DashboardControlOption,
    DashboardIcon,
    RefreshDataButton,
    SearchInput,
    SelectDashboardGrid,
    UnViewIcon,
    ButtonPrimary,
} from '@src/components'
import { DndContext, closestCenter, useSensor, MouseSensor, useSensors } from '@dnd-kit/core'
import { rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable'
import { CreateNewDashboard } from '@src/components/DashboardControlPanel/components/CreateNewDashboard/CreateNewDashboard'
import { useData } from './useData'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { searchWidgetInDashboardActions } from '@src/store/searchWidgetInDashboard/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { WidgetsPeriodPanel } from './components/WidgetsPeriodPanel/WidgetsPeriodPanel'

export const DashboardControlPanel: FC = () => {
    const dispatch = useAppDispatch()
    const { activeDashboard, isUserAdminInDashboard } = useDashboard()
    console.log('activeDashboard:', isUserAdminInDashboard(activeDashboard?.users))
    const searchWidgetInDashboard = useAppSelector(state => state.searchWidgetInDashboard.searchValueOfWidget)

    const { t } = useTranslation()
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { delay: 150, tolerance: 50 },
        }),
    )

    const {
        isSettingsOpen,
        handleOpenDashboardsPanel,
        isHiddenDashboardsOnPanelVisible,
        handleVisabilityHiddenDashboards,
        dashboardsList,
        handleDragEnd,
    } = useData()
    return (
        <>
            <div className={styles.root}>
                <div className={`container ${styles.container}`}>
                    <div className={styles.panelWrap}>
                        <ButtonWithIcon
                            text={t('dashboard_control_panel.dashboard_btn')}
                            className={`${styles.btn} ${isSettingsOpen ? styles.active : ''}`}
                            title={isSettingsOpen ? t('hide_dashboards') : t('show_dashboards')}
                            icon={<DashboardIcon />}
                            isBoarded
                            onClick={handleOpenDashboardsPanel}
                        />
                        <ButtonIcon
                            title={
                                isHiddenDashboardsOnPanelVisible
                                    ? t('dashboard_control_panel.hideDashboards')
                                    : t('dashboard_control_panel.showDashboards')
                            }
                            className={`${styles.hideBtn} ${!isHiddenDashboardsOnPanelVisible ? styles.active : ''}`}
                            onClick={handleVisabilityHiddenDashboards}
                            disabled={!isSettingsOpen}
                            icon={<UnViewIcon />}
                            isBoarded
                        />
                        <div className={'divider'} />
                        <SelectDashboardGrid isDisabled={!isUserAdminInDashboard(activeDashboard?.users ?? [])} />
                        <RefreshDataButton isDisabled={!isUserAdminInDashboard(activeDashboard?.users ?? [])} />
                        <WidgetsPeriodPanel />
                    </div>
                    <SearchInput
                        value={searchWidgetInDashboard}
                        onSearch={e => dispatch(searchWidgetInDashboardActions.setSearchValueOfWidget(e))}
                        reset={() => dispatch(searchWidgetInDashboardActions.resetSearchValueOfWidget())}
                    />
                </div>
            </div>
            {isSettingsOpen && (
                <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter} sensors={sensors}>
                    <div className={styles.settings}>
                        <div className={`container ${styles.settingsWrap}`}>
                            <div className={styles.dashboardSettings}>
                                <SortableContext items={dashboardsList} strategy={rectSwappingStrategy}>
                                    {dashboardsList.length > 0 &&
                                        dashboardsList.map(({ id, name, isHidden, users }) => (
                                            <DashboardControlOption
                                                key={id}
                                                dashboardID={id}
                                                dashboardTitle={name}
                                                isLocked={!isUserAdminInDashboard(users)}
                                                isHidden={isHidden}
                                                isShared={isUserAdminInDashboard(users) && users.length > 1}
                                                isActive={activeDashboard?.id === id}
                                            />
                                        ))}
                                </SortableContext>

                                <CreateNewDashboard />
                            </div>
                        </div>
                    </div>
                </DndContext>
            )}
        </>
    )
}
