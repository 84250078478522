import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { ChartField, WidgetBarChart, WidgetLineChart } from '@src/api/widgets/widgets.types'
import { useAggregateFunctions } from '@src/hooks/useAggregateFunctions/useAggregateFunctions'
import { YAxisLineChartItem } from './YAxisLineChartItem'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'

export const YAxisLineChartSetttings = () => {
    const { t } = useTranslation()
    const { lineChartWidget, updateWidget } = useWidget()
    const { findFieldByID } = useUserFields({
        datasourceTypeID: lineChartWidget?.listData.dataSourceType.id,
        datasetID: lineChartWidget?.listData.dataset.id,
    })
    const { getAggregateFunctionType } = useAggregateFunctions()
    const [rows, setRows] = useState<WidgetLineChart['widgetFields']>([])
    useEffect(() => {
        if (lineChartWidget?.widget?.widgetFields?.length === 0) {
            setRows([
                {
                    widgetID: lineChartWidget?.id,
                    dataField: null,
                    aggregateFunctionType: null,
                    goal: null,
                    isGoalEnabled: false,
                    isDisabled: false,
                    color: '#437EC3',
                },
            ])
        }
        if (lineChartWidget?.widget?.widgetFields && lineChartWidget.widget.widgetFields.length > 0)
            setRows(lineChartWidget.widget.widgetFields)
    }, [lineChartWidget?.widget?.widgetFields])

    const isAllDataFilled = () => {
        return rows.every(row => row?.dataField?.id)
    }
    const updateSingleField = (index: number, data: Partial<ChartField>) => {
        return rows.map((row, i) => (i === index ? { ...row, ...data } : row))
    }
    const updateSingleFieldGoal = (index: number, data: Partial<ChartField>) => {
        return rows.map((row, i) => (i === index ? { ...row, ...data } : { ...row, isGoalEnabled: false }))
    }

    const handleDataChange = (index: number, id: string) => {
        const matchedDataset = findFieldByID(id)
        if (matchedDataset) {
            if (matchedDataset.fieldType.id === 1 || matchedDataset.fieldType.id === 2) {
                const newRows = updateSingleField(index, { dataField: matchedDataset })
                setRows(newRows)
                updateWidget({ widgetFields: newRows })
            }
            if (matchedDataset.fieldType.id !== 1 && matchedDataset.fieldType.id !== 2) {
                const countFunc = getAggregateFunctionType(2)
                const newRows = updateSingleField(index, {
                    dataField: matchedDataset,
                    aggregateFunctionType: countFunc,
                })
                setRows(newRows)
                updateWidget({ widgetFields: newRows })
            }
        }
    }
    const handleFuncChange = (index: number, id: number) => {
        const aggregateFunctionType = getAggregateFunctionType(id)
        if (aggregateFunctionType) {
            const newRows = updateSingleField(index, { aggregateFunctionType })
            setRows(newRows)
            if (isAllDataFilled()) updateWidget({ widgetFields: newRows })
        }
    }
    const handleColorChange = (index: number, color: string) => {
        const newRows = updateSingleField(index, { color })
        setRows(newRows)
        if (isAllDataFilled()) updateWidget({ widgetFields: newRows })
    }
    const handleGoalSwitch = (index: number, status: boolean) => {
        const newRows = updateSingleFieldGoal(index, { isGoalEnabled: status })
        setRows(newRows)
        if (isAllDataFilled()) updateWidget({ widgetFields: newRows })
    }
    const handleGoalChange = (index: number, value: number) => {
        const newRows = updateSingleField(index, { goal: value })
        setRows(newRows)
        if (isAllDataFilled()) updateWidget({ widgetFields: newRows })
    }
    const handleRowDelete = (index: number) => {
        const rowsCopy = [...rows]
        rowsCopy.splice(index, 1)
        rows[index].dataField?.id ? updateWidget({ widgetFields: rowsCopy }) : setRows(rowsCopy)
    }
    const handleRowAdd = (index: number) => {
        const rowsCopy = [...rows]
        const newRowObj: WidgetBarChart['widgetFields'][number] = {
            widgetID: lineChartWidget?.id ?? 0,
            dataField: null,
            aggregateFunctionType: null,
            goal: null,
            isGoalEnabled: false,
            isDisabled: false,
            color: '',
        }
        rowsCopy.splice(index + 1, 0, newRowObj)
        setRows(rowsCopy)
    }
    const handleRowSwitch = (index: number, status: boolean) => {
        const newRows = updateSingleField(index, { isDisabled: !status })
        setRows(newRows)
        if (isAllDataFilled()) updateWidget({ widgetFields: newRows })
    }

    return (
        <div className={styles.settingItem}>
            <p className={styles.settingItemTitle}>{t('create_widget.settings.axis_y')}</p>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableHeader}>
                        <td>{t('create_widget.settings.field_data_label')}</td>
                        <td>{t('create_widget.settings.field_function_label')}</td>
                        <td>{t('create_widget.settings.field_color_label')}</td>
                        <td>{t('create_widget.settings.field_goal_label')}</td>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((item, index) => (
                        <YAxisLineChartItem
                            key={item.id ?? `${item.isDisabled}`}
                            rowData={item}
                            isAvailableDeleteRow={rows.length > 1}
                            isAvailableAddRow={isAllDataFilled()}
                            onDataChange={(id: string) => handleDataChange(index, id)}
                            onFuncChange={(id: number) => handleFuncChange(index, id)}
                            onColorChange={(color: string) => handleColorChange(index, color)}
                            onGoalSwitch={(status: boolean) => handleGoalSwitch(index, status)}
                            onGoalChange={(value: number) => handleGoalChange(index, value)}
                            onRowDelete={() => handleRowDelete(index)}
                            onRowAdd={() => handleRowAdd(index)}
                            onRowSwitch={(status: boolean) => handleRowSwitch(index, status)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
