import { type AllEffect, type ForkEffect, call, put, all, takeLatest, takeEvery } from 'redux-saga/effects'
import {
    errorDashboardWidgetsList,
    errorDeleteWidgetById,
    errorMoveWidgetToDashboard,
    errorUpdateWidgetById,
    errorWidgetById,
    errorWidgetsTableData,
    fetchCreateWidgetByType,
    fetchCreateWidgetByTypeForDasboardCopy,
    fetchDashboardWidgetsList,
    fetchDeleteWidgetById,
    fetchMoveWidgetToDashboard,
    fetchUpdateWidgetById,
    fetchUpdateWidgetByIdAllRequests,
    fetchWidgetById,
    fetchWidgetTableData,
    setCreateWidgetByType,
    setDashboardWidgetsList,
    setDeleteWidgetById,
    setMoveWidgetToDashboard,
    setUpdateWidgetById,
    setWidgetById,
    setWidgetsTableData,
} from './slice'
import { widgetAPI } from '@src/api/widgets/widgets.api'
import type {
    FetchWidgetByIdPayload,
    FetchDashboardWidgetsListPayload,
    WidgetResponseType,
    FetchCreateWidgetByType,
    FetchDeleteWidgetByIdPayload,
    FetchUpdateWidgetByIdPayload,
    UpdateCounterBody,
    UpdateTextBody,
    FetchMoveWidgetToDashboardPayload,
    UpdatePieChartBody,
    UpdateBarChartBody,
    UpdateLineChartBody,
} from './widgets.types'
import type {
    CreateWidgetBarChartRequest,
    CreateWidgetCounterRequest,
    CreateWidgetLineChartRequest,
    CreateWidgetPieChartRequest,
    CreateWidgetTableRequest,
    getWidgetsTableDataArgs,
    UpdateWidgetTable,
    WidgetListResponse,
    WidgetTableDataType,
} from '@src/api/widgets/widgets.types'

function* fetchDashboardWidgetsListWorker({ payload }: FetchDashboardWidgetsListPayload) {
    try {
        const { onSuccess, dateFrom, dateTo, ...body } = payload
        const res: WidgetListResponse = yield call(widgetAPI.getWidgetsList, body)
        if (res) {
            yield all(
                res.map(elem =>
                    put(
                        fetchWidgetById({
                            widgetID: elem.id,
                            dashboardID: elem.dashboardID,
                            widgetType: elem.widgetType,
                            dateFrom,
                            dateTo,
                        }),
                    ),
                ),
            )
            yield put(setDashboardWidgetsList({ dashboardID: body.dashboardID, res }))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(errorDashboardWidgetsList(payload))
    }
}

function* fetchCreateWidgetByTypeWorker({ payload }: FetchCreateWidgetByType) {
    const { onSuccess, ...body } = payload
    try {
        let res: WidgetResponseType | undefined
        switch (payload.widgetType.id) {
            case 1:
                res = yield call(widgetAPI.createTextBlock, body)
                break
            case 2:
                res = yield call(widgetAPI.createTableWidget, body as CreateWidgetTableRequest)
                break
            case 3:
                res = yield call(widgetAPI.createCounter, body as CreateWidgetCounterRequest)
                break
            case 4:
                res = yield call(widgetAPI.createPieChart, body as CreateWidgetPieChartRequest)
                break
            case 5:
                res = yield call(widgetAPI.createBarChart, body as CreateWidgetBarChartRequest)
                break
            case 6:
                res = yield call(widgetAPI.createLineChart, body as CreateWidgetLineChartRequest)
                break
        }
        if (res) {
            yield put(setCreateWidgetByType(res))
            onSuccess?.()
        }
    } catch (err: any) {
        console.log(err)
    }
}

function* fetchWidgetsByIDWorker({ payload }: FetchWidgetByIdPayload) {
    try {
        const { onSuccess, widgetID, dateFrom, dateTo } = payload
        let res: WidgetResponseType | undefined
        switch (payload.widgetType.id) {
            case 1:
                res = yield call(widgetAPI.getTextBlock, { widgetID })
                break
            case 2:
                res = yield call(widgetAPI.getTableWidget, { widgetID, dateFrom, dateTo })
                break
            case 3:
                res = yield call(widgetAPI.getCounter, { widgetID, dateFrom, dateTo })
                break
            case 4:
                res = yield call(widgetAPI.getPieChart, { widgetID, dateFrom, dateTo })
                break
            case 5:
                res = yield call(widgetAPI.getBarChart, { widgetID, dateFrom, dateTo })
                break
            case 6:
                res = yield call(widgetAPI.getLineChart, { widgetID, dateFrom, dateTo })
                break
        }
        if (res) {
            yield put(setWidgetById(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(errorWidgetById(payload))
    }
}

function* fetchUpdateWidgetByIdWorker({ payload }: FetchUpdateWidgetByIdPayload) {
    const { onSuccess, ...body } = payload
    try {
        let res: WidgetResponseType | undefined
        switch (payload.widgetType.id) {
            case 1:
                res = yield call(widgetAPI.updateTextBlock, body as UpdateTextBody)
                break
            case 2:
                res = yield call(widgetAPI.updateTableWidget, body as UpdateWidgetTable)
                break
            case 3:
                res = yield call(widgetAPI.updateCounter, body as UpdateCounterBody)
                break
            case 4:
                res = yield call(widgetAPI.updatePieChart, body as UpdatePieChartBody)
                break
            case 5:
                res = yield call(widgetAPI.updateBarChart, body as UpdateBarChartBody)
                break
            case 6:
                res = yield call(widgetAPI.updateLineChart, body as UpdateLineChartBody)
                break
        }
        if (res) {
            yield put(setUpdateWidgetById(res))
            onSuccess?.()
        }
    } catch (err: any) {
        console.log(err)
        yield put(errorUpdateWidgetById(body))
    }
}
function* fetchMoveWidgetToDashboardWorker({ payload }: FetchMoveWidgetToDashboardPayload) {
    const { onSuccess, widgetType, currentDashboardID, ...body } = payload
    try {
        let res: WidgetResponseType | undefined
        switch (widgetType.id) {
            case 1:
                res = yield call(widgetAPI.updateTextBlock, {
                    ...body,
                })
                break
            case 2:
                res = yield call(widgetAPI.updateTableWidget, {
                    ...body,
                })
                break
            case 3:
                res = yield call(widgetAPI.updateCounter, {
                    ...body,
                })
                break
            case 4:
                res = yield call(widgetAPI.updatePieChart, {
                    ...body,
                })
                break
            case 5:
                res = yield call(widgetAPI.updateBarChart, {
                    ...body,
                })
                break
            case 6:
                res = yield call(widgetAPI.updateLineChart, {
                    ...body,
                })
                break
        }

        if (res) {
            yield put(
                setMoveWidgetToDashboard({
                    oldDashboardID: currentDashboardID,
                    newDashboardID: res.dashboardID,
                    widget: res,
                }),
            )
            onSuccess?.()
        }
    } catch (err: any) {
        console.log(err)
        yield put(errorMoveWidgetToDashboard(payload))
    }
}
function* fetchWidgetTableDataWorker({ payload }: { payload: getWidgetsTableDataArgs }) {
    try {
        const res: WidgetTableDataType = yield call(widgetAPI.getWidgetsTableData, payload)
        if (res) {
            yield put(setWidgetsTableData(res))
        }
    } catch (err: any) {
        console.log(err)
        yield put(errorWidgetsTableData())
    }
}

function* fetchDeleteWidgetByIdWorker({ payload }: FetchDeleteWidgetByIdPayload) {
    const { onSuccess, widgetID, dashboardID } = payload
    try {
        const res: string = yield call(widgetAPI.deleteWidgetById, { widgetID })
        if (res) {
            yield put(setDeleteWidgetById({ widgetID, dashboardID }))
            onSuccess?.()
        }
    } catch (err: any) {
        console.log(err)
        yield put(errorDeleteWidgetById())
    }
}

export default function* widgetsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeEvery(fetchDashboardWidgetsList, fetchDashboardWidgetsListWorker),
        takeLatest(fetchCreateWidgetByType, fetchCreateWidgetByTypeWorker),
        takeEvery(fetchCreateWidgetByTypeForDasboardCopy, fetchCreateWidgetByTypeWorker),
        takeEvery(fetchWidgetById, fetchWidgetsByIDWorker),
        takeLatest(fetchUpdateWidgetById, fetchUpdateWidgetByIdWorker),
        takeEvery(fetchUpdateWidgetByIdAllRequests, fetchUpdateWidgetByIdWorker),
        takeLatest(fetchMoveWidgetToDashboard, fetchMoveWidgetToDashboardWorker),
        takeLatest(fetchWidgetTableData, fetchWidgetTableDataWorker),
        takeLatest(fetchDeleteWidgetById, fetchDeleteWidgetByIdWorker),
    ])
}
