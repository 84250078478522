import React, { type FC } from 'react'
import styles from './style.module.scss'
import { Table } from 'antd'
import type { AccessRightsTableProps } from './AccesRights.types'

export const AccessRightsTable: FC<AccessRightsTableProps> = ({ cols, tableData }) => {
    return (
        <div className={styles.tableRoot}>
            <div className={styles.tableContainer}>
                <Table
                    columns={cols}
                    dataSource={tableData}
                    size='middle'
                    pagination={false}
                    rowKey={record => record.key as string}
                    rowClassName={record => (record.role === 'admin' ? styles.adminRow : '')}
                />
            </div>
        </div>
    )
}
