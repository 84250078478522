import { useTranslation } from 'react-i18next'
import type { LogicOperatorOptionsType } from '../FilterPanel.types'

export const useLogicOperators = () => {
    const { t } = useTranslation()
    const logicOperatorOptions: LogicOperatorOptionsType[] = [
        {
            value: 'all',
            label: t('logic_operator_all'),
        },
        {
            value: 'any',
            label: t('logic_operator_any'),
        },
    ]
    return logicOperatorOptions
}
