import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@src/components'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import type { WidgetType } from '@src/api/dictionary/dictionary.types'
import { CreateWidgetTypes } from '@src/components/CreateWidget/CreateWidgetTypes/CreateWidgetTypes'
import { setSetupWidgetPageData } from '@src/store/widgets/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'

export const EmptyWidget: FC<{ x: number; y: number; isLocked?: boolean }> = ({ x, y, isLocked }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { activeDashboard } = useDashboard()
    const handleCreateWidget = (widgetType: WidgetType) => {
        if (activeDashboard?.id)
            dispatch(
                setSetupWidgetPageData({
                    dashboardID: activeDashboard.id,
                    widgetType,
                    positionX: x,
                    positionY: y,
                    cellsX: 1,
                    cellsY: 1,
                }),
            )
    }

    return (
        <>
            {!isLocked && (
                <div className={styles.root}>
                    <div className={styles.plusIcon}>
                        <PlusIcon />
                    </div>
                    <p className={styles.title}>{t('widgets.empty.add')}</p>
                    <CreateWidgetTypes className={styles.row} onClick={handleCreateWidget} />
                </div>
            )}
        </>
    )
}
