import type { Localization } from '@src/api/userinterface/userinterface.types'
import { Locales } from '@src/helpers/locales.enum'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { appStateActions } from '@src/store/appState/slice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
type lang = {
    code: Localization
    title: string
}
export const useData = () => {
    const languages = [
        { code: 'uk_UA', title: 'Українська' },
        { code: 'en_US', title: 'English' },
    ] as lang[]
    const { i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale) ?? 'uk_UA'

    const [activeSubMenu, setActiveSubMenu] = useState(false)
    const [currentLangName, setCurrentLangName] = useState('')

    useEffect(() => {
        const langName = locale === 'en_US' ? 'English' : 'Українська'
        const langCode = Locales[locale]
        setCurrentLangName(langName)
        i18n.changeLanguage(langCode)
    }, [locale])

    const handleChangeLang = (langCode: Localization) => {
        dispatch(appStateActions.fetchUpdateUISettings({ locale: langCode }))
    }

    return {
        languages,
        locale,
        activeSubMenu,
        setActiveSubMenu,
        currentLangName,
        handleChangeLang,
    }
}
