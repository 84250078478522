import { HighlightText } from '@src/components/HighlightText/HighlightText'
import type { useDataWidgetDataModel } from './WidgetDataModel.types'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import dayjs from 'dayjs'
import type { ColumnsType } from 'antd/es/table'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { fetchWidgetTableData } from '@src/store/widgets/slice'
import { DATE_FORMAT, DATE_FORMAT_FULL_WITH_TIME, DATE_FORMAT_ISO_PARSE } from '@src/config/constants'
import { utils, writeFile } from 'xlsx'
import type { SelectListValue } from '@src/components/StyleGuide/Ui/SelectList/SelectList.types'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import type { CalendarDatesPeriod } from '@src/components/PopupCalendar/PopupCalendar.types'

export const useData = ({ widgetID, dashboardID, isOpen }: useDataWidgetDataModel) => {
    const dispatch = useAppDispatch()
    const dataTables = useAppSelector(state => state.widgets.widgetTableData.data)
    const [rows, setRows] = useState<Array<Record<string, string>>>([])
    const [cols, setCols] = useState<ColumnsType<Record<string, string>>>([])
    const [searchValue, setSearchValue] = useState('')
    const { findPeriodById, getPeriodByPeriodId } = usePeriod()
    const { currentWidget, updateWidget } = useWidget({ widgetID, dashboardID })

    const createRows = useCallback(
        () =>
            dataTables?.rows?.map(row => {
                const rowData: Record<string, string> = {}
                row.forEach(cell => {
                    rowData[cell.fieldID] = cell.value
                })
                return rowData
            }) ?? [],
        [dataTables],
    )
    useEffect(() => {
        const createdRows = createRows()
        setRows(createdRows)
    }, [createRows])

    useEffect(() => {
        const createCols =
            dataTables?.fields?.map((field, i) => {
                return {
                    title: <HighlightText text={field.name} searchInput={searchValue} />,
                    dataIndex: field.id,
                    key: field.id,
                    sorter: (a: any, b: any) => {
                        const valueA = a[field.id]
                        const valueB = b[field.id]
                        const numberA = Number.parseFloat(valueA)
                        const numberB = Number.parseFloat(valueB)
                        if (
                            dayjs(valueA, DATE_FORMAT_ISO_PARSE).isValid() &&
                            dayjs(valueB, DATE_FORMAT_ISO_PARSE).isValid()
                        ) {
                            return dayjs(valueB).isBefore(dayjs(valueA)) ? 1 : -1
                        }
                        if (numberA && numberB) {
                            return numberA - numberB
                        }
                        return +(valueA > valueB) - +(valueA < valueB)
                    },
                    render: (text: string) => {
                        return (
                            <HighlightText
                                text={
                                    text && dayjs.utc(text, DATE_FORMAT_ISO_PARSE).isValid()
                                        ? dayjs.utc(text).format(DATE_FORMAT_FULL_WITH_TIME)
                                        : text ?? ''
                                }
                                searchInput={searchValue}
                            />
                        )
                    },
                }
            }) ?? []
        setCols(createCols)
    }, [dataTables, rows, searchValue])

    useEffect(() => {
        const flatDataID = currentWidget?.widget?.flatDataID
        if (isOpen && flatDataID) {
            dispatch(fetchWidgetTableData({ widgetID, flatDataID }))
        }
    }, [isOpen, currentWidget?.widget?.flatDataID])

    const dragProps = {
        onDragEnd(fromIndex: number, toIndex: number) {
            const newColumns = [...cols]
            const item = newColumns.splice(fromIndex, 1)[0]
            newColumns.splice(toIndex, 0, item)
            setCols(newColumns)
        },
        nodeSelector: 'th',
        handleSelector: '.ant-table-cell',
    }

    const onChangePeriod = (value: SelectListValue) => {
        const periodType = findPeriodById(value as number)
        const { fromToPeriod } = getPeriodByPeriodId(value as number)
        if (currentWidget?.widget?.periodType?.id !== value) updateWidget({ periodType, ...fromToPeriod })
    }

    const widgetName = currentWidget?.listData?.name ?? ''

    const downloadExcelFile = useCallback(() => {
        const downloadTime = dayjs().format(DATE_FORMAT_FULL_WITH_TIME)
        const name = currentWidget?.listData?.name ?? ''
        const dataForDownloading =
            dataTables?.rows?.map(row => {
                const rowData: Record<string, string> = {}
                dataTables?.fields?.forEach(field => {
                    const value = row.find(elem => elem.fieldID === field.id)?.value
                    rowData[field.name] =
                        value && dayjs.utc(value, DATE_FORMAT_ISO_PARSE).isValid()
                            ? dayjs.utc(value).format(DATE_FORMAT_FULL_WITH_TIME)
                            : value ?? ''
                })
                return rowData
            }) ?? []
        const ws = utils.json_to_sheet(dataForDownloading)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Data')
        writeFile(wb, `${name}_${downloadTime}.xlsx`)
    }, [dataTables, currentWidget])

    const handleDatePeriodChange = (dates: CalendarDatesPeriod) => {
        updateWidget(dates)
    }

    return {
        widgetName,
        dragProps,
        setSearchValue,
        onChangePeriod,
        downloadExcelFile,
        handleDatePeriodChange,
        cols,
        rows,
    }
}
