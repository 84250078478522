import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    WidgetsInitialState,
    FetchWidgetByIdPayload,
    FetchDashboardWidgetsListPayload,
    FetchCreateWidgetByType,
    FetchDeleteWidgetByIdPayload,
    FetchDeleteWidget,
    SetupWidget,
    WidgetResponseType,
    WidgetItem,
    FetchMoveWidgetToDashboardPayload,
    SetMoveWidgetToDashboard,
    FetchUpdateWidgetById,
    FetchGetWidgetById,
    TemporaryDates,
    SetDashboardWidgetsList,
} from './widgets.types'
import type { WidgetListResponse, WidgetTableDataType } from '@src/api/widgets/widgets.types'
import type { RequestDashboardID } from '@src/api/dashboardApi/dashboard.types'

const initialState: WidgetsInitialState = {
    widgetsByDashboards: {},
    temporaryWidgetsDates: null,
    setupWidgetPage: {},
    widgetTableData: {},
}

export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        fetchDashboardWidgetsList: (state, { payload }: PayloadAction<FetchDashboardWidgetsListPayload['payload']>) => {
            state.widgetsByDashboards[payload.dashboardID] = {
                isLoadingDashboardWidgets: true,
                isErrorDashboardWidgets: false,
                widgets: state.widgetsByDashboards[payload.dashboardID]?.widgets ?? [],
            }
        },
        setDashboardWidgetsList: (state, { payload }: PayloadAction<SetDashboardWidgetsList>) => {
            if (payload.dashboardID) {
                const dashboard = state.widgetsByDashboards[payload.dashboardID]
                dashboard.isLoadingDashboardWidgets = false
                dashboard.widgets = payload.res.map(elem => ({
                    id: elem.id,
                    isLoadingWidget: true,
                    listData: elem,
                }))
            }
        },
        errorDashboardWidgetsList: (state, { payload }: PayloadAction<RequestDashboardID>) => {
            const dashboard = state.widgetsByDashboards[payload.dashboardID]
            dashboard.isErrorDashboardWidgets = true
            dashboard.isLoadingDashboardWidgets = false
        },

        fetchCreateWidgetByTypeForDasboardCopy: (
            state,
            { payload }: PayloadAction<FetchCreateWidgetByType['payload']>,
        ) => {
            if (!state.widgetsByDashboards[payload.dashboardID])
                state.widgetsByDashboards[payload.dashboardID] = {
                    widgets: [],
                }
        },
        fetchCreateWidgetByType: (state, { payload }: PayloadAction<FetchCreateWidgetByType['payload']>) => {
            state.setupWidgetPage.isLoadingCreateWidget = true
            if (!state.widgetsByDashboards[payload.dashboardID])
                state.widgetsByDashboards[payload.dashboardID] = {
                    widgets: [],
                }
        },
        setCreateWidgetByType: (state, { payload }: PayloadAction<WidgetResponseType>) => {
            state.setupWidgetPage.isLoadingCreateWidget = false
            const dashboard = state.widgetsByDashboards[payload.dashboardID]
            state.setupWidgetPage.id = payload.id
            const newWidget: WidgetItem = {
                id: payload.id,
                listData: {
                    id: payload.id,
                    dashboardID: payload.dashboardID,
                    widgetType: payload.widgetType,
                    name: payload.name,
                    dataSourceType: payload.dataSourceType,
                    dataset: payload.dataset,
                    positionX: payload.positionX,
                    positionY: payload.positionY,
                    cellsX: payload.cellsX,
                    cellsY: payload.cellsY,
                    deniedFields: payload.deniedFields,
                },
                widget: payload,
            }
            dashboard.widgets = [...dashboard.widgets, newWidget]
        },
        errorCreateWidgetByType: state => {
            state.setupWidgetPage.isLoadingCreateWidget = false
            state.setupWidgetPage.isErrorCreateWidget = true
        },

        fetchWidgetById: (state, { payload }: PayloadAction<FetchWidgetByIdPayload['payload']>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.widgetID
                    ? {
                          ...elem,
                          isLoadingWidget: true,
                          isErrorWidget: false,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        setWidgetById: ({ widgetsByDashboards }, { payload }: PayloadAction<WidgetResponseType>) => {
            const widgets = widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets: WidgetItem[] = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isLoadingWidget: false,
                          widget: payload,
                      }
                    : elem,
            )

            widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        errorWidgetById: (state, { payload }: PayloadAction<FetchGetWidgetById>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.widgetID
                    ? {
                          ...elem,
                          isLoadingWidget: false,
                          isErrorWidget: true,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        fetchUpdateWidgetById: (state, { payload }: PayloadAction<FetchUpdateWidgetById>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isErrorUpdateWidget: false,
                          isLoadingUpdateWidget: true,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        setUpdateWidgetById: (state, { payload }: PayloadAction<WidgetResponseType>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const isTempDate = !!state.temporaryWidgetsDates
            const newWidgets = widgets.map(widget => {
                const newWidgetData = (widget.widget as any)?.data
                    ? { ...payload, data: (widget.widget as any).data }
                    : payload
                return widget.id === payload.id
                    ? {
                          ...widget,
                          isLoadingUpdateWidget: false,
                          listData: {
                              id: payload.id,
                              dashboardID: payload.dashboardID,
                              widgetType: payload.widgetType,
                              name: payload.name,
                              dataSourceType: payload.dataSourceType,
                              dataset: payload.dataset,
                              positionX: payload.positionX,
                              positionY: payload.positionY,
                              cellsX: payload.cellsX,
                              cellsY: payload.cellsY,
                              deniedFields: payload.deniedFields,
                          },
                          widget: isTempDate ? newWidgetData : payload,
                      }
                    : widget
            })

            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        errorUpdateWidgetById: (state, { payload }: PayloadAction<FetchUpdateWidgetById>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isLoadingUpdateWidget: false,
                          isErrorUpdateWidget: true,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },
        fetchUpdateWidgetByIdAllRequests: (state, { payload }: PayloadAction<FetchUpdateWidgetById>) => {
            const widgets = state.widgetsByDashboards[payload.dashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isErrorUpdateWidget: false,
                          isLoadingUpdateWidget: true,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.dashboardID].widgets = newWidgets
        },

        fetchMoveWidgetToDashboard: (
            state,
            { payload }: PayloadAction<FetchMoveWidgetToDashboardPayload['payload']>,
        ) => {
            const widgets = state.widgetsByDashboards[payload.currentDashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isLoadingUpdateWidget: true,
                          isErrorUpdateWidget: false,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.currentDashboardID].widgets = newWidgets
        },
        setMoveWidgetToDashboard: (state, { payload }: PayloadAction<SetMoveWidgetToDashboard>) => {
            const movedWidget = state.widgetsByDashboards[payload.oldDashboardID].widgets.find(
                elem => elem.id === payload.widget.id,
            )
            if (movedWidget) {
                const oldWidgets = state.widgetsByDashboards[payload.oldDashboardID].widgets
                const newWidgets: WidgetItem[] = [
                    ...state.widgetsByDashboards[payload.newDashboardID].widgets,
                    {
                        ...movedWidget,
                        isLoadingUpdateWidget: false,
                        listData: {
                            ...movedWidget.listData,
                            dashboardID: payload.newDashboardID,
                            positionX: payload.widget.positionX,
                            positionY: payload.widget.positionY,
                            cellsX: payload.widget.cellsX,
                            cellsY: payload.widget.cellsY,
                        },
                        widget: payload.widget,
                    },
                ]
                state.widgetsByDashboards[payload.oldDashboardID].widgets = oldWidgets.filter(
                    elem => elem.id !== payload.widget.id,
                )
                state.widgetsByDashboards[payload.newDashboardID].widgets = newWidgets
            }
        },
        errorMoveWidgetToDashboard: (
            state,
            { payload }: PayloadAction<FetchMoveWidgetToDashboardPayload['payload']>,
        ) => {
            const widgets = state.widgetsByDashboards[payload.currentDashboardID].widgets
            const newWidgets = widgets.map(elem =>
                elem.id === payload.id
                    ? {
                          ...elem,
                          isLoadingUpdateWidget: false,
                          isErrorUpdateWidget: true,
                      }
                    : elem,
            )
            state.widgetsByDashboards[payload.currentDashboardID].widgets = newWidgets
        },
        fetchDeleteWidgetById: (state, { payload }: PayloadAction<FetchDeleteWidgetByIdPayload['payload']>) => {
            state.isLoadingDeleteWidget = true
            state.isErrorDeleteWidget = false
        },
        setDeleteWidgetById: (state, { payload }: PayloadAction<FetchDeleteWidget>) => {
            state.isLoadingDeleteWidget = false
            if (state.widgetsByDashboards[payload.dashboardID]?.widgets)
                state.widgetsByDashboards[payload.dashboardID].widgets = state.widgetsByDashboards[
                    payload.dashboardID
                ].widgets.filter(widget => widget.id !== payload.widgetID)
        },
        errorDeleteWidgetById: state => {
            state.isLoadingDeleteWidget = false
            state.isErrorDeleteWidget = true
        },
        fetchWidgetTableData: (state, action) => {
            state.widgetTableData.isErrorWidgetTableData = false
            state.widgetTableData.isLoadingWidgetTableData = true
            state.widgetTableData.data = {}
        },
        setWidgetsTableData: (state, { payload }: PayloadAction<WidgetTableDataType>) => {
            state.widgetTableData.data = payload
            state.widgetTableData.isErrorWidgetTableData = false
            state.widgetTableData.isLoadingWidgetTableData = false
        },
        errorWidgetsTableData: state => {
            state.widgetTableData.isErrorWidgetTableData = true
            state.widgetTableData.isLoadingWidgetTableData = false
            state.widgetTableData.data = {}
        },
        // local actions
        setSetupWidgetPageData: (state, { payload }: PayloadAction<SetupWidget>) => {
            state.setupWidgetPage = { ...payload }
        },
        deleteSetupWidgetPageData: state => {
            state.setupWidgetPage = {}
        },
        setTemporaryWidgetsDates: (state, { payload }: PayloadAction<TemporaryDates | null>) => {
            state.temporaryWidgetsDates = payload
        },
    },
})

export const {
    fetchWidgetTableData,
    setWidgetsTableData,
    errorWidgetsTableData,
    fetchDashboardWidgetsList,
    setDashboardWidgetsList,
    errorDashboardWidgetsList,
    fetchWidgetById,
    setWidgetById,
    errorWidgetById,
    fetchUpdateWidgetById,
    fetchUpdateWidgetByIdAllRequests,
    setUpdateWidgetById,
    errorUpdateWidgetById,
    fetchMoveWidgetToDashboard,
    setMoveWidgetToDashboard,
    errorMoveWidgetToDashboard,
    fetchDeleteWidgetById,
    setDeleteWidgetById,
    errorDeleteWidgetById,
    fetchCreateWidgetByTypeForDasboardCopy,
    fetchCreateWidgetByType,
    deleteSetupWidgetPageData,
    setCreateWidgetByType,
    errorCreateWidgetByType,
    setSetupWidgetPageData,
    setTemporaryWidgetsDates,
} = widgetsSlice.actions
export default widgetsSlice.reducer
