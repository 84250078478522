import React, { useEffect, type FC, useMemo } from 'react'
import styles from './styles.module.scss'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { useTranslation } from 'react-i18next'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import type { MoveWidgetBodyProps } from './MoveWidgetModal.types'

export const MoveWidgetModalBody: FC<MoveWidgetBodyProps> = ({
    widgetName,
    dashboardID,
    isCantMoveError,
    isWillReduceWidgetSizeWarning,
    setDashboardID,
}) => {
    const { t } = useTranslation()
    const { notSharedDashboardsOptions, dashboardListOptions, dashboardID: ID, isUserAdminInDashboard } = useDashboard()

    const filterOptions = useMemo(
        () =>
            (isUserAdminInDashboard() ? dashboardListOptions : notSharedDashboardsOptions).filter(
                elem => elem.value !== ID,
            ),
        [notSharedDashboardsOptions, dashboardListOptions, ID],
    )

    useEffect(() => {
        const firstOption = filterOptions?.[0]
        if (firstOption && !dashboardID) setDashboardID(firstOption.value)
    }, [filterOptions])

    const onChange = (value: number | string) => {
        setDashboardID(value as number)
    }
    const style = isCantMoveError ? styles.modalWarning : isWillReduceWidgetSizeWarning ? styles.modalInfo : ''

    return (
        <div className={styles.modalBodyContent}>
            <div className={styles.infoContainer}>
                <p className={styles.infoContainer__title}>{t('move_widget_modal.widget_name')}</p>
                <p className={styles.infoContainer__body}>{widgetName}</p>
            </div>
            <div className={styles.selectContainer}>
                <p className={styles.selectContainer__title}>{t('move_widget_modal.choose_dashboard')}</p>
                <SelectList
                    selectedValue={dashboardID}
                    onChange={onChange}
                    options={filterOptions}
                    placeholder={'Оберіть значення'}
                />
            </div>
            <div className={style}>
                {isCantMoveError && <p>{t('dashboard_control_panel.cantRaplaceWidget.warning')}</p>}
                {isWillReduceWidgetSizeWarning && <p>{t('dashboard_control_panel.cantRaplaceWidget.info')}</p>}
            </div>
        </div>
    )
}
