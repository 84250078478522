import axiosInstance from '@src/services/axios'
import type {
    AggregateFunctionTypeListResponse,
    AutoUpdateIntervalsResponse,
    CommandTypeListResponse,
    CompareFunctionTypeListResponse,
    DashboardTypeListResponse,
    DatasourceTypeListResponse,
    FieldTypeListResponse,
    FormTypeListResponse,
    PeriodTypeListResponse,
    SortTypeListResponse,
    TimeAxisTypeListResponse,
    WidgetTypeListResponse,
} from './dictionary.types'
import { dictionaryUrlConstants } from './dictionaryConst.enum'

export const dictionaryApi = {
    getDashboardTypes: async () => {
        const { data } = await axiosInstance.get<DashboardTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.DASHBOARD_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getWidgetTypes: async () => {
        const { data } = await axiosInstance.get<WidgetTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.WIDGET_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getPeriodTypes: async () => {
        const { data } = await axiosInstance.get<PeriodTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.PERIOD_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getAggregateFunctionTypes: async () => {
        const { data } = await axiosInstance.get<AggregateFunctionTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.AGGREGATE_FUNCTION_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getCompareFunctionTypes: async () => {
        const { data } = await axiosInstance.get<CompareFunctionTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.COMPARE_FUNCTION_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getSortTypes: async () => {
        const { data } = await axiosInstance.get<SortTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.SORT_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getTimeAxisTypes: async () => {
        const { data } = await axiosInstance.get<TimeAxisTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.TIME_AXIS_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getDatasourceTypes: async () => {
        const { data } = await axiosInstance.get<DatasourceTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.DATASOURCE_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getFieldTypes: async () => {
        const { data } = await axiosInstance.get<FieldTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.FIELD_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getCommandTypes: async () => {
        const { data } = await axiosInstance.get<CommandTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.COMMAND_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getFormTypes: async () => {
        const { data } = await axiosInstance.get<FormTypeListResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.FORM_TYPE_URL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
    getAutoUpdateInterval: async () => {
        const { data } = await axiosInstance.get<AutoUpdateIntervalsResponse>(
            `${dictionaryUrlConstants.DICTIONARY_URL}${dictionaryUrlConstants.AUTO_UPDATE_INTERVAL}${dictionaryUrlConstants.LIST_URL}`,
        )
        return data
    },
}
