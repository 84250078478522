import { authApi } from '@src/api/auth/auth.api'
import { type AuthResponse } from '@src/api/auth/auth.types'
import { type AllEffect, type ForkEffect, call, put, all, takeLatest } from 'redux-saga/effects'
import { userActions } from './slice'
import { localStorageKeys } from '@src/config/localStorageKeys.enum'

function* authorizationWorker() {
    try {
        const res: AuthResponse = yield call(authApi.authorize)
        if (res) {
            yield put(userActions.setAuthorization(res))
        }
    } catch (err: any) {
        const error = err.response.data.error
        localStorage.removeItem(localStorageKeys.userData)
        yield put(userActions.errorAuthorization(error))
    }
}

export default function* authorizationSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([takeLatest(userActions.fetchAuthorization, authorizationWorker)])
}
