import React, { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from '@src/layouts'
import styles from '@src/components/Modals/styles.module.scss'
import { ButtonPrimary, CancelIcon, CheckBox, InfoIcon, UnViewIcon } from '@src/components'
import type { HideDashboardModalProps } from '@src/components/Modals/DashboardOptionsModals/@types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'

export const ModalHide: FC<HideDashboardModalProps> = ({ dashboardTitle, setModalOpen, onAccept }) => {
    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    // State for remember checkbox
    const [isRemember, setIsRemember] = useState(false)

    const handleRemember = () => {
        setIsRemember(prev => !prev)
    }

    const handleCancel = () => {
        setModalOpen(false)
    }
    const handleOk = () => {
        onAccept()
        if (isRemember) dispatch(appStateActions.setLocalUISettings({ dontAskAgainHideModal: isRemember }))
    }

    return (
        <ModalLayout setIsModalOpen={setModalOpen} title={t('dashboard_control_panel.hide_modal.title')}>
            <div className={styles.root}>
                <div className={styles.wrap}>
                    <p className={styles.titlePlaceholder}>{t('dashboard_control_panel.modals_placeholder')}</p>
                    <p className={styles.title}>{dashboardTitle}</p>
                </div>
                <div className={styles.checkWrap}>
                    <CheckBox
                        isChecked={isRemember}
                        onChange={handleRemember}
                        label={t('dashboard_control_panel.hide_modal.check')}
                    />
                </div>
                <p className={`${styles.desc} ${styles.info}`}>{t('dashboard_control_panel.hide_modal.message')}</p>
                <div className={`${styles.wrap} ${styles.withIcon} ${styles.withExample}`}>
                    <InfoIcon />
                    <p>{t('dashboard_control_panel.hide_modal.warning')}</p>
                    <div className={styles.exampleIcon}>
                        <UnViewIcon />
                    </div>
                </div>
                <div className={styles.btnWrap}>
                    <ButtonPrimary
                        className={styles.btnUnView}
                        icon={<UnViewIcon />}
                        title={t('dashboard_control_panel.hide_modal.hide_btn')}
                        type='primary'
                        onClick={handleOk}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('dashboard_control_panel.modal_cancel_btn')}
                        type='transparent'
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}
