import React, { useState, type FC, useEffect } from 'react'
import { WidgetMenuActions } from './WidgetMenuActions'
import { useActions } from '@src/hooks/useActions/useAction'
import type { WidgetMenuCommandsProps } from './WidgetMenuCommands.type'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { Form } from '@src/api/actions/actions.types'
import type { WidgetPinnedForm } from '@src/api/widgets/widgets.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { CantPinMoreItemsModal } from '@src/components/Modals/CantPinMoreItemsModal/CantPinMoreItemsModal'
import { IFrameFormModal } from '@src/components/Modals/IFrameFormModal/IFrameFormModal'
import { useTranslation } from 'react-i18next'

export const WidgetMenuForms: FC<WidgetMenuCommandsProps> = ({ widgetID, dashboardID }) => {
    const { t } = useTranslation()
    const [openedForm, setOpenedForm] = useState<Form | null>(null)
    const [isOpenModalLimitPinned, setIsOpenModalLimitPinned] = useState(false)
    const [isAbleToPin, setIsAbleToPin] = useState(false)
    const [pinnedForms, setPinnedForms] = useState<WidgetPinnedForm[]>([])
    const { widgetTypeID, currentWidget, updateWidget } = useWidget({ widgetID, dashboardID })
    const { getFormsListByWidgetType } = useActions()
    const fixedWigetsCount = useAppSelector(({ appState }) => appState.UISettings?.fixedWigetsCount)

    useEffect(() => {
        setPinnedForms(currentWidget?.widget?.pinnedForms ?? [])
    }, [currentWidget?.widget?.pinnedForms])
    useEffect(() => {
        setIsAbleToPin(
            (currentWidget?.widget?.pinnedCommands?.length ?? 0) + (currentWidget?.widget?.pinnedForms?.length ?? 0) <
                (fixedWigetsCount ?? 3),
        )
    }, [currentWidget?.widget?.pinnedCommands, currentWidget?.widget?.pinnedForms, fixedWigetsCount])
    const handlePinAction = (formID: number) => {
        const copyPinnedForms = [...pinnedForms]
        const formsByType = getFormsListByWidgetType(widgetTypeID)
        const form = formsByType.find(elem => elem.id === formID)
        const isFormPinned = copyPinnedForms.some(elem => elem.form.id === formID)
        if (isFormPinned) {
            const removeForm = copyPinnedForms.filter(elem => elem.form.id !== formID)
            setPinnedForms(removeForm)
            updateWidget({ pinnedForms: removeForm })
        }
        if (!isFormPinned && form && isAbleToPin) {
            copyPinnedForms.push({ form })
            setPinnedForms(copyPinnedForms)
            updateWidget({ pinnedForms: copyPinnedForms })
        }
        if (!isFormPinned && form && !isAbleToPin) {
            setIsOpenModalLimitPinned(true)
        }
    }

    const handleOpenForm = (formID: number) => {
        const formsByType = getFormsListByWidgetType(widgetTypeID)
        const form = formsByType.find(elem => elem.id === formID)
        if (form) {
            setOpenedForm(form)
        }
    }
    return (
        <>
            {currentWidget && (
                <WidgetMenuActions
                    list={getFormsListByWidgetType(widgetTypeID)}
                    title={t('forms')}
                    pinnedList={pinnedForms}
                    onClick={handleOpenForm}
                    onPin={handlePinAction}
                />
            )}
            {isOpenModalLimitPinned && (
                <CantPinMoreItemsModal
                    isOpen={isOpenModalLimitPinned}
                    onCancel={() => setIsOpenModalLimitPinned(false)}
                />
            )}
            {!!openedForm && (
                <IFrameFormModal isOpen={!!openedForm} onCancel={() => setOpenedForm(null)} form={openedForm} />
            )}
        </>
    )
}
