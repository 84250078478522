import React, { type FC } from 'react'
import styles from '@src/components/SystemSettings/styles.module.scss'
import { ArrowRoundedIcon, LanguageIcon } from '@src/components'
import { useTranslation } from 'react-i18next'
import { useData } from './useData'

export const LangSetting: FC = () => {
    const { t } = useTranslation()
    const { languages, locale, activeSubMenu, setActiveSubMenu, currentLangName, handleChangeLang } = useData()

    return (
        <div
            className={styles.item}
            onMouseEnter={() => setActiveSubMenu(true)}
            onMouseLeave={() => setActiveSubMenu(false)}
        >
            <div className={styles.itemIcon}>
                <LanguageIcon />
            </div>
            <div className={styles.itemWrap}>
                <p className={styles.itemTitle}>{t('system_settings.lang_title')}</p>
                <p className={styles.itemValue}>{currentLangName}</p>
            </div>
            <div className={styles.itemArrow}>
                <ArrowRoundedIcon />
            </div>
            {activeSubMenu && (
                <ul className={styles.itemInclude}>
                    {languages.map(({ code, title }) => (
                        <li
                            key={code}
                            onClick={() => handleChangeLang(code)}
                            className={locale === code ? styles.active : ''}
                        >
                            <p>{title}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
