import { useState, useRef, useEffect, type RefObject, useLayoutEffect } from 'react'

interface ResizableDivProps {
    resizaHandlerRef: RefObject<HTMLDivElement>
    resizeElementRef: RefObject<HTMLDivElement>
    width: number | undefined
    height: number | undefined
}

export const useResizableDiv = (): ResizableDivProps => {
    const resizaHandlerRef = useRef<HTMLDivElement>(null)
    const resizeElementRef = useRef<HTMLDivElement | null>(null)
    const [width, setWidth] = useState<number>()
    const [height, setHeight] = useState<number>()
    const [isResizing, setIsResizing] = useState<boolean>(false)
    const [resizeStartX, setResizeStartX] = useState<number>(0)
    const [resizeStartY, setResizeStartY] = useState<number>(0)
    useLayoutEffect(() => {
        setWidth(resizeElementRef?.current?.clientWidth)
        setHeight(resizeElementRef?.current?.clientHeight)
    }, [resizeElementRef?.current])

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            event.preventDefault()
            setIsResizing(true)
            setResizeStartX(event.clientX)
            setResizeStartY(event.clientY)
        }

        const handleMouseUp = () => {
            setIsResizing(false)
        }

        const handleMouseMove = (event: MouseEvent) => {
            if (!isResizing) return
            if (width && height) {
                const newWidth = width + (event.clientX - resizeStartX)
                const newHeight = height + (event.clientY - resizeStartY)
                setWidth(newWidth)
                setHeight(newHeight)
                setResizeStartX(event.clientX)
                setResizeStartY(event.clientY)
            }
        }

        resizaHandlerRef.current?.addEventListener('mousedown', handleMouseDown)
        window.addEventListener('mouseup', handleMouseUp)
        window.addEventListener('mousemove', handleMouseMove)

        return () => {
            resizaHandlerRef.current?.removeEventListener('mousedown', handleMouseDown)
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [isResizing, height, width])

    return { resizaHandlerRef, resizeElementRef, width, height }
}
