import React, { useMemo, type FC } from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useTranslation } from 'react-i18next'
import type { CustomTooltipProps } from './CustomTooltip.types'

export const CustomTooltip: FC<CustomTooltipProps> = ({ tooltip, widgetData }) => {
    const lang = useAppSelector(({ appState }) => appState.UISettings?.locale)
    const { t } = useTranslation()
    const widgetDataTips = useMemo(() => {
        if (tooltip.active && tooltip.payload?.length) {
            return tooltip.payload.map(elem => {
                if (elem.dataKey) {
                    const fieldData = widgetData[elem.dataKey]
                    return (
                        <div key={elem.dataKey} className={styles.tooltipItem}>
                            <div className={styles.rowWrapper}>
                                <span className={styles.field}>{t('create_widget.settings.field_function_label')}</span>
                                <span className={styles.value}>{fieldData?.aggregateFunctionType?.name}</span>
                            </div>
                            <div className={styles.rowWrapper}>
                                <span style={{ color: elem.color }} className={styles.field}>
                                    {fieldData?.dataField?.name}
                                </span>
                                <span className={styles.value}>{elem.value}</span>
                            </div>
                            <div className={styles.divider} />
                        </div>
                    )
                }
                return null
            })
        }
        return null
    }, [widgetData, tooltip, lang])
    return (
        <div className={styles.tooltipContainer}>
            <p className={styles.title}>{tooltip.label}</p>
            {widgetDataTips}
        </div>
    )
}
