import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { ApplyIcon, ButtonPrimary, SuccessIcon } from '@src/components/StyleGuide'
import { useTranslation } from 'react-i18next'
import type { ActionExecuteResultModalProps } from './ActionExecuteResultModal.type'

export const ActionExecuteResultModal: FC<ActionExecuteResultModalProps> = ({ isOpen, onCancel, commandName }) => {
    const { t } = useTranslation()
    return (
        <AntModalLayout isOpen={isOpen} onCancel={onCancel}>
            <div className={styles.content}>
                <SuccessIcon />
                <p className={styles.text}>
                    {t('commands.command')} <span className={styles.commandName}>{commandName}</span>{' '}
                    {t('commands.executed')}
                </p>
                <ButtonPrimary
                    className={styles.button}
                    icon={<ApplyIcon stroke='white' />}
                    title={t('widgets.popup.close')}
                    type='primary'
                    onClick={onCancel}
                />
            </div>
        </AntModalLayout>
    )
}
