import React, { type FC, useEffect, useState, useCallback, MouseEventHandler } from 'react'
import { Line, LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, ReferenceLine } from 'recharts'
import styles from './styles.module.scss'
import type { WidgetProps } from '../widgets.types'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { ChartField } from '@src/api/widgets/widgets.types'
import { DATE_FORMAT_FULL } from '@src/config/constants'
import dayjs from 'dayjs'
import { useTheme } from '@src/hooks'
import { CustomTooltip } from '../components/CustomTooltip/CustomTooltip'
import type { ChartFields } from '../components/CustomTooltip/CustomTooltip.types'
import { MouseTooltip } from '../components/MouseTooltip'
import { transformBigNumbersToshortString } from '@src/helpers/utils/transformBigNumbersToshortString'

export const LineChartWidget: FC<WidgetProps> = ({ widgetID, dashboardID }) => {
    const { theme } = useTheme()
    const { lineChartWidget } = useWidget({ widgetID, dashboardID })
    const isDateXAxis = lineChartWidget?.widget?.xAxisField?.fieldType?.id === 5
    const [data, setData] = useState<Array<Record<string, string | number>>>([])
    const [keys, setKeys] = useState<string[]>([])
    const [goal, setGoal] = useState<ChartField | null>(null)
    const [hoveredYTitle, setHoveredYTitle] = useState<Record<string, number> | null>(null)

    const fingWidgetFiels = useCallback(
        (id: number) => {
            const widgetFields = lineChartWidget?.widget?.widgetFields
            return widgetFields?.find(elem => elem.id === id)
        },
        [lineChartWidget?.widget?.widgetFields],
    )

    const getNamesOfAllfields = useCallback(() => {
        const keyNamePairs = keys.map(key => {
            const field = fingWidgetFiels(Number(key))
            return [key, field]
        })
        return Object.fromEntries(keyNamePairs) as ChartFields
    }, [keys, fingWidgetFiels])
    useEffect(() => {
        const newData =
            lineChartWidget?.widget?.data?.xAsisElements?.map(elem => {
                const newDataElement: Record<string, string | number> = {
                    name: isDateXAxis ? dayjs(elem.xAsisValue).format(DATE_FORMAT_FULL) : elem.xAsisValue,
                }
                elem.yAsisValues.forEach(item => {
                    newDataElement[item.widgetFieldID] = item.yAsisValue
                })
                return newDataElement
            }) ?? []
        setData(newData)
    }, [lineChartWidget?.widget?.data?.xAsisElements])
    useEffect(() => {
        const newKeys = Object.keys(data?.[0] ?? {}).filter(key => key !== 'name')
        setKeys(newKeys)
    }, [data])
    useEffect(() => {
        const fieldWithGoal = lineChartWidget?.widget?.widgetFields?.find(elem => elem.isGoalEnabled) ?? null
        setGoal(fieldWithGoal)
    }, [lineChartWidget?.widget?.widgetFields])

    const strokeColor = theme === 'light' ? 'hsla(220, 31%, 94%, 1)' : 'hsla(215, 21%, 30%, 1)'

    return (
        <>
            <ResponsiveContainer className={styles.root} debounce={20}>
                <LineChart data={data} className={styles.chart} defaultShowTooltip={false}>
                    {!lineChartWidget?.widget?.isLabelsVisible && (
                        <Tooltip
                            wrapperStyle={{ zIndex: 9 }}
                            content={state => <CustomTooltip tooltip={state} widgetData={getNamesOfAllfields()} />}
                        />
                    )}
                    <CartesianGrid
                        vertical={false}
                        strokeDasharray='0'
                        stroke={theme === 'light' ? 'hsla(220, 31%, 94%, 1)' : 'hsla(215, 21%, 30%, 1)'}
                    />
                    <XAxis
                        dataKey='name'
                        padding={{
                            left:
                                typeof data[0]?.name === 'string' && data[0]?.name?.length > 7
                                    ? data[0]?.name?.length * 1.5
                                    : 10,
                            right: 10,
                        }}
                        stroke={strokeColor}
                    />
                    <YAxis
                        width={37}
                        allowDecimals={false}
                        includeHidden
                        yAxisId='left'
                        orientation='left'
                        type='number'
                        domain={[
                            0,
                            (dataMax: number) =>
                                goal?.goal && goal.goal > dataMax
                                    ? Math.ceil(goal.goal * 1.2)
                                    : Math.ceil(dataMax * 1.25),
                        ]}
                        minTickGap={1}
                        tickFormatter={(value: number) => transformBigNumbersToshortString(value)}
                        scale='sequential'
                        stroke={strokeColor}
                        tick={{ fontSize: 14 }}
                        tickCount={7}
                        tickSize={0}
                        tickMargin={4}
                        onMouseEnter={(e: any) => setHoveredYTitle(e)}
                        onMouseLeave={() => setHoveredYTitle(null)}
                    />

                    {goal?.isGoalEnabled && (
                        <ReferenceLine
                            yAxisId='left'
                            stroke='#54C142'
                            strokeDasharray='0'
                            strokeWidth={1}
                            y={goal.goal ?? 0}
                            isFront
                        />
                    )}
                    {keys.map(key => (
                        <Line
                            key={key}
                            yAxisId='left'
                            type='linear'
                            dot={false}
                            dataKey={key}
                            stroke={fingWidgetFiels(+key)?.color || '#437EC3'}
                            strokeWidth='2'
                            label={
                                !!lineChartWidget?.widget?.isLabelsVisible && {
                                    position: 'top',
                                    className: styles.lineLabel,
                                    stroke: fingWidgetFiels(+key)?.color || '#437EC3',
                                }
                            }
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
            {hoveredYTitle && (hoveredYTitle?.value ?? 0) > 999 && (
                <MouseTooltip value={hoveredYTitle.value} coordinate={hoveredYTitle.coordinate} />
            )}
        </>
    )
}
