import { useCallback, useMemo } from 'react'
import { useAppSelector } from '../redux/useAppSelector'

type UseDatasource = {
    datasourceID: number | null
    clientID: number | null
    datasetID: string | null
}

export const useDatasource = (props?: UseDatasource) => {
    const datasourceList = useAppSelector(({ structures }) => structures.datasourceList)
    const datasourceType = useMemo(() => {
        return props?.datasourceID ? datasourceList[props.datasourceID]?.datasourceType : undefined
    }, [datasourceList, props?.datasourceID])
    const dataSourceOptions = useMemo(() => {
        const datasourcesId = Object.keys(datasourceList)
        return datasourcesId.map(id => ({
            label: datasourceList[+id].datasourceType.name,
            value: datasourceList[+id].datasourceType.id,
        }))
    }, [datasourceList])
    const adminDataSourceOptions = useMemo(() => {
        const datasourcesId = Object.keys(datasourceList)
        const adminDatasources = datasourcesId.filter(id =>
            datasourceList[+id]?.clients.some(client => client.isAdminAccess),
        )
        return adminDatasources.map(id => ({
            label: datasourceList[+id].datasourceType.name,
            value: datasourceList[+id].datasourceType.id,
        }))
    }, [datasourceList])
    const clientsList = useMemo(() => {
        return props?.datasourceID ? datasourceList[props.datasourceID]?.clients ?? [] : []
    }, [datasourceList, props?.datasourceID])

    const adminClientsList = useMemo(() => {
        return clientsList.filter(elem => elem.isAdminAccess)
    }, [clientsList])

    const adminClientsOptions = useMemo(() => {
        return adminClientsList.map(elem => ({
            label: elem.name,
            value: elem.id,
        }))
    }, [adminClientsList])
    const clientsListOptions = useMemo(() => {
        return clientsList.map(elem => ({
            label: elem.name,
            value: elem.id,
        }))
    }, [clientsList])
    const client = useMemo(() => {
        return clientsList.find(elem => elem.id === props?.clientID)
    }, [clientsList, props?.clientID])

    const datasets = useMemo(() => {
        return client?.datasets ?? []
    }, [client])
    const datasetsListOptions = useMemo(() => {
        return datasets?.map(elem => ({
            label: elem.name,
            value: elem.id,
        }))
    }, [datasets])

    const findDatasetByID = useCallback(
        (id: string | null | undefined) => {
            const fullDataset = datasets.find(elem => elem.id === id)
            if (fullDataset)
                return {
                    fullDataset,
                    datasetForRequest: {
                        id: fullDataset.id,
                        name: fullDataset.name,
                    },
                }
        },
        [datasets],
    )

    const currentDatasetFull = useMemo(() => {
        return findDatasetByID(props?.datasetID)?.fullDataset
    }, [datasets, props?.datasetID])
    const currentDatasetForRequest = useMemo(() => {
        return findDatasetByID(props?.datasetID)?.datasetForRequest
    }, [datasets, props?.datasetID])

    const adminUsers = useMemo(() => {
        const datasourcesId = Object.keys(datasourceList)
        const users = datasourcesId.flatMap(id =>
            datasourceList[+id].clients.flatMap(elem =>
                elem.isAdminAccess ? elem.users.map(user => ({ ...user, datasourceID: +id })) : [],
            ),
        )
        return users
    }, [datasourceList])
    const usersList = useMemo(() => {
        return client?.users ?? []
    }, [client])

    return {
        datasourceType,
        datasourceList,
        dataSourceOptions,
        adminDataSourceOptions,
        clientsList,
        clientsListOptions,
        adminClientsList,
        adminClientsOptions,
        client,
        datasets,
        datasetsListOptions,
        currentDatasetFull,
        currentDatasetForRequest,
        findDatasetByID,
        adminUsers,
        usersList,
    }
}
