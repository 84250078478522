import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../redux/useAppSelector'
import { CounterSettings } from '@src/components/CreateWidget/CreateWidgetSettings/CounterSettings/CounterSettings'
import {
    BarChartIcon,
    BarChartWidget,
    CounterIcon,
    CounterWidget,
    LineChartIcon,
    LineChartWidget,
    PieChartIcon,
    PieChartWidget,
    TableIcon,
    TableWidget,
    TextWidgetIcon,
} from '@src/components'
import type {
    FetchUpdateWidgetById,
    WidgetItem,
    WidgetItemBarChart,
    WidgetItemCounter,
    WidgetItemLineChart,
    WidgetItemPieChart,
    WidgetItemTable,
    WidgetItemTextBlock,
} from '@src/store/widgets/widgets.types'
import { useAppDispatch } from '../redux/useAppDispatch'
import { fetchUpdateWidgetById, fetchUpdateWidgetByIdAllRequests } from '@src/store/widgets/slice'
import type { WidgetType } from '@src/api/dictionary/dictionary.types'
import { TextEditorWithoutToolbar } from '@src/components/TextEditorWithoutToolbar/TextEditorWithoutToolbar'
import { TableWidgetSettings } from '@src/components/CreateWidget/CreateWidgetSettings/TableWidgetSettings'
import { WrapTextEditor } from '@src/components/TextEditor/WrapTextEditor'
import { PieChartSettings } from '@src/components/CreateWidget/CreateWidgetSettings/PieChartSettings/PieChartSettings'
import { LineChartSettings } from '@src/components/CreateWidget/CreateWidgetSettings/LineChartSettings/LineChartSettings'
import { BarChartSettings } from '@src/components/CreateWidget/CreateWidgetSettings/BarChartSettings/BarChartSettings'
import { getCurrentWidget, getCurrentDashboardWidgets } from '@src/store/widgets/selectors'

export const useWidget = (props?: { dashboardID?: number | null; widgetID?: number; widgetTypeID?: number }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const setupWidgetPage = useAppSelector(({ widgets }) => widgets.setupWidgetPage)
    const widgetTypes = useAppSelector(({ dictionary }) => dictionary.widgetTypes)
    const [dashboardID, setDashboardID] = useState(props?.dashboardID ?? 0)
    const [widgetID, seWidgetID] = useState(props?.widgetID ?? 0)
    const currentWidget = useAppSelector(store => getCurrentWidget(store, { dashboardID, widgetID }))

    const currentDashboardWidgets: WidgetItem[] | undefined = useAppSelector(store =>
        getCurrentDashboardWidgets(store, dashboardID),
    ) as WidgetItem[] | undefined
    // WidgetType
    const [widgetTypeID, setWidgetTypeID] = useState(0)
    const [currentWidgetType, setCurrentWidgetType] = useState<WidgetType>()
    // Widgets with correct types
    const [counterWidget, setCounterWidget] = useState<WidgetItemCounter | null>(null)
    const [pieChartWidget, setPieChartWidget] = useState<WidgetItemPieChart | null>(null)
    const [barChartWidget, setBarChartWidget] = useState<WidgetItemBarChart | null>(null)
    const [lineChartWidget, setLineChartWidget] = useState<WidgetItemLineChart | null>(null)
    const [tableWidget, setWidgetTable] = useState<WidgetItemTable | null>(null)
    const [textBlockWidget, setTextWidget] = useState<WidgetItemTextBlock | null>(null)

    useEffect(() => {
        setDashboardID(props?.dashboardID ?? setupWidgetPage.dashboardID ?? 0)
    }, [props?.dashboardID, setupWidgetPage.dashboardID])
    useEffect(() => {
        seWidgetID(props?.widgetID ?? setupWidgetPage.id ?? 0)
    }, [props?.widgetID, setupWidgetPage.id])

    useEffect(() => {
        setWidgetTypeID(
            currentWidget?.listData.widgetType?.id ?? props?.widgetTypeID ?? setupWidgetPage.widgetType?.id ?? 0,
        )
    }, [props?.widgetTypeID, setupWidgetPage.widgetType?.id, currentWidget?.listData.widgetType?.id])
    useEffect(() => {
        setCurrentWidgetType(currentWidget?.widget?.widgetType ?? widgetTypes.find(elem => elem.id === widgetTypeID))
    }, [currentWidget?.widget?.widgetType, widgetTypes, widgetTypeID])

    const isTextBlock = (wid?: WidgetItem): wid is WidgetItemTextBlock => {
        return (wid as WidgetItemTextBlock)?.widget?.widgetType.id === 1
    }
    const isCounter = (wid?: WidgetItem): wid is WidgetItemCounter => {
        return (wid as WidgetItemCounter)?.widget?.widgetType.id === 3
    }
    const isPieChart = (wid?: WidgetItem): wid is WidgetItemPieChart => {
        return (wid as WidgetItemPieChart)?.widget?.widgetType.id === 4
    }
    const isBarChart = (wid?: WidgetItem): wid is WidgetItemBarChart => {
        return (wid as WidgetItemBarChart)?.widget?.widgetType.id === 5
    }
    const isLineChart = (wid?: WidgetItem): wid is WidgetItemLineChart => {
        return (wid as WidgetItemLineChart)?.widget?.widgetType.id === 6
    }
    const isTableWidget = (wid?: WidgetItem): wid is WidgetItemTable => {
        return (wid as WidgetItemTable)?.widget?.widgetType.id === 2
    }
    useEffect(() => {
        const counter = isCounter(currentWidget) ? currentWidget : null
        setCounterWidget(counter)
        const pieChart = isPieChart(currentWidget) ? currentWidget : null
        setPieChartWidget(pieChart)
        const barChart = isBarChart(currentWidget) ? currentWidget : null
        setBarChartWidget(barChart)
        const lineChart = isLineChart(currentWidget) ? currentWidget : null
        setLineChartWidget(lineChart)
        const table = isTableWidget(currentWidget) ? currentWidget : null
        setWidgetTable(table)
        const text = isTextBlock(currentWidget) ? currentWidget : null
        setTextWidget(text)
    }, [currentWidget])

    const widgetTitle: Record<number, string> = {
        1: t('widgets.types.text'),
        2: t('widgets.types.table'),
        3: t('widgets.types.counter'),
        4: t('widgets.types.pie_chart'),
        5: t('widgets.types.bar_chart'),
        6: t('widgets.types.line_chart'),
    }

    const settingsTitle = widgetTypeID === 1 ? t('create_widget.settings.text') : t('create_widget.settings.period')
    const updateWidget = (payload: Partial<FetchUpdateWidgetById>, sendAllRequests?: boolean) => {
        if (
            (widgetID || payload.id) &&
            (dashboardID || payload.dashboardID) &&
            (payload.widgetType || currentWidgetType)
        ) {
            if (!sendAllRequests) {
                dispatch(
                    fetchUpdateWidgetById({
                        ...payload,
                        id: (widgetID || payload.id) as number,
                        dashboardID: (dashboardID || payload.dashboardID) as number,
                        widgetType: (payload.widgetType ?? currentWidgetType) as WidgetType,
                    }),
                )
            }
            if (sendAllRequests) {
                dispatch(
                    fetchUpdateWidgetByIdAllRequests({
                        ...payload,
                        id: (widgetID || payload.id) as number,
                        dashboardID: (dashboardID || payload.dashboardID) as number,
                        widgetType: (payload.widgetType ?? currentWidgetType) as WidgetType,
                    }),
                )
            }
        }
    }
    const widgetSettingsComponent: Record<number, JSX.Element> = {
        1: <WrapTextEditor />,
        2: <TableWidgetSettings />,
        3: <CounterSettings />,
        4: <PieChartSettings />,
        5: <BarChartSettings />,
        6: <LineChartSettings />,
    }

    const widgetComponent = useCallback(() => {
        if (dashboardID && widgetID && widgetTypeID) {
            const widgets: Record<number, JSX.Element> = {
                1: <TextEditorWithoutToolbar dashboardID={dashboardID} widgetID={widgetID} />,
                2: <TableWidget dashboardID={dashboardID} widgetID={widgetID} />,
                3: <CounterWidget dashboardID={dashboardID} widgetID={widgetID} />,
                4: <PieChartWidget dashboardID={dashboardID} widgetID={widgetID} />,
                5: <BarChartWidget dashboardID={dashboardID} widgetID={widgetID} />,
                6: <LineChartWidget dashboardID={dashboardID} widgetID={widgetID} />,
            }
            return widgets[widgetTypeID as number]
        }
        return null
    }, [dashboardID, widgetID, widgetTypeID])

    const widgetIcon = (type: number) => {
        const icons: Record<number, JSX.Element> = {
            1: <TextWidgetIcon />,
            2: <TableIcon />,
            3: <CounterIcon />,
            4: <PieChartIcon />,
            5: <BarChartIcon />,
            6: <LineChartIcon />,
        }
        return icons[type]
    }

    const findWidgetTypeByWidgetID = (ID: number) => {
        return currentDashboardWidgets?.find(elem => elem.id === ID)?.listData.widgetType
    }

    return {
        widgetID,
        dashboardID,
        widgetTypeID,
        widgetTitle: widgetTitle[widgetTypeID] ?? '',
        settingsTitle,
        widgetSettingsComponent: widgetSettingsComponent[widgetTypeID],
        widgetComponent,
        widgetIcon,
        findWidgetTypeByWidgetID,
        setupWidgetPage,
        currentDashboardWidgets,
        currentWidget,
        currentWidgetType,
        textBlockWidget,
        updateWidget,
        counterWidget,
        pieChartWidget,
        barChartWidget,
        lineChartWidget,
        tableWidget,
        widgetTypes,
    }
}
