import React, { useMemo, type FC, useEffect } from 'react'
import { SharedUserItem } from '@src/components/DashboardControlOption/SharedUsers/SharedUserItem/SharedUserItem'
import styles from './styles.module.sass'
import { useDatasource } from '@src/hooks/useDatasource/useDatasource'
import type { SharedUsersProps } from './SharedUsers.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchDashboardUsersPermissions } from '@src/store/dashboards/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { HighlightText } from '@src/components/HighlightText/HighlightText'

export const SharedUsers: FC<SharedUsersProps> = ({ dashboardID, users, setUsers, search }) => {
    const dispatch = useAppDispatch()
    const loginedUser = useAppSelector(({ user }) => user.userData?.accountID)
    const { adminUsers } = useDatasource()
    useEffect(() => {
        const usersID = adminUsers.map(user => user.id)
        if (usersID.length > 0) dispatch(fetchDashboardUsersPermissions({ dashboardID, userIDs: usersID }))
    }, [adminUsers])
    const usersPermissions = useAppSelector(({ dashboards }) => dashboards.dashboardUsersPermissions?.[dashboardID])
    const isChecked = (id: number) => {
        return !!users?.some(elem => elem.id === id)
    }

    const handleUserAccessChange = (userID: number, status: boolean) => {
        if (status) {
            const user = usersPermissions?.find(elem => elem.id === userID)
            if (user) {
                setUsers([...(users ?? []), user])
            }
        } else {
            setUsers(users?.filter(elem => elem.id !== userID))
        }
    }

    return (
        <div className={styles.root}>
            {usersPermissions?.map(({ id, name, deniedFields }) => (
                <SharedUserItem
                    key={id}
                    userName={<HighlightText text={name} searchInput={search} />}
                    disabled={id === loginedUser}
                    isShared={isChecked(id)}
                    hasNotRight={deniedFields.length > 0 && isChecked(id)}
                    onChange={value => handleUserAccessChange(id, value)}
                />
            ))}
        </div>
    )
}
