import React, { type FC } from 'react'
import styles from './styles.module.scss'
import type { LoaderProps } from './Loader.types'

export const Loader: FC<LoaderProps> = ({ children, isLoading, loadingText }) => {
    return (
        <div className={styles.loaderContainer}>
            {isLoading && (
                <div className={styles.loadingWrapper}>
                    {loadingText && <p>{loadingText}</p>}
                    <span className={styles.loader} />
                </div>
            )}
            <div className={`${styles.childrenWrapper} ${isLoading ? styles.bluredLoading : ''}`}>{children}</div>
        </div>
    )
}
