import type { WidgetListItem } from '@src/api/widgets/widgets.types'
import type { Coordinates, WidgetGridType } from './GridLayout.types'

export const getWidgetPositions = (x: number, y: number, w: number, h: number) => {
    const positions: Coordinates = []
    for (let i = 0; i < h; i += 1) {
        for (let j = 0; j < w; j += 1) {
            positions.push([y + i, x + j])
        }
    }
    return positions
}

export const createWidgetObjForGrid = (
    widget: WidgetListItem,
    colsNumber: number,
    rowsNumber: number,
): WidgetGridType => ({
    id: widget.id,
    x: widget.positionX,
    y: widget.positionY,
    w: widget.cellsX,
    h: widget.cellsY,
    positions: getWidgetPositions(widget.positionX, widget.positionY, widget.cellsX, widget.cellsY),
    isOutside: widget.positionX + 1 > colsNumber || widget.positionY + 1 > rowsNumber,
    isOutborder: widget.positionX + widget.cellsX > colsNumber || widget.positionY + widget.cellsY > rowsNumber,
})
export const createWidgetObjForGridWithoutOverflow = (widget: WidgetListItem) => ({
    id: widget.id,
    x: widget.positionX,
    y: widget.positionY,
    w: widget.cellsX,
    h: widget.cellsY,
    positions: getWidgetPositions(widget.positionX, widget.positionY, widget.cellsX, widget.cellsY),
})
