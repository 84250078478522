import React, { type FC, type Dispatch, type SetStateAction } from 'react'
import styles from './styles.module.scss'
import { Modal } from 'antd'
import './styles.scss'
import { CloseIcon } from '@src/components/StyleGuide'
import { Title } from './Title/Title'
import { FooterButtons } from './FooterButtons/FooterButtons'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { WidgetLayout } from '@src/layouts'

interface IPopupModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    widgetID: number
    dashboardID: number
}

export const PopupModal: FC<IPopupModalProps> = ({ widgetID, open, setOpen, dashboardID }) => {
    const { currentWidget, widgetTitle, currentWidgetType } = useWidget({
        widgetID,
        dashboardID,
    })
    return (
        <Modal
            rootClassName='PopupModal'
            className={`${styles.modal} noDrag`}
            open={open}
            title={<Title title={widgetTitle} subtitle={currentWidget?.widget?.name} />}
            onCancel={() => setOpen(false)}
            closeIcon={<CloseIcon />}
            centered
            footer={<FooterButtons onCancel={() => setOpen(false)} />}
        >
            <div className={styles.content}>
                {currentWidgetType && (
                    <WidgetLayout
                        simpleWidgetView
                        modal
                        widgetID={widgetID}
                        dashboardID={dashboardID}
                        widgetType={currentWidgetType}
                    />
                )}
            </div>
        </Modal>
    )
}
