import { ButtonWithIcon, DirectionIcon } from '@src/components/StyleGuide'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { appStateActions } from '@src/store/appState/slice'
import styles from '../styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DirectionSwitch = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const directionView = useAppSelector(({ appState }) => appState.UISettings?.createWidgetDirection) ?? 'vertical'

    const setDirection = (direction: 'horizontal' | 'vertical') => {
        dispatch(appStateActions.setLocalUISettings({ createWidgetDirection: direction }))
    }
    return (
        <div className={styles.wrap}>
            <span className={styles.label}>{t('create_widget.labels.direction')}</span>
            <div className={styles.directionWrap}>
                <ButtonWithIcon
                    onClick={() => setDirection('vertical')}
                    className={`${styles.btn} ${directionView === 'vertical' ? styles.active : ''}`}
                    text={t('create_widget.direction.vertical')}
                    icon={<DirectionIcon />}
                />
                <ButtonWithIcon
                    onClick={() => setDirection('horizontal')}
                    className={`${styles.btn} ${styles.horizontal} ${
                        directionView === 'horizontal' ? styles.active : ''
                    }`}
                    text={t('create_widget.direction.horizontal')}
                    icon={<DirectionIcon />}
                />
            </div>
        </div>
    )
}
