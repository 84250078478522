import React, { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowRoundedIcon, DarkThemeIcon, LightThemeIcon } from '@src/components'
import type { Theme } from '@src/hooks/useTheme/types'
import styles from '@src/components/SystemSettings/styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'

export const ThemeSetting: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const UISettings = useAppSelector(({ appState }) => appState.UISettings)

    const activeTheme = UISettings?.theme ?? 'light'

    const themes = [
        { value: 'light', title: t('system_settings.theme_light') },
        { value: 'dark', title: t('system_settings.theme_dark') },
    ]

    const [activeSubMenu, setActiveSubMenu] = useState(false)

    const renderOptionIcon: Record<Theme, JSX.Element> = {
        light: <LightThemeIcon />,
        dark: <DarkThemeIcon />,
    }

    const handleChangeTheme = (theme: Theme) => {
        dispatch(appStateActions.fetchUpdateUISettings({ theme }))
    }

    return (
        <div
            className={styles.item}
            onMouseEnter={() => setActiveSubMenu(true)}
            onMouseLeave={() => setActiveSubMenu(false)}
        >
            <div className={styles.itemIcon}>{activeTheme === 'light' ? <LightThemeIcon /> : <DarkThemeIcon />}</div>
            <div className={styles.itemWrap}>
                <p className={styles.itemTitle}>{t('system_settings.theme_title')}</p>
                <p className={styles.itemValue}>
                    {activeTheme === 'light' ? t('system_settings.theme_light') : t('system_settings.theme_dark')}
                </p>
            </div>
            <div className={styles.itemArrow}>
                <ArrowRoundedIcon />
            </div>
            {activeSubMenu && (
                <ul className={styles.itemInclude}>
                    {themes.map(({ value, title }) => (
                        <li
                            key={value}
                            className={activeTheme === value ? styles.active : ''}
                            onClick={() => handleChangeTheme(value as Theme)}
                        >
                            {renderOptionIcon[value as Theme]}
                            <p>{title}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
