import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    DataPermissionsList,
    FetchDataPermissionsList,
    FetchUpdageUsersDataPermissions,
    FetchUserDataPermissions,
    InitialState,
    SetUpdateUsersDataPrmission,
    UserDataPermissions,
} from './permissions.types'
import type { DataPermissionForUser } from '@src/api/permissions/permissions.types'

const initialState: InitialState = {
    userDataPermissions: null,
    unsavedFields: [],
    dataPermissionsList: null,
    isErrorDataPermissionsList: false,
    isErrorUserDataPermissions: false,
    isLoadingDataPermissionsList: false,
    isLoadingUserDataPermissions: false,
    isLoadingUpdateUserDataPermissions: false,
    isErrorUpdateUserDataPermissions: false,
}

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setUnsavedFields: (state, { payload }: PayloadAction<DataPermissionForUser[]>) => {
            state.unsavedFields = payload
        },
        fetchUserDataPermissions: (state, { payload }: PayloadAction<FetchUserDataPermissions['payload']>) => {
            state.isLoadingUserDataPermissions = true
            state.isErrorUserDataPermissions = false
        },
        setUserDataPermissions: (state, { payload }: PayloadAction<UserDataPermissions>) => {
            state.isLoadingUserDataPermissions = false
            state.userDataPermissions = payload
        },
        errorUserDataPermissions: state => {
            state.isLoadingUserDataPermissions = false
            state.isErrorUserDataPermissions = true
        },
        fetchUsersDataPermissions: (state, { payload }: PayloadAction<FetchDataPermissionsList['payload']>) => {
            state.isLoadingDataPermissionsList = true
            state.isErrorDataPermissionsList = false
        },
        setUsersDataPermissions: (state, { payload }: PayloadAction<DataPermissionsList>) => {
            state.isLoadingDataPermissionsList = false
            state.dataPermissionsList = payload
        },
        errorUsersDataPermissions: state => {
            state.isLoadingDataPermissionsList = false
            state.isErrorDataPermissionsList = true
        },
        fetchUpdateUsersDataPermissions: (
            state,
            { payload }: PayloadAction<FetchUpdageUsersDataPermissions['payload']>,
        ) => {
            state.isLoadingUpdateUserDataPermissions = true
            state.isErrorUpdateUserDataPermissions = false
        },
        setUpdateUsersDataPermissions: (state, { payload }: PayloadAction<SetUpdateUsersDataPrmission>) => {
            state.isLoadingUpdateUserDataPermissions = false
            state.dataPermissionsList =
                state.dataPermissionsList?.map(elem => {
                    const matchedObjFromPayload = payload.find(item => item.userID === elem.user.id)
                    return matchedObjFromPayload
                        ? {
                              ...elem,
                              fields: elem.fields.map(item => {
                                  const matchedFieldFromPayload = payload.find(
                                      field => field.userID === elem.user.id && field.field.id === item.field.id,
                                  )
                                  return matchedFieldFromPayload ?? item
                              }),
                          }
                        : elem
                }) ?? null
        },
        errorUpdateUsersDataPermissions: state => {
            state.isLoadingUpdateUserDataPermissions = false
            state.isErrorUpdateUserDataPermissions = true
        },
    },
})

export const { actions: permissionsActions, reducer: permissions } = permissionsSlice
