import React, { useState, type MouseEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CloudServerIcon, RefreshButtonModal, RefreshIcon, SettingsIcon } from '@src/components'
import styles from './styles.module.scss'
import { TimerProgressIcon } from './TimerProgressIcon'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'
import type { RefreshDataButtonProps } from './RefreshDataButton.types'

export const RefreshDataButton: FC<RefreshDataButtonProps> = ({ isDisabled }) => {
    const dispatch = useAppDispatch()
    const autoUpdateInterval = useAppSelector(({ dashboards }) => dashboards.currentDashboard?.autoUpdateInterval)
    const currentDashboardId = useAppSelector(({ dashboards }) => dashboards.currentDashboard?.id)

    const dataStatus = () => {
        if (autoUpdateInterval === 0) return 'default'
        if (autoUpdateInterval && autoUpdateInterval > 0 && autoUpdateInterval < 1_000_000) return 'autoRefresh'
        if (autoUpdateInterval && autoUpdateInterval >= 1_000_000) return 'server'
        return 'default'
    }

    const { t } = useTranslation()

    // State for settings modal
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    const handleSettingsModal = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        !isDisabled && setIsSettingsOpen(!isSettingsOpen)
    }
    const handleRefreshDashboard = () => {
        if (currentDashboardId) dispatch(fetchDashboardWidgetsList({ dashboardID: currentDashboardId }))
    }

    const renderIcon = {
        default: <RefreshIcon />,
        autoRefresh: <TimerProgressIcon />,
        server: (
            <div className={`${styles.iconWrap} ${styles.serverIcon}`}>
                <CloudServerIcon />
            </div>
        ),
    }

    return (
        <>
            <div
                className={`${styles.root} ${dataStatus() !== 'default' ? styles.notDefault : ''}`}
                onClick={handleRefreshDashboard}
            >
                {renderIcon[dataStatus()]}
                <span>{t('dashboard_control_panel.refresh_btn')}</span>
                <button
                    title={t('dashboard_control_panel.settings_timer')}
                    className={`${styles.settingsBtn} ${isDisabled ? styles.disabled : ''}`}
                    onClick={handleSettingsModal}
                >
                    <SettingsIcon />
                </button>
            </div>
            {isSettingsOpen && <RefreshButtonModal setModalOpen={setIsSettingsOpen} />}
        </>
    )
}
