import React, { useState } from 'react'
import styles from '../styles.module.scss'
import { CreateWidgetTypes } from '../../CreateWidgetTypes/CreateWidgetTypes'
import { useTranslation } from 'react-i18next'
import type { WidgetType } from '@src/api/dictionary/dictionary.types'
import { ConfirmChangeTypeModal } from '@src/components/Modals'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchCreateWidgetByType, fetchDeleteWidgetById, setSetupWidgetPageData } from '@src/store/widgets/slice'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'

export const WidgetTypesPicker = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const dontShowChangeTypeConfirmationModal = useAppSelector(
        ({ appState }) => appState.UISettings?.dontAskAgainChangeWidgetTypeModal,
    )
    const { currentWidget, setupWidgetPage } = useWidget()
    const { widgetType, dashboardID, positionX, positionY, cellsX, cellsY, isLoadingCreateWidget, id } = setupWidgetPage
    const [newPickedWidgetType, setNewPickedWidgetType] = useState<WidgetType | undefined>(setupWidgetPage.widgetType)
    const [openModalChangeWidgetType, setOpenModalChangeWidgetType] = useState(false)
    const { currentPeriod, fromToPeriod } = usePeriod(5)

    const createWidget = (type: WidgetType) => {
        if (
            currentWidget &&
            widgetType &&
            dashboardID &&
            typeof positionX === 'number' &&
            typeof positionY === 'number' &&
            cellsX &&
            cellsY &&
            !isLoadingCreateWidget
        ) {
            dispatch(
                fetchCreateWidgetByType({
                    name: currentWidget.listData.name,
                    dataSourceType: currentWidget.listData.dataSourceType,
                    dataset: currentWidget.listData.dataset,
                    widgetType: type,
                    dashboardID,
                    positionX,
                    positionY,
                    cellsX,
                    cellsY,
                    isDisabledFiltering: true,
                    isExtendedFiltering: false,
                    periodType: currentPeriod,
                    deniedFields: [],
                    ...fromToPeriod,
                }),
            )
        }
    }
    const handleTryWidgetTypeChange = (type: WidgetType) => {
        if (type.id !== widgetType?.id && id && !dontShowChangeTypeConfirmationModal) {
            setNewPickedWidgetType(type)
            setOpenModalChangeWidgetType(true)
        }
        if (type.id !== widgetType?.id && id && dontShowChangeTypeConfirmationModal) {
            setNewPickedWidgetType(type)
            confirmChangeWidgetType(type)
        }
        if (type.id !== widgetType?.id && !id) {
            setNewPickedWidgetType(type)
            dispatch(
                setSetupWidgetPageData({
                    ...setupWidgetPage,
                    widgetType: type,
                }),
            )
        }
    }
    const onCancelChangeWidgetType = () => {
        setOpenModalChangeWidgetType(false)
        setNewPickedWidgetType(currentWidget?.widget?.widgetType)
    }
    const confirmChangeWidgetType = (type?: WidgetType) => {
        if (currentWidget?.widget && setupWidgetPage.dashboardID && setupWidgetPage.id && type) {
            dispatch(fetchDeleteWidgetById({ dashboardID: setupWidgetPage.dashboardID, widgetID: setupWidgetPage.id }))
            dispatch(
                setSetupWidgetPageData({
                    ...setupWidgetPage,
                    id: undefined,
                    widgetType: type,
                }),
            )
            createWidget(type)
        }
    }
    return (
        <>
            <div className={styles.wrap}>
                <span className={styles.label}>{t('create_widget.labels.type')}</span>
                <CreateWidgetTypes activeType={newPickedWidgetType} isCreatePage onClick={handleTryWidgetTypeChange} />
            </div>
            <ConfirmChangeTypeModal
                onCancelChange={onCancelChangeWidgetType}
                open={openModalChangeWidgetType}
                setOpen={setOpenModalChangeWidgetType}
                onConfirm={() => confirmChangeWidgetType(newPickedWidgetType)}
            />
        </>
    )
}
