import { type AllEffect, type ForkEffect, call, put, all, takeLatest } from 'redux-saga/effects'
import {
    errorCreateDashboard,
    errorDashboardById,
    errorDashboardUsersPermissins,
    errorDashboardsList,
    errorDeleteDashboard,
    errorGrandAccessAndShareDashboard,
    errorUpdateDashboard,
    fetchCreateCopyDashboard,
    fetchCreateDashboard,
    fetchDashboardById,
    fetchDashboardUsersPermissions,
    fetchDashboardsList,
    fetchDeleteDashboard,
    fetchFullUpdateDashboard,
    fetchGrandAccessAndShareDashboard,
    fetchUpdateDashboard,
    setCreateDashboard,
    setDashboardById,
    setDashboardUsersPermissions,
    setDashboardsList,
    setDeleteDashboard,
    setGrandAccessAndShareDashboard,
    setUpdateDashboard,
} from './slice'
import { dashboardApi } from '@src/api'
import type {
    DeleteDashboardResponse,
    DashboardResponse,
    DashboardsListResponse,
    DashboardUsers,
} from '@src/api/dashboardApi/dashboard.types'
import type {
    FetchUpdateDashboardPayload,
    FetchCreateDashboardPayload,
    FetchFullUpdateDashboardPayload,
    FetchDeleteDashboardPayload,
    FetchDashboardByIdPayload,
    FetchCreateCopyDashboardPayload,
    FetchDashboardUsersPermissionsPayload,
    FetchGrandAccessAndShareDashboard,
} from './dashboards.types'
import { type AxiosError } from 'axios'
import { fetchCreateWidgetByTypeForDasboardCopy } from '../widgets/slice'
import { permissionsActions } from '../permissions/slice'

function* fetchDashboardsListWorker() {
    try {
        const res: DashboardsListResponse = yield call(dashboardApi.getDashboardsList)
        if (res) {
            yield put(setDashboardsList(res))
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorDashboardsList(error))
    }
}

function* fetchDashboardByIdListWorker({ payload }: FetchDashboardByIdPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DashboardResponse = yield call(dashboardApi.getDashboardById, body)
        if (res) {
            yield put(setDashboardById(res))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorDashboardById(error))
    }
}

function* fetchCreateDashboardWorker({ payload }: FetchCreateDashboardPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DashboardResponse = yield call(dashboardApi.createDashboard, body)
        if (res) {
            yield put(setCreateDashboard(res))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorCreateDashboard(error))
    }
}
function* fetchCreateCopyDashboardWorker({ payload }: FetchCreateCopyDashboardPayload) {
    try {
        const { onSuccess, widgets, ...body } = payload
        const res: DashboardResponse = yield call(dashboardApi.createDashboard, body)
        if (res) {
            yield put(setCreateDashboard(res))
            yield all(
                widgets.map(widget => {
                    return put(fetchCreateWidgetByTypeForDasboardCopy({ ...widget, dashboardID: res.id }))
                }),
            )

            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorCreateDashboard(error))
    }
}

function* fetchUpdateDashboardWorker({ payload }: FetchUpdateDashboardPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DashboardResponse = yield call(dashboardApi.updateDashboard, body)
        if (res) {
            yield put(setUpdateDashboard(res))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorUpdateDashboard(error))
    }
}
function* fetchFullUpdateDashboardWorker({ payload }: FetchFullUpdateDashboardPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DashboardResponse = yield call(dashboardApi.fullUpdateDashboard, body)
        if (res) {
            yield put(setUpdateDashboard(res))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorUpdateDashboard(error))
    }
}

function* fetchDeleteDashboardWorker({ payload }: FetchDeleteDashboardPayload) {
    const { onSuccess, ...body } = payload
    try {
        const res: DeleteDashboardResponse = yield call(dashboardApi.deleteDashboard, body)
        if (res) {
            yield put(setDeleteDashboard(body))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorDeleteDashboard(error))
    }
}
function* fetchDashboardUsersPermissionsWorker({ payload }: FetchDashboardUsersPermissionsPayload) {
    const { onSuccess, ...body } = payload
    try {
        const res: DashboardUsers[] = yield call(dashboardApi.checkUsersPermissionsToShare, body)
        if (res) {
            yield put(setDashboardUsersPermissions({ [payload.dashboardID]: res }))
            onSuccess?.()
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorDashboardUsersPermissins())
    }
}
function* fetchGrandAccessAndShareDashboardWorker({ payload }: FetchGrandAccessAndShareDashboard) {
    const { onSuccess, fields, users, dashboardID } = payload
    try {
        yield put(permissionsActions.fetchUpdateUsersDataPermissions({ data: fields }))
        const userIDs = users.map(user => user.id)
        const res: DashboardUsers[] = yield call(dashboardApi.checkUsersPermissionsToShare, { dashboardID, userIDs })
        if (res) {
            const isAllUsersHasRights = res.every(user => user.deniedFields.length === 0)
            if (isAllUsersHasRights) yield put(fetchUpdateDashboard({ id: dashboardID, users: res }))
            onSuccess?.()
            yield put(setDashboardUsersPermissions({ [payload.dashboardID]: res }))
            yield put(setGrandAccessAndShareDashboard())
        }
    } catch (err: any) {
        const error = (err as AxiosError).message
        yield put(errorGrandAccessAndShareDashboard())
    }
}

export default function* dashboardsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(fetchDashboardsList, fetchDashboardsListWorker),
        takeLatest(fetchDashboardById, fetchDashboardByIdListWorker),
        takeLatest(fetchCreateDashboard, fetchCreateDashboardWorker),
        takeLatest(fetchCreateCopyDashboard, fetchCreateCopyDashboardWorker),
        takeLatest(fetchUpdateDashboard, fetchUpdateDashboardWorker),
        takeLatest(fetchFullUpdateDashboard, fetchFullUpdateDashboardWorker),
        takeLatest(fetchDeleteDashboard, fetchDeleteDashboardWorker),
        takeLatest(fetchDashboardUsersPermissions, fetchDashboardUsersPermissionsWorker),
        takeLatest(fetchGrandAccessAndShareDashboard, fetchGrandAccessAndShareDashboardWorker),
    ])
}
