import { useMemo } from 'react'
import { useAppSelector } from '../redux/useAppSelector'

const dataTypeFunctions: Record<number, number[]> = {
    1: [2, 3, 4, 5, 6],
    2: [2, 3, 4, 5, 6],
    3: [2],
    4: [2],
    5: [2, 4, 5],
    6: [2, 4, 5],
}

export const useAggregateFunctions = (dataTypeId?: number) => {
    const aggregateFunctionTypes = useAppSelector(({ dictionary }) => dictionary.aggregateFunctionTypes)
    const availableFunctionsID = dataTypeId ? dataTypeFunctions[dataTypeId] : [1, 2, 3, 4, 5, 6]
    const availableFunctions = useMemo(
        () => aggregateFunctionTypes.filter(func => availableFunctionsID.includes(func.id)),
        [aggregateFunctionTypes, availableFunctionsID],
    )
    const aggregateFunctionOptions = useMemo(
        () =>
            availableFunctions.map(elem => ({
                label: elem.name,
                value: elem.id,
            })),
        [availableFunctions],
    )
    const availableYAxisOptions = useMemo(() => {
        if (dataTypeId === 1 || dataTypeId === 2) {
            return aggregateFunctionTypes.map(elem => ({
                label: elem.name,
                value: elem.id,
            }))
        } else {
            const countOption = aggregateFunctionTypes.find(elem => elem.id === 2)
            if (countOption) {
                return [{ label: countOption.name, value: countOption.id }]
            }
            return []
        }
    }, [dataTypeId, aggregateFunctionTypes])
    const getAggregateFunctionType = (funcId: number) => aggregateFunctionTypes.find(func => func.id === funcId)
    return {
        availableYAxisOptions,
        aggregateFunctionOptions,
        getAggregateFunctionType,
    }
}
