import { type PeriodType } from '@src/api/dictionary/dictionary.types'
import { useAppSelector } from '../redux/useAppSelector'
import type { SelectOptions } from '@src/components/StyleGuide/Ui/SelectList/SelectList.types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { DATE_FORMAT, DATE_FORMAT_FOR_REQUEST } from '@src/config/constants'

export const usePeriod = (id?: PeriodType['id'] | null) => {
    const { t } = useTranslation()
    const periodTypes = useAppSelector(({ dictionary }) => dictionary.periodTypes)
    const userPeriod = periodTypes.find(elem => elem.id === 1)
    const periodOptions: SelectOptions = periodTypes.map(({ id: typeId, name }) => ({
        value: typeId,
        label: typeId !== 1 ? name : t('buttons.period'),
    }))
    const todayEndDay = dayjs().endOf('d')
    const todayBeginDay = dayjs().startOf('d')
    const yestedayEndDay = todayEndDay.subtract(1, 'd')
    const yestedayBeginDay = todayBeginDay.subtract(1, 'd')
    const weekBeginDay = todayBeginDay.isoWeekday(1)
    const weekEndDay = todayEndDay.isoWeekday(7).endOf('d')
    const mountBeginDay = todayBeginDay.startOf('month')
    const mountEndDay = todayEndDay.endOf('month')

    const periodTypesWithoutUserPeriod = periodTypes.filter(elem => elem.id !== 1)

    const findPeriodById = (periodId?: PeriodType['id'] | null) => {
        return periodTypes.find(type => type.id === periodId)
    }
    const currentPeriod = findPeriodById(id)

    const getPeriodByPeriodId = (periodId?: number | null) => {
        let period
        switch (periodId) {
            case 2:
                period = [mountBeginDay, mountEndDay]
                break
            case 3:
                period = [weekBeginDay, weekEndDay]
                break
            case 4:
                period = [yestedayBeginDay, yestedayEndDay]
                break
            case 5:
                period = [todayBeginDay, todayEndDay]
                break
            default:
                period = [todayBeginDay, todayEndDay]
        }
        const fromToPeriod = {
            dateFrom: period[0].format(DATE_FORMAT_FOR_REQUEST),
            dateTo: period[1].format(DATE_FORMAT_FOR_REQUEST),
        }
        return {
            period,
            fromToPeriod,
        }
    }

    const getDateFromISOFormat = (isoDate?: string) => {
        return isoDate ? dayjs(isoDate) : dayjs().utc(true).startOf('d')
    }
    const getFormatedDate = (date?: string) => {
        return date ? dayjs(date).format(DATE_FORMAT) : ''
    }

    const periodVariants = getPeriodByPeriodId(id)

    return {
        periodTypes,
        userPeriod,
        periodTypesWithoutUserPeriod,
        periodOptions,
        currentPeriod,
        findPeriodById,
        getPeriodByPeriodId,
        getDateFromISOFormat,
        getFormatedDate,
        ...periodVariants,
    }
}
