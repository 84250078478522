import React, { type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { ButtonIcon, GroupPlusIcon, Input, MinusIcon, PlusIcon, Switcher } from '@src/components'
import type { FilterPanelItemProps } from '../../FilterPanel.types'

import { useCompareOptions } from '@src/hooks/useCompareFunctions/useCompareFunctions'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'
import { useWidget } from '@src/hooks/useWidget/useWidget'

export const FilterPanelItem: FC<FilterPanelItemProps> = ({
    advancedMode,
    filterItem,
    onChangeFilterItem,
    onRowAdd,
    onRowDelete,
    onAddFilterGroup,
    isPosibleDeleteRow,
    isPosibleAddRow,
}) => {
    const { setDataType, findCompareFunctionById, compareFunctionsOptions } = useCompareOptions()
    const { currentWidget } = useWidget()
    const { allowedUserDataOptions, findFieldByID } = useUserFields({
        datasourceTypeID: currentWidget?.listData.dataSourceType.id,
        datasetID: currentWidget?.listData.dataset.id,
    })
    const { t } = useTranslation()
    useEffect(() => {
        if (filterItem.field) {
            setDataType(filterItem.field.fieldType.id)
        }
    }, [filterItem.field])

    const handleUpdateField = (id: string | number) => {
        const field = findFieldByID(id as string)
        if (field) {
            setDataType(field.fieldType.id)
            onChangeFilterItem({
                ...filterItem,
                field,
            })
        }
    }
    const handleUpdateCompareFunc = (id: string | number) => {
        const func = findCompareFunctionById(id as number)
        if (func) {
            onChangeFilterItem({ ...filterItem, compareFunctionType: func })
        }
    }
    const handleUpdateCompareValue = (value: string) => {
        onChangeFilterItem({ ...filterItem, compareValue: value })
    }
    const handleUpdateDisableRow = (value: boolean) => {
        onChangeFilterItem({ ...filterItem, isDisabled: !value })
    }

    return (
        <div className={styles.root}>
            <SelectList
                className={styles.select}
                options={allowedUserDataOptions}
                onChange={handleUpdateField}
                selectedValue={filterItem?.field?.id}
                isTransparentBg={true}
                placeholder={t('placeholder.select_column')}
            />
            <SelectList
                className={styles.select}
                options={compareFunctionsOptions}
                isDisabled={!filterItem.field?.id}
                selectedValue={filterItem?.compareFunctionType?.id}
                onChange={handleUpdateCompareFunc}
                isTransparentBg
                placeholder={t('placeholder.select_function')}
            />
            <Input
                className={styles.input}
                value={filterItem?.compareValue}
                disabled={!filterItem.field?.id}
                setInputValue={handleUpdateCompareValue}
                setChangeOnFocus
            />
            <ButtonIcon
                className={`${styles.actionBtn} ${styles.minusBtn}`}
                icon={<MinusIcon />}
                title={t('buttons.removeFilter')}
                disabled={!isPosibleDeleteRow}
                onClick={onRowDelete}
            />
            <ButtonIcon
                className={styles.actionBtn}
                onClick={onRowAdd}
                disabled={!isPosibleAddRow}
                icon={<PlusIcon />}
                title={t('buttons.addFilter')}
            />
            {advancedMode && (
                <ButtonIcon
                    className={styles.actionBtn}
                    icon={<GroupPlusIcon />}
                    title={t('create_widget.settings.filter.addGroup')}
                    onClick={onAddFilterGroup}
                />
            )}
            <Switcher
                title={t('buttons.switchOnRowFilters')}
                value={filterItem?.isDisabled === undefined ? false : !filterItem.isDisabled}
                onChange={handleUpdateDisableRow}
            />
        </div>
    )
}
