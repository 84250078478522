import { Route, Routes } from 'react-router-dom'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { AccessRightsSettings, CreateWidgetPage, Home, WidgetSettingsPage } from '@src/pages'
import { useLocalSettings } from '@src/hooks/useLocalSettings/useLocalSettings'
import { useTheme } from '@src/hooks'
import enUS from 'antd/lib/locale/en_US'
import ukUA from 'antd/lib/locale/uk_UA'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchDashboardsList, setCurrentDashBoard } from '@src/store/dashboards/slice'
import {
    fetchAggregateFunctionTypes,
    fetchAutoUpdateIntervals,
    fetchCommandTypes,
    fetchCompareFunctionTypes,
    fetchDashboardTypes,
    fetchDatasourceTypes,
    fetchFieldTypes,
    fetchFormTypes,
    fetchPeriodTypes,
    fetchSortTypes,
    fetchTimeAxisTypes,
    fetchWidgetTypes,
} from '../store/dictionary/slice'
import { appStateActions } from '@src/store/appState/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'
import { getLocalStorageValue } from '@src/helpers/utils/getLocalStorageValue'
import { actionsActions } from '@src/store/actions/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { userActions } from '@src/store/user/slice'
import { ConfigProvider } from 'antd'
import { Locales } from '@src/helpers/locales.enum'
import { useTranslation } from 'react-i18next'

export const RoutesLayout = () => {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()

    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale)
    useLayoutEffect(() => {
        dispatch(actionsActions.fetchGetCommandsList())
        dispatch(actionsActions.fetchGetFormsList())
        dispatch(appStateActions.fetchUISettings())
        dispatch(fetchDashboardTypes())
        dispatch(fetchWidgetTypes())
        dispatch(fetchPeriodTypes())
        dispatch(fetchAggregateFunctionTypes())
        dispatch(fetchCompareFunctionTypes())
        dispatch(fetchSortTypes())
        dispatch(fetchTimeAxisTypes())
        dispatch(fetchDatasourceTypes())
        dispatch(fetchFieldTypes())
        dispatch(fetchCommandTypes())
        dispatch(fetchFormTypes())
        dispatch(fetchAutoUpdateIntervals())
        dispatch(fetchDashboardsList())
        dispatch(appStateActions.fetchMainMenuSettings())
    }, [])
    useEffect(() => {
        const localLocale = localStorage.getItem('locale')
        if (localLocale && locale && JSON.parse(localLocale) !== locale) {
            dispatch(actionsActions.fetchGetCommandsList())
            dispatch(actionsActions.fetchGetFormsList())
            dispatch(fetchDashboardTypes())
            dispatch(fetchWidgetTypes())
            dispatch(fetchPeriodTypes())
            dispatch(fetchAggregateFunctionTypes())
            dispatch(fetchCompareFunctionTypes())
            dispatch(fetchSortTypes())
            dispatch(fetchTimeAxisTypes())
            dispatch(fetchAutoUpdateIntervals())
            dispatch(appStateActions.fetchMainMenuSettings())
        }
        const langCode = Locales[locale ?? 'uk_UA']
        i18n.changeLanguage(langCode)
    }, [locale])

    useLocalSettings()
    useTheme()
    const datasourceTypesWithAdminAcces = useAppSelector(
        ({ appState }) => appState.UISettings?.datasourceTypesWithAdminAcces,
    )

    const lastChosenDashboard = Number(getLocalStorageValue('lastActiveDashboardID'))
    const { currentDashboard, activeDashboard } = useDashboard({
        id: lastChosenDashboard || undefined,
    })

    useEffect(() => {
        if (currentDashboard) dispatch(setCurrentDashBoard(currentDashboard))
    }, [currentDashboard])
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    useEffect(() => {
        if (activeDashboard?.id && isFirstLoad) {
            dispatch(fetchDashboardWidgetsList({ dashboardID: activeDashboard?.id }))
            setIsFirstLoad(false)
        }
    }, [activeDashboard?.id, isFirstLoad])
    useEffect(() => {
        if (datasourceTypesWithAdminAcces) dispatch(userActions.setAdmin(datasourceTypesWithAdminAcces.length > 0))
    }, [datasourceTypesWithAdminAcces])

    return (
        <ConfigProvider locale={locale === 'uk_UA' ? ukUA : enUS}>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/create-widget' element={<CreateWidgetPage />} />
                <Route path='/widget-settings' element={<WidgetSettingsPage />} />
                <Route path='/access-rights' element={<AccessRightsSettings />} />
            </Routes>
        </ConfigProvider>
    )
}
