import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DragDropContext, type DropResult } from 'react-beautiful-dnd'
import styles from './styles.module.scss'
import { type ColumnType } from '@src/components/CreateWidget/CreateWidgetSettings/CreateTableWidget/tableWidgetData'
import { DroppableColumn } from '@src/components/CreateWidget/CreateWidgetSettings/CreateTableWidget/DroppableColumn/DroppableColumn'
import { type WidgetProps } from '@src/components/Widgets/widgets.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { type DatasetField } from '@src/api/structures/structures.types'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { type SelectOptionsItem } from '@src/components/StyleGuide/Ui/SelectList/SelectList.types'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'
export type SelectItem = { value: string; label: string }

export interface TableWidgetSettingsProps extends WidgetProps {}
export const TableWidgetSettings = ({ widgetID, dashboardID }: Partial<TableWidgetSettingsProps>) => {
    const { t } = useTranslation()
    const { tableWidget, updateWidget } = useWidget({
        widgetID,
        dashboardID,
    })
    const { allowedUserDataOptions, findFieldByID } = useUserFields({
        datasourceTypeID: tableWidget?.listData.dataSourceType.id,
        datasetID: tableWidget?.listData.dataset.id,
    })
    const sortTypes = useAppSelector(state => state.dictionary.sortTypes)
    const locale = useAppSelector(state => state.appState.UISettings?.locale)
    const [leftCol, setLeftCol] = React.useState<DatasetField[] | []>([])
    const [rightCol, setRightCol] = React.useState<DatasetField[] | []>([])

    const [tableData, setTableData] = React.useState<ColumnType[]>([
        {
            id: '1',
            name: t('create_widget.settings.table_available'),
            type: 'available',
            items: leftCol || [],
        },
        {
            id: '2',
            name: t('create_widget.settings.table_in_use'),
            type: 'inUse',
            items: rightCol || [],
        },
    ])
    useEffect(() => {
        setTableData(prev =>
            prev.map(elem =>
                elem.id === '1'
                    ? { ...elem, name: t('create_widget.settings.table_available') }
                    : { ...elem, name: t('create_widget.settings.table_in_use') },
            ),
        )
    }, [locale])
    useEffect(() => {
        if (leftCol.length === 0 && rightCol.length === 0 && allowedUserDataOptions.length && tableWidget) {
            const newRightCol = tableWidget?.widget
                ? (tableWidget.widget.widgetFields
                      .filter(field => field?.dataField?.id)
                      .map(field => findFieldByID(field.dataField.id)) as DatasetField[])
                : ([] as DatasetField[])

            const newLeftCol = allowedUserDataOptions
                .filter(
                    item =>
                        item.value &&
                        !tableWidget?.widget?.widgetFields.some(field => field?.dataField?.id === item.value),
                )
                .map(item => findFieldByID(item.value)) as DatasetField[]
            setRightCol(newRightCol)
            setLeftCol(newLeftCol)
            const updatedTableData = tableData.map(item => {
                if (item.id === '1') {
                    return { ...item, items: newLeftCol }
                } else if (item.id === '2') {
                    return { ...item, items: newRightCol }
                } else {
                    return item
                }
            })
            setTableData(updatedTableData)
        }
    }, [leftCol, rightCol, allowedUserDataOptions, tableWidget?.widget])
    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result
        if (!destination) {
            return
        }
        const startColumn = tableData.find(column => column.id === source.droppableId)
        const finishColumn = tableData.find(column => column.id === destination.droppableId)
        if (!startColumn || !finishColumn) {
            return
        }
        if (startColumn === finishColumn) {
            if (finishColumn.type === 'inUse') {
                const newItems = Array.from(startColumn.items)
                const movedItem = newItems.splice(source.index, 1)[0]
                newItems.splice(destination.index, 0, movedItem)
                const updatedStartColumn = {
                    ...startColumn,
                    items: newItems,
                }
                const newData = tableData.map(column =>
                    column.id === updatedStartColumn.id ? updatedStartColumn : column,
                )
                setTableData(newData)
                setLeftCol((prev: any) => [...prev, movedItem])
                const patchData = newItems.map(item => ({
                    widgetID,
                    dataField: item,
                }))
                updateWidget({
                    widgetFields: patchData,
                })
            }
        } else {
            if (finishColumn.type === 'inUse') {
                const startItems = Array.from(startColumn.items)
                const [movedItem] = startItems.splice(source.index, 1)
                const newStartColumn = {
                    ...startColumn,
                    items: startItems,
                }
                const finishItems = Array.from(finishColumn.items)
                finishItems.splice(destination.index, 0, movedItem)
                const newFinishColumn = {
                    ...finishColumn,
                    items: finishItems,
                }
                const newData = tableData.map(column =>
                    column.id === newStartColumn.id
                        ? newStartColumn
                        : column.id === newFinishColumn.id
                        ? newFinishColumn
                        : column,
                )
                setRightCol((prev: any) => [...prev, movedItem])
                setTableData(newData)
                const patchData = finishItems.map(item => ({
                    widgetID,
                    dataField: item,
                }))
                updateWidget({
                    widgetFields: patchData,
                })
            } else {
                const startItems = Array.from(startColumn.items)
                const [movedItem] = startItems.splice(source.index, 1)
                const newStartColumn = {
                    ...startColumn,
                    items: startItems,
                }
                const finishItems = Array.from(finishColumn.items)
                finishItems.splice(destination.index, 0, movedItem)
                const newFinishColumn = {
                    ...finishColumn,
                    items: finishItems,
                }
                const newData = tableData.map(column =>
                    column.id === newStartColumn.id
                        ? newStartColumn
                        : column.id === newFinishColumn.id
                        ? newFinishColumn
                        : column,
                )
                setRightCol(prevState => prevState.filter(item => item.id !== movedItem.id))
                setTableData(newData)
                const patchData =
                    tableWidget?.widget?.widgetFields &&
                    tableWidget?.widget?.widgetFields.filter(item => item.dataField.id !== movedItem.id)
                updateWidget({
                    widgetFields: patchData,
                })
            }
        }
    }
    const datasetForSelect =
        tableData[1]?.items &&
        tableData[1]?.items.reduce<SelectOptionsItem[]>((acc, { id, name }) => [...acc, { value: id, label: name }], [])
    const sortTypesForSelect = sortTypes.map(item => ({ value: item.id, label: item.name }))
    const handleDataset = (value: string | number) => {
        const matchedDataset = findFieldByID(value as string)
        const field = tableData[1]?.items.find(item => item.id === value)
        if (matchedDataset) {
            updateWidget({
                sortField: field,
            })
        }
    }
    const handleSortBy = (value: string | number) => {
        const sortType = sortTypes.find(item => item.id === (value as number))
        updateWidget({
            sortType,
        })
    }

    return (
        <div>
            <div className={styles.settingItem}>
                <p className={styles.settingItemTitle}>{t('create_widget.settings.columns')}</p>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div
                    className={`${styles.settingItemWrap} ${tableWidget?.isLoadingUpdateWidget ? styles.disa : ''} ${
                        styles.settingTableWrap
                    }`}
                >
                    {tableData.map((column, index) => (
                        <DroppableColumn
                            key={index}
                            type={column.type}
                            name={column.name}
                            columnID={column.id}
                            draggableItems={column.items}
                        />
                    ))}
                </div>
            </DragDropContext>
            <p style={{ marginTop: '12px' }} className={styles.settingItemTitle}>
                {t('create_widget.settings.sort_by')}
            </p>
            <div style={{ display: 'flex', gap: '12px' }} className={styles.settingItemWrap}>
                <div className={styles.settingField} style={{ flex: 1 }}>
                    <SelectList
                        options={datasetForSelect}
                        onChange={handleDataset}
                        selectedValue={tableWidget?.widget?.sortField?.id}
                    />
                </div>
                <div className={styles.settingField} style={{ flex: 1 }}>
                    <SelectList
                        options={sortTypesForSelect}
                        onChange={handleSortBy}
                        selectedValue={tableWidget?.widget?.sortType?.id ?? sortTypesForSelect[0]?.value}
                    />
                </div>
            </div>
        </div>
    )
}
