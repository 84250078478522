import React, { type FC } from 'react'
import { ConfigProvider, Modal } from 'antd'
import { CloseIcon } from '@src/components/StyleGuide'
import type { AntModalLayoutProps } from './AntModalLayout.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const AntModalLayout: FC<AntModalLayoutProps> = ({
    onCancel,
    isOpen,
    title,
    children,
    footer = null,
    className,
}) => {
    const { UISettings } = useAppSelector(({ appState }) => appState)

    const handleClose = () => {
        onCancel()
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        contentBg: UISettings?.theme === 'dark' ? 'hsla(214, 22%, 20%, 1)' : 'white',
                        headerBg: UISettings?.theme === 'dark' ? 'hsla(214, 22%, 20%, 1)' : 'white',
                        titleColor: UISettings?.theme === 'dark' ? 'white' : 'black',
                    },
                },
            }}
        >
            <Modal
                className={className}
                open={isOpen}
                onCancel={handleClose}
                closeIcon={<CloseIcon />}
                title={title}
                centered
                width='fit-content'
                style={{ background: 'rgba(4, 20, 38, 0.99)' }}
                footer={footer}
            >
                {children}
            </Modal>
        </ConfigProvider>
    )
}
