import { combineReducers } from 'redux'
import { user } from '@src/store/user/slice'
import dashboards from '@src/store/dashboards/slice'
import widgets from '@src/store/widgets/slice'
import { appState } from '@src/store/appState/slice'
import dictionary from '@src/store/dictionary/slice'
import { structures } from '@src/store/structures/slice'
import { searchWidgetInDashboard } from './searchWidgetInDashboard/slice'
import { grid } from '@src/store/grid/slice'
import { permissions } from './permissions/slice'
import { actions } from './actions/slice'

export const rootReducer = combineReducers({
    user,
    dashboards,
    widgets,
    appState,
    dictionary,
    structures,
    searchWidgetInDashboard,
    grid,
    permissions,
    actions,
})

export type RootState = ReturnType<typeof rootReducer>
