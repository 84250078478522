import React, { type FC } from 'react'
import { SelectDashboardPeriod, Switcher } from '@src/components'
import styles from './styles.module.scss'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { OnChangePeriod } from '@src/components/SelectDashboardPeriod/SelectDashboardPeriod.types'
import { FilterPanel } from '../FilterPanel/FilterPanel'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useTranslation } from 'react-i18next'

export const CreateWidgetSettings: FC = () => {
    const { currentWidget, widgetSettingsComponent, settingsTitle, widgetTypeID, updateWidget } = useWidget()
    const { t } = useTranslation()
    const direction = useAppSelector(({ appState }) => appState.UISettings?.createWidgetDirection)
    const isTextWidget = (widgetTypeID || 1) === 1
    const widgetsWithDataLabels = widgetTypeID === 4 || widgetTypeID === 5 || widgetTypeID === 6

    const dateValues = currentWidget?.widget
        ? { dateFrom: currentWidget.widget.dateFrom, dateTo: currentWidget.widget.dateTo }
        : undefined

    const handleSavePeriod: OnChangePeriod = (periodType, date) => {
        if (periodType) {
            updateWidget({
                periodType,
                dateFrom: date.dateFrom,
                dateTo: date.dateTo,
            })
        }
    }
    const handleShowLabel = (status: boolean) => {
        updateWidget({
            isLabelsVisible: status,
        })
    }

    return (
        <div className={`${styles.root} ${direction === 'horizontal' ? styles.horizontal : ''}`}>
            <div className={styles.wrap}>
                <div className={styles.settingItem}>
                    <p className={styles.settingItemTitle}>{settingsTitle}</p>
                    {!isTextWidget ? (
                        <div className={styles.settingPeriod}>
                            <SelectDashboardPeriod
                                values={dateValues}
                                isCreatePage
                                defaultPeriodID={5}
                                selectedPeriodType={currentWidget?.widget?.periodType}
                                className={styles.createPagePeriod}
                                onChangePeriod={handleSavePeriod}
                            />
                            {widgetsWithDataLabels && (
                                <div className={styles.settingSwitchWrap}>
                                    <p className={styles.settingSwitchTitle}>
                                        {t('create_widget.settings.data_label')}
                                    </p>
                                    <Switcher
                                        value={currentWidget?.widget?.isLabelsVisible ?? false}
                                        onChange={handleShowLabel}
                                    />
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
                {widgetSettingsComponent}
            </div>
            <div className={styles.wrap}>{!isTextWidget && <FilterPanel />}</div>
        </div>
    )
}
