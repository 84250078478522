type ParamsTypes = string | number | boolean | undefined | null

export const createURLQueryParams = (params: Record<string, ParamsTypes | ParamsTypes[]>) => {
    const query = new URLSearchParams()
    Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
            values.forEach(value => {
                query.append(key, `${value}`)
            })
        } else if (values || typeof values === 'number' || typeof values === 'boolean') {
            query.append(key, `${values}`)
        }
    })
    return query.toString() ? `?${query.toString()}` : ''
}
