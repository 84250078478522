import React, { type FC, useRef, type MouseEvent, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import type { HorizontalPosition, MenuLayoutProps, VerticalPosition } from './MenuLayout.types'
import { useWindowSize } from 'usehooks-ts'

export const MenuLayout: FC<MenuLayoutProps> = ({
    children,
    className,
    setIsMenuActive,
    verticalPosition,
    horizontalPosition,
    menuElement,
    preventBubbling = true,
    onlyHorizontalPosition = false,
    hoverActive = false,
}) => {
    const menuRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [vertical, setVertical] = useState<VerticalPosition>('bottom')
    const [horizontal, setHorizontal] = useState<HorizontalPosition>('right')
    const { width, height } = useWindowSize()
    useEffect(() => {
        if (verticalPosition && verticalPosition !== vertical) setVertical(verticalPosition)
    }, [verticalPosition])
    useEffect(() => {
        if (horizontalPosition && horizontalPosition !== horizontal) setHorizontal(horizontalPosition)
    }, [horizontalPosition])

    useEffect(() => {
        if (isMenuOpen && !hoverActive) menuRef.current?.focus()
    }, [isMenuOpen, hoverActive])

    useEffect(() => {
        const menuWidth = menuRef.current?.getBoundingClientRect().width
        const menuXPosition = buttonRef.current?.getBoundingClientRect().left
        if (menuWidth && menuXPosition && menuWidth + menuXPosition > width) setHorizontal('left')
        else setHorizontal(horizontalPosition ?? 'left')
    }, [width, horizontalPosition, isMenuOpen])
    useEffect(() => {
        const menuHeight = containerRef.current?.getBoundingClientRect().height
        const menuYPosition = buttonRef.current?.getBoundingClientRect().bottom
        if (menuHeight && menuYPosition && menuHeight + menuYPosition > height) setVertical('top')
        else setVertical(verticalPosition ?? 'bottom')
    }, [height, verticalPosition, isMenuOpen])

    const handleContainerEvent = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setIsMenuOpen(false)
        setIsMenuActive(false)
    }
    const handleMenuTrigger = (e: MouseEvent<HTMLDivElement>) => {
        if (preventBubbling) e.stopPropagation()
        if (!hoverActive && !isMenuOpen) {
            setIsMenuOpen(!isMenuOpen)
            setIsMenuActive(!isMenuOpen)
        }
    }
    useEffect(() => {
        if (!isMenuOpen) setIsMenuActive(false)
    }, [isMenuOpen])
    const vericalStyles = () => {
        if (onlyHorizontalPosition) return ''
        return vertical === 'top' ? styles.top : styles.bottom
    }

    return (
        <div
            ref={buttonRef}
            className={`${styles.wrapper} ${vericalStyles()} ${horizontal === 'right' ? styles.right : styles.left} ${
                isMenuOpen ? styles.displayed : ''
            }`}
            onClick={handleMenuTrigger}
            onMouseEnter={() => hoverActive && setIsMenuOpen(() => true)}
            onMouseLeave={() => hoverActive && setIsMenuOpen(() => false)}
        >
            {menuElement}
            <div
                ref={menuRef}
                tabIndex={-1}
                onBlur={() => setIsMenuOpen(false)}
                className={`${styles.root} ${className ?? ''}`}
                onMouseEnter={() => hoverActive && setIsMenuOpen(() => true)}
            >
                <div ref={containerRef} onClick={handleContainerEvent} className={`${styles.container}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}
