import { type DashboardResponse } from '@src/api/dashboardApi/dashboard.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { appStateActions } from '@src/store/appState/slice'
import { useMemo, useCallback, useEffect } from 'react'
import { type DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { fetchDashboardsList, setDashboardsList } from '@src/store/dashboards/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'

export const useData = () => {
    const UISettings = useAppSelector(({ appState }) => appState.UISettings)
    const { dashboardsList: list } = useDashboard()
    const isSettingsOpen = UISettings?.isDashboardPanelVisible
    const isHiddenDashboardsOnPanelVisible = UISettings?.isHiddenDashboardsOnPanelVisible ?? true

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchDashboardsList())
    }, [])

    const sortDashboards = useCallback(() => {
        const order = UISettings?.dashboardsOrder ?? []
        const unsavedDashboards = list.filter(dashboard => !order.includes(dashboard.id))
        const sortSavedDashboards: DashboardResponse[] = []
        order.forEach(id => {
            const findedDashboard = list.find(dashboard => dashboard.id === id)
            if (findedDashboard) sortSavedDashboards.push(findedDashboard)
        })
        const sortedList = [...sortSavedDashboards, ...unsavedDashboards]
        return sortedList
    }, [UISettings?.dashboardsOrder, list])
    const dashboardsList = useMemo(() => {
        const sortedList = sortDashboards()
        if (isHiddenDashboardsOnPanelVisible) return sortedList
        return sortedList.filter(dashboard => !dashboard.isHidden)
    }, [isHiddenDashboardsOnPanelVisible, sortDashboards])

    const handleOpenDashboardsPanel = () => {
        dispatch(
            appStateActions.fetchUpdateUISettings({
                isDashboardPanelVisible: !isSettingsOpen,
            }),
        )
        dispatch(
            appStateActions.setLocalUISettings({
                isDashboardPanelVisible: !isSettingsOpen,
            }),
        )
    }

    const handleVisabilityHiddenDashboards = () => {
        dispatch(
            appStateActions.fetchUpdateUISettings({
                isHiddenDashboardsOnPanelVisible: !isHiddenDashboardsOnPanelVisible,
            }),
        )
        dispatch(
            appStateActions.setLocalUISettings({
                isHiddenDashboardsOnPanelVisible: !isHiddenDashboardsOnPanelVisible,
            }),
        )
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        const from = dashboardsList.findIndex(elem => elem.id === active.id)
        const to = dashboardsList.findIndex(elem => elem.id === over?.id)
        const result = arrayMove(dashboardsList, from, to)
        const order = result.map(item => item.id)
        dispatch(appStateActions.setLocalUISettings({ dashboardsOrder: order }))
        dispatch(setDashboardsList(result))
    }
    return {
        isSettingsOpen,
        isHiddenDashboardsOnPanelVisible,
        dashboardsList,
        handleOpenDashboardsPanel,
        handleVisabilityHiddenDashboards,
        handleDragEnd,
    }
}
