import React, { useCallback, useEffect, useState } from 'react'
import { CheckBox } from '../StyleGuide'
import type { DataType } from './AccesRights.types'
import { type ColumnsType } from 'antd/es/table'
import styles from './style.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useDatasource } from '@src/hooks/useDatasource/useDatasource'
import { useTranslation } from 'react-i18next'
import { HighlightText } from '../HighlightText/HighlightText'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { permissionsActions } from '@src/store/permissions/slice'

export const useAccessRightsData = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useAppSelector(({ appState }) => appState.UISettings?.locale)
    const unsavedFields = useAppSelector(({ permissions }) => permissions.unsavedFields)
    const [tableData, setTableData] = useState<DataType[]>([])
    const [oldTableData, setOldTableData] = useState<DataType[]>([])
    const [cols, setCols] = useState<ColumnsType<DataType>>([])
    const [clientID, setClientID] = useState<number | null>(null)
    const [dataSetId, setDataSetId] = useState<string | null>(null)
    const [searchInput, setSearchInput] = useState<string>('')
    const [searchParams] = useSearchParams()

    const datasourceID = Number(searchParams.get('datasourceID'))
    const usersPermissions = useAppSelector(({ permissions }) => permissions.dataPermissionsList)
    const isLoadingDataPermissionsList = useAppSelector(({ permissions }) => permissions.isLoadingDataPermissionsList)
    const isLoadingUpdateUserDataPermissions = useAppSelector(
        ({ permissions }) => permissions.isLoadingUpdateUserDataPermissions,
    )
    const { datasetsListOptions, adminClientsOptions, datasourceList, client, usersList } = useDatasource({
        datasetID: dataSetId,
        datasourceID: datasourceID || null,
        clientID,
    })
    const handleSelectAllRow = useCallback(
        (data: DataType[]) => {
            const selectAllRow: DataType = {
                key: 0,
                name: t('access_page.select_all'),
                role: 'user',
            }
            if (data[0]) {
                const keys = Object.keys(data[0]).filter(key => key !== 'key' && key !== 'name' && key !== 'role')

                keys.forEach(key => {
                    selectAllRow[key] = data.filter(row => row.key !== 0).every(row => row[key].isAllowed)
                })
                if (data[0].key !== 0) {
                    return [selectAllRow, ...data]
                }
                if (data[0].key === 0) {
                    const copyData = [...data]
                    copyData.splice(0, 1, selectAllRow)
                    return [...copyData]
                }
            }
            return []
        },
        [location],
    )
    useEffect(() => {
        if (adminClientsOptions.length) setClientID(adminClientsOptions[0].value)
    }, [adminClientsOptions])
    useEffect(() => {
        datasetsListOptions.length ? setDataSetId(datasetsListOptions[0].value) : setDataSetId('')
    }, [datasetsListOptions])
    useEffect(() => {
        if (!isLoadingDataPermissionsList && !isLoadingUpdateUserDataPermissions && datasourceID && dataSetId) {
            const newData =
                usersPermissions?.map(elem => {
                    const fieldsPermissions: Record<string, any> = {}
                    elem.fields.forEach(field => {
                        fieldsPermissions[field.field.id] = field
                    })
                    return {
                        key: elem.user.id,
                        name: elem.user.name,
                        role: elem.user.isAdmin ? 'admin' : 'user',
                        ...fieldsPermissions,
                    } as DataType
                }) ?? []
            const tableDataWithSelectAll = handleSelectAllRow(newData)
            setTableData(tableDataWithSelectAll)
            setOldTableData(tableDataWithSelectAll)
        } else {
            setTableData([])
            setOldTableData([])
        }
    }, [
        usersPermissions,
        handleSelectAllRow,
        usersList,
        isLoadingDataPermissionsList,
        isLoadingUpdateUserDataPermissions,
        datasourceID,
        dataSetId,
    ])

    useEffect(() => {
        if (clientID) {
            const columns: any = (
                client?.datasets?.map(dataset =>
                    dataset.groups.map(group => ({
                        title: group.name,
                        dataIndex: group.id,
                        align: 'center',
                        children: [
                            ...group.objects.map(obj => ({
                                title: obj.name,
                                dataIndex: obj.id,
                                align: 'center',
                                children: [
                                    ...obj.fields.map(field => ({
                                        title: field.name,
                                        dataIndex: field.id,
                                        align: 'center',
                                        render: (_: any, record: any, index: number) => {
                                            return (
                                                <div className={styles.checkWrapper}>
                                                    <CheckBox
                                                        label=''
                                                        isChecked={
                                                            record.key === 0
                                                                ? record[field.id]
                                                                : record[field.id].isAllowed
                                                        }
                                                        disabled={record.role === 'admin'}
                                                        onChange={(checked: boolean) => {
                                                            if (record.key === 0)
                                                                handleSelectAll(field.id as string, checked)
                                                            if (record.key !== 0)
                                                                handleCheckbox(
                                                                    record,
                                                                    field.id as string,
                                                                    checked,
                                                                    index,
                                                                )
                                                        }}
                                                    />
                                                </div>
                                            )
                                        },
                                    })),
                                ],
                            })),
                        ],
                    })),
                ) ?? []
            ).flat(1)
            const newCols = [
                {
                    title: t('access_page.users'),
                    dataIndex: 'name',
                    render: (text: string) => <HighlightText text={text} searchInput={searchInput} />,
                },
                ...columns,
            ]
            setCols(newCols)
        }
    }, [datasourceList, clientID, datasourceID, tableData, location, searchInput])

    const handleSelectAll = (key: string, value: boolean) => {
        const changedPropertiesData = tableData.map(elem => {
            if (elem.key === 0) return { ...elem, [key]: value }
            if (elem.role === 'admin') return { ...elem, [key]: { ...elem[key], isAllowed: true } }
            return { ...elem, [key]: { ...elem[key], isAllowed: value } }
        })
        setTableData(changedPropertiesData)
        handleColumnFieldsChangeStatus(key, value)
    }

    const handleColumnFieldsChangeStatus = (key: string, value: boolean) => {
        const filteredFields = oldTableData
            .filter(elem => elem.key !== 0 && elem.role !== 'admin')
            .map(elem => elem[key])
        const changedColumnFields = filteredFields
            .filter(elem => elem.isAllowed !== value)
            .map(elem => ({ ...elem, isAllowed: value }))
        const filteredChangedFields = unsavedFields.filter(elem => elem.field.id !== key)
        dispatch(permissionsActions.setUnsavedFields([...filteredChangedFields, ...changedColumnFields]))
    }

    const handleCheckbox = (record: any, key: string, value: boolean, index: number) => {
        const changedPropertiesData = tableData.map(elem => {
            return elem.key === record.key
                ? {
                      ...record,
                      [key]: { ...record[key], isAllowed: value },
                  }
                : elem
        })
        const tableDataWithSelectAll = handleSelectAllRow(changedPropertiesData)
        setTableData(tableDataWithSelectAll)
        handleSingleFieldChangeStatus(record, key, value)
    }
    const handleSingleFieldChangeStatus = (record: any, key: string, value: boolean) => {
        const wasFieldChanged = unsavedFields.some(
            field => field.userID === record[key].userID && field.field.id === key,
        )
        if (wasFieldChanged) {
            dispatch(
                permissionsActions.setUnsavedFields(
                    unsavedFields.filter(field => field.userID !== record[key].userID || field.field.id !== key),
                ),
            )
        }
        if (!wasFieldChanged) {
            dispatch(permissionsActions.setUnsavedFields([...unsavedFields, { ...record[key], isAllowed: value }]))
        }
    }

    const handleSaveUsersPermissions = (onSuccess?: () => void) => {
        dispatch(
            permissionsActions.fetchUpdateUsersDataPermissions({
                data: unsavedFields,
                onSuccess: () => {
                    dispatch(permissionsActions.setUnsavedFields([]))
                    onSuccess?.()
                },
            }),
        )
    }

    return {
        tableData,
        cols,
        datasetsListOptions,
        adminClientsOptions,
        clientID,
        dataSetId,
        setClientID,
        setDataSetId,
        setSearchInput,
        handleSaveUsersPermissions,
    }
}
