import { type AllEffect, type ForkEffect, call, put, all, takeLatest, takeEvery } from 'redux-saga/effects'
import { actionsActions } from './slice'
import { actionsApi } from '@src/api/actions/actions.api'
import type { CommandsListResponse, ExecuteCommandResponse, FormsListResponse } from '@src/api/actions/actions.types'
import type { FetchExecuteCommandPayload } from './actions.types'

function* fetchGetCommandsListWorker() {
    try {
        const res: CommandsListResponse = yield call(actionsApi.getCommandsList)
        if (res) {
            yield put(actionsActions.setGetCommandsList(res))
        }
    } catch (err) {
        yield put(actionsActions.errorGetCommandsList())
    }
}
function* fetchGetFormsListWorker() {
    try {
        const res: FormsListResponse = yield call(actionsApi.getFormsList)
        if (res) {
            yield put(actionsActions.setGetFormsList(res))
        }
    } catch (err) {
        yield put(actionsActions.errorGetFormsList())
    }
}
function* fetchExecuteCommandWorker({ payload }: FetchExecuteCommandPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: ExecuteCommandResponse = yield call(actionsApi.executeCommand, body)
        if (res) {
            yield put(actionsActions.setExecuteCommand())
            onSuccess?.()
        }
    } catch (err) {
        yield put(actionsActions.errorExecuteCommand())
    }
}

export default function* actionsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(actionsActions.fetchGetCommandsList, fetchGetCommandsListWorker),
        takeLatest(actionsActions.fetchGetFormsList, fetchGetFormsListWorker),
        takeEvery(actionsActions.fetchExecuteCommand, fetchExecuteCommandWorker),
    ])
}
