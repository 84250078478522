import React, { useState } from 'react'
import styles from '../styles.module.scss'
import popupStyles from './ShareAccess.styles.module.scss'
import {
    AddUserIcon,
    CopyIcon,
    DeleteIcon,
    EditIcon,
    ShowIcon,
    SubMenuIcon,
    UnViewIcon,
} from '@src/components/StyleGuide'
import { MenuLayout } from '@src/layouts'
import { useTranslation } from 'react-i18next'
import { type SubMenuProps } from './SubMenu.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'

export const SubMenu = ({ onCopy, onDelete, onEditName, onHide, onShare, dashboardId }: SubMenuProps) => {
    const { t } = useTranslation()
    const [isMenuActive, setIsMenuActive] = useState(false)
    const { currentDashboard, isUserAdminInDashboard } = useDashboard({ id: dashboardId })
    const UISettings = useAppSelector(({ appState }) => appState.UISettings)
    const isDashboardHidden = currentDashboard?.isHidden ?? UISettings?.hidenDashboardIDs?.includes(dashboardId)

    const menuOptions = [
        {
            id: 1,
            icon: <EditIcon />,
            text: t('dashboard_control_panel.options.edit_name'),
            isShow: isUserAdminInDashboard(),
            event: onEditName,
        },
        {
            id: 2,
            icon: <DeleteIcon />,
            text: t('dashboard_control_panel.options.delete'),
            isShow: isUserAdminInDashboard(),
            event: onDelete,
        },
        {
            id: 3,
            icon: isDashboardHidden ? <ShowIcon /> : <UnViewIcon />,
            text: isDashboardHidden
                ? t('dashboard_control_panel.options.show')
                : t('dashboard_control_panel.options.hide'),
            isShow: isUserAdminInDashboard(),
            event: onHide,
        },
        {
            id: 4,
            icon: <AddUserIcon />,
            text: t('dashboard_control_panel.options.share'),
            isShow: currentDashboard?.isAdminAccess,
            event: onShare,
        },
        {
            id: 5,
            icon: <CopyIcon />,
            text: t('dashboard_control_panel.options.copy'),
            isShow: true,
            event: onCopy,
        },
    ]

    return (
        <MenuLayout
            menuElement={
                <button
                    title={t('widgets.counter.settings_btn')}
                    className={`${styles.button} ${isMenuActive ? styles.hover : ''}`}
                >
                    <SubMenuIcon />
                </button>
            }
            setIsMenuActive={setIsMenuActive}
            className={popupStyles.accessMenu}
            horizontalPosition='right'
        >
            {menuOptions.map(({ id, icon, text, isShow, event }) =>
                isShow ? (
                    <div key={id} onClick={event}>
                        {icon}
                        <p>{text}</p>
                    </div>
                ) : null,
            )}
        </MenuLayout>
    )
}
