import { structuresApi } from '../../api/structures/structures.api'
import { type AllEffect, type ForkEffect, call, put, all, takeLatest, takeEvery } from 'redux-saga/effects'
import type { FetchDatasetPayload, FetchDatasetsListPayload, FetchDatasourcePayload } from './structures.types'
import { structureActions } from './slice'
import type { DatasetResponse, DatasetsListResponse, DatasourceResponse } from '@src/api/structures/structures.types'

function* fetchDatasourceWorker({ payload }: FetchDatasourcePayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DatasourceResponse = yield call(structuresApi.getDatasource, body)
        if (res) {
            yield put(structureActions.setDatasource(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(structureActions.errorDatasource())
    }
}
function* fetchDatasetWorker({ payload }: FetchDatasetPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DatasetResponse = yield call(structuresApi.getDataset, body)
        if (res) {
            yield put(structureActions.setDataset(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(structureActions.errorDataset())
    }
}
function* fetchDatasetslistWorker({ payload }: FetchDatasetsListPayload) {
    try {
        const { onSuccess, ...body } = payload
        const res: DatasetsListResponse = yield call(structuresApi.getDatasetsList, body)
        if (res) {
            yield put(structureActions.setDatasetsList(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(structureActions.errorDatasetsList())
    }
}

export default function* structuresSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(structureActions.fetchDataset, fetchDatasetWorker),
        takeLatest(structureActions.fetchDatasetsList, fetchDatasetslistWorker),
        takeEvery(structureActions.fetchDatasource, fetchDatasourceWorker),
    ])
}
