import React, { type FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import type { WidgetType } from '@src/api/dictionary/dictionary.types'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { CreateWidgetTypesProps } from './CreateWidgetTypes.types'

export const CreateWidgetTypes: FC<CreateWidgetTypesProps> = ({ className, isCreatePage, onClick, activeType }) => {
    const { widgetTypes } = useAppSelector(({ dictionary }) => dictionary)
    const { widgetIcon } = useWidget({})
    const handleClick = (type: WidgetType) => onClick?.(type)
    const handleCreateWidgetByType = (type: WidgetType) => {
        handleClick(type)
    }

    const renderButtons = widgetTypes.map(({ id, name }) => {
        if (isCreatePage) {
            return (
                <button
                    key={id}
                    className={`${styles.btn} ${activeType?.id === id ? styles.active : ''} `}
                    title={name}
                    onClick={() => handleClick({ id, name })}
                >
                    {widgetIcon(id)}
                </button>
            )
        } else {
            return (
                <Link
                    to={'/create-widget'}
                    key={id}
                    className={styles.btn}
                    title={name}
                    onClick={() => handleCreateWidgetByType({ id, name })}
                >
                    {widgetIcon(id)}
                </Link>
            )
        }
    })

    return <div className={`${styles.root} ${className ?? ''}`}>{renderButtons}</div>
}
