import React from 'react'
import styles from './styles.module.scss'

/**
 * Interface for ButtonWithIcon
 * @interface IButtonWithIcon
 * @param {string} className - Optional class name for the button
 * @param {React.CSSProperties} style - Optional CSS properties for the button
 * @param {React.ReactNode} icon - Optional icon for the button
 * @param {string} title - Required title for the button
 * @param {boolean} disabled - Optional disabled status for the button
 */

interface IButtonWithIcon {
    id?: string
    className?: string
    style?: React.CSSProperties
    icon?: React.ReactNode
    text?: string
    title?: string
    disabled?: boolean
    isActive?: boolean
    isBoarded?: boolean
    onClick?: () => void
}

export const ButtonWithIcon: React.FC<IButtonWithIcon> = ({
    id,
    className,
    style,
    icon,
    text,
    title,
    disabled,
    isActive,
    isBoarded,
    onClick,
}) => {
    const handleClick = () => {
        onClick?.()
    }

    return (
        <button
            id={id}
            className={`${styles.root} ${isBoarded ? 'boarded' : ''} ${className ?? ''} ${
                isActive ? styles.active : ''
            } ${styles[className ?? ''] ?? ''}`}
            style={style ?? {}}
            disabled={disabled}
            onClick={handleClick}
            title={title}
        >
            {icon}
            {text && <span>{text}</span>}
        </button>
    )
}
