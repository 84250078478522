import type { PeriodType } from '@src/api/dictionary/dictionary.types'
import { ChangeAllWidgetsDateModal } from '@src/components/Modals/ChangeAllWidgetsDateModal/ChangeAllWidgetsDateModal'
import { SelectDashboardPeriod } from '@src/components/SelectDashboardPeriod/SelectDashboardPeriod'
import type { DatePeriods } from '@src/components/SelectDashboardPeriod/SelectDashboardPeriod.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { fetchDashboardWidgetsList, setTemporaryWidgetsDates } from '@src/store/widgets/slice'
import React, { type FC, useEffect, useState } from 'react'

type ActivePeriodHistoryType = {
    prevActivePeriod: PeriodType | null
    currentActivePeriod: PeriodType | null
}
export const WidgetsPeriodPanel = () => {
    const { activeDashboard } = useDashboard()
    const dispatch = useAppDispatch()
    const [dateRange, setDateRange] = useState<DatePeriods | null>(null)
    const [activePeriodHistory, setActivePeriodHistory] = useState<ActivePeriodHistoryType>({
        prevActivePeriod: null,
        currentActivePeriod: null,
    })
    const dontAskAgainPickTemporaryDatesForWidgets = useAppSelector(
        state => state.appState.UISettings?.dontAskAgainPickTemporaryDatesForWidgets,
    )
    const temporaryWidgetsDates = useAppSelector(state => state.widgets.temporaryWidgetsDates)
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)

    useEffect(() => {
        if (temporaryWidgetsDates || dateRange) {
            dispatch(setTemporaryWidgetsDates(null))
            setDateRange(null)
            setActivePeriodHistory({ prevActivePeriod: null, currentActivePeriod: null })
        }
    }, [])

    const handleRefreshDashboard = (date?: DatePeriods) => {
        if (activeDashboard)
            dispatch(
                fetchDashboardWidgetsList({
                    dashboardID: activeDashboard.id,
                    dateFrom: date?.dateFrom,
                    dateTo: date?.dateTo,
                    onSuccess: () => setIsWarningModalOpen(false),
                }),
            )
    }
    const handlePickPeriod = (periodType: PeriodType, date: DatePeriods) => {
        setDateRange(date)
        setActivePeriodHistory(prev => ({ ...prev, currentActivePeriod: periodType }))
        if (dontAskAgainPickTemporaryDatesForWidgets && activeDashboard) {
            handleRefreshDashboard(date)
            dispatch(setTemporaryWidgetsDates(date))
        }
        if (!dontAskAgainPickTemporaryDatesForWidgets) setIsWarningModalOpen(true)
    }

    const handleAcceptWarningModal = () => {
        if (dateRange) {
            handleRefreshDashboard(dateRange)
            dispatch(setTemporaryWidgetsDates(dateRange))
            setActivePeriodHistory(prev => ({ ...prev, prevActivePeriod: prev.currentActivePeriod }))
        }
    }

    const onCancelWarningModal = () => {
        setIsWarningModalOpen(false)
        setDateRange(temporaryWidgetsDates)
        setActivePeriodHistory(prev => ({ ...prev, currentActivePeriod: prev.prevActivePeriod }))
    }

    const closeDatePanel = () => {
        if (temporaryWidgetsDates || dateRange) {
            dispatch(setTemporaryWidgetsDates(null))
            setDateRange(null)
            handleRefreshDashboard()
            setActivePeriodHistory({ prevActivePeriod: null, currentActivePeriod: null })
        }
    }

    return (
        <>
            <SelectDashboardPeriod
                onClose={closeDatePanel}
                values={dateRange}
                onChangePeriod={handlePickPeriod}
                selectedPeriodType={activePeriodHistory.currentActivePeriod}
            />
            <ChangeAllWidgetsDateModal
                isOpen={isWarningModalOpen}
                onCancel={onCancelWarningModal}
                onApply={handleAcceptWarningModal}
            />
        </>
    )
}
