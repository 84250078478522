import React, { type ChangeEvent, useState } from 'react'
import type { CopyDashboardModalProps } from '@src/components/Modals/DashboardOptionsModals/@types'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from '@src/layouts'
import styles from '@src/components/Modals/styles.module.scss'
import { ApplyIcon, ButtonPrimary, CancelIcon } from '@src/components'

export const ModalCopy: React.FC<CopyDashboardModalProps> = ({ dashboardTitle, setModalOpen, onAccept }) => {
    const { t } = useTranslation()
    const [name, setName] = useState(`${dashboardTitle} - ${t('dashboard_control_panel.modal_copy.copy')}`)

    const handleCancel = () => {
        setModalOpen(false)
    }

    return (
        <ModalLayout setIsModalOpen={setModalOpen} title={t('dashboard_control_panel.modal_copy.title')}>
            <div className={styles.root}>
                <div className={styles.wrap}>
                    <p className={styles.titlePlaceholder}>{t('dashboard_control_panel.modals_placeholder')}</p>
                    <p className={styles.title}>{dashboardTitle}</p>
                </div>
                <div className={styles.checkWrap}>
                    <p className={styles.label}>{t('dashboard_control_panel.modal_copy.placeholder')}</p>
                    <input
                        type='text'
                        value={name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                </div>
                <div className={styles.btnWrap}>
                    <ButtonPrimary
                        className={styles.btnUnView}
                        icon={<ApplyIcon />}
                        title={t('dashboard_control_panel.modal_copy.copy_btn')}
                        type='primary'
                        onClick={() => onAccept(name)}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('dashboard_control_panel.modal_cancel_btn')}
                        type='transparent'
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}
