import React from 'react'
import styles from '../styles.module.scss'
import { t } from 'i18next'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { ColorPicker } from '@src/components'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useAggregateFunctions } from '@src/hooks/useAggregateFunctions/useAggregateFunctions'
import { GoalPanel } from '../../GoalPanel/GoalPanel'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'
import { DEFAULT_COUNTER_COLOR } from '@src/config/constants'

export const CounterSettings = () => {
    const { counterWidget, updateWidget } = useWidget()
    const { aggregateFunctionOptions, getAggregateFunctionType } = useAggregateFunctions(
        counterWidget?.widget?.calcField?.fieldType?.id,
    )

    const { allowedUserDataOptions, findFieldByID } = useUserFields({
        datasourceTypeID: counterWidget?.listData.dataSourceType.id,
        datasetID: counterWidget?.listData.dataset.id,
    })

    const handleDataset = (value: string | number) => {
        const matchedDataset = findFieldByID(value as string)
        if (matchedDataset)
            updateWidget({
                calcField: { ...matchedDataset },
                aggregateFunctionType: { id: 2, name: 'Count' },
            })
    }
    const handleAggregateFunction = (value: string | number) => {
        const funcType = getAggregateFunctionType(value as number)
        if (funcType) updateWidget({ aggregateFunctionType: funcType })
    }

    const handleGoalActivate = (isGoal: boolean) => {
        updateWidget({ isGoalEnabled: isGoal, goal: isGoal ? 0 : null })
    }
    const handleSetGoal = (goal: number) => {
        updateWidget({ goal })
    }

    const handleUpdateColor = (color: string) => {
        updateWidget({ defaultColor: color })
    }
    const handleEnableGoalColor = (status: boolean) => {
        updateWidget({ goalColor: status ? DEFAULT_COUNTER_COLOR : '' })
    }
    const handleChangeGoalColor = (color: string) => {
        updateWidget({ goalColor: color })
    }

    return (
        <>
            <div className={styles.settingItem}>
                <p className={styles.settingItemTitle}>{t('create_widget.settings.search_label')}</p>
                <div className={styles.settingItemWrap}>
                    <div className={styles.settingField} style={{ flex: 1 }}>
                        <span className={styles.settingFieldLabel}>
                            {t('create_widget.settings.field_params_label')}
                        </span>
                        <SelectList
                            isDisabled={
                                !counterWidget?.listData.dataSourceType.id || !counterWidget?.listData.dataset.id
                            }
                            options={allowedUserDataOptions}
                            onChange={handleDataset}
                            selectedValue={counterWidget?.widget?.calcField?.id ?? ''}
                        />
                    </div>
                    <div className={styles.settingField} style={{ flex: 1 }}>
                        <span className={styles.settingFieldLabel}>
                            {t('create_widget.settings.field_value_label')}
                        </span>
                        <SelectList
                            isDisabled={!counterWidget?.widget?.calcField?.id}
                            options={aggregateFunctionOptions}
                            onChange={handleAggregateFunction}
                            selectedValue={counterWidget?.widget?.aggregateFunctionType?.id ?? 0}
                        />
                    </div>
                    <div className={styles.settingField} style={{ flex: 'unset' }}>
                        <ColorPicker
                            colorValue={counterWidget?.widget?.defaultColor ?? DEFAULT_COUNTER_COLOR}
                            onChange={handleUpdateColor}
                        />
                    </div>
                </div>
            </div>
            <GoalPanel
                goal={counterWidget?.widget?.goal ?? 0}
                color={counterWidget?.widget?.goalColor}
                isActive={counterWidget?.widget?.isGoalEnabled ?? false}
                onGoalActivate={handleGoalActivate}
                onGoalChange={handleSetGoal}
                isCreateCounterWidgetPage={true}
                onColorChangeActivate={handleEnableGoalColor}
                onColorChange={handleChangeGoalColor}
            />
        </>
    )
}
