import React, { type FC, useState, type Dispatch, type SetStateAction } from 'react'
import styles from '../styles.module.scss'
import popupStyles from './ShareAccess.styles.module.scss'
import { AddUserIcon } from '@src/components/StyleGuide'
import { MenuLayout } from '@src/layouts'
import { useTranslation } from 'react-i18next'
import type { DashboardId } from '@src/api/dashboardApi/dashboard.types'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
type ShareAccessPopupProps = {
    isShared: boolean
    dashboardID: DashboardId['id']
    setShareAccessModal: Dispatch<SetStateAction<boolean>>
    isSharingAccess: boolean
}
export const ShareAccessPopup: FC<ShareAccessPopupProps> = ({
    isShared,
    dashboardID,
    setShareAccessModal,
    isSharingAccess,
}) => {
    const { t } = useTranslation()
    // State for Users Access list. Used by hover on Users Access btn
    const [accessListActive, setAccessListActive] = useState(false)
    // State for open/close modal menu
    const [isMenuActive, setIsMenuActive] = useState(false)
    const { currentDashboard } = useDashboard({ id: dashboardID })

    const handleShareModal = () => {
        setShareAccessModal(true)
        setIsMenuActive(!isMenuActive)
    }

    const renderAddedUsersList =
        currentDashboard?.users.map(user => (
            <p key={user.id} className={popupStyles.accessItem}>
                {user.name}
            </p>
        )) ?? null
    return (
        <>
            {isShared && (
                <div className={popupStyles.accessWrap}>
                    <MenuLayout
                        menuElement={
                            <button className={`${styles.button}${accessListActive ? ' ' + styles.hover : ''}`}>
                                <AddUserIcon />
                            </button>
                        }
                        setIsMenuActive={setAccessListActive}
                        hoverActive
                        horizontalPosition='right'
                        className={popupStyles.accessMenu}
                    >
                        <div className={popupStyles.accessList}>{renderAddedUsersList}</div>
                        {isSharingAccess && (
                            <div className={popupStyles.accessBtn} onClick={handleShareModal}>
                                <AddUserIcon />
                                <p>{t('dashboard_control_panel.options.share')}</p>
                            </div>
                        )}
                    </MenuLayout>
                </div>
            )}
        </>
    )
}
