import React, { useState, type FC, useEffect } from 'react'
import { WidgetMenuActions } from './WidgetMenuActions'
import { useActions } from '@src/hooks/useActions/useAction'
import type { WidgetMenuCommandsProps } from './WidgetMenuCommands.type'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { WidgetPinnedCommand } from '@src/api/widgets/widgets.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { actionsActions } from '@src/store/actions/slice'
import type { Command } from '@src/api/actions/actions.types'
import { ActionExecuteResultModal } from '@src/components/Modals/ActionExecuteResultModal/ActionExecuteResultModal'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { CantPinMoreItemsModal } from '@src/components/Modals/CantPinMoreItemsModal/CantPinMoreItemsModal'
import { useTranslation } from 'react-i18next'

export const WidgetMenuCommands: FC<WidgetMenuCommandsProps> = ({ widgetID, dashboardID }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [isOpenModalAfterExecute, setIsOpenModalAfterExecute] = useState(false)
    const [isOpenModalLimitPinned, setIsOpenModalLimitPinned] = useState(false)
    const [isAbleToPin, setIsAbleToPin] = useState(false)
    const [executedCommand, setExecutedCommand] = useState('')
    const [commands, setCommands] = useState<Command[]>([])
    const [pinnedCommands, setPinnedCommands] = useState<WidgetPinnedCommand[]>([])
    const { widgetTypeID, currentWidget, updateWidget } = useWidget({ widgetID, dashboardID })
    const { getCommandsListByWidgetType } = useActions()
    const fixedWigetsCount = useAppSelector(({ appState }) => appState.UISettings?.fixedWigetsCount)
    useEffect(() => {
        setCommands(getCommandsListByWidgetType(widgetTypeID))
    }, [getCommandsListByWidgetType, widgetTypeID])
    useEffect(() => {
        setPinnedCommands(currentWidget?.widget?.pinnedCommands ?? [])
    }, [currentWidget?.widget?.pinnedCommands])
    useEffect(() => {
        setIsAbleToPin(
            (currentWidget?.widget?.pinnedCommands?.length ?? 0) + (currentWidget?.widget?.pinnedForms?.length ?? 0) <
                (fixedWigetsCount ?? 3),
        )
    }, [currentWidget?.widget?.pinnedCommands, currentWidget?.widget?.pinnedForms, fixedWigetsCount])
    const handlePinAction = (commandID: number) => {
        const copyPinnedCommands = [...pinnedCommands]
        const command = commands.find(elem => elem.id === commandID)
        const isCommandPinned = copyPinnedCommands.some(elem => elem.command.id === commandID)
        if (isCommandPinned) {
            const removeCommand = copyPinnedCommands.filter(elem => elem.command.id !== commandID)
            setPinnedCommands(removeCommand)
            updateWidget({ pinnedCommands: removeCommand })
        }
        if (!isCommandPinned && command && isAbleToPin) {
            copyPinnedCommands.push({ command })
            setPinnedCommands(copyPinnedCommands)
            updateWidget({ pinnedCommands: copyPinnedCommands })
        }
        if (!isCommandPinned && command && !isAbleToPin) {
            setIsOpenModalLimitPinned(true)
        }
    }
    const handleExecuteCommand = (commandID: number) => {
        dispatch(actionsActions.fetchExecuteCommand({ commandID, onSuccess: () => setIsOpenModalAfterExecute(true) }))
        setExecutedCommand(commands.find(elem => elem.id === commandID)?.name ?? '')
    }
    const handleCloseModal = () => {
        setIsOpenModalAfterExecute(false)
        setExecutedCommand('')
    }
    return (
        <>
            {currentWidget && (
                <WidgetMenuActions
                    list={getCommandsListByWidgetType(widgetTypeID)}
                    title={t('commands.commands')}
                    pinnedList={pinnedCommands}
                    onClick={handleExecuteCommand}
                    onPin={handlePinAction}
                />
            )}
            {isOpenModalLimitPinned && (
                <CantPinMoreItemsModal
                    isOpen={isOpenModalLimitPinned}
                    onCancel={() => setIsOpenModalLimitPinned(false)}
                />
            )}
            {isOpenModalAfterExecute && (
                <ActionExecuteResultModal
                    isOpen={isOpenModalAfterExecute}
                    onCancel={handleCloseModal}
                    commandName={executedCommand}
                />
            )}
        </>
    )
}
