import type { UserData, UserState } from '@src/store/user/user.types'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { localStorageKeys } from '@src/config/localStorageKeys.enum'

const initialState: UserState = {
    userData: null,
    isAuthorized: false,
    isAuthorizationLoading: false,
    isAuthorizationError: false,
    authorizationError: '',
    isAdmin: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchAuthorization: state => {
            state.isAuthorizationLoading = true
        },
        setAuthorization: (state: UserState, { payload }: PayloadAction<UserData>) => {
            state.isAuthorized = true
            state.isAuthorizationLoading = false
            state.userData = payload
            localStorage.setItem(localStorageKeys.userData, JSON.stringify(payload))
        },
        errorAuthorization: (state, { payload }: PayloadAction<string>) => {
            state.isAuthorizationLoading = false
            state.isAuthorizationError = true
            state.authorizationError = payload
            state.userData = null
        },
        logOutAction: state => {
            state.userData = null
            state.isAuthorized = false
            localStorage.removeItem(localStorageKeys.userData)
        },
        setAdmin: (state, { payload }: PayloadAction<boolean>) => {
            state.isAdmin = payload
            localStorage.setItem('isAdmin', JSON.stringify(payload))
        },
    },
})

export const { reducer: user, actions: userActions } = userSlice
