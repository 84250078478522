import React, { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary, CancelIcon, CheckBox } from '@src/components/StyleGuide'
import { Title } from './Title'
import styles from './styles.module.scss'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import type { AntModalProps } from '@src/layouts/AntModalLayout/AntModalLayout.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'

export const WidgetNotEnoughtFreeSpace: FC<AntModalProps> = ({ onApply, onCancel, isOpen }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [dontAskAgain, setDontAskAgain] = useState(false)

    const handleApply = () => {
        onApply?.()
        if (dontAskAgain)
            dispatch(appStateActions.setLocalUISettings({ dontAskAgainReduceWidgetsSizeAttention: dontAskAgain }))
    }

    return (
        <AntModalLayout
            isOpen={isOpen}
            onCancel={onCancel}
            title={<Title title={t('widget_no_enought_free_space.title')} />}
        >
            <div className={styles.bodyWrapper}>
                <div className={styles.modalWarning}>
                    <p>{t('widget_no_enought_free_space.warning')}</p>
                </div>
                <p className={styles.modalDescription}>{t('widget_no_enought_free_space.description')}</p>
                <CheckBox
                    isChecked={dontAskAgain}
                    onChange={() => setDontAskAgain(!dontAskAgain)}
                    label={t('widget_no_enought_free_space.dont_show_again')}
                />
                <div className={styles.buttonsWrapper}>
                    <ButtonPrimary
                        icon={<ApplyIcon stroke='white' />}
                        title={t('buttons.confirm')}
                        type='primary'
                        onClick={handleApply}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('buttons.cancel')}
                        type='transparent'
                        onClick={onCancel}
                    />
                </div>
            </div>
        </AntModalLayout>
    )
}
