import { ButtonWithIcon, CancelIcon, CloseIcon, ReplaceIcon } from '@src/components/StyleGuide'
import { Modal } from 'antd'
import React, { useState, type FC, useCallback, useEffect } from 'react'
import './styles.scss'
import styles from './styles.module.scss'
import { CopyWidgetModalBody } from './CopyWidgetModalBody'
import { useTranslation } from 'react-i18next'
import type { CopyWidgetModalProps, WidgetNewProperties } from './CopyWidgetModal.types'
import { ModalTitle } from './ModalTitle'
import { useGridData } from '@src/layouts/GridLayout/hooks/useGridData'
import { createWidgetObjForGridWithoutOverflow } from '@src/layouts/GridLayout/GridLayout.functions'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { getCurrentDashboardWidgets, getCurrentDashboardLoading } from '@src/store/widgets/selectors'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'

export const CopyWidgetModal: FC<CopyWidgetModalProps> = ({ open, onConfirm, onCancel, widget }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [pickedDashboardID, setPickedDashboardID] = useState(0)
    const widgetName = widget?.name ?? ''
    const copySuffix = t('dashboard_control_panel.modal_copy.copy')
    const nameWithCopySuffix = `${widget?.name ?? ''} - ${t('dashboard_control_panel.modal_copy.copy')}`
    const [newName, setNewName] = useState(nameWithCopySuffix)
    const [newWidgetProperties, setNewWidgetProperties] = useState<WidgetNewProperties | undefined>()
    const [isCantCreateError, setIsCantCreateError] = useState(false)
    const [isWillReduceWidgetSizeWarning, setIsWillReduceWidgetSizeWarning] = useState(false)

    const currentDashboardWidgets = useAppSelector(store => getCurrentDashboardWidgets(store, pickedDashboardID))
    const currentDashboardWidgetsLoading = useAppSelector(store => getCurrentDashboardLoading(store, pickedDashboardID))
    useEffect(() => {
        const widgetNames = currentDashboardWidgets?.map(elem => elem.listData.name)
        if (widgetNames) {
            const matchedCopiesNames = widgetNames.filter(
                name => !!name && name.includes(nameWithCopySuffix),
            ) as string[]
            const copyName = matchedCopiesNames.map(name => {
                return Number(name.split(nameWithCopySuffix)[1].trim())
            })
            for (let i = 1; i <= 12; i += 1) {
                if (!copyName.includes(i)) {
                    setNewName(`${widget?.name ?? ''} - ${t('dashboard_control_panel.modal_copy.copy')} ${i}`)
                    break
                }
            }
        }
    }, [currentDashboardWidgets])

    const isDashboardLoading = !currentDashboardWidgets || !!currentDashboardWidgetsLoading

    const setID = (id: number) => {
        setPickedDashboardID(id)
    }
    const { emptyPlace } = useGridData(pickedDashboardID)
    useEffect(() => {
        if (emptyPlace.length === 0 && !isDashboardLoading) {
            setIsCantCreateError(true)
            setNewWidgetProperties(undefined)
            setIsWillReduceWidgetSizeWarning(false)
        }
        if (isDashboardLoading) {
            setIsCantCreateError(false)
            setNewWidgetProperties(undefined)
            setIsWillReduceWidgetSizeWarning(false)
        }
    }, [emptyPlace, isDashboardLoading])

    useEffect(() => {
        if (pickedDashboardID && !currentDashboardWidgets)
            dispatch(
                fetchDashboardWidgetsList({
                    dashboardID: pickedDashboardID,
                }),
            )
    }, [pickedDashboardID, currentDashboardWidgets])

    useEffect(() => {
        if (emptyPlace.length && widget) {
            const widgetParams = createWidgetObjForGridWithoutOverflow(widget)
            const widgetSize = widgetParams.positions.length
            const newCellForWidgetCopy = emptyPlace.find(([y, x], _, source) => {
                const newMaxY = y + widgetParams.h - 1
                const newMaxX = x + widgetParams.w - 1
                const availableFreePlaces = source.filter(
                    elem => elem[0] >= y && elem[0] <= newMaxY && elem[1] >= x && elem[1] <= newMaxX,
                )
                return availableFreePlaces.length === widgetSize
            })
            if (newCellForWidgetCopy && pickedDashboardID) {
                setNewWidgetProperties({
                    dashboardID: pickedDashboardID,
                    name: newName,
                    positionY: newCellForWidgetCopy[0],
                    positionX: newCellForWidgetCopy[1],
                })
                setIsWillReduceWidgetSizeWarning(false)
                setIsCantCreateError(false)
            }
            if (!newCellForWidgetCopy && pickedDashboardID && emptyPlace.length) {
                setNewWidgetProperties({
                    dashboardID: pickedDashboardID,
                    name: newName,
                    positionY: emptyPlace[0][0],
                    positionX: emptyPlace[0][1],
                    cellsX: 1,
                    cellsY: 1,
                })
                setIsWillReduceWidgetSizeWarning(true)
                setIsCantCreateError(false)
            }
        }
    }, [emptyPlace, newName])

    const handleOnConfirm = () => {
        if (newWidgetProperties) onConfirm(newWidgetProperties)
    }

    return (
        <AntModalLayout
            isOpen={open}
            onCancel={onCancel}
            title={<ModalTitle text={t('dashboard_control_panel.modal_copy.title')} />}
            footer={
                <div className={styles.footerContainer}>
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.okBtn}`}
                        icon={<ReplaceIcon />}
                        text={t('dashboard_control_panel.modal_copy.copy_btn')}
                        disabled={isCantCreateError || isDashboardLoading}
                        onClick={handleOnConfirm}
                    />
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.cancelBtn}`}
                        icon={<CancelIcon />}
                        text={t('buttons.cancel')}
                        onClick={onCancel}
                    />
                </div>
            }
        >
            <CopyWidgetModalBody
                isWillReduceWidgetSizeWarning={isWillReduceWidgetSizeWarning}
                widgetName={widget?.name ?? ''}
                widgetNewName={newName}
                setName={setNewName}
                pickedDashboardID={pickedDashboardID}
                isCantCreateError={isCantCreateError}
                setPickedDashboardID={setID}
            />
        </AntModalLayout>
    )
}
