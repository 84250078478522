import React from 'react'
import styles from './styles.module.scss'
import { LangSetting } from '@src/components/SystemSettings/LangSetting/LangSetting'
import { ThemeSetting } from '@src/components/SystemSettings/ThemeSetting/ThemeSetting'
import { WidgetSetting } from '@src/components/SystemSettings/WidgetSetting/WidgetSetting'
import { AccessSetting } from '@src/components/SystemSettings/AccessSetting/AccessSetting'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const SystemSettings: React.FC = () => {
    const isAdmin = useAppSelector(({ user }) => user.isAdmin)
    return (
        <div className={styles.root}>
            <LangSetting />
            <ThemeSetting />
            <WidgetSetting />
            {isAdmin && <AccessSetting />}
        </div>
    )
}
