import React, { type FC, type Dispatch, type ReactNode, type SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '@src/components'
import { useOutsideClick } from '@src/hooks'
import styles from './styles.module.scss'

interface IModalLayout {
    title?: string
    titleSpan?: string
    children: ReactNode
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    isUseClickOutside?: boolean
}

export const ModalLayout: FC<IModalLayout> = ({ children, title, setIsModalOpen, titleSpan, isUseClickOutside }) => {
    const { t } = useTranslation()

    const modalRef = useRef<HTMLDivElement>(null)

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    if (!isUseClickOutside) {
        useOutsideClick(modalRef, setIsModalOpen)
    }

    return (
        <div className={styles.root}>
            <div className={styles.modalContainer} ref={modalRef}>
                {title && (
                    <p className={styles.title}>
                        {title}
                        {titleSpan && <span>{` ${titleSpan}`}</span>}
                    </p>
                )}
                <button title={t('buttons.close')} className={styles.closeBtn} onClick={handleCloseModal}>
                    <CloseIcon />
                </button>
                {children}
            </div>
        </div>
    )
}
