import React, { type FC, useState, useEffect, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { ArrowDropDownIcon } from '@src/components'

interface IInputCount {
    value: number
    maxValue?: number
    disabled?: boolean
    inputClassName?: string
    onChange?: ((value: number) => void) | Dispatch<SetStateAction<number>>
}

export const InputCount: FC<IInputCount> = ({ value, maxValue, disabled, inputClassName, onChange }) => {
    const { t } = useTranslation()

    const [inputValue, setInputValue] = useState('0')

    useEffect(() => {
        if (Number(inputValue) !== value) {
            setInputValue(value.toString())
        }
    }, [value])

    const handleIncrement = () => {
        if (maxValue) {
            if (+inputValue < maxValue) {
                setInputValue((+inputValue + 1).toString())
                onChange?.(+inputValue + 1)
            }
        } else {
            onChange?.(+inputValue + 1)
            setInputValue((+inputValue + 1).toString())
        }
    }

    const handleDecrement = () => {
        if (+inputValue > 0) {
            onChange?.(+inputValue - 1)
            setInputValue((+inputValue - 1).toString())
        }
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(Number(e.target.value).toString())
        onChange?.(Number(e.target.value))
    }

    return (
        <div className={`${styles.root} ${disabled ? styles.disabled : ''}`}>
            <input
                className={inputClassName ?? ''}
                type='number'
                min='0'
                value={inputValue}
                onChange={e => handleOnChange(e)}
            />
            <div className={styles.btnWrap}>
                <button
                    title={t('system_settings.widgets_less_btn')}
                    onClick={handleDecrement}
                    disabled={+inputValue === 0}
                    className={`${styles.actionBtn} ${styles.down}`}
                >
                    <ArrowDropDownIcon />
                </button>
                <button
                    title={t('system_settings.widgets_more_btn')}
                    onClick={handleIncrement}
                    disabled={+inputValue === maxValue}
                    className={`${styles.actionBtn} ${styles.up}`}
                >
                    <ArrowDropDownIcon />
                </button>
            </div>
        </div>
    )
}
