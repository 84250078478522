import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { type DeleteWidgetModalProps } from './DeleteWidgetModal.types'
import { ButtonPrimary, CancelIcon, DeleteIcon } from '@src/components/StyleGuide'
import styles from './styles.module.scss'
import './styles.scss'
import { ModalTitle } from './ModalTitle'
import { DeleteWidgetModalBody } from './DeleteWidgetModalBody'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'

export const DeleteWidgetModal: FC<DeleteWidgetModalProps> = ({
    onAccept,
    onClose,
    isOpen,
    isAccessShared,
    widgetName,
}) => {
    const { t } = useTranslation()

    const handleClose = () => {
        onClose(false)
    }
    return (
        <AntModalLayout
            isOpen={isOpen}
            onCancel={handleClose}
            title={<ModalTitle text={t('delete_widget_modal.delete_widget')} />}
            footer={
                <div className={styles.deleteModalBtnWrap}>
                    <ButtonPrimary
                        icon={<DeleteIcon />}
                        title={t('dashboard_control_panel.delete_modal.delete_btn')}
                        type='red'
                        onClick={onAccept}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('dashboard_control_panel.modal_cancel_btn')}
                        type='transparent'
                        onClick={handleClose}
                    />
                </div>
            }
        >
            <DeleteWidgetModalBody widgetName={widgetName} isAccessShared={isAccessShared} />
        </AntModalLayout>
    )
}
