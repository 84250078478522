import axiosInstance from '@src/services/axios'
import { type AuthResponse } from './auth.types'

const AUTH_URL = '/auth'

export const authApi = {
    authorize: async () => {
        const urlToken = window.location.href
        // const urlToken = 'https://dashboard.itsites.com.ua/'
        const { data } = await axiosInstance.get<AuthResponse>(`${AUTH_URL}`, {
            headers: {
                Authorization: `Bearer ${urlToken}`,
            },
        })
        return data
    },
}
