import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary, ButtonWithIcon, CancelIcon } from '@src/components/StyleGuide'
import { Title } from './Title'
import styles from './styles.module.scss'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import type { UnsavedUsersAccessProps } from './UnsavedUsersAccess.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { permissionsActions } from '@src/store/permissions/slice'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const UnsavedUsersAccess: FC<UnsavedUsersAccessProps> = ({ isOpen, setIsOpen, url }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const unsavedFields = useAppSelector(({ permissions }) => permissions.unsavedFields)

    const handleBackWithoutSaving = () => {
        setIsOpen(false)
        dispatch(permissionsActions.setUnsavedFields([]))
        if (url) {
            window.location.href = url
        } else {
            navigate('/')
        }
    }
    const handleSaveUsersPermissions = () => {
        dispatch(
            permissionsActions.fetchUpdateUsersDataPermissions({
                data: unsavedFields,
                onSuccess: () => {
                    dispatch(permissionsActions.setUnsavedFields([]))
                    if (url) {
                        window.location.href = url
                    } else {
                        navigate('/')
                    }
                },
            }),
        )
    }

    return (
        <AntModalLayout
            isOpen={isOpen}
            onCancel={() => setIsOpen(false)}
            title={<Title title={t('grid_cant_reduce_modal.title')} />}
        >
            <div className={styles.bodyWrapper}>
                <div className={styles.modalWarning}>
                    <p>{t('access_page.warning')}</p>
                </div>
                <p className={styles.modalDescription}>{t('access_page.warning_question')}</p>
                <div className={styles.buttonsWrapper}>
                    <ButtonPrimary
                        icon={<ApplyIcon stroke='white' />}
                        title={t('buttons.save')}
                        type='primary'
                        onClick={handleSaveUsersPermissions}
                    />
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.cancelBtn}`}
                        icon={<CancelIcon />}
                        text={t('buttons.cancel')}
                        onClick={handleBackWithoutSaving}
                    />
                </div>
            </div>
        </AntModalLayout>
    )
}
