import { useMemo } from 'react'
import { useAppSelector } from '../redux/useAppSelector'
import type { UseUserFieldsProps } from './useUserFields.types'

export const useUserFields = ({ datasetID, datasourceTypeID }: UseUserFieldsProps) => {
    const userDataPermissions = useAppSelector(({ permissions }) => permissions.userDataPermissions)

    const allowedUserDataOptions = useMemo(() => {
        return (
            userDataPermissions
                ?.filter(elem => elem.isAllowed)
                ?.map(elem => ({
                    label: elem.field.name,
                    value: elem.field.id,
                })) ?? []
        )
    }, [userDataPermissions])
    const numericFieldsOption = useMemo(() => {
        return (
            userDataPermissions
                ?.filter(elem => elem.isAllowed && (elem.field.fieldType.id === 2 || elem.field.fieldType.id === 1))
                ?.map(elem => ({
                    label: elem.field.name,
                    value: elem.field.id,
                })) ?? []
        )
    }, [userDataPermissions])
    const findFieldByID = (id: string) => {
        return userDataPermissions?.find(elem => elem.field.id === id)?.field
    }
    return {
        userDataPermissions,
        allowedUserDataOptions,
        numericFieldsOption,
        findFieldByID,
    }
}
