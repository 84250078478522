import React, { type FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowRoundedIcon } from '@src/components'
import { CellItem } from '@src/components/SelectDashboardGrid/CellItem/CellItem'
import { useOutsideClick } from '@src/hooks'
import styles from './styles.module.scss'
import type { DashboardGridLayoutType } from '@src/store/dashboards/dashboards.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { fetchUpdateDashboard } from '@src/store/dashboards/slice'
import { ReduceGridWarningModal } from '../Modals/ReduceGridWarningModal/ReduceGridWarningModal'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { GridCantReduceModal } from '../Modals/GridCantReduceModal/GridCantReduceModal'

interface ISelectDashboardGrid {
    isDisabled?: boolean
}

const MAX_COLUMN_SIZE = 4
const MAX_ROW_SIZE = 3

export const SelectDashboardGrid: FC<ISelectDashboardGrid> = ({ isDisabled }) => {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const { activeDashboard, dashboardID } = useDashboard()
    const isDontShowWarning = useAppSelector(({ appState }) => appState.UISettings?.dontAskAgainReduceGridSizeAttention)

    const cellsX = activeDashboard?.cellsX ?? 0
    const cellsY = activeDashboard?.cellsY ?? 0
    const { currentDashboardWidgets } = useWidget({ dashboardID })
    const [maxWidgetsArea, setMaxWidgetsArea] = useState({
        cols: 0,
        rows: 0,
    })

    useEffect(() => {
        const widgetsEdgeX = currentDashboardWidgets?.map(elem => elem.listData.positionX + elem.listData.cellsX) ?? [1]
        const widgetsEdgeY = currentDashboardWidgets?.map(elem => elem.listData.positionY + elem.listData.cellsY) ?? [1]
        const maxColsArea = Math.max(...widgetsEdgeX)
        const maxRowsArea = Math.max(...widgetsEdgeY)
        setMaxWidgetsArea({
            cols: maxColsArea,
            rows: maxRowsArea,
        })
    }, [currentDashboardWidgets])

    const [isActiveDropDown, setIsActiveDropDown] = useState(false)

    const [selectedSize, setSelectedSize] = useState<DashboardGridLayoutType>({
        rows: cellsX,
        cols: cellsY,
    })
    const [highlightedSize, setHighlightedSize] = useState<DashboardGridLayoutType>({
        rows: cellsY,
        cols: cellsX,
    })
    const [isOpenReduceGridAttentionsModal, setIsOpenReduceGridAttentionsModal] = useState(false)
    const [isOpenCantChangeGridAttentionsModal, setIsOpenCantChangeGridAttentionsModal] = useState(false)

    useEffect(() => {
        setSelectedSize({ rows: cellsY, cols: cellsX })
    }, [cellsX, cellsY])

    const selectDropDownRef = useRef<HTMLDivElement>(null)

    const handleMouseEnter = (row: number, col: number) => {
        setHighlightedSize({ rows: row + 1, cols: col + 1 })
    }

    const handleDropDown = () => {
        setIsActiveDropDown(!isActiveDropDown)
    }
    const handleUpdateGridSize = (grid: { rows: number; cols: number }) => {
        if (dashboardID && (grid.cols !== selectedSize.cols || grid.rows !== selectedSize.rows))
            dispatch(
                fetchUpdateDashboard({
                    id: dashboardID,
                    cellsX: grid.cols,
                    cellsY: grid.rows,
                }),
            )
        setIsOpenReduceGridAttentionsModal(false)
        setSelectedSize(highlightedSize)
    }

    const handleSetGrid = () => {
        if (dashboardID) {
            const cantChangeGridSize =
                highlightedSize.cols * highlightedSize.rows < (currentDashboardWidgets?.length ?? 0)
            const isWillReduceWidgets =
                maxWidgetsArea.cols > highlightedSize.cols || maxWidgetsArea.rows > highlightedSize.rows
            if (isWillReduceWidgets && !cantChangeGridSize) {
                isDontShowWarning ? handleUpdateGridSize(highlightedSize) : setIsOpenReduceGridAttentionsModal(true)
            }
            if (cantChangeGridSize) setIsOpenCantChangeGridAttentionsModal(true)
            if (!isWillReduceWidgets && !cantChangeGridSize) handleUpdateGridSize(highlightedSize)
            handleDropDown()
        }
    }

    useOutsideClick(selectDropDownRef, setIsActiveDropDown)

    return (
        <div className={styles.root} ref={selectDropDownRef}>
            <button
                className={`${styles.btn} ${isActiveDropDown ? styles.active : ''}`}
                title={t('dashboard_control_panel.select_grid')}
                disabled={isDisabled}
                onClick={() => handleDropDown()}
            >
                <span>
                    {selectedSize.rows > 0
                        ? `${selectedSize.cols as unknown as string}x${selectedSize.rows as unknown as string}`
                        : t('dashboard_control_panel.select_grid')}
                </span>
                <div className={styles.btnIcon} style={isActiveDropDown ? { transform: 'rotate(180deg)' } : {}}>
                    <ArrowRoundedIcon />
                </div>
            </button>
            {isActiveDropDown && (
                <div className={styles.dropdown}>
                    {[...Array(MAX_ROW_SIZE)].map((_, rowIndex) =>
                        [...Array(MAX_COLUMN_SIZE)].map((__, colIndex) => (
                            <CellItem
                                key={`${rowIndex}-${colIndex}`}
                                active={rowIndex < selectedSize.rows && colIndex < selectedSize.cols}
                                highlighted={rowIndex < highlightedSize.rows && colIndex < highlightedSize.cols}
                                onClick={handleSetGrid}
                                onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                            />
                        )),
                    )}
                </div>
            )}
            <ReduceGridWarningModal
                isOpen={isOpenReduceGridAttentionsModal}
                onApply={() => handleUpdateGridSize(highlightedSize)}
                onCancel={() => setIsOpenReduceGridAttentionsModal(false)}
            />
            <GridCantReduceModal
                isOpen={isOpenCantChangeGridAttentionsModal}
                onCancel={() => setIsOpenCantChangeGridAttentionsModal(false)}
            />
        </div>
    )
}
