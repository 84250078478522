import React from 'react'
import styles from '@src/components/Modals/styles.module.scss'
import { ApplyIcon, ButtonPrimary, CancelIcon } from '@src/components'
import { ModalLayout } from '@src/layouts'
import type { IRefreshButtonModal } from '@src/components/Modals/RefreshButtonModal/@types'
import { useTranslation } from 'react-i18next'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { useData } from './useData'

export const RefreshButtonModal: React.FC<IRefreshButtonModal> = ({ setModalOpen }) => {
    const { t } = useTranslation()
    const handleCancel = () => {
        setModalOpen(false)
    }
    const { options, currentIntervalValue, setInterval, updateDashboardAutoUpdateInterval } = useData({
        setModalClose: handleCancel,
    })

    return (
        <ModalLayout
            setIsModalOpen={setModalOpen}
            title={t('dashboard_control_panel.refresh_modal.title')}
            isUseClickOutside={true}
        >
            <div className={styles.root} style={{ maxWidth: '408px' }}>
                <p className={styles.subTitle}>{t('dashboard_control_panel.refresh_modal.subtitle')}</p>
                <div className={styles.checkWrap}>
                    <SelectList selectedValue={currentIntervalValue} options={options} onChange={setInterval} />
                </div>
                <div className={styles.btnWrap}>
                    <ButtonPrimary
                        className={styles.btnUnView}
                        icon={<ApplyIcon />}
                        title={t('dashboard_control_panel.refresh_modal.btn')}
                        type='primary'
                        onClick={updateDashboardAutoUpdateInterval}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        title={t('dashboard_control_panel.modal_cancel_btn')}
                        type='transparent'
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}
