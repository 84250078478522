import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { GridInitialState, SetEmptySpacesAction, SetLayoutAction, SetWidgetsGridAction } from './grid.types'

const initialState: GridInitialState = {}

export const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setLayout: (state, { payload }: PayloadAction<SetLayoutAction>) => {
            const { dashboardID, layout } = payload
            if (!dashboardID) return
            if (state[dashboardID]) state[dashboardID].gridLayout = layout
            else
                state[dashboardID] = {
                    gridLayout: layout,
                    widgetsGrid: [],
                    emptySpaces: [],
                }
        },
        setWidgetsGrid: (state, { payload }: PayloadAction<SetWidgetsGridAction>) => {
            const { dashboardID, widgetsGrid } = payload
            if (!dashboardID) return
            if (state[dashboardID]) state[dashboardID].widgetsGrid = widgetsGrid
            else
                state[dashboardID] = {
                    gridLayout: [],
                    widgetsGrid,
                    emptySpaces: [],
                }
        },
        setEmptySpaces: (state, { payload }: PayloadAction<SetEmptySpacesAction>) => {
            const { dashboardID, emptySpaces } = payload
            if (!dashboardID) return
            if (state[dashboardID]) state[dashboardID].emptySpaces = emptySpaces
            else
                state[dashboardID] = {
                    gridLayout: [],
                    widgetsGrid: [],
                    emptySpaces,
                }
        },
    },
})

export const { reducer: grid, actions: gridActions } = gridSlice
