import React, { useState, type Dispatch, type FC, type SetStateAction, useEffect } from 'react'
import { Switch } from 'antd'
import styles from './styles.module.sass'

interface ISwitcher {
    className?: string
    value: boolean
    title?: string
    onChange?: ((active: boolean) => void) | Dispatch<SetStateAction<boolean>>
}

export const Switcher: FC<ISwitcher> = ({ className, value, onChange, title }) => {
    const [checked, setChecked] = useState(false)
    const handleChange = (state: boolean) => {
        onChange?.(state)
        setChecked(state)
    }
    useEffect(() => {
        setChecked(value)
    }, [value])

    return (
        <Switch
            title={title}
            className={`${styles.root} ${value ? styles.checked : ''} ${className ?? ''}`}
            checked={checked}
            onChange={handleChange}
        />
    )
}
