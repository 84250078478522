import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { fetchWidgetById } from '@src/store/widgets/slice'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import type { ArrayOfWidgetsIdsType, OnNotifyFieldChangeResponse, WebsocketProps } from './types'

export const WebSocketConnect = ({ socket }: WebsocketProps) => {
    const widgetsLists = useAppSelector(({ widgets }) =>
        Object.values(widgets.widgetsByDashboards)
            .flatMap(item => item.widgets)
            .map(item => item.listData),
    )

    socket.onmessage = function (event: MessageEvent) {
        const res: OnNotifyFieldChangeResponse = JSON.parse(event.data)
        if (res.Data.Dashboards) {
            const ArrayOfWidgetsIds: ArrayOfWidgetsIdsType = res.Data.Dashboards.map(elem => elem.Widgets).flat(1)
            const result = widgetsLists.filter(widget => ArrayOfWidgetsIds.some(idObj => idObj.ID === widget.id))
            if (result) {
                result.forEach(item => {
                    if (item) {
                        dispatch(
                            fetchWidgetById({
                                widgetID: item.id,
                                widgetType: item.widgetType,
                                dashboardID: item.dashboardID,
                            }),
                        )
                    }
                })
            }
        }
    }

    const dispatch = useAppDispatch()
    return null
}
