import React, { type FC } from 'react'
import styles from './styles.module.scss'

export const Title: FC<{ title: string }> = ({ title }) => {
    return (
        <div className={styles.titleWrapper}>
            <h2>{title}</h2>
        </div>
    )
}
