import React, { type FC, useEffect } from 'react'
import './sass/app.sass'
import { useAppDispatch } from './hooks/redux/useAppDispatch'
import { userActions } from './store/user/slice'
import { useAppSelector } from './hooks/redux/useAppSelector'
import { localStorageKeys } from './config/localStorageKeys.enum'
import { RoutesLayout } from './layouts/RoutesLayout'
import { WebSocketConnect } from '@src/components/WebSocketConnect/WebSoketConnect'
import type { UserData } from './store/user/user.types'

const App: FC = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.user.userData?.token)
    const isAuthorized = useAppSelector(state => state.user.isAuthorized)

    useEffect(() => {
        const userData = localStorage.getItem(localStorageKeys.userData)
        const parseUserData = userData ? (JSON.parse(userData) as UserData) : null
        if (parseUserData?.token) dispatch(userActions.setAuthorization(parseUserData))
        if (!parseUserData?.token) dispatch(userActions.fetchAuthorization())
    }, [isAuthorized])

    if (!isAuthorized) return null

    const socket = new WebSocket('wss://dev-socket-dashboard.plainandsimplesolution.com:5004')
    socket.onopen = function () {
        socket.send(
            JSON.stringify({
                type: 1,
                token: token ?? '',
                data: '',
            }),
        )
    }

    return (
        <>
            <WebSocketConnect socket={socket} />
            <RoutesLayout />
        </>
    )
}

export default App
