import React, { Fragment } from 'react'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const HighlightText = ({ text, searchInput }: { text: string | number | undefined; searchInput?: string }) => {
    const searchValue = searchInput ?? useAppSelector(state => state.searchWidgetInDashboard.searchValueOfWidget)

    const markSearchText = () => {
        const value = typeof text === 'string' ? text : text?.toString() ?? ''
        const parts = value.toLowerCase().split(searchValue.toLowerCase())
        let clearString = ''

        return searchValue
            ? parts.map((part, i) => {
                  clearString = `${clearString}${part}${searchValue}`
                  const isLastIteration = i === parts.length - 1
                  const originPartStartIndex = clearString.lastIndexOf(part)
                  const originPartEndIndex = originPartStartIndex + part.length
                  const originPart = value.slice(originPartStartIndex, originPartEndIndex)
                  const originSearchValueStartIndex = clearString.lastIndexOf(searchValue)
                  const originSearchValueEndIndex = originSearchValueStartIndex + searchValue.length
                  const originSearchValue = value.slice(originSearchValueStartIndex, originSearchValueEndIndex)
                  return (
                      <Fragment key={`${part}${i}`}>
                          {originPart}
                          {!isLastIteration && (
                              <span style={{ background: 'yellow', color: 'black', fontWeight: 500 }}>
                                  {originSearchValue}
                              </span>
                          )}
                      </Fragment>
                  )
              })
            : value
    }

    return <>{markSearchText()}</>
}
