import React, { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import {
    ButtonIcon,
    CopyIcon,
    DeleteIcon,
    EditableTitle,
    EditIcon,
    LockIcon,
    PopupModal,
    ReplaceIconAlt,
    SettingsLinesIcon,
    ShareIcon,
    TextEditWidgetModal,
    WarningIcon,
    WidgetDataModal,
} from '@src/components'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { cutLongString } from '@src/helpers/utils/cutLongString'
import { MoveWidgetModal } from '@src/components/Modals/MoveWidget/MoveWidgetModal'
import { DeleteWidgetModal } from '@src/components/Modals/DeleteWidgetModal/DeleteWidgetModal'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import type { WidgetLayoutProps } from './WidgetLayout.types'
import {
    fetchCreateWidgetByType,
    fetchDeleteWidgetById,
    fetchMoveWidgetToDashboard,
    setSetupWidgetPageData,
} from '@src/store/widgets/slice'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { WidgetMenu } from './components/WidgetMenu/WidgetMenu'
import type { SelectListValue } from '@src/components/StyleGuide/Ui/SelectList/SelectList.types'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { HighlightText } from '@src/components/HighlightText/HighlightText'
import type { WidgetNewProperties } from '@src/components/Modals/MoveWidget/MoveWidgetModal.types'
import { CopyWidgetModal } from '@src/components/Modals/CopyWidgetModal/CopyWidgetModal'
import { fetchDatasourceTypes } from '@src/store/dictionary/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { Loader } from '@src/components/Loader/Loader'
import { PopupCalendar } from '@src/components/PopupCalendar/PopupCalendar'
import type { FetchUpdateWidgetById } from '@src/store/widgets/widgets.types'
import type { CalendarDatesPeriod } from '@src/components/PopupCalendar/PopupCalendar.types'
import { WidgetCommands } from './components/WidgetCommands/WidgetCommands'
import { WidgetForms } from './components/WidgetCommands/WidgetForms'
import { fetchDashboardById, fetchDashboardsList } from '@src/store/dashboards/slice'

// eslint-disable-next-line react/display-name
export const WidgetLayout: FC<WidgetLayoutProps> = ({
    widgetID,
    access = true,
    dashboardID,
    widgetType,
    simpleWidgetView,
    className = '',
    modal = false,
    isLocked,
}) => {
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupOpenEditTextWidget, setPopupOpenEditTextWidget] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isWidgetDataOpen, setIsWidgetDataOpen] = useState(false)
    const [editStatus, setEditStatus] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { periodOptions, findPeriodById, getPeriodByPeriodId, getFormatedDate } = usePeriod()
    const { widgetTitle, currentWidget, widgetComponent, updateWidget } = useWidget({
        widgetID,
        dashboardID,
    })
    const { isSharedDashboard } = useDashboard()

    const isTextWidget = widgetType.id === 1

    const onChangeTitle = (value: string) => {
        updateWidget({ name: value })
    }
    const onChangePeriod = (value: SelectListValue) => {
        const periodType = findPeriodById(value as number)
        const { fromToPeriod } = getPeriodByPeriodId(value as number)
        const requestBody: Partial<FetchUpdateWidgetById> = {
            periodType,
        }
        if (value !== 1 && currentWidget?.widget?.periodType?.id !== value) {
            requestBody.dateFrom = fromToPeriod.dateFrom
            requestBody.dateTo = fromToPeriod.dateTo
        }
        updateWidget(requestBody)
    }

    const handleDatePeriodChange = (dates: CalendarDatesPeriod) => {
        updateWidget(dates)
    }

    const handlePopupOpen = () => {
        if (!modal) {
            setPopupOpen(true)
        }
    }

    const handleConfirmWidgetCopy = (widget: WidgetNewProperties) => {
        if (currentWidget?.widget) {
            dispatch(
                fetchCreateWidgetByType({
                    ...currentWidget.widget,
                    ...widget,
                    onSuccess: () => {
                        dispatch(fetchDashboardsList())
                        setIsCopyModalOpen(false)
                    },
                }),
            )
        }
    }

    const handleDeleteWidget = () => {
        dispatch(
            fetchDeleteWidgetById({
                widgetID,
                dashboardID,
                onSuccess: () => dispatch(fetchDashboardsList()),
            }),
        )
    }
    const handleConfirmWidgetMove = (widget: WidgetNewProperties) => {
        dispatch(
            fetchMoveWidgetToDashboard({
                ...widget,
                id: widgetID,
                currentDashboardID: dashboardID,
                widgetType,
                onSuccess: () => {
                    dispatch(fetchDashboardsList())
                    setIsMoveModalOpen(false)
                },
            }),
        )
    }
    const handleMoveModalOpen = () => {
        setIsMoveModalOpen(true)
    }
    const handleTitleChange = () => {
        if (!isLocked) setEditStatus(true)
    }

    const handleDeleteModalOpen = () => {
        setIsDeleteModalOpen(true)
    }

    const handleCopyWidget = () => {
        setIsCopyModalOpen(true)
    }
    const handleLinkToWidgetSettings = () => {
        navigate('/widget-settings')
        dispatch(fetchDatasourceTypes())
        dispatch(
            setSetupWidgetPageData({
                name: currentWidget?.widget?.name,
                id: widgetID,
                dashboardID,
                widgetType,
                positionX: currentWidget?.widget?.positionX,
                positionY: currentWidget?.widget?.positionY,
                cellsX: currentWidget?.widget?.cellsX,
                cellsY: currentWidget?.widget?.cellsY,
                dataset: currentWidget?.widget?.dataset,
                dataSourceType: currentWidget?.widget?.dataSourceType,
            }),
        )
    }

    const list = [
        {
            icon: <EditIcon />,
            text: t('widgets.menu.edit_name'),
            isShow: !isLocked,
            isDisabled: !access,
            onClick: handleTitleChange,
        },
        {
            icon: <SettingsLinesIcon />,
            text: t('widgets.menu.settings'),
            isShow: !isLocked,
            isDisabled: !access,
            onClick: handleLinkToWidgetSettings,
        },
        {
            icon: <DeleteIcon />,
            text: t('widgets.menu.delete'),
            isShow: !isLocked,
            isDisabled: false,
            onClick: handleDeleteModalOpen,
        },
        {
            icon: <CopyIcon />,
            text: t('widgets.menu.copy'),
            isShow: true,
            isDisabled: !access,
            onClick: handleCopyWidget,
        },
        {
            icon: <ReplaceIconAlt />,
            text: t('widgets.menu.move'),
            isShow: !isLocked,
            isDisabled: !access,
            onClick: handleMoveModalOpen,
        },
    ]

    if (!currentWidget) {
        return null
    }
    return (
        <>
            <div className={`${styles.root} ${className}`}>
                <div className={`${styles.wrap}`}>
                    {editStatus ? (
                        <EditableTitle
                            className={`${styles.titleEdit} ${editStatus ? styles.inputEdit : ''}`}
                            titleValue={currentWidget?.widget?.name ?? ''}
                            editStatus={editStatus}
                            setEditStatus={setEditStatus}
                            onSave={onChangeTitle}
                        />
                    ) : (
                        <div className={styles.widgetHeaderWrapper} onClick={handleTitleChange}>
                            <p className={styles.widgetType}>{widgetTitle}</p>
                            <p className={styles.widgetName} title={currentWidget?.listData?.name ?? ''}>
                                <HighlightText text={cutLongString(currentWidget?.listData?.name ?? '', 50)} />
                            </p>
                        </div>
                    )}
                    {!isLocked && !simpleWidgetView && <div className={`${styles.draggHandler} handleDragg`} />}
                    {!simpleWidgetView && (
                        <div className={styles.actions}>
                            {!isLocked && (
                                <WidgetCommands pinnedCommands={currentWidget?.widget?.pinnedCommands ?? []} />
                            )}
                            {!isLocked && <WidgetForms pinnedForms={currentWidget?.widget?.pinnedForms ?? []} />}
                            {isLocked && <ButtonIcon icon={<LockIcon />} title={t('widgets.no_access_btn')} />}
                            <ButtonIcon
                                icon={isTextWidget ? <EditIcon /> : <ShareIcon />}
                                title={isTextWidget ? t('widgets.counter.edit_btn') : t('widgets.counter.share_btn')}
                                disabled={!access || (isLocked && isTextWidget)}
                                onClick={() =>
                                    isTextWidget ? setPopupOpenEditTextWidget(true) : setIsWidgetDataOpen(true)
                                }
                            />
                            <WidgetMenu
                                list={list}
                                access={(!access && !isLocked) || access}
                                widgetID={widgetID}
                                dashboardID={dashboardID}
                                isLockedWidget={!!isLocked}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={`${styles.dataContainer} ${modal ? styles.containerInModal : ''} `}
                    onClick={() => {
                        if (!modal && access) handlePopupOpen()
                    }}
                >
                    {access ? (
                        <Loader isLoading={currentWidget?.isLoadingUpdateWidget || currentWidget?.isLoadingWidget}>
                            {widgetComponent()}
                        </Loader>
                    ) : (
                        <div className={styles.noAccess}>
                            <WarningIcon />
                            <p>{t('widgets.no_access')}</p>
                        </div>
                    )}
                </div>
                {!isTextWidget && !simpleWidgetView && (
                    <div className={styles.selectWrap}>
                        <SelectList
                            options={periodOptions}
                            selectedValue={currentWidget?.widget?.periodType?.id}
                            className={styles.select}
                            isDisabled={!access || isLocked}
                            isAllowAction={!simpleWidgetView}
                            onChange={onChangePeriod}
                        />
                        {currentWidget?.widget?.periodType?.id === 1 ? (
                            <PopupCalendar
                                values={
                                    currentWidget?.widget?.dateFrom && currentWidget?.widget?.dateTo
                                        ? {
                                              dateFrom: currentWidget.widget.dateFrom,
                                              dateTo: currentWidget.widget.dateTo,
                                          }
                                        : null
                                }
                                onChangePeriod={handleDatePeriodChange}
                            />
                        ) : (
                            <p className={styles.selectTime}>
                                <HighlightText
                                    text={`${getFormatedDate(currentWidget?.widget?.dateFrom)} - ${getFormatedDate(
                                        currentWidget?.widget?.dateTo,
                                    )}`}
                                />
                            </p>
                        )}
                    </div>
                )}
            </div>
            {!modal && (
                <>
                    <PopupModal open={popupOpen} setOpen={setPopupOpen} widgetID={widgetID} dashboardID={dashboardID} />
                    {popupOpenEditTextWidget && (
                        <TextEditWidgetModal
                            open={popupOpenEditTextWidget}
                            setOpen={setPopupOpenEditTextWidget}
                            widgetID={widgetID}
                            dashboardID={dashboardID}
                        />
                    )}
                    {isWidgetDataOpen && (
                        <WidgetDataModal
                            isOpen={isWidgetDataOpen}
                            onCancel={() => setIsWidgetDataOpen(false)}
                            widgetID={widgetID}
                            dashboardID={dashboardID}
                        />
                    )}
                    {isMoveModalOpen && (
                        <MoveWidgetModal
                            open={isMoveModalOpen}
                            widget={currentWidget?.listData}
                            onConfirm={handleConfirmWidgetMove}
                            onCancel={() => setIsMoveModalOpen(false)}
                        />
                    )}
                    {isDeleteModalOpen && (
                        <DeleteWidgetModal
                            widgetName={currentWidget?.listData.name ?? ''}
                            isOpen={isDeleteModalOpen}
                            onAccept={handleDeleteWidget}
                            onClose={setIsDeleteModalOpen}
                            isAccessShared={isSharedDashboard}
                        />
                    )}
                    {isCopyModalOpen && (
                        <CopyWidgetModal
                            open={isCopyModalOpen}
                            onCancel={() => setIsCopyModalOpen(false)}
                            onConfirm={handleConfirmWidgetCopy}
                            widget={currentWidget?.listData}
                        />
                    )}
                </>
            )}
        </>
    )
}
