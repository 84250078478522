import React, { type FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, ConfigProvider } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import 'dayjs/locale/uk'
import 'dayjs/locale/en'
import enUS from 'antd/lib/locale/en_US'
import ukUA from 'antd/lib/locale/uk_UA'
import {
    ArrowIcon,
    ButtonIcon,
    ButtonPrimary,
    CalendarCloseIcon,
    CalendarIcon,
    CancelIcon,
    CheckIcon,
    TabContainer,
} from '@src/components'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import type { SelectDashboardPeriodProps } from './SelectDashboardPeriod.types'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { DATE_FORMAT, DATE_FORMAT_FOR_REQUEST } from '@src/config/constants'
import type { PeriodType } from '@src/api/dictionary/dictionary.types'

type CalendarType = [Dayjs, Dayjs]

export const SelectDashboardPeriod: FC<SelectDashboardPeriodProps> = ({
    values,
    isCreatePage,
    className,
    onChangePeriod,
    selectedPeriodType,
    defaultPeriodID = null,
    onClose,
}) => {
    const { RangePicker } = DatePicker
    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale) ?? 'uk_UA'
    const [isOpen, setIsOpen] = useState(false)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodType | null>(null)
    const [dates, setDates] = useState<CalendarType>([dayjs(), dayjs()])
    const [dateRange, setDateRange] = useState({ dateFrom: '', dateTo: '' })
    const { periodTypesWithoutUserPeriod, userPeriod, getPeriodByPeriodId, getDateFromISOFormat, findPeriodById } =
        usePeriod(selectedPeriod?.id)

    const { t } = useTranslation()

    useEffect(() => {
        const defaultPeriod = findPeriodById(defaultPeriodID)
        if (defaultPeriod) setSelectedPeriod(defaultPeriod)
    }, [defaultPeriodID])

    useEffect(() => {
        if (values) {
            const datesFromProps: CalendarType = [
                getDateFromISOFormat(values.dateFrom),
                getDateFromISOFormat(values.dateTo),
            ]
            setDateRange(values)
            setDates(datesFromProps)
        }
        if (!values) {
            setDates([dayjs(), dayjs()])
            setDateRange({ dateFrom: '', dateTo: '' })
            setSelectedPeriod(null)
        }
        const defaultPeriod = findPeriodById(defaultPeriodID)
        setSelectedPeriod(prev => selectedPeriodType ?? defaultPeriod ?? prev)
    }, [selectedPeriodType?.id, values, defaultPeriodID])

    useEffect(() => {
        if (!isOpen && !isCreatePage) {
            setSelectedPeriod(null)
            setDates([dayjs(), dayjs()])
            setDateRange({ dateFrom: '', dateTo: '' })
        }
    }, [isOpen, isCreatePage])

    const handleOpen = () => {
        if (isOpen) onClose?.()
        setIsOpen(!isOpen)
    }

    const handleSelect = (value: PeriodType) => {
        setSelectedPeriod(value)
        if (dateRange.dateFrom && dateRange.dateTo && value.id === selectedPeriod?.id && userPeriod) {
            setSelectedPeriod(userPeriod)
            onChangePeriod?.(userPeriod, dateRange)
        }
        if (value.id !== selectedPeriod?.id) {
            const { fromToPeriod } = getPeriodByPeriodId(value.id)
            onChangePeriod?.(value, fromToPeriod)
        }
    }

    const handleOnChangeRange = (value: [any, any] | null) => {
        const dayjsValue = value as CalendarType
        const period = {
            dateFrom: dayjsValue?.[0]?.utc(true)?.startOf('d')?.format(DATE_FORMAT_FOR_REQUEST),
            dateTo: dayjsValue?.[1]?.utc(true)?.endOf('d')?.format(DATE_FORMAT_FOR_REQUEST),
        }
        if (period?.dateFrom && period?.dateTo) {
            setDateRange(period)
        }
        setDates(dayjsValue)
    }

    const handleCancel = () => {
        if (values) {
            setDateRange(values)
            setDates([dayjs(values.dateFrom), dayjs(values.dateTo)])
        } else setDates([dayjs(), dayjs()])
        setIsCalendarOpen(false)
    }
    const handleAccept = () => {
        if (userPeriod) {
            setSelectedPeriod(userPeriod)
            onChangePeriod?.(userPeriod, dateRange)
            setIsCalendarOpen(false)
        }
    }
    const localeUA = ukUA?.Calendar
        ? {
              ...ukUA,
              Calendar: {
                  ...ukUA.Calendar,
                  lang: {
                      ...ukUA.Calendar.lang,
                      monthFormat: 'MMMM',
                  },
              },
          }
        : undefined
    const localeUS = enUS?.Calendar
        ? {
              ...enUS,
              Calendar: {
                  ...enUS.Calendar,
                  lang: {
                      ...enUS.Calendar.lang,
                      monthFormat: 'MMMM',
                  },
              },
          }
        : undefined
    return (
        <div className={`${styles.root} ${className ?? ''}`}>
            {!isCreatePage && (
                <ButtonIcon
                    title={isOpen ? t('buttons.hideAdditionMenuDates') : t('buttons.showAdditionMenuDates')}
                    icon={isOpen ? <CalendarCloseIcon /> : <CalendarIcon />}
                    className={`${styles.openBtn} ${isOpen ? styles.active : ''}`}
                    onClick={handleOpen}
                />
            )}
            {isOpen || isCreatePage ? (
                <div className={styles.dateContainer}>
                    <TabContainer className={styles.tabContainer}>
                        {periodTypesWithoutUserPeriod.map(period => (
                            <button
                                key={period.id}
                                onClick={() => handleSelect(period)}
                                className={`${styles.tabBtn} ${period.id === selectedPeriod?.id ? styles.active : ''}`}
                            >
                                {period.name}
                            </button>
                        ))}
                    </TabContainer>
                    <ConfigProvider locale={locale === 'uk_UA' ? localeUA : localeUS}>
                        <RangePicker
                            open={isCalendarOpen}
                            value={dates}
                            onChange={handleOnChangeRange}
                            onClick={() => setIsCalendarOpen(true)}
                            rootClassName={styles.datePicker}
                            prevIcon={<ArrowIcon />}
                            nextIcon={<ArrowIcon />}
                            suffixIcon={<CalendarIcon />}
                            allowClear={false}
                            allowEmpty={[true, true]}
                            format={DATE_FORMAT}
                            placeholder={[t('dashboard_control_panel.date_from'), t('dashboard_control_panel.date_to')]}
                            popupClassName={styles.dropdownPicker}
                            size='large'
                            separator='-'
                            renderExtraFooter={() => (
                                <div className={styles.datePickerFooter}>
                                    <ButtonPrimary
                                        title={t('buttons.apply')}
                                        onClick={handleAccept}
                                        type='primary'
                                        icon={<CheckIcon />}
                                    />
                                    <ButtonPrimary
                                        title={t('buttons.cancel2')}
                                        className={styles.cancelButton}
                                        onClick={handleCancel}
                                        type='transparent'
                                        icon={<CancelIcon />}
                                    />
                                </div>
                            )}
                        />
                    </ConfigProvider>
                </div>
            ) : null}
        </div>
    )
}
