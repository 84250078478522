import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Modal } from 'antd'
import './styles.scss'
import { CloseIcon, Input } from '@src/components/StyleGuide'
import type { TextEditWidgetModalProps } from './@types'
import { FooterButtons } from './FooterButtons/FooterButtons'
import { Title } from './Title/Title'
import { t } from 'i18next'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { WrapTextEditor } from '@src/components/TextEditor/WrapTextEditor'

export const emptyWidget = {
    title: '',
    data: [''],
}

export const TextEditWidgetModal: React.FC<TextEditWidgetModalProps> = ({ open, setOpen, widgetID, dashboardID }) => {
    const { setupWidgetPage } = useAppSelector(({ widgets }) => widgets)
    const { textBlockWidget, updateWidget } = useWidget({
        widgetID,
        dashboardID,
        widgetTypeID: setupWidgetPage?.widgetType?.id,
    })
    const [subjectValue, setSubjectValue] = useState(textBlockWidget?.widget?.name)
    const [valueText, setValueText] = useState('')
    const saveTextBlockModalName = (value: string) => {
        setSubjectValue(value)
    }
    const onCancel = () => {
        setValueText(String(textBlockWidget?.widget?.text))
        setOpen(false)
    }
    const onSave = () => {
        updateWidget({ text: valueText, name: subjectValue, onSuccess: () => setOpen(false) })
    }
    useEffect(() => {
        if (textBlockWidget?.widget?.name) {
            setSubjectValue(textBlockWidget?.widget?.name)
        }
    }, [textBlockWidget?.widget?.name])
    return (
        <Modal
            width='100%'
            rootClassName='textWidgetModal'
            className={`${styles.modal} noDrag`}
            open={open}
            title={<Title title={subjectValue}></Title>}
            onCancel={() => setOpen(false)}
            closeIcon={<CloseIcon />}
            centered
            footer={<FooterButtons onSave={onSave} onCancel={onCancel} />}
        >
            <>
                <div className={styles.subject}>
                    <span>{t('widgets.text.modal.subject')}</span>
                    <Input value={subjectValue} setInputValue={saveTextBlockModalName} />
                </div>
                <WrapTextEditor
                    widgetID={widgetID}
                    dashboardID={dashboardID}
                    setValueText={setValueText}
                    autosend={false}
                />
            </>
        </Modal>
    )
}
