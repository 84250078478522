import React, { type FC, useCallback, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import { getCurrentLanguage } from '@src/helpers'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useDebounce } from '@src/hooks/useDebounce/useDebounce'
import type { TextEditorProps } from './TextEditorWithoutToolbar.types'

export const TextEditorWithoutToolbar: FC<TextEditorProps> = ({
    setValueText,
    autosend = false,
    widgetID,
    dashboardID,
}) => {
    const [valueState, setValueState] = useState('')
    const { textBlockWidget, updateWidget } = useWidget({
        widgetID,
        dashboardID,
    })
    const [textBlockValue, setTextBlockValue] = useState('')
    const debounceValue = useDebounce(textBlockValue)
    const editorTopRef = useRef(null)
    const searchValue = useAppSelector(state => state.searchWidgetInDashboard.searchValueOfWidget)

    const onChange = (event: any, editor: Editor) => {
        const newData = editor.getData()
        setTextBlockValue(newData)
        setValueText?.(newData)
    }
    const config = {
        language: getCurrentLanguage(),
        htmlSupport: {
            allow: [
                {
                    name: 'span',
                    attributes: false,
                    classes: false,
                    styles: true,
                },
            ],
        },
    }
    const highlightTextInTags = useCallback(() => {
        const text = textBlockWidget?.widget?.text ?? ''
        if (searchValue) {
            const openTag = '<'
            const closeTag = '>'
            const clearSearchValue = searchValue
                .split('')
                .filter(elem => elem !== openTag && elem !== closeTag)
                .join('')
            const parts = text.toLowerCase().split(clearSearchValue.toLowerCase())
            let clearString = ''
            let resultString = ''

            parts.forEach((part, i) => {
                clearString = `${clearString}${part}${clearSearchValue}`
                const fullStringOnCurrentIteation = `${resultString}${part}${clearSearchValue}`
                const openTagIndex = fullStringOnCurrentIteation.lastIndexOf(openTag)
                const closeTagIndex = fullStringOnCurrentIteation.lastIndexOf(closeTag)
                const isTag =
                    openTagIndex > closeTagIndex || clearSearchValue === openTag || clearSearchValue === closeTag
                const isLastIteration = i === parts.length - 1
                const originPartStartIndex = clearString.lastIndexOf(part)
                const originPartEndIndex = originPartStartIndex + part.length
                const originPart = text.slice(originPartStartIndex, originPartEndIndex)
                const originSearchValueStartIndex = clearString.lastIndexOf(clearSearchValue)
                const originSearchValueEndIndex = originSearchValueStartIndex + clearSearchValue.length
                const originSearchValue = text.slice(originSearchValueStartIndex, originSearchValueEndIndex)
                resultString += isLastIteration
                    ? originPart
                    : isTag
                    ? `${originPart}${originSearchValue}`
                    : `${originPart}<span style="background:yellow; color: black; font-weight: 500">${originSearchValue}</span>`
            })
            return resultString
        }
        return text
    }, [textBlockWidget?.widget?.text, searchValue])
    useEffect(() => {
        if (debounceValue && autosend) {
            updateWidget({ text: debounceValue })
        }
    }, [debounceValue])
    useEffect(() => {
        if (textBlockWidget) {
            setValueState(textBlockWidget?.widget?.text ?? '')
        }
    }, [textBlockWidget?.widget?.text])

    useEffect(() => {
        const newString = highlightTextInTags()
        setValueState(newString)
    }, [highlightTextInTags])

    return (
        <div className='textEditorWithoutToolbar'>
            <CKEditor
                disabled={true}
                ref={editorTopRef}
                config={config}
                editor={Editor}
                data={valueState || ''}
                onChange={onChange}
            />
        </div>
    )
}
