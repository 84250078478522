import type { WidgetFilter, WidgetFilterGroup } from '@src/api/widgets/widgets.types'

export const sortFilters = (newFilters: WidgetFilterGroup[]) => {
    return newFilters.map((elem, index) => ({
        ...elem,
        sequenceNumber: index + 1,
        filters: elem.filters.map((item, i) => ({
            ...item,
            sequenceNumber: i + 1,
        })),
    }))
}

export const getCleanFilters = (newFilters: WidgetFilterGroup[]) => {
    return newFilters.map(elem => ({
        ...elem,
        filters: elem.filters.filter(item => !!item.field && !!item.compareFunctionType),
    }))
}

export const createEmptyFilterGroup = (widgetID: number) => {
    const emptyFilterGroup: WidgetFilterGroup = {
        sequenceNumber: 1,
        widgetID,
        isUseOperatorOR: true,
        filters: [],
    }
    return emptyFilterGroup
}
export const createEmptyFilterItem = () => {
    const emptyFilterItem: WidgetFilter = {
        field: null,
        sequenceNumber: 1,
        compareFunctionType: null,
        compareValue: null,
        isDisabled: true,
    }
    return emptyFilterItem
}
