import React from 'react'
import { Header } from '@src/components'
import { GridLayout } from '@src/layouts'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'

export const Home = () => {
    const { dashboardID } = useDashboard()
    return (
        <>
            <Header isMainPage={true} />
            <GridLayout key={dashboardID ?? 1} />
        </>
    )
}
