import React, { type FC, useState, useRef, useEffect, type KeyboardEvent, type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { ApplyIcon, CloseIcon } from '@src/components'
import type { EditableTitleProps } from './EditableTitle.types'

export const EditableTitle: FC<EditableTitleProps> = ({
    titleValue,
    className,
    editStatus,
    isDashboardActive,
    isForbidenAction,
    setEditStatus,
    onSave,
}) => {
    const { t } = useTranslation()
    const [localValue, setLocalValue] = useState(titleValue)
    const isValueChanged = localValue !== titleValue
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (editStatus) {
            inputRef.current?.focus()
        }
    }, [editStatus])

    const handleApplyEdit = () => {
        if (isValueChanged) {
            onSave(localValue)
        }
        setEditStatus(false)
    }

    const handleCancelEdit = () => {
        setEditStatus(false)
        setLocalValue(titleValue)
    }

    const handleOnTitleClick = () => {
        if (isDashboardActive && !isForbidenAction) setEditStatus(true)
    }

    const handleKeyboard = (e: KeyboardEvent<HTMLInputElement>) => {
        const isEnterPressed = e.code === 'Enter'
        if (isEnterPressed) handleApplyEdit()
    }
    return (
        <>
            {editStatus ? (
                <div
                    className={`${styles.root} ${className ?? ''} ${styles.inputEdit}`}
                    onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation()
                    }}
                >
                    <input
                        ref={inputRef}
                        className={styles.input}
                        type='text'
                        value={localValue}
                        onChange={e => setLocalValue(e.target.value)}
                        onBlur={handleApplyEdit}
                        onKeyDown={handleKeyboard}
                    />
                    <div
                        className={styles.inputActions}
                        onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                    >
                        <button title={t('buttons.save')} className={styles.btn} onClick={handleApplyEdit}>
                            <ApplyIcon />
                        </button>
                        <button
                            title={t('buttons.cancel')}
                            className={`${styles.btn} ${styles.cancel}`}
                            onMouseDown={handleCancelEdit}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            ) : (
                <p className={styles.text} onClick={handleOnTitleClick}>
                    {localValue}
                </p>
            )}
        </>
    )
}
