import React, { type FC, useState } from 'react'
import { LockIcon, UnViewIcon, ModalCopy, ModalDelete, ModalHide, ModalShare, EditableTitle } from '@src/components'
import styles from './styles.module.scss'
import {
    fetchCreateCopyDashboard,
    fetchDashboardsList,
    fetchDeleteDashboard,
    fetchUpdateDashboard,
    setCurrentDashBoard,
} from '@src/store/dashboards/slice'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { ShareAccessPopup } from './components/ShareAccessPopup'
import { SubMenu } from './components/SubMenu'
import { type DashboardControlOptionProps } from './DashboardControlOption.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { WidgetsType } from '@src/store/widgets/widgets.types'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { appStateActions } from '@src/store/appState/slice'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'
import { useTranslation } from 'react-i18next'

export const DashboardControlOption: FC<DashboardControlOptionProps> = ({
    dashboardID,
    dashboardTitle,
    isLocked,
    isHidden,
    isShared,
    isActive,
}) => {
    const dispatch = useAppDispatch()
    const userID = useAppSelector(({ user }) => user.userData?.accountID)
    const UISettings = useAppSelector(({ appState }) => appState.UISettings)
    const temporaryWidgetsDates = useAppSelector(({ widgets }) => widgets.temporaryWidgetsDates)
    const { currentDashboard, activeDashboard } = useDashboard({ id: dashboardID })
    const { currentDashboardWidgets } = useWidget({ dashboardID })

    const [editNameStatus, setEditNameStatus] = useState(false)
    const [deleteDashboardModal, setDeleteDashboardModal] = useState(false)
    const [hideDashboardModal, setHideDashboardModal] = useState(false)
    const [shareDashboardModal, setShareDashboardModal] = useState(false)
    const [copyDashboardModal, setCopyDashboardModal] = useState(false)

    const onSelectCurrentDashboard = () => {
        if (
            (currentDashboard && !activeDashboard) ||
            (currentDashboard && activeDashboard && activeDashboard.id !== dashboardID)
        ) {
            dispatch(setCurrentDashBoard(currentDashboard))
            dispatch(appStateActions.setLocalUISettings({ lastActiveDashboardID: dashboardID }))
            dispatch(
                fetchDashboardWidgetsList({
                    dashboardID,
                    dateFrom: temporaryWidgetsDates?.dateFrom,
                    dateTo: temporaryWidgetsDates?.dateTo,
                }),
            )
            dispatch(fetchDashboardsList())
        }
    }

    const handleFetchHideDashboard = () => {
        if (currentDashboard) {
            dispatch(
                fetchUpdateDashboard({
                    id: dashboardID,
                    isHidden: !currentDashboard.isHidden,
                    onSuccess: () => setHideDashboardModal(false),
                }),
            )
        }
    }

    const handleEditNameStatus = () => setEditNameStatus(!editNameStatus)
    const handleDeleteModal = () => setDeleteDashboardModal(!deleteDashboardModal)
    const handleShareModal = () => setShareDashboardModal(!shareDashboardModal)
    const handleCopyModal = () => setCopyDashboardModal(!copyDashboardModal)

    const handleHideDashboard = () => {
        if (!UISettings?.dontAskAgainHideModal && !currentDashboard?.isHidden) {
            setHideDashboardModal(true)
        }
        if (UISettings?.dontAskAgainHideModal || currentDashboard?.isHidden) handleFetchHideDashboard()
    }

    const editDashboardName = (newValue: string) => {
        if (newValue) dispatch(fetchUpdateDashboard({ id: dashboardID, name: newValue }))
    }

    const handleCreateCopyDashboard = (newName: string) => {
        const dashboardWidgets =
            (currentDashboardWidgets?.filter(elem => !!elem.widget).map(elem => elem.widget) as WidgetsType[]) ?? []

        if (currentDashboard)
            dispatch(
                fetchCreateCopyDashboard({
                    ...currentDashboard,
                    users: currentDashboard.users.filter(elem => elem.id === userID),
                    name: newName,
                    widgets: dashboardWidgets,
                    onSuccess: handleCopyModal,
                }),
            )
    }

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: dashboardID,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 'inherit',
    }
    const { t } = useTranslation()
    return (
        <>
            <div
                ref={setNodeRef}
                className={`${styles.dashboardContainer} ${isActive ? styles.activeDashboard : ''} ${
                    editNameStatus ? styles.inputEdit : ''
                }`}
                style={style}
                onClick={onSelectCurrentDashboard}
            >
                <EditableTitle
                    className={styles.title}
                    titleValue={dashboardTitle}
                    editStatus={editNameStatus}
                    setEditStatus={setEditNameStatus}
                    isDashboardActive={isActive}
                    isForbidenAction={isActive && isLocked}
                    onSave={editDashboardName}
                />
                {!editNameStatus && <div {...listeners} {...attributes} className={styles.draggHandler} />}
                <div className={styles.controlPanel}>
                    {isLocked && (
                        <button title={t('dashboard_control_panel.options.access')} className={styles.button}>
                            <LockIcon />
                        </button>
                    )}
                    {isHidden && (
                        <button title={t('dashboard_control_panel.options.hidden')} className={styles.button}>
                            <UnViewIcon />
                        </button>
                    )}
                    <ShareAccessPopup
                        setShareAccessModal={setShareDashboardModal}
                        dashboardID={dashboardID}
                        isShared={isShared}
                        isSharingAccess={!!currentDashboard?.isAdminAccess}
                    />
                    <SubMenu
                        dashboardId={dashboardID}
                        onCopy={handleCopyModal}
                        onShare={handleShareModal}
                        onHide={handleHideDashboard}
                        onDelete={handleDeleteModal}
                        onEditName={handleEditNameStatus}
                    />
                </div>
            </div>
            {deleteDashboardModal && (
                <ModalDelete
                    dashboardTitle={dashboardTitle}
                    setModalOpen={setDeleteDashboardModal}
                    isShared={isShared}
                    onAccept={() => dispatch(fetchDeleteDashboard({ dashboardID }))}
                />
            )}
            {hideDashboardModal && (
                <ModalHide
                    dashboardTitle={dashboardTitle}
                    setModalOpen={setHideDashboardModal}
                    onAccept={handleFetchHideDashboard}
                />
            )}
            {shareDashboardModal && (
                <ModalShare
                    dashboardTitle={dashboardTitle}
                    dashboardID={dashboardID}
                    setModalOpen={setShareDashboardModal}
                />
            )}
            {copyDashboardModal && (
                <ModalCopy
                    dashboardTitle={dashboardTitle}
                    setModalOpen={setCopyDashboardModal}
                    onAccept={handleCreateCopyDashboard}
                />
            )}
        </>
    )
}
