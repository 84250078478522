export type localPropertiesKeysType = localPropertiesKeysUnion[]

export type localPropertiesKeysUnion =
    | 'dontAskAgainHideModal'
    | 'fixedWigetsCount'
    | 'hidenDashboardIDs'
    | 'isDashboardPanelVisible'
    | 'isHiddenDashboardsOnPanelVisible'
    | 'locale'
    | 'theme'
    | 'dashboardsOrder'
    | 'createWidgetDirection'
    | 'dontAskAgainChangeWidgetTypeModal'
    | 'dontAskAgainReduceWidgetsSizeAttention'
    | 'dontAskAgainReduceGridSizeAttention'
    | 'lastActiveDashboardID'
    | 'dontAskAgainPickTemporaryDatesForWidgets'
export type localPropertiesKeysUnionFull = localPropertiesKeysUnion | 'isAdmin'

export const localPropertiesKeys: localPropertiesKeysType = [
    'dontAskAgainHideModal',
    'fixedWigetsCount',
    'hidenDashboardIDs',
    'isDashboardPanelVisible',
    'isHiddenDashboardsOnPanelVisible',
    'locale',
    'theme',
    'dashboardsOrder',
    'createWidgetDirection',
    'dontAskAgainChangeWidgetTypeModal',
    'dontAskAgainReduceWidgetsSizeAttention',
    'dontAskAgainReduceGridSizeAttention',
    'lastActiveDashboardID',
    'dontAskAgainPickTemporaryDatesForWidgets',
]
