import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { AddUserIcon } from '@src/components/StyleGuide'
import { type DeleteWidgetModalBodyProps } from './DeleteWidgetModal.types'

export const DeleteWidgetModalBody: FC<DeleteWidgetModalBodyProps> = ({ isAccessShared, widgetName }) => {
    const { t } = useTranslation()
    return (
        <div className={styles.rootBody}>
            <div className={styles.deleteModalInfo}>
                <p className={styles.deleteModalInfo__title}>{t('delete_widget_modal.widget_name')}</p>
                <p className={styles.deleteModalInfo__body}>{widgetName}</p>
            </div>

            <p className={styles.deleteModalWarning}>{t('delete_widget_modal.widget_confirm_message')}</p>
            {isAccessShared && (
                <div className={styles.deleteModalNoteBlock}>
                    <AddUserIcon />
                    <p>
                        <span className={styles.deleteModalPayAttention}>{t('delete_widget_modal.pay_attention')}</span>{' '}
                        {t('delete_widget_modal.widget_warning_message')}
                    </p>
                </div>
            )}
        </div>
    )
}
