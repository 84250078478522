import React, { type FC, useState, useEffect } from 'react'
import { Select } from 'antd'
import styles from './styles.module.scss'
import { ArrowDownIcon, ArrowRoundedIcon } from '@src/components'
import type { ISelectList, SelectListValue } from './SelectList.types'

export const SelectList: FC<ISelectList> = ({
    options,
    placeholder,
    selectedValue,
    className,
    isDisabled,
    isFilter,
    isTransparentBg,
    isAllowAction = true,
    onChange,
}) => {
    const [currentValue, setCurrentValue] = useState<SelectListValue>()
    const isOption = options.some(option => option.value === selectedValue)

    const handleSelect = (value: SelectListValue) => {
        if (isAllowAction) {
            setCurrentValue(value)
            onChange?.(value)
        }
    }
    useEffect(() => {
        if (isOption && (selectedValue || typeof selectedValue === 'number')) {
            setCurrentValue(selectedValue)
        }
        if (!isOption) setCurrentValue(undefined)
    }, [selectedValue, isOption])
    const renderIcon = () => {
        if (isFilter) {
            return <ArrowDownIcon />
        } else {
            return <ArrowRoundedIcon />
        }
    }
    return (
        <Select
            className={`${styles.root} ${className ?? ''} ${isDisabled ? styles.disabled : ''} ${
                isTransparentBg ? 'transparent' : ''
            } ${isFilter ? styles.filter : ''}`}
            suffixIcon={renderIcon()}
            placeholder={placeholder && placeholder}
            onSelect={handleSelect}
            options={options}
            value={currentValue}
        />
    )
}
