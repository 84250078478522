import React, { type FC } from 'react'
import { Header } from '@src/components'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { AccesRights } from '@src/components/AccessRights/AccessRights'

export const AccessRightsSettings: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.pageContainer}>
            <Header markerText={t('header.markers.access_settings')} />
            <AccesRights />
        </div>
    )
}
