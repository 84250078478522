import React, { type CSSProperties, type FC, type ReactNode, type MouseEvent } from 'react'
import styles from './styles.module.sass'

interface IButtonIcon {
    className?: string
    style?: CSSProperties
    icon: ReactNode
    title: string
    disabled?: boolean
    isBoarded?: boolean
    onClick?: ((e: MouseEvent<HTMLButtonElement>) => void) | (() => void)
}

export const ButtonIcon: FC<IButtonIcon> = props => {
    const { className, style, icon, title, disabled, isBoarded, onClick } = props

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (!disabled) {
            onClick?.(e)
        }
    }

    return (
        <button
            className={`${styles.root} ${isBoarded ? 'boarded' : ''} ${className ?? ''}`}
            style={style}
            title={title}
            disabled={disabled}
            onClick={handleClick}
        >
            {icon}
        </button>
    )
}
