import React, { useEffect, type FC } from 'react'
import styles from './styles.module.scss'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { useTranslation } from 'react-i18next'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import type { CopyWidgetBodyProps } from './CopyWidgetModal.types'
import { Input } from '@src/components/StyleGuide'

export const CopyWidgetModalBody: FC<CopyWidgetBodyProps> = ({
    widgetName,
    widgetNewName,
    setName,
    pickedDashboardID,
    isCantCreateError,
    isWillReduceWidgetSizeWarning,
    setPickedDashboardID,
}) => {
    const { t } = useTranslation()
    const { notSharedDashboardsOptions, dashboardListOptions, activeDashboard, isUserAdminInDashboard } = useDashboard()

    useEffect(() => {
        const defaultOption = (isUserAdminInDashboard() ? dashboardListOptions : notSharedDashboardsOptions).find(
            option => option.value === activeDashboard?.id,
        )
        if (defaultOption && !pickedDashboardID) setPickedDashboardID(defaultOption.value)
    }, [
        notSharedDashboardsOptions,
        pickedDashboardID,
        activeDashboard?.id,
        isUserAdminInDashboard,
        dashboardListOptions,
    ])

    const onChangeName = (value: string) => {
        setName(value)
    }
    const onChange = (value: number | string) => {
        setPickedDashboardID(value as number)
    }
    const style = isCantCreateError ? styles.modalWarning : isWillReduceWidgetSizeWarning ? styles.modalInfo : ''

    return (
        <div className={styles.modalBodyContent}>
            <div className={styles.infoContainer}>
                <p className={styles.infoContainer__title}>{t('move_widget_modal.widget_name')}</p>
                <p className={styles.infoContainer__body}>{widgetName}</p>
            </div>
            <div className={styles.selectContainer}>
                <p className={styles.selectContainer__title}>{t('dashboard_control_panel.modal_copy.placeholder')}</p>
                <Input setInputValue={onChangeName} value={widgetNewName} />
            </div>
            <div className={styles.selectContainer}>
                <p className={styles.selectContainer__title}>{t('move_widget_modal.choose_dashboard')}</p>
                <SelectList
                    selectedValue={pickedDashboardID}
                    onChange={onChange}
                    options={isUserAdminInDashboard() ? dashboardListOptions : notSharedDashboardsOptions}
                />
            </div>
            <div className={style}>
                {isCantCreateError && <p>{t('dashboard_control_panel.modal_copy.warning')}</p>}
                {isWillReduceWidgetSizeWarning && <p>{t('dashboard_control_panel.modal_copy.info')}</p>}
            </div>
        </div>
    )
}
