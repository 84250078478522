import axiosInstance from '@src/services/axios'
import type { MainMenuSettingsResponse, UISettingsResponse, UpdateUISettingsRequest } from './userinterface.types'

const UI_URL = '/userInterface'
const SETTINGS_URL = '/settings'
const MAIN_MENU_URL = '/mainMenu'

export const userinterfaceApi = {
    getUISettings: async () => {
        const { data } = await axiosInstance.get<UISettingsResponse>(`${UI_URL}${SETTINGS_URL}`)
        return data
    },
    updateUISettings: async (body: UpdateUISettingsRequest) => {
        const { data } = await axiosInstance.patch(`${UI_URL}${SETTINGS_URL}`, body)
        return data
    },
    getMainMenuSettings: async () => {
        const { data } = await axiosInstance.get<MainMenuSettingsResponse>(`${UI_URL}${MAIN_MENU_URL}`)
        return data
    },
}
