import { ButtonWithIcon, CancelIcon, CloseIcon, ReplaceIcon } from '@src/components/StyleGuide'
import { Modal } from 'antd'
import React, { useState, type FC, useEffect } from 'react'
import './styles.scss'
import styles from './styles.module.scss'
import { MoveWidgetModalBody } from './MoveWidgetModalBody'
import { useTranslation } from 'react-i18next'
import type { WidgetNewProperties, MoveWidgetModalProps } from './MoveWidgetModal.types'
import { ModalTitle } from './ModalTitle'
import { useGridData } from '@src/layouts/GridLayout/hooks/useGridData'
import { createWidgetObjForGridWithoutOverflow } from '@src/layouts/GridLayout/GridLayout.functions'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { getCurrentDashboardLoading, getCurrentDashboardWidgets } from '@src/store/widgets/selectors'

export const MoveWidgetModal: FC<MoveWidgetModalProps> = ({ open, onConfirm, onCancel, widget }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [dashboardID, setDashboardID] = useState(0)
    const [newWidgetProperties, setNewWidgetProperties] = useState<WidgetNewProperties | undefined>()
    const [isCantMoveError, setIsCantMoveError] = useState(false)
    const [isWillReduceWidgetSizeWarning, setIsWillReduceWidgetSizeWarning] = useState(false)
    const currentDashboardWidgets = useAppSelector(store => getCurrentDashboardWidgets(store, dashboardID))
    const currentDashboardWidgetsLoading = useAppSelector(store => getCurrentDashboardLoading(store, dashboardID))

    const isDashboardLoading = !currentDashboardWidgets || !!currentDashboardWidgetsLoading

    const setID = (id: number) => {
        setDashboardID(id)
    }
    const { emptyPlace } = useGridData(dashboardID)
    useEffect(() => {
        if (emptyPlace.length === 0 && !isDashboardLoading) {
            setIsCantMoveError(true)
            setNewWidgetProperties(undefined)
            setIsWillReduceWidgetSizeWarning(false)
        }
        if (isDashboardLoading) {
            setIsCantMoveError(false)
            setNewWidgetProperties(undefined)
            setIsWillReduceWidgetSizeWarning(false)
        }
    }, [emptyPlace, isDashboardLoading])

    useEffect(() => {
        if (dashboardID && !currentDashboardWidgets)
            dispatch(
                fetchDashboardWidgetsList({
                    dashboardID,
                }),
            )
    }, [dashboardID, currentDashboardWidgets])

    useEffect(() => {
        if (emptyPlace.length && widget) {
            const widgetParams = createWidgetObjForGridWithoutOverflow(widget)
            const widgetSize = widgetParams.positions.length
            const newCellForWidgetCopy = emptyPlace.find(([y, x], _, source) => {
                const newMaxY = y + widgetParams.h - 1
                const newMaxX = x + widgetParams.w - 1
                const availableFreePlaces = source.filter(
                    elem => elem[0] >= y && elem[0] <= newMaxY && elem[1] >= x && elem[1] <= newMaxX,
                )
                return availableFreePlaces.length === widgetSize
            })
            if (newCellForWidgetCopy && dashboardID) {
                setNewWidgetProperties({
                    dashboardID,
                    positionY: newCellForWidgetCopy[0],
                    positionX: newCellForWidgetCopy[1],
                })
                setIsWillReduceWidgetSizeWarning(false)
                setIsCantMoveError(false)
            }
            if (!newCellForWidgetCopy && dashboardID && emptyPlace.length) {
                setNewWidgetProperties({
                    dashboardID,
                    positionY: emptyPlace[0][0],
                    positionX: emptyPlace[0][1],
                    cellsX: 1,
                    cellsY: 1,
                })
                setIsWillReduceWidgetSizeWarning(true)
                setIsCantMoveError(false)
            }
        }
    }, [emptyPlace, widget])

    const handleOnConfirm = () => {
        if (newWidgetProperties) {
            onConfirm(newWidgetProperties)
        }
    }

    return (
        <Modal
            open={open}
            rootClassName='moveModal'
            centered
            onCancel={onCancel}
            closeIcon={<CloseIcon />}
            width='fit-content'
            title={<ModalTitle text={t('move_widget_modal.move_widget')} />}
            footer={
                <div className={styles.footerContainer}>
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.okBtn}`}
                        icon={<ReplaceIcon />}
                        text={t('widgets.menu.move')}
                        disabled={isCantMoveError || isDashboardLoading}
                        onClick={handleOnConfirm}
                    />
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.cancelBtn}`}
                        icon={<CancelIcon />}
                        text={t('buttons.cancel')}
                        onClick={onCancel}
                    />
                </div>
            }
        >
            <MoveWidgetModalBody
                isWillReduceWidgetSizeWarning={isWillReduceWidgetSizeWarning}
                widgetName={widget?.name ?? ''}
                dashboardID={dashboardID}
                isCantMoveError={isCantMoveError}
                setDashboardID={setID}
            />
        </Modal>
    )
}
