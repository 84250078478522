import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { FetchExecuteCommandPayload, actionsInitialState } from './actions.types'
import type { CommandsListResponse, FormsListResponse } from '@src/api/actions/actions.types'

const initialState: actionsInitialState = {
    commandsList: null,
    formsList: null,
    isLoadingFormsList: false,
    isErrorFormsList: false,
    isLoadingCommandsList: false,
    isErrorCommandsList: false,
    isLoadingExecuteCommand: false,
    isErrorExecuteCommand: false,
}

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        fetchGetCommandsList: state => {
            state.isErrorCommandsList = false
            state.isLoadingCommandsList = true
        },
        setGetCommandsList: (state, { payload }: PayloadAction<CommandsListResponse>) => {
            state.isLoadingCommandsList = false
            state.commandsList = payload
        },
        errorGetCommandsList: state => {
            state.isLoadingCommandsList = false
            state.isErrorCommandsList = true
        },
        fetchGetFormsList: state => {
            state.isErrorFormsList = false
            state.isLoadingFormsList = true
        },
        setGetFormsList: (state, { payload }: PayloadAction<FormsListResponse>) => {
            state.isLoadingFormsList = false
            state.formsList = payload
        },
        errorGetFormsList: state => {
            state.isLoadingFormsList = false
            state.isErrorFormsList = true
        },
        fetchExecuteCommand: (state, { payload }: PayloadAction<FetchExecuteCommandPayload['payload']>) => {
            state.isLoadingExecuteCommand = true
            state.isErrorExecuteCommand = false
        },
        setExecuteCommand: state => {
            state.isLoadingExecuteCommand = false
        },
        errorExecuteCommand: state => {
            state.isLoadingExecuteCommand = false
            state.isErrorExecuteCommand = true
        },
    },
})

export const { actions: actionsActions, reducer: actions } = actionsSlice
