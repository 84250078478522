import React, { useState, type FC, useEffect, useCallback } from 'react'
import styles from './styles.module.scss'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ReferenceLine } from 'recharts'
import { useTheme } from '@src/hooks'
import type { WidgetProps } from '../widgets.types'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '@src/config/constants'
import type { ChartField } from '@src/api/widgets/widgets.types'
import { CustomTooltip } from '../components/CustomTooltip/CustomTooltip'
import type { ChartFields } from '../components/CustomTooltip/CustomTooltip.types'
import { transformBigNumbersToshortString } from '@src/helpers/utils/transformBigNumbersToshortString'
import { MouseTooltip } from '../components/MouseTooltip'

const AVARAGE_CHARACTER_WIDTH = 9
export const BarChartWidget: FC<WidgetProps> = ({ widgetID, dashboardID }) => {
    const { theme } = useTheme()
    const { barChartWidget } = useWidget({ widgetID, dashboardID })
    const isDateXAxis = barChartWidget?.widget?.xAxisField?.fieldType?.id === 5
    const [data, setData] = useState<Array<Record<string, string | number>>>([])
    const [keys, setKeys] = useState<string[]>([])
    const [goal, setGoal] = useState<ChartField | null>(null)
    const [showTooltip, setShowTooltip] = useState(false)
    const [chartWidth, setChartWidth] = useState(0)
    const [hoveredYTitle, setHoveredYTitle] = useState<Record<string, number> | null>(null)

    const fingWidgetFiels = useCallback(
        (id: number) => {
            const widgetFields = barChartWidget?.widget?.widgetFields
            return widgetFields?.find(elem => elem.id === id)
        },
        [barChartWidget?.widget?.widgetFields],
    )

    const getNamesOfAllfields = useCallback(() => {
        const keyNamePairs = keys.map(key => {
            const field = fingWidgetFiels(Number(key))
            return [key, field]
        })
        return Object.fromEntries(keyNamePairs) as ChartFields
    }, [keys, fingWidgetFiels])
    useEffect(() => {
        const newData =
            barChartWidget?.widget?.data?.xAsisElements?.map(elem => {
                const newDataElement: Record<string, string | number> = {
                    name: isDateXAxis ? dayjs(elem.xAsisValue).format(DATE_FORMAT) : elem.xAsisValue,
                }
                elem.yAsisValues.forEach(item => {
                    newDataElement[item.widgetFieldID] = item.yAsisValue
                })
                return newDataElement
            }) ?? []
        setData(newData)
    }, [barChartWidget?.widget?.data?.xAsisElements])
    useEffect(() => {
        const newKeys = Object.keys(data?.[0] ?? {}).filter(key => key !== 'name')
        setKeys(newKeys)
    }, [data])
    useEffect(() => {
        const fieldWithGoal = barChartWidget?.widget?.widgetFields?.find(elem => elem.isGoalEnabled) ?? null
        setGoal(fieldWithGoal)
    }, [barChartWidget?.widget?.widgetFields])

    const colors = ['#437EC3', '#F49404', '#54c142', '#FFC700', '#D73737']
    const calculateXLabelLength = useCallback(() => {
        const res = data.length ? Math.floor((chartWidth - 37) / data.length / AVARAGE_CHARACTER_WIDTH) : 0
        return res > 0 ? res : 0
    }, [data, chartWidth])

    useEffect(() => {
        const maxLabelLength = calculateXLabelLength()
        const dataLabels = data.map(elem => elem.name)
        setShowTooltip(dataLabels.some(key => typeof key === 'string' && key.length > maxLabelLength))
    }, [data, calculateXLabelLength])
    return (
        <>
            <ResponsiveContainer className={styles.root} debounce={20} onResize={width => setChartWidth(width)}>
                <BarChart data={data} className={styles.chart}>
                    <CartesianGrid
                        vertical={false}
                        strokeDasharray='0'
                        stroke={theme === 'light' ? 'hsla(220, 31%, 94%, 1)' : 'hsla(215, 21%, 30%, 1)'}
                    />
                    {(!barChartWidget?.widget?.isLabelsVisible || showTooltip) && (
                        <Tooltip
                            cursor={false}
                            wrapperStyle={{ zIndex: 9 }}
                            content={state => <CustomTooltip tooltip={state} widgetData={getNamesOfAllfields()} />}
                        />
                    )}
                    <XAxis
                        dataKey='name'
                        stroke={theme === 'light' ? 'hsla(220, 31%, 94%, 1)' : 'hsla(215, 21%, 30%, 1)'}
                        padding='gap'
                        interval={0}
                        tickFormatter={(value: string) => {
                            const cuttedString = value.slice(0, calculateXLabelLength()).trim()
                            return `${cuttedString}${cuttedString.length < value.length ? '..' : ''}`
                        }}
                    />
                    <YAxis
                        width={37}
                        allowDecimals={false}
                        yAxisId='left'
                        orientation='left'
                        domain={[
                            0,
                            (dataMax: number) =>
                                goal?.goal && goal.goal > dataMax
                                    ? Math.ceil(goal.goal * 1.2)
                                    : Math.ceil(dataMax * 1.25),
                        ]}
                        minTickGap={1}
                        tickFormatter={(value: number) => transformBigNumbersToshortString(value)}
                        stroke={theme === 'light' ? 'hsla(220, 31%, 94%, 1)' : 'hsla(215, 21%, 30%, 1)'}
                        tick={{ fontSize: 14 }}
                        scale='sequential'
                        tickCount={7}
                        tickSize={0}
                        tickMargin={4}
                        onMouseEnter={(e: any) => setHoveredYTitle(e)}
                        onMouseLeave={() => setHoveredYTitle(null)}
                    />
                    {goal?.isGoalEnabled && (
                        <ReferenceLine
                            yAxisId='left'
                            stroke='#54C142'
                            strokeDasharray='0'
                            strokeWidth={1}
                            y={goal.goal ?? 0}
                            isFront
                        />
                    )}
                    {keys.map((key, index) => (
                        <Bar
                            key={key}
                            yAxisId='left'
                            dataKey={key}
                            maxBarSize={27}
                            fill={colors[index % colors.length]}
                            label={
                                !!barChartWidget?.widget?.isLabelsVisible && {
                                    position: 'top',
                                    className: styles.barLabel,
                                    stroke: colors[index % colors.length],
                                }
                            }
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
            {hoveredYTitle && (hoveredYTitle?.value ?? 0) > 999 && (
                <MouseTooltip value={hoveredYTitle.value} coordinate={hoveredYTitle.coordinate} />
            )}
        </>
    )
}
