import React, { type FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, ConfigProvider } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import 'dayjs/locale/uk'
import 'dayjs/locale/en'
import enUS from 'antd/lib/locale/en_US'
import ukUA from 'antd/lib/locale/uk_UA'
import { ArrowIcon } from '@src/components'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import type { PopupCalendarProps } from './PopupCalendar.types'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { DATE_FORMAT } from '@src/config/constants'

type CalendarType = [Dayjs, Dayjs]

export const PopupCalendar: FC<PopupCalendarProps> = ({ values, onChangePeriod }) => {
    const { RangePicker } = DatePicker
    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale) ?? 'uk_UA'
    const [dates, setDates] = useState<CalendarType>([dayjs(), dayjs()])
    const [dirty, setDirty] = useState(false)
    const [dateRange, setDateRange] = useState({ dateFrom: '', dateTo: '' })
    const { getDateFromISOFormat } = usePeriod()

    const { t } = useTranslation()

    useEffect(() => {
        if (values) {
            const datesFromProps: CalendarType = [
                getDateFromISOFormat(values.dateFrom),
                getDateFromISOFormat(values.dateTo),
            ]
            setDateRange(values)
            setDates(datesFromProps)
        }
    }, [values])

    const handleOnChangeRange = (value: [any, any] | null) => {
        const dayjsValue = value as CalendarType
        const period = {
            dateFrom: dayjsValue?.[0]?.utc(true)?.startOf('d')?.format(),
            dateTo: dayjsValue?.[1]?.utc(true)?.endOf('d')?.format(),
        }
        if (period?.dateFrom && period?.dateTo) {
            setDateRange(period)
        }
        setDates(dayjsValue)
        setDirty(true)
    }

    const handleAccept = () => {
        onChangePeriod?.(dateRange)
    }
    const localeUA = ukUA?.Calendar
        ? {
              ...ukUA,
              Calendar: {
                  ...ukUA.Calendar,
                  lang: {
                      ...ukUA.Calendar.lang,
                      monthFormat: 'MMMM',
                  },
              },
          }
        : undefined
    const localeUS = enUS?.Calendar
        ? {
              ...enUS,
              Calendar: {
                  ...enUS.Calendar,
                  lang: {
                      ...enUS.Calendar.lang,
                      monthFormat: 'MMMM',
                  },
              },
          }
        : undefined
    return (
        <ConfigProvider locale={locale === 'uk_UA' ? localeUA : localeUS}>
            <RangePicker
                value={dates}
                onChange={handleOnChangeRange}
                className={styles.datePicker}
                prevIcon={<ArrowIcon />}
                nextIcon={<ArrowIcon />}
                suffixIcon={null}
                allowClear={false}
                allowEmpty={[true, true]}
                format={DATE_FORMAT}
                onOpenChange={open => {
                    if (!open && dirty) {
                        handleAccept()
                        setDirty(false)
                    }
                }}
                size='large'
                placeholder={[t('dashboard_control_panel.date_from'), t('dashboard_control_panel.date_to')]}
                popupClassName='inModalPopup'
                separator='-'
            />
        </ConfigProvider>
    )
}
