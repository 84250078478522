import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { fetchUpdateDashboard } from '@src/store/dashboards/slice'
import { useState } from 'react'

export const useData = ({ setModalClose }: { setModalClose: () => void }) => {
    const dispatch = useAppDispatch()
    const autoUpdateIntervals = useAppSelector(({ dictionary }) => dictionary.autoUpdateIntervals)
    const currentDashboard = useAppSelector(({ dashboards }) => dashboards.currentDashboard)
    const currentIntervalValue =
        useAppSelector(({ dashboards }) => dashboards.currentDashboard?.autoUpdateInterval) ?? 0
    const [newInterval, setInterval] = useState<string | number>(currentIntervalValue)

    const options = autoUpdateIntervals.map(({ value, name }) => ({ value, label: name }))

    const updateDashboardAutoUpdateInterval = () => {
        if (currentDashboard?.id && currentDashboard?.autoUpdateInterval !== newInterval)
            dispatch(
                fetchUpdateDashboard({
                    id: currentDashboard.id,
                    autoUpdateInterval: newInterval as number,
                    onSuccess: setModalClose,
                }),
            )
    }
    return {
        setInterval,
        options,
        updateDashboardAutoUpdateInterval,
        currentIntervalValue,
    }
}
