import { type AllEffect, type ForkEffect, call, put, all, takeLatest, takeEvery } from 'redux-saga/effects'
import { permissionsActions } from './slice'
import type { FetchUpdageUsersDataPermissions, FetchUserDataPermissions } from './permissions.types'
import { permisionApi } from '@src/api/permissions/permissions.api'
import type {
    DataPermissionForUser,
    DataPermissionForUserResponse,
    DataPermissionsListResponse,
} from '@src/api/permissions/permissions.types'

function* fetchUserPermissionsWorker({ payload }: FetchUserDataPermissions) {
    try {
        const { onSuccess, ...body } = payload
        const userData = localStorage.getItem('userData')
        const userID = userData && JSON.parse(userData).accountID
        if (userID) {
            const res: DataPermissionForUserResponse[] = yield call(permisionApi.getDataPermissionsForUser, {
                ...body,
                userID: Number(userID),
            })
            if (res) {
                yield put(permissionsActions.setUserDataPermissions(res))
            }
        }
    } catch (err: any) {
        yield put(permissionsActions.errorUserDataPermissions())
    }
}
function* fetchUsersPermissionsWorker({ payload }: FetchUserDataPermissions) {
    try {
        const { onSuccess, ...body } = payload
        const res: DataPermissionsListResponse = yield call(permisionApi.getDataPermissionsList, {
            ...body,
        })
        if (res) {
            yield put(permissionsActions.setUsersDataPermissions(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(permissionsActions.errorUsersDataPermissions())
    }
}

function* fetchUpdateUsersPermissionsWorker({ payload }: FetchUpdageUsersDataPermissions) {
    try {
        const { onSuccess, ...body } = payload
        const res: DataPermissionForUser[] = yield all(
            body.data.map(elem => call(permisionApi.updateDataPermissionsForUser, elem)),
        )
        if (res) {
            yield put(permissionsActions.setUpdateUsersDataPermissions(res))
            onSuccess?.()
        }
    } catch (err: any) {
        yield put(permissionsActions.errorUpdateUsersDataPermissions())
    }
}

export default function* permissionsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(permissionsActions.fetchUserDataPermissions, fetchUserPermissionsWorker),
        takeLatest(permissionsActions.fetchUsersDataPermissions, fetchUsersPermissionsWorker),
        takeEvery(permissionsActions.fetchUpdateUsersDataPermissions, fetchUpdateUsersPermissionsWorker),
    ])
}
