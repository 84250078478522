import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type searchWidgetInDashboardTypes } from '@src/store/searchWidgetInDashboard/searchWidgetInDashboardSlice.types'

const initialState: searchWidgetInDashboardTypes = {
    searchValueOfWidget: '',
}

export const searchWidgetInDashboardSlice = createSlice({
    name: 'searchWidgetInDashboard',
    initialState,
    reducers: {
        setSearchValueOfWidget: (state, action: PayloadAction<string>) => {
            state.searchValueOfWidget = action.payload
        },
        resetSearchValueOfWidget: state => {
            state.searchValueOfWidget = ''
        },
    },
})

export const { reducer: searchWidgetInDashboard, actions: searchWidgetInDashboardActions } =
    searchWidgetInDashboardSlice
