import React, { useState, type FC, useMemo, useRef, useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import styles from './styles.module.scss'
import { useResize } from './hooks/useResize'
import { useDragNDrop } from './hooks/useDragNDrop'
import { useGridData } from './hooks/useGridData'
import { EmptyWidget } from '@src/components'
import { WidgetLayout } from '../WidgetLayout/WidgetLayout'
import { WidgetNotEnoughtFreeSpace } from '@src/components/Modals/WidgetNotEnoughtFreeSpace/WidgetNotEnoughtFreeSpace'
import { useMediaQuery } from '@src/hooks/useMediaQuery/useMediaQuery'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'

export const GridLayout: FC = () => {
    const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), [])
    const gridRef = useRef<null | HTMLDivElement>(null)

    const [gridHeight, setGridHeight] = useState(0)
    if (gridRef.current && gridRef.current.clientHeight !== gridHeight) {
        setGridHeight(gridRef.current.clientHeight)
    }
    useEffect(() => {
        const setConteinerHeight = () => {
            if (gridRef.current) {
                setGridHeight(gridRef.current.clientHeight)
            }
        }
        gridRef?.current?.addEventListener('resize', setConteinerHeight)
        return () => {
            gridRef?.current?.removeEventListener('resize', setConteinerHeight)
        }
    }, [])
    const mediaQueryLess1366px = useMediaQuery('(max-width: 1366px)')
    const { dashboardID, isSharedDashboard, isUserAdminInDashboard, activeDashboard } = useDashboard()

    const [isOpenNotEnoughtFreeSpace, setIsOpenNotEnoughtFreeSpace] = useState(false)
    const [activeGridItemID, setActiveGridItemID] = useState(0)
    const {
        layout,
        emptyPlace,
        colsNumber,
        rowsNumber,
        widgetsGrid,
        currentsWidgetsList,
        setWidgetsGrid,
        createWidgetsGrid,
        sendChangedWidgets,
    } = useGridData(dashboardID)
    const { handleResizeStart, handleResize, handleResizeStop, isResizing } = useResize({
        emptyPlace,
        widgetsGrid,
        setWidgetsGrid,
        setActiveGridItemID,
    })

    const { maxRows, isDrag, handleOnDragStart, handleOnDrag, handleOnDragStop } = useDragNDrop({
        rowsNumber,
        setWidgetsGrid,
        sendChangedWidgets,
        widgetsGrid,
        emptyPlace,
        setIsOpenNotEnoughtFreeSpace,
        setActiveGridItemID,
    })

    const cancelNotEnoughtFreeSpaceModal = () => {
        setIsOpenNotEnoughtFreeSpace(false)
        createWidgetsGrid()
    }
    const applyNotEnoughtFreeSpaceModal = () => {
        setIsOpenNotEnoughtFreeSpace(false)
        sendChangedWidgets()
    }

    const gridChildren = useMemo(() => {
        return layout.map(item => {
            const matchedWidget = currentsWidgetsList.find(widget => widget.id.toString() === item.i)
            if (matchedWidget)
                return (
                    <div
                        key={item.i}
                        data-grid={{ ...item, static: !isUserAdminInDashboard(activeDashboard?.users) }}
                        style={{ zIndex: activeGridItemID === matchedWidget.id ? 1000 : 1 }}
                        className={`${styles.item}`}
                        onClick={() => setActiveGridItemID(matchedWidget.id)}
                    >
                        {dashboardID && (
                            <WidgetLayout
                                access={matchedWidget.deniedFields.length === 0}
                                widgetType={matchedWidget.widgetType}
                                widgetID={matchedWidget.id}
                                dashboardID={dashboardID}
                                isLocked={!isUserAdminInDashboard(activeDashboard?.users)}
                            />
                        )}
                    </div>
                )
            return (
                <div
                    key={item.i}
                    className={`${styles.item} ${styles.emptyWidget} ${
                        isResizing || isDrag || !isUserAdminInDashboard(activeDashboard?.users) ? '' : styles.hover
                    } `}
                >
                    <EmptyWidget x={item.x} y={item.y} isLocked={!isUserAdminInDashboard(activeDashboard?.users)} />
                </div>
            )
        })
    }, [
        layout,
        isSharedDashboard,
        isUserAdminInDashboard,
        activeDashboard?.users,
        dashboardID,
        isResizing,
        activeGridItemID,
        isDrag,
    ])
    const calculateRowHeight = () => {
        const minHeight = mediaQueryLess1366px ? 230 : 275
        const rowHeight = (gridHeight - ((rowsNumber ?? 1) - 1) * 12) / (rowsNumber ?? 1)
        if (rowHeight < minHeight) return minHeight
        if (rowHeight > gridHeight) return gridHeight
        return rowHeight
    }

    return (
        <>
            <div className={styles.root}>
                <div ref={gridRef} className={`container ${styles.gridContainer}`}>
                    <ResponsiveGridLayout
                        className={styles.layout}
                        draggableCancel='.noDrag'
                        draggableHandle='.handleDragg'
                        layouts={{ lg: layout }}
                        compactType='horizontal'
                        allowOverlap
                        preventCollision
                        onDragStart={handleOnDragStart}
                        onDrag={handleOnDrag}
                        onDragStop={handleOnDragStop}
                        onResizeStart={handleResizeStart}
                        onResize={handleResize}
                        onResizeStop={handleResizeStop}
                        maxRows={maxRows}
                        margin={[12, 12]}
                        containerPadding={[0, 0]}
                        breakpoints={{
                            lg: 1200,
                        }}
                        cols={{ lg: colsNumber || 1 }}
                        rowHeight={calculateRowHeight()}
                    >
                        {gridChildren}
                    </ResponsiveGridLayout>
                </div>
            </div>
            <WidgetNotEnoughtFreeSpace
                isOpen={isOpenNotEnoughtFreeSpace}
                onApply={applyNotEnoughtFreeSpaceModal}
                onCancel={cancelNotEnoughtFreeSpaceModal}
            />
        </>
    )
}
