import axiosInstance from '@src/services/axios'
import type {
    WidgetID,
    WidgetListResponse,
    WidgetCounterResponse,
    CreateWidgetCounterRequest,
    UpdateWidgetCounterRequest,
    getWidgetsTableDataArgs,
    WidgetTableDataType,
    WidgetTextBlockResponse,
    CreateWidgetTextBlockRequest,
    UpdateWidgetText,
    CreateWidgetTableRequest,
    WidgetTableResponse,
    UpdateWidgetTable,
    CreateWidgetPieChartRequest,
    WidgetPieChartResponse,
    UpdateWidgetPieChartRequest,
    CreateWidgetBarChartRequest,
    UpdateWidgetBarChartRequest,
    WidgetBarChartResponse,
    CreateWidgetLineChartRequest,
    UpdateWidgetLineChartRequest,
    WidgetLineChartResponse,
    RequestWidgetID,
    RequestWidgetWithTemporaryDate,
} from './widgets.types'
import type { RequestDashboardID } from '../dashboardApi/dashboard.types'
import { createURLQueryParams } from '@src/helpers/utils/createURLQueryParams'

const DATA_URL = '/flatData'
const DASHBOARD_URL = '/dashboard'
const WIDGET_URL = '/widget'
const LIST_URL = '/list'
const TEXT_BLOCK = '/textBlock'
const COUNTER_URL = '/counter'
const PIE_URL = '/pieChart'
const BAR_URL = '/barChart'
const LINE_URL = '/lineChart'
const TABLE = '/table'

export const widgetAPI = {
    getWidgetsList: async (params: RequestDashboardID) => {
        const { data } = await axiosInstance.get<WidgetListResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${LIST_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    // widgets API

    // TextBlock widget api
    getTextBlock: async (params: RequestWidgetID) => {
        const { data } = await axiosInstance.get<WidgetTextBlockResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${TEXT_BLOCK}${createURLQueryParams(params)}`,
        )
        return data
    },
    createTextBlock: async (body: CreateWidgetTextBlockRequest) => {
        const { data } = await axiosInstance.post<WidgetTextBlockResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${TEXT_BLOCK}`,
            body,
        )
        return data
    },
    updateTextBlock: async (body: UpdateWidgetText) => {
        const { data } = await axiosInstance.patch<WidgetTextBlockResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${TEXT_BLOCK}`,
            body,
        )
        return data
    },
    // table widget api
    getTableWidget: async (params: RequestWidgetWithTemporaryDate) => {
        const { data } = await axiosInstance.get<WidgetTableResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${TABLE}${createURLQueryParams(params)}`,
        )
        return data
    },
    createTableWidget: async (body: CreateWidgetTableRequest) => {
        const { data } = await axiosInstance.post<WidgetTableResponse>(`${DASHBOARD_URL}${WIDGET_URL}${TABLE}`, body)
        return data
    },
    updateTableWidget: async (body: UpdateWidgetTable) => {
        const { data } = await axiosInstance.patch<WidgetTableResponse>(`${DASHBOARD_URL}${WIDGET_URL}${TABLE}`, body)
        return data
    },
    // Counter widget api
    getCounter: async (params: RequestWidgetWithTemporaryDate) => {
        const { data } = await axiosInstance.get<WidgetCounterResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${COUNTER_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    createCounter: async (body: CreateWidgetCounterRequest) => {
        const { data } = await axiosInstance.post<WidgetCounterResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${COUNTER_URL}`,
            body,
        )
        return data
    },

    updateCounter: async (body: UpdateWidgetCounterRequest) => {
        const { data } = await axiosInstance.patch<WidgetCounterResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${COUNTER_URL}`,
            body,
        )
        return data
    },
    // PieChart widget API
    getPieChart: async (params: RequestWidgetWithTemporaryDate) => {
        const { data } = await axiosInstance.get<WidgetPieChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${PIE_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    createPieChart: async (body: CreateWidgetPieChartRequest) => {
        const { data } = await axiosInstance.post<WidgetPieChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${PIE_URL}`,
            body,
        )
        return data
    },
    updatePieChart: async (body: UpdateWidgetPieChartRequest) => {
        const { data } = await axiosInstance.patch<WidgetPieChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${PIE_URL}`,
            body,
        )
        return data
    },
    // BarChart widget API
    getBarChart: async (params: RequestWidgetWithTemporaryDate) => {
        const { data } = await axiosInstance.get<WidgetBarChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${BAR_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    createBarChart: async (body: CreateWidgetBarChartRequest) => {
        const { data } = await axiosInstance.post<WidgetBarChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${BAR_URL}`,
            body,
        )
        return data
    },
    updateBarChart: async (body: UpdateWidgetBarChartRequest) => {
        const { data } = await axiosInstance.patch<WidgetBarChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${BAR_URL}`,
            body,
        )
        return data
    },
    // lineChart widget API
    getLineChart: async (params: RequestWidgetWithTemporaryDate) => {
        const { data } = await axiosInstance.get<WidgetLineChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${LINE_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    createLineChart: async (body: CreateWidgetLineChartRequest) => {
        const { data } = await axiosInstance.post<WidgetLineChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${LINE_URL}`,
            body,
        )
        return data
    },
    updateLineChart: async (body: UpdateWidgetLineChartRequest) => {
        const { data } = await axiosInstance.patch<WidgetLineChartResponse>(
            `${DASHBOARD_URL}${WIDGET_URL}${LINE_URL}`,
            body,
        )
        return data
    },

    deleteWidgetById: async (params: RequestWidgetID) => {
        const { data } = await axiosInstance.delete<string>(
            `${DASHBOARD_URL}${WIDGET_URL}${createURLQueryParams(params)}`,
        )
        return data
    },

    getWidgetsTableData: async (params: getWidgetsTableDataArgs) => {
        const { data } = await axiosInstance.get<WidgetTableDataType>(
            `${DASHBOARD_URL}${DATA_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
}
