import React, { useState, type FC, useEffect } from 'react'
import styles from '@src/components/Modals/styles.module.scss'
import { ApplyIcon, ButtonPrimary, CancelIcon, InfoIcon, SearchInput, WarningIcon } from '@src/components'
import { ModalLayout } from '@src/layouts'
import { useTranslation } from 'react-i18next'
import type { IOptionsModal } from '@src/components/Modals/DashboardOptionsModals/@types'
import { SharedUsers } from '@src/components/DashboardControlOption/SharedUsers/SharedUsers'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import type { DashboardUsers } from '@src/api/dashboardApi/dashboard.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchGrandAccessAndShareDashboard, fetchUpdateDashboard } from '@src/store/dashboards/slice'

export const ModalShare: FC<IOptionsModal> = ({ dashboardTitle, setModalOpen, dashboardID }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { currentDashboard } = useDashboard({ id: dashboardID })
    const [search, setSearch] = useState('')
    const [usersWithDeniedAccess, setUsersWithDeniedAccess] = useState<DashboardUsers[]>()
    const isAllUsersHasDeniedAccess = (usersWithDeniedAccess?.length ?? 0) === 0
    const [users, setUsers] = useState<DashboardUsers[]>()
    useEffect(() => {
        setUsers(currentDashboard?.users)
    }, [currentDashboard?.users])

    useEffect(() => {
        const deniedAccessUsers = users?.filter(user => user.deniedFields.length > 0)
        setUsersWithDeniedAccess(deniedAccessUsers)
    }, [users])

    const handleCancel = () => {
        setModalOpen(false)
    }

    const getFieldsListForGrantAccess = () =>
        usersWithDeniedAccess
            ?.map(user =>
                user.deniedFields.map(field => ({
                    datasourceTypeID: field.datasourceType.id,
                    userID: user.id,
                    field,
                    isAllowed: true,
                })),
            )
            ?.flat(1)
    const onSave = () => {
        if (isAllUsersHasDeniedAccess)
            dispatch(fetchUpdateDashboard({ id: dashboardID, users, onSuccess: handleCancel }))
        if (!isAllUsersHasDeniedAccess && users) {
            const fields = getFieldsListForGrantAccess()
            if (fields)
                dispatch(fetchGrandAccessAndShareDashboard({ dashboardID, users, fields, onSuccess: handleCancel }))
        }
    }

    return (
        <ModalLayout
            setIsModalOpen={setModalOpen}
            title={t('dashboard_control_panel.share_modal.title')}
            titleSpan={dashboardTitle}
        >
            <div className={`${styles.root} ${styles.noWidth}`}>
                <div className={styles.wrap}>
                    <p className={styles.msg}>{t('dashboard_control_panel.share_modal.message')}</p>
                    <div className={styles.inner}>
                        <div className={styles.innerSearch}>
                            <SearchInput onSearch={value => setSearch(value)} />
                            <SharedUsers dashboardID={dashboardID} users={users} setUsers={setUsers} search={search} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.wrap} ${styles.withIcon}`}>
                    {!isAllUsersHasDeniedAccess ? (
                        <>
                            <WarningIcon />
                            <p className={styles.shareWarning}>
                                {t('dashboard_control_panel.share_modal.warning_error')}
                            </p>
                        </>
                    ) : (
                        <>
                            <InfoIcon />
                            <p className={styles.shareWarning}>
                                <span>{t('dashboard_control_panel.share_modal.warning_title')}</span>
                                {t('dashboard_control_panel.share_modal.warning_msg')}
                            </p>
                        </>
                    )}
                </div>
                <div className={styles.btnWrap}>
                    <ButtonPrimary
                        className={styles.btnUnView}
                        icon={<ApplyIcon />}
                        title={
                            !isAllUsersHasDeniedAccess
                                ? t('dashboard_control_panel.share_modal.share_btn_error')
                                : t('dashboard_control_panel.share_modal.share_btn')
                        }
                        type='primary'
                        onClick={onSave}
                    />
                    <ButtonPrimary
                        icon={<CancelIcon />}
                        className={styles.cancel}
                        title={t('dashboard_control_panel.modal_cancel_btn')}
                        type='transparent'
                        onClick={handleCancel}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}
