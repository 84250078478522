import { useEffect } from 'react'
import { useAppSelector } from '../redux/useAppSelector'

export const useTheme = () => {
    const theme = useAppSelector(({ appState }) => appState.UISettings?.theme)
    useEffect(() => {
        document.body.setAttribute('data-theme', theme ?? 'light')
    }, [theme])
    return {
        theme,
    }
}
