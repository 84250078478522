export enum localStorageKeys {
    userData = 'userData',
    dontAskAgainHideModal = 'dontAskAgainHideModal',
    fixedWigetsCount = 'fixedWigetsCount',
    hidenDashboardIDs = 'hidenDashboardIDs',
    isDashboardPanelVisible = 'isDashboardPanelVisible',
    isHiddenDashboardsOnPanelVisible = 'isHiddenDashboardsOnPanelVisible',
    locale = 'locale',
    theme = 'theme',
    dashboardsOrder = 'dashboardsOrder',
    createWidgetDirection = 'createWidgetDirection',
    dontAskAgainChangeWidgetTypeModal = 'dontAskAgainChangeWidgetTypeModal',
    dontAskAgainReduceWidgetsSizeAttention = 'dontAskAgainReduceWidgetsSizeAttention',
    dontAskAgainReduceGridSizeAttention = 'dontAskAgainReduceGridSizeAttention',
    lastActiveDashboardID = 'lastActiveDashboardID',
    dontAskAgainPickTemporaryDatesForWidgets = 'dontAskAgainPickTemporaryDatesForWidgets',
}
