import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { ButtonIcon, ColorPicker, MinusIcon, PlusIcon, Switcher } from '@src/components'
import styles from './styles.module.scss'
import { GoalPanel } from '../../GoalPanel/GoalPanel'
import type { YAxisLineChartItemProps } from './LineChartSettings.types'
import { useAggregateFunctions } from '@src/hooks/useAggregateFunctions/useAggregateFunctions'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'

export const YAxisLineChartItem: FC<YAxisLineChartItemProps> = ({
    rowData,
    isAvailableAddRow,
    isAvailableDeleteRow,
    onDataChange,
    onFuncChange,
    onColorChange,
    onGoalChange,
    onGoalSwitch,
    onRowAdd,
    onRowDelete,
    onRowSwitch,
}) => {
    const { t } = useTranslation()
    const { availableYAxisOptions } = useAggregateFunctions(rowData?.dataField?.fieldType?.id)
    const { lineChartWidget } = useWidget()

    const { allowedUserDataOptions } = useUserFields({
        datasourceTypeID: lineChartWidget?.listData.dataSourceType.id,
        datasetID: lineChartWidget?.listData.dataset.id,
    })
    const handleYAxisData = (value: string | number) => {
        onDataChange(value as string)
    }
    const handleFunc = (value: string | number) => {
        onFuncChange(value as number)
    }

    return (
        <tr className={styles.root}>
            <td>
                <div className={styles.dataCol}>
                    <SelectList
                        options={allowedUserDataOptions}
                        onChange={handleYAxisData}
                        selectedValue={rowData.dataField?.id}
                    />
                </div>
            </td>
            <td>
                <div className={styles.funcCol}>
                    <SelectList
                        options={availableYAxisOptions}
                        onChange={handleFunc}
                        selectedValue={rowData.aggregateFunctionType?.id}
                    />
                </div>
            </td>
            <td>
                <div>
                    <ColorPicker colorValue={rowData.color as string} onChange={onColorChange} />
                </div>
            </td>
            <td>
                <div>
                    <GoalPanel
                        goal={rowData.goal ?? 0}
                        isActive={rowData.isGoalEnabled ?? false}
                        onGoalActivate={onGoalSwitch}
                        onGoalChange={onGoalChange}
                    />
                    <div className={styles.actionsWrap}>
                        <ButtonIcon
                            className={`${styles.actionBtn} ${styles.minusBtn}`}
                            icon={<MinusIcon />}
                            disabled={!isAvailableDeleteRow}
                            title={t('buttons.removeData')}
                            onClick={onRowDelete}
                        />
                        <ButtonIcon
                            className={styles.actionBtn}
                            icon={<PlusIcon />}
                            disabled={!isAvailableAddRow}
                            title={t('buttons.addData')}
                            onClick={onRowAdd}
                        />
                    </div>
                    <Switcher title={t('buttons.switchOnRowData')} value={!rowData.isDisabled} onChange={onRowSwitch} />
                </div>
            </td>
        </tr>
    )
}
