import { ColorPicker as AntdColorPicker } from 'antd'
import React, { type FC, useState, useEffect } from 'react'
import { ArrowRoundedIcon } from '@src/components'
import styles from './styles.module.sass'
import type { ColorPickerProps } from './ColorPicker.types'
import type { Color } from 'antd/es/color-picker'

export const ColorPicker: FC<ColorPickerProps> = ({ colorValue, isDisabled, className, onChange }) => {
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState<Color | string>('#437EC3')
    useEffect(() => {
        if (colorValue !== color) setColor(colorValue)
    }, [colorValue])

    const handleOpen = () => {
        setOpen(!open)
        if (open) {
            const formatColor = typeof color !== 'string' ? color.toRgbString() : color
            onChange?.(formatColor)
        }
    }

    return (
        <AntdColorPicker
            className={`${styles.root} ${isDisabled ? styles.disabled : ''} ${className ?? ''}`}
            open={open}
            value={color}
            onChange={setColor}
            onOpenChange={handleOpen}
            disabled={isDisabled ?? false}
            disabledAlpha
            defaultFormat='rgb'
            format='rgb'
            showText={() => (
                <div className={styles.icon} style={open ? { transform: 'rotate(180deg)' } : {}}>
                    <ArrowRoundedIcon />
                </div>
            )}
        />
    )
}
