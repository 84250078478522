import React, { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowRoundedIcon, SettingsLinesIcon } from '@src/components'
import styles from '@src/components/SystemSettings/styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDatasource } from '@src/hooks/useDatasource/useDatasource'

export const AccessSetting: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { adminDataSourceOptions } = useDatasource()

    const [activeSubMenu, setActiveSubMenu] = useState(false)

    const [datasource, setDatasource] = useState<(typeof adminDataSourceOptions)[number]>()
    useEffect(() => {
        if (adminDataSourceOptions.length && !datasource) setDatasource(adminDataSourceOptions[0])
    }, [adminDataSourceOptions])

    const handleSelectOption = (label: string, value: number) => {
        setDatasource({ label, value })
        navigate(`/access-rights?datasourceID=${value}`)
    }

    return (
        <div
            className={styles.item}
            onMouseEnter={() => setActiveSubMenu(true)}
            onMouseLeave={() => setActiveSubMenu(false)}
        >
            <div className={styles.itemIcon}>
                <SettingsLinesIcon />
            </div>
            <div className={styles.itemWrap}>
                <p className={styles.itemTitle}>{t('system_settings.access_title')}</p>
                <p className={styles.itemValue}>{datasource?.label ?? ''}</p>
            </div>
            <div className={styles.itemArrow}>
                <ArrowRoundedIcon />
            </div>
            {activeSubMenu && (
                <ul className={styles.itemInclude}>
                    {adminDataSourceOptions.map(({ value, label }) => (
                        <li
                            key={value}
                            className={datasource?.value === value ? styles.active : ''}
                            onClick={() => handleSelectOption(label, value)}
                        >
                            <p>{label}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
