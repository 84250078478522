import React, { useState, type ChangeEvent, type FC, useEffect } from 'react'
import styles from './styles.module.scss'
import type { InputProps } from './Input.types'

export const Input: FC<InputProps> = ({ className, value, setInputValue, setChangeOnFocus, disabled }) => {
    const [input, setInput] = useState(value ?? '')
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
        if (!setChangeOnFocus) setInputValue(e.target.value)
    }
    const handleOnBlur = () => {
        if (setChangeOnFocus && input !== value) {
            setInputValue(input)
        }
    }
    useEffect(() => {
        if (value) setInput(value)
    }, [value])
    if (!value) <></>
    return (
        <input
            className={`${styles.root} ${className ?? ''}`}
            value={input}
            disabled={disabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
        />
    )
}
