import React from 'react'
import { t } from 'i18next'
import styles from './styles.module.scss'

interface ITitleProps {
    title?: string | undefined
    subtitle: string | null | undefined
}

export const Title: React.FC<ITitleProps> = ({ title, subtitle }) => (
    <div className={styles.modalTitle}>
        <span>{title ?? t('widgets.text.modal.title')}</span>
        <span className={styles.widgetTitle}> {subtitle}</span>
    </div>
)
