import { useEffect } from 'react'
import { useAppDispatch } from '../redux/useAppDispatch'
import { useAppSelector } from '../redux/useAppSelector'
import { localPropertiesKeys } from '@src/config/localUISettingsKeys'
import { appStateActions } from '@src/store/appState/slice'
import { type LocalUISettingsMergedType } from '@src/store/appState/appState.types'
import { userActions } from '@src/store/user/slice'
import { getLocalStorageValue } from '@src/helpers/utils/getLocalStorageValue'

export const useLocalSettings = () => {
    const dispatch = useAppDispatch()
    const UISettings = useAppSelector(({ appState }) => appState.UISettings)
    useEffect(() => {
        if (!UISettings) {
            const localProperties: LocalUISettingsMergedType = {}
            localPropertiesKeys.forEach(key => {
                const value = localStorage.getItem(key)
                if (value) {
                    localProperties[key] = JSON.parse(value)
                }
            })
            dispatch(appStateActions.setLocalUISettings(localProperties))
        }
    }, [UISettings])
    useEffect(() => {
        const isAdmin = getLocalStorageValue('isAdmin')
        if (isAdmin) {
            const parsedIsAdmin: boolean = JSON.parse(isAdmin)
            dispatch(userActions.setAdmin(parsedIsAdmin))
        }
    }, [])
    useEffect(() => {
        if (UISettings) {
            localPropertiesKeys.forEach(key => {
                const storeValue = UISettings[key]
                const localValue = localStorage.getItem(key)
                if (storeValue && localValue !== storeValue) {
                    localStorage.setItem(key, JSON.stringify(storeValue))
                }
            })
        }
    }, [UISettings])
}
