import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary } from '@src/components/StyleGuide'
import { Title } from './Title'
import styles from './styles.module.scss'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import type { AntModalProps } from '@src/layouts/AntModalLayout/AntModalLayout.types'

export const GridCantReduceModal: FC<AntModalProps> = ({ onCancel, isOpen }) => {
    const { t } = useTranslation()

    const handleClose = () => {
        onCancel()
    }

    return (
        <AntModalLayout isOpen={isOpen} onCancel={onCancel} title={<Title title={t('grid_cant_reduce_modal.title')} />}>
            <div className={styles.bodyWrapper}>
                <div className={styles.modalWarning}>
                    <p>{t('grid_cant_reduce_modal.warning')}</p>
                </div>
                <p className={styles.modalDescription}>{t('grid_cant_reduce_modal.description')}</p>
                <div className={styles.buttonsWrapper}>
                    <ButtonPrimary
                        icon={<ApplyIcon stroke='white' />}
                        title={t('buttons.understand')}
                        type='primary'
                        onClick={handleClose}
                    />
                </div>
            </div>
        </AntModalLayout>
    )
}
