import React, { type FC, useEffect, useMemo, useState } from 'react'
import './styles.scss'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import { getCurrentLanguage } from '@src/helpers'
import { useToolbar } from '@src/hooks'
import type { FetchUpdateWidgetById } from '@src/store/widgets/widgets.types'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { Locales } from '@src/helpers/locales.enum'

interface TextEditorProps {
    data?: string
    setData?: (data: string) => void
    hidePanel?: boolean
    autosend?: boolean
    valueText?: string
    setValueText?: (value: string) => void
    onChange?: (event: any, editor: Editor) => void
    debounceValue?: string
    updateWidget: (payload: Partial<FetchUpdateWidgetById>, sendAllRequests?: boolean | undefined) => void
}
export const TextEditor: FC<TextEditorProps> = ({ onChange, data, autosend = true, debounceValue, updateWidget }) => {
    const [init] = useToolbar({})
    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale)
    const config = {
        language: locale ? Locales[locale] : 'uk',
    }
    useEffect(() => {
        if (debounceValue && autosend) {
            updateWidget({ text: debounceValue }, true)
        }
    }, [debounceValue])

    return (
        <div className='textEditor'>
            <CKEditor
                key={locale}
                config={config}
                onReady={init}
                i18nIsDynamicList
                editor={Editor}
                data={data}
                onChange={onChange}
            />
        </div>
    )
}
