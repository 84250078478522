import React, { useState, type Dispatch, type FC, type SetStateAction, useEffect } from 'react'
import styles from './styles.module.scss'
import { ColorPicker, InputCount, Switcher, TargetIcon } from '@src/components'
import { useTranslation } from 'react-i18next'
import { DEFAULT_COUNTER_COLOR } from '@src/config/constants'

interface GoalPanelProps {
    goal?: number
    color?: string | null
    isActive: boolean
    onGoalActivate: (active: boolean) => void
    onGoalChange: ((value: number) => void) | Dispatch<SetStateAction<number>>
    isCreateCounterWidgetPage?: boolean
    onColorChangeActivate?: (status: boolean) => void
    onColorChange?: (color: string) => void
}

export const GoalPanel: FC<GoalPanelProps> = ({
    goal = 0,
    color,
    isActive,
    onGoalActivate,
    onGoalChange,
    isCreateCounterWidgetPage,
    onColorChangeActivate,
    onColorChange,
}) => {
    const { t } = useTranslation()
    const [isColorChange, setIsColorChange] = useState(false)

    useEffect(() => {
        if (color) setIsColorChange(true)
    }, [color])
    const handleChangeColorWhenGoalAchived = (value: boolean) => {
        onColorChangeActivate?.(value)
        setIsColorChange(value)
    }

    return (
        <div className={styles.root}>
            <div
                className={`${styles.switchBtn} ${isCreateCounterWidgetPage ? styles.fixedHeight : ''} ${
                    isColorChange && isActive ? styles.colorChangeActive : ''
                }`}
                style={isCreateCounterWidgetPage && isActive ? { width: '100%' } : {}}
            >
                <TargetIcon />
                <span className={styles.switchBtnLabel}>{t('create_widget.settings.field_goal_label')}</span>
                <Switcher value={isActive} onChange={onGoalActivate} />
                {isCreateCounterWidgetPage && isActive && (
                    <>
                        <InputCount
                            value={goal}
                            disabled={!isActive}
                            inputClassName={styles.settingInput}
                            onChange={onGoalChange}
                        />
                        <div className={styles.settingWrap}>
                            <div className={'divider'} />
                            <span className={styles.settingTitle}>{t('target_panel.setting_title')}</span>
                            <Switcher value={isColorChange} onChange={handleChangeColorWhenGoalAchived} />
                        </div>
                        {isColorChange && (
                            <>
                                <div className={'divider'} />
                                <span className={styles.settingTitle}>{t('target_panel.target_more')}</span>
                                <ColorPicker
                                    className={styles.settingColorPicker}
                                    colorValue={color || DEFAULT_COUNTER_COLOR}
                                    onChange={onColorChange}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
            {!isCreateCounterWidgetPage && <InputCount value={goal} disabled={!isActive} onChange={onGoalChange} />}
        </div>
    )
}
