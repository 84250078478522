import { createURLQueryParams } from '@src/helpers/utils/createURLQueryParams'
import type {
    DashboardResponse,
    CreateDashboardRequest,
    DashboardsListResponse,
    UpdateDashboardRequest,
    DeleteDashboardRequest,
    DeleteDashboardResponse,
    FullUpdateDashboardRequest,
    DashboardId,
    CheckUsersPermissionRequest,
    DashboardUsers,
    RequestDashboardID,
} from './dashboard.types'
import axiosInstance from '@src/services/axios'

const DASHBOARD_URL = '/dashboard'
const LIST_URL = '/list'
const USER_URL = '/user'
const CHECK_URL = '/check'

export const dashboardApi = {
    getDashboardsList: async () => {
        const { data } = await axiosInstance.get<DashboardsListResponse>(`${DASHBOARD_URL}${LIST_URL}`)
        return data
    },
    getDashboardById: async (params: RequestDashboardID) => {
        const { data } = await axiosInstance.get<DashboardResponse>(`${DASHBOARD_URL}${createURLQueryParams(params)}`)
        return data
    },
    createDashboard: async (body: CreateDashboardRequest) => {
        const { data } = await axiosInstance.post<DashboardResponse>(DASHBOARD_URL, body)
        return data
    },
    fullUpdateDashboard: async (body: FullUpdateDashboardRequest) => {
        const { data } = await axiosInstance.put<DashboardResponse>(DASHBOARD_URL, body)
        return data
    },
    updateDashboard: async (body: UpdateDashboardRequest) => {
        const { data } = await axiosInstance.patch<DashboardResponse>(DASHBOARD_URL, body)
        return data
    },
    deleteDashboard: async (params: RequestDashboardID) => {
        const { data } = await axiosInstance.delete<DeleteDashboardResponse>(
            `${DASHBOARD_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    checkUsersPermissionsToShare: async (params: CheckUsersPermissionRequest) => {
        const { data } = await axiosInstance.get<DashboardUsers[]>(
            `${DASHBOARD_URL}${USER_URL}${CHECK_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
}
