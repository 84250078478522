import React, { type FC, useState, useEffect } from 'react'
import styles from './styles.module.sass'
import { MainMenu } from '@src/components/MainMenu/MainMenu'
import { DashboardControlPanel, Logo, LogOutIcon, QuestionIcon, SettingsIcon, SystemSettings } from '@src/components'
import { ButtonIcon } from '@src/components/StyleGuide/Ui/Buttons/ButtonIcon/ButtonIcon'
import { useTranslation } from 'react-i18next'
import type { HeaderProps } from './Header.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { userActions } from '@src/store/user/slice'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import { IFrameFormModal } from '../Modals/IFrameFormModal/IFrameFormModal'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'

export const Header: FC<HeaderProps> = ({ isMainPage, markerText }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const helpForm = useAppSelector(({ actions }) => actions.formsList)?.find(elem => elem.formType.id === 2)
    const activeDashboard = useAppSelector(({ dashboards }) => dashboards.currentDashboard)
    const temporaryWidgetsDates = useAppSelector(state => state.widgets.temporaryWidgetsDates)
    const navigate = useNavigate()
    const location = useLocation()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen)
    }
    const [searchParams] = useSearchParams()
    const isOpenFromSearch = searchParams.get('isOpenSettings')
    useEffect(() => {
        if (isOpenFromSearch) setIsModalOpen(true)
    }, [isOpenFromSearch])
    useEffect(() => {
        if (temporaryWidgetsDates && activeDashboard)
            dispatch(
                fetchDashboardWidgetsList({
                    dashboardID: activeDashboard.id,
                }),
            )
    }, [location.pathname])
    const handleLogOut = () => {
        dispatch(userActions.logOutAction())
    }
    const handleCloseModal = () => {
        if (isOpenFromSearch) {
            navigate('/')
        }
        setIsModalOpen(false)
    }

    return (
        <>
            <header className={styles.root}>
                <div className={`container ${styles.headerContent}`}>
                    <Logo textClassName={styles.logo} />
                    <div className={styles.headerMenu}>
                        <MainMenu />
                    </div>
                    <div className={styles.headerWrap}>
                        <ButtonIcon
                            icon={<QuestionIcon />}
                            title={t('header.menu.help')}
                            onClick={() => setIsHelpModalOpen(true)}
                        />
                        <ButtonIcon
                            icon={<SettingsIcon />}
                            onClick={handleOpenModal}
                            title={t('system_settings.title')}
                        />
                        {/* <ButtonIcon icon={<LogOutIcon />} title={t('header.menu.logout')} onClick={handleLogOut} /> */}
                    </div>
                </div>
                {!isMainPage && markerText && <div className={styles.headerMarker}>{markerText}</div>}
            </header>
            {isMainPage && <DashboardControlPanel />}
            {isModalOpen && (
                <AntModalLayout title={t('system_settings.title')} isOpen={isModalOpen} onCancel={handleCloseModal}>
                    <SystemSettings />
                </AntModalLayout>
            )}
            {isHelpModalOpen && !!helpForm && (
                <IFrameFormModal isOpen={isHelpModalOpen} onCancel={() => setIsHelpModalOpen(false)} form={helpForm} />
            )}
        </>
    )
}
