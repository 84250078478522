import { type AllEffect, type ForkEffect, call, put, all, takeLatest } from 'redux-saga/effects'
import { appStateActions } from './slice'
import { type FetchUpdateUISettingsPayload } from './appState.types'
import { userinterfaceApi } from '@src/api/userinterface/userinterface.api'
import type { MainMenuSettingsResponse, UISettingsResponse } from '@src/api/userinterface/userinterface.types'
import { userActions } from '../user/slice'

function* fetchUISettingsWorker() {
    try {
        const res: UISettingsResponse = yield call(userinterfaceApi.getUISettings)
        if (res) {
            const isAdmin = res.datasourceTypesWithAdminAcces.length > 0
            yield put(appStateActions.setUISettings(res))
            yield put(userActions.setAdmin(isAdmin))
        }
    } catch (err: any) {
        yield put(appStateActions.errorUISettings())
    }
}

function* fetchUpdateUISettingsWorker({ payload }: FetchUpdateUISettingsPayload) {
    try {
        const res: string = yield call(userinterfaceApi.updateUISettings, payload)
        if (res) {
            yield put(appStateActions.setUpdateUISettings(payload))
        }
    } catch (err: any) {
        yield put(appStateActions.errorUpdateUISettings())
    }
}

function* fetchMainMenuSettingsWorker() {
    try {
        const res: MainMenuSettingsResponse = yield call(userinterfaceApi.getMainMenuSettings)
        if (res) {
            yield put(appStateActions.setMainMenuSettings(res))
        }
    } catch (err: any) {
        yield put(appStateActions.errorMainMenuSettings())
    }
}

export default function* uiSettingsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(appStateActions.fetchUISettings, fetchUISettingsWorker),
        takeLatest(appStateActions.fetchUpdateUISettings, fetchUpdateUISettingsWorker),
        takeLatest(appStateActions.fetchMainMenuSettings, fetchMainMenuSettingsWorker),
    ])
}
