import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { DictionaryInitialState } from './dictionary.types'
import type {
    AggregateFunctionTypeListResponse,
    AutoUpdateIntervalsResponse,
    CommandTypeListResponse,
    CompareFunctionTypeListResponse,
    DashboardTypeListResponse,
    DatasourceTypeListResponse,
    FieldTypeListResponse,
    FormTypeListResponse,
    PeriodTypeListResponse,
    SortTypeListResponse,
    TimeAxisTypeListResponse,
    WidgetTypeListResponse,
} from '@src/api/dictionary/dictionary.types'

const initialState: DictionaryInitialState = {
    dashboardTypes: [],
    widgetTypes: [],
    periodTypes: [],
    aggregateFunctionTypes: [],
    compareFunctionTypes: [],
    sortTypes: [],
    timeAxisTypes: [],
    dataSourceTypes: [],
    fieldTypes: [],
    commandTypes: [],
    formTypes: [],
    autoUpdateIntervals: [],
}

export const dictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    reducers: {
        fetchDashboardTypes: state => {
            state.isLoadingDashboardTypes = true
            state.isErrorDashboardTypes = false
        },
        setDashboardTypes: (state, { payload }: PayloadAction<DashboardTypeListResponse>) => {
            state.isLoadingDashboardTypes = false
            state.dashboardTypes = payload
        },
        errorDashboardTypes: state => {
            state.isLoadingDashboardTypes = false
            state.isErrorDashboardTypes = true
        },

        fetchWidgetTypes: state => {
            state.isLoadingWidgetTypes = true
            state.isErrorWidgetTypes = false
        },
        setWidgetTypes: (state, { payload }: PayloadAction<WidgetTypeListResponse>) => {
            state.isLoadingWidgetTypes = false
            state.widgetTypes = payload
        },
        errorWidgetTypes: state => {
            state.isLoadingWidgetTypes = false
            state.isErrorWidgetTypes = true
        },

        fetchPeriodTypes: state => {
            state.isLoadingPeriodTypes = true
            state.isErrorPeriodTypes = false
        },
        setPeriodTypes: (state, { payload }: PayloadAction<PeriodTypeListResponse>) => {
            state.isLoadingPeriodTypes = false
            state.periodTypes = payload
        },
        errorPeriodTypes: state => {
            state.isLoadingPeriodTypes = false
            state.isErrorPeriodTypes = true
        },

        fetchAggregateFunctionTypes: state => {
            state.isLoadingAggregateFunctionTypes = true
            state.isErrorAggregateFunctionTypes = false
        },
        setAggregateFunctionTypes: (state, { payload }: PayloadAction<AggregateFunctionTypeListResponse>) => {
            state.isLoadingAggregateFunctionTypes = false
            state.aggregateFunctionTypes = payload
        },
        errorAggregateFunctionTypes: state => {
            state.isLoadingAggregateFunctionTypes = false
            state.isErrorAggregateFunctionTypes = true
        },

        fetchCompareFunctionTypes: state => {
            state.isLoadingCompareFunctionTypes = true
            state.isErrorCompareFunctionTypes = false
        },
        setCompareFunctionTypes: (state, { payload }: PayloadAction<CompareFunctionTypeListResponse>) => {
            state.isLoadingCompareFunctionTypes = false
            state.compareFunctionTypes = payload
        },
        errorCompareFunctionTypes: state => {
            state.isLoadingCompareFunctionTypes = false
            state.isErrorCompareFunctionTypes = true
        },

        fetchSortTypes: state => {
            state.isLoadingSortTypes = true
            state.isErrorSortTypes = false
        },
        setSortTypes: (state, { payload }: PayloadAction<SortTypeListResponse>) => {
            state.isLoadingSortTypes = false
            state.sortTypes = payload
        },
        errorSortTypes: state => {
            state.isLoadingSortTypes = false
            state.isErrorSortTypes = true
        },

        fetchTimeAxisTypes: state => {
            state.isLoadingTimeAxisTypes = true
            state.isErrorTimeAxisTypes = false
        },
        setTimeAxisTypes: (state, { payload }: PayloadAction<TimeAxisTypeListResponse>) => {
            state.isLoadingTimeAxisTypes = false
            state.timeAxisTypes = payload
        },
        errorTimeAxisTypes: state => {
            state.isLoadingTimeAxisTypes = false
            state.isErrorTimeAxisTypes = true
        },

        fetchDatasourceTypes: state => {
            state.isLoadingDataSourceTypes = true
        },
        setDatasourceTypes: (state, { payload }: PayloadAction<DatasourceTypeListResponse>) => {
            state.isLoadingDataSourceTypes = false
            state.dataSourceTypes = payload
        },
        errorDatasourceTypes: state => {
            state.isLoadingDataSourceTypes = false
            state.isErrorDataSourceTypes = true
        },

        fetchFieldTypes: state => {
            state.isLoadingFieldTypes = true
            state.isErrorFieldTypes = false
        },
        setFieldTypes: (state, { payload }: PayloadAction<FieldTypeListResponse>) => {
            state.isLoadingFieldTypes = false
            state.fieldTypes = payload
        },
        errorFieldTypes: state => {
            state.isLoadingFieldTypes = false
            state.isErrorFieldTypes = true
        },

        fetchCommandTypes: state => {
            state.isLoadingCommandTypes = true
            state.isErrorCommandTypes = false
        },
        setCommandTypes: (state, { payload }: PayloadAction<CommandTypeListResponse>) => {
            state.isLoadingCommandTypes = false
            state.commandTypes = payload
        },
        errorCommandTypes: state => {
            state.isLoadingCommandTypes = false
            state.isErrorCommandTypes = true
        },

        fetchFormTypes: state => {
            state.isLoadingFormTypes = true
            state.isErrorFormTypes = false
        },
        setFormTypes: (state, { payload }: PayloadAction<FormTypeListResponse>) => {
            state.isLoadingFormTypes = false
            state.formTypes = payload
        },
        errorFormTypes: state => {
            state.isLoadingFormTypes = false
            state.isErrorFormTypes = true
        },

        fetchAutoUpdateIntervals: state => {
            state.isLoadingAutoUpdateIntervals = true
        },
        setAutoUpdateIntervals: (state, { payload }: PayloadAction<AutoUpdateIntervalsResponse>) => {
            state.isLoadingAutoUpdateIntervals = false
            state.autoUpdateIntervals = payload
        },
        errorAutoUpdateIntervals: state => {
            state.isLoadingAutoUpdateIntervals = false
            state.isErrorAutoUpdateIntervals = true
        },
    },
})

export const {
    fetchDashboardTypes,
    setDashboardTypes,
    errorDashboardTypes,
    fetchWidgetTypes,
    setWidgetTypes,
    errorWidgetTypes,
    fetchPeriodTypes,
    setPeriodTypes,
    errorPeriodTypes,
    fetchAggregateFunctionTypes,
    setAggregateFunctionTypes,
    errorAggregateFunctionTypes,
    fetchCompareFunctionTypes,
    setCompareFunctionTypes,
    errorCompareFunctionTypes,
    fetchSortTypes,
    setSortTypes,
    errorSortTypes,
    fetchTimeAxisTypes,
    setTimeAxisTypes,
    errorTimeAxisTypes,
    fetchDatasourceTypes,
    setDatasourceTypes,
    errorDatasourceTypes,
    fetchFieldTypes,
    setFieldTypes,
    errorFieldTypes,
    fetchCommandTypes,
    setCommandTypes,
    errorCommandTypes,
    fetchFormTypes,
    setFormTypes,
    errorFormTypes,
    fetchAutoUpdateIntervals,
    setAutoUpdateIntervals,
    errorAutoUpdateIntervals,
} = dictionarySlice.actions

export default dictionarySlice.reducer
