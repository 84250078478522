import { useMemo, useState } from 'react'
import { useAppSelector } from '../redux/useAppSelector'
import type { SelectOptions } from '@src/components/StyleGuide/Ui/SelectList/SelectList.types'

const compareFunctionByDataType: Record<number, number[]> = {
    1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    3: [1, 2, 7, 8, 9, 10],
    4: [1, 2, 7, 8, 9, 10],
    5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    6: [1, 2, 9, 10],
}

export const useCompareOptions = () => {
    const compareFunctions = useAppSelector(({ dictionary }) => dictionary.compareFunctionTypes)
    const [dataType, setDataType] = useState<number | null>(null)

    const compareFunctionsOptions = useMemo(() => {
        const options: SelectOptions = compareFunctions.map(elem => ({
            value: elem.id,
            label: elem.name,
        }))
        if (dataType) {
            const availableCompareFunctionsIDs = compareFunctionByDataType[dataType]
            return availableCompareFunctionsIDs
                ? options?.filter(elem => availableCompareFunctionsIDs.includes(elem.value as number)) ?? []
                : []
        }
        return options
    }, [compareFunctions, dataType])

    const findCompareFunctionById = (id: number) => {
        return compareFunctions.find(elem => elem.id === id)
    }

    return {
        compareFunctions,
        compareFunctionsOptions,
        setDataType,
        findCompareFunctionById,
    }
}
