import axiosInstance from '@src/services/axios'
import type { CommandsListResponse, ExecuteCommandRequest, ExecuteCommandResponse, FormsListResponse } from './actions.types'

const ACTION_URL = '/action'
const COMMAND_URL = '/command'
const FORM_URL = '/form'
const LIST_URL = '/list'
const EXECUTE_URL = '/execute'

export const actionsApi = {
    getCommandsList: async () => {
        const { data } = await axiosInstance.get<CommandsListResponse>(`${ACTION_URL}${COMMAND_URL}${LIST_URL}`)
        return data
    },
    getFormsList: async () => {
        const { data } = await axiosInstance.get<FormsListResponse>(`${ACTION_URL}${FORM_URL}${LIST_URL}`)
        return data
    },
    executeCommand: async (body: ExecuteCommandRequest) => {
        const { data } = await axiosInstance.post<ExecuteCommandResponse>(
            `${ACTION_URL}${COMMAND_URL}${EXECUTE_URL}`,
            body,
        )
        return data
    },
}
