import React, { useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import styles from './styles.module.scss'
import { t } from 'i18next'
import type Editor from 'ckeditor5-custom-build/build/ckeditor'
import { ButtonIcon, ShowMoreIcon } from '@src/components'

const ShowButton = () => (
    <ButtonIcon icon={<ShowMoreIcon width={20} height={20} />} title={t('dashboard_control_panel.show_btn')} />
)

const shortConfig = [0, 1, 5, 6, 10, 11, 12, 13]
export interface useToolBarType {
    hidePanel?: boolean
}
export const useToolbar = ({ hidePanel }: useToolBarType) => {
    const isFullModeRef = useRef(false)
    const isHidden = (el: HTMLElement) => {
        return el.offsetParent === null
    }
    const getCurrentToolbar = () => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const toolBarNodes = document.querySelectorAll('.ck-toolbar__items')
        const [currentToolbar] = Array.from(toolBarNodes).filter(el => !isHidden(el as HTMLElement))
        return currentToolbar
    }
    const init = (editor: Editor) => {
        if (hidePanel) {
            renderNunPanel()
        } else {
            isFullModeRef.current = false

            const language = editor.config.get('language')
            if (language === 'uk') {
                translateToUkrainian()
            }
            getCurrentToolbar()
            renderVLine()
            renderShowButton()
            renderShortPanel()
        }
        // renderVLine()
        // renderShowButton()
        // renderShortPanel()
    }
    const translateToUkrainian = () => {
        const toolbar = getCurrentToolbar()
        if (!toolbar) return

        const childen = toolbar.children
        const textAlignment = childen[7].querySelector('button')
        const emoji = childen[11].querySelector('button')
        const fontFamily = childen[18].querySelector('button')
        const fontSize = childen[19].querySelector('button')
        const fontColor = childen[20].querySelector('button')

        const placeholderAtr = 'data-cke-tooltip-text'
        textAlignment?.setAttribute(placeholderAtr, t('widgets.text.modal.toolbar.align'))
        emoji?.setAttribute(placeholderAtr, t('widgets.text.modal.toolbar.emoji'))
        fontFamily?.setAttribute(placeholderAtr, t('widgets.text.modal.toolbar.font_family'))
        fontSize?.setAttribute(placeholderAtr, t('widgets.text.modal.toolbar.font_size'))
        fontColor?.setAttribute(placeholderAtr, t('widgets.text.modal.toolbar.font_color'))
    }
    const toggleDisplayMode = () => {
        const showButtonNodes = document.querySelectorAll(`.${styles.showButton}`)
        const [currentShowButtonNode] = Array.from(showButtonNodes).filter(el => !isHidden(el as HTMLElement))
        if (!isFullModeRef.current) {
            currentShowButtonNode.className = `${styles.showButton} ${styles.buttonActive}`
            renderFullPanel()
        } else {
            currentShowButtonNode.className = `${styles.showButton} ${styles.buttonPassive}`
            renderShortPanel()
        }
        isFullModeRef.current = !isFullModeRef.current
    }

    const renderShowButton = () => {
        const toolbar = getCurrentToolbar()
        if (!toolbar) return

        const showButtonHTML = ReactDOMServer.renderToString(<ShowButton />)
        const showButtonElement = document.createElement('div')

        showButtonElement.innerHTML = showButtonHTML
        showButtonElement.onclick = toggleDisplayMode
        showButtonElement.className = `${styles.showButton} ${styles.buttonPassive}`

        toolbar.insertBefore(showButtonElement, toolbar.firstChild)
    }
    const renderVLine = () => {
        const toolbar = getCurrentToolbar()
        if (!toolbar) return

        const VLine = document.createElement('span')
        VLine.className = 'ck ck-toolbar__separator'
        toolbar.insertBefore(VLine, toolbar.firstChild)
    }
    const renderShortPanel = () => {
        const toolbar = getCurrentToolbar()
        if (!toolbar) return

        const childElements = toolbar.children
        for (let i = 0; i < childElements.length; i++) {
            if (!shortConfig.includes(i)) {
                const child = childElements[i] as HTMLElement
                child.style.display = 'none'
            }
        }
        const emojiDrop = childElements[13].querySelector('div')?.style
        if (emojiDrop) {
            emojiDrop.left = ''
        }
    }
    const renderFullPanel = () => {
        const toolbar = getCurrentToolbar()
        if (!toolbar) return

        const childElements = toolbar.children
        for (let i = 0; i < childElements.length; i++) {
            if (!shortConfig.includes(i)) {
                const child = childElements[i] as HTMLElement
                child.style.display = 'block'
            }
        }
        const emojiDrop = childElements[13].querySelector('div')?.style
        if (emojiDrop) {
            emojiDrop.left = '-120px'
        }
    }
    const renderNunPanel = () => {
        const elementToRemove = document.querySelector('.ck-editor__top') as HTMLElement

        if (elementToRemove) {
            elementToRemove.parentNode?.removeChild(elementToRemove)
        }
    }
    return [init]
}
