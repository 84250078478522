import axiosInstance from '@src/services/axios'
import type {
    DataPermissionForUserResponse,
    DataPermissionsForUserRequest,
    DataPermissionsListRequest,
    DataPermissionsListResponse,
    UpdateDataPermissionForUserRequest,
} from './permissions.types'
import { createURLQueryParams } from '@src/helpers/utils/createURLQueryParams'

const PERMISSION_URL = '/permission'
const USER_URL = '/user'
const LIST_URL = '/list'

export const permisionApi = {
    getDataPermissionsList: async (params: DataPermissionsListRequest) => {
        const { data } = await axiosInstance.get<DataPermissionsListResponse>(
            `${PERMISSION_URL}${USER_URL}${LIST_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    getDataPermissionsForUser: async (params: DataPermissionsForUserRequest) => {
        const { data } = await axiosInstance.get<DataPermissionForUserResponse[]>(
            `${PERMISSION_URL}${USER_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    updateDataPermissionsForUser: async (body: UpdateDataPermissionForUserRequest) => {
        const { data } = await axiosInstance.put<DataPermissionForUserResponse>(`${PERMISSION_URL}`, body)
        return data
    },
}
