import React from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const MouseTooltip = ({ value, coordinate }: { value: number; coordinate: number }) => {
    const locale = useAppSelector(({ appState }) => appState.UISettings?.locale)
    return (
        <p className={styles.mouseTooltip} style={{ top: coordinate }}>
            {value.toLocaleString(locale === 'en_US' ? 'en-US' : undefined)}
        </p>
    )
}
