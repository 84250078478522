import React, { useMemo, type FC } from 'react'
import styles from './styles.module.scss'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import dayjs from 'dayjs'
import './col.scss'
import { HighlightText } from '@src/components/HighlightText/HighlightText'
import type { WidgetProps } from '../widgets.types'
import { Table } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_FULL_WITH_TIME, DATE_FORMAT_ISO_PARSE } from '@src/config/constants'

export const TableWidget: FC<WidgetProps> = ({ widgetID, dashboardID }) => {
    const { tableWidget } = useWidget({
        widgetID,
        dashboardID,
    })
    const rows = tableWidget?.widget?.data?.rows ?? []
    const cols = tableWidget?.widget?.widgetFields?.map(elem => elem.dataField) ?? []
    const createRows = useMemo(() => {
        return rows.map((row, i) => {
            const rowData: Record<string, string> = {
                key: `${i}_${new Date().getTime()}`,
            }
            cols.forEach(cell => {
                const field = row.find(elem => elem.fieldID === cell.id)
                rowData[cell.id] =
                    field?.value && dayjs.utc(field?.value, DATE_FORMAT_ISO_PARSE).isValid()
                        ? dayjs.utc(field?.value).format(DATE_FORMAT_FULL_WITH_TIME)
                        : field?.value ?? ''
            })
            return rowData
        })
    }, [rows, cols])
    const createCols = useMemo(() => {
        return cols.map(col => {
            return {
                dataIndex: col.id,
                key: col.id,
                title: col.name,
                render: (text: string) => <HighlightText text={text} />,
            }
        })
    }, [cols])

    return (
        <div className={`${styles.root} tableContainer`}>
            <Table columns={createCols} dataSource={createRows} size='middle' pagination={false} />
        </div>
    )
}
