import React, { type FC } from 'react'
import styles from './styles.module.scss'
import type { ButtonPrimaryProps } from './ButtonPrimary.types'

export const ButtonPrimary: FC<ButtonPrimaryProps> = ({
    onClick,
    icon,
    className,
    title,
    type = 'primary',
    disabled,
}) => {
    const styleType = () => {
        switch (type) {
            case 'primary':
                return styles.primary
            case 'transparent':
                return styles.transparent
            case 'red':
                return styles.red
        }
    }

    return (
        <button
            className={`${styles.root} ${styleType()} ${className ?? ''}`}
            disabled={disabled}
            title={icon ? '' : title}
            onClick={onClick}
        >
            {icon}
            <span>{title}</span>
        </button>
    )
}
