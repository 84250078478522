import axiosInstance from '@src/services/axios'
import type {
    DatasetRequest,
    DatasetResponse,
    DatasetListRequest,
    DatasetsListResponse,
    DatasourceRequest,
    DatasourceResponse,
} from './structures.types'
import { createURLQueryParams } from '@src/helpers/utils/createURLQueryParams'

const STRUCTURE_URL = '/structure'
const DATASOURCE_URL = '/datasource'
const DATASET_URL = '/dataset'
const LIST_URL = '/list'

export const structuresApi = {
    getDatasource: async (params: DatasourceRequest) => {
        const { data } = await axiosInstance.get<DatasourceResponse>(
            `${STRUCTURE_URL}${DATASOURCE_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    getDataset: async (params: DatasetRequest) => {
        const { data } = await axiosInstance.get<DatasetResponse>(
            `${STRUCTURE_URL}${DATASET_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
    getDatasetsList: async (params: DatasetListRequest) => {
        const { data } = await axiosInstance.get<DatasetsListResponse>(
            `${STRUCTURE_URL}${DATASET_URL}${LIST_URL}${createURLQueryParams(params)}`,
        )
        return data
    },
}
