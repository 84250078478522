import React, { type CSSProperties, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import type { WidgetProps } from '../widgets.types'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { HighlightText } from '@src/components/HighlightText/HighlightText'
import { changeColorOpacity } from '@src/helpers/utils/changeColorOpacity'
import { DEFAULT_COUNTER_COLOR } from '@src/config/constants'

export const CounterWidget: FC<WidgetProps> = ({ widgetID, dashboardID }) => {
    const { t } = useTranslation()
    const { counterWidget } = useWidget({ widgetID, dashboardID })
    const value = counterWidget?.widget?.data?.value ?? 0
    const goal = counterWidget?.widget?.goal ?? 0
    const goalColor = counterWidget?.widget?.goalColor
    const defaultColor = counterWidget?.widget?.defaultColor
    const getWidgetColor = () => {
        if (counterWidget?.widget?.isGoalEnabled && value >= goal) return goalColor || defaultColor
        return defaultColor
    }
    const color = getWidgetColor() || DEFAULT_COUNTER_COLOR
    const inlineStyles: CSSProperties = {
        color,
        backgroundColor: changeColorOpacity(color, 0.12),
    }

    return (
        <div className={styles.root} style={inlineStyles}>
            <div className={styles.dataValue}>
                <HighlightText text={value} />
            </div>

            <div className={styles.dataLabel}>
                <HighlightText text={counterWidget?.widget?.calcField?.name || ''} />
            </div>

            {counterWidget?.widget?.isGoalEnabled && (
                <div className={styles.dataGoal}>
                    {`${t('create_widget.settings.field_goal_label')}: `}
                    <HighlightText text={goal} />
                </div>
            )}
        </div>
    )
}
