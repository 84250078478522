import { Progress } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { MINUT_IN_MS } from '@src/config/constants'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { updateDashboardsTimer } from '@src/store/dashboards/slice'
import { fetchDashboardWidgetsList } from '@src/store/widgets/slice'

export const TimerProgressIcon = () => {
    const dispatch = useAppDispatch()
    const autoUpdateInterval = useAppSelector(({ dashboards }) => dashboards.currentDashboard?.autoUpdateInterval)
    const autoUpdateDashboard = useAppSelector(({ dashboards }) => dashboards.autoUpdateDashboard)

    const autoUpdateIntervalInMS = (autoUpdateInterval ?? 0) * MINUT_IN_MS

    const [progress, setProgress] = useState(0)
    const [timeLeft, setTimeLeft] = useState(autoUpdateInterval)

    const getLeftTime = useCallback(() => {
        if (autoUpdateDashboard?.timerEndPoint) {
            const leftTime = autoUpdateDashboard.timerEndPoint - Date.now()
            const timeLeftInMinuts = Math.ceil((leftTime - 1000) / MINUT_IN_MS)
            return timeLeftInMinuts >= 0 ? timeLeftInMinuts : 0
        }
        return 0
    }, [autoUpdateDashboard?.timerEndPoint])

    const getPercents = useCallback(() => {
        if (autoUpdateDashboard?.timerStartPoint) {
            const pastTime = Date.now() - autoUpdateDashboard.timerStartPoint
            const percents = (pastTime * 100) / autoUpdateIntervalInMS
            return percents
        }
        return 0
    }, [autoUpdateDashboard?.timerStartPoint, autoUpdateIntervalInMS])

    useEffect(() => {
        let timer: NodeJS.Timer
        if (autoUpdateDashboard?.timerStartPoint && autoUpdateDashboard?.timerEndPoint) {
            timer = setInterval(() => {
                setTimeLeft(getLeftTime())
                setProgress(getPercents)
                if (autoUpdateDashboard.timerEndPoint < Date.now() && autoUpdateDashboard?.id) {
                    dispatch(updateDashboardsTimer())
                    dispatch(fetchDashboardWidgetsList({ dashboardID: autoUpdateDashboard.id }))
                }
            }, 500)
        }
        return () => {
            clearInterval(timer)
        }
    }, [
        autoUpdateDashboard?.timerStartPoint,
        autoUpdateDashboard?.timerEndPoint,
        autoUpdateDashboard?.id,
        getLeftTime,
        getPercents,
    ])
    useEffect(() => {
        setTimeLeft(getLeftTime())
        setProgress(getPercents)
    }, [getLeftTime, getPercents])

    return (
        <div className={`${styles.iconWrap} ${styles.progress}`}>
            <Progress
                type='circle'
                percent={progress}
                size={40}
                className={styles.progressCircle}
                strokeColor={'#437ec3'}
                strokeWidth={10}
                format={() => timeLeft}
            />
        </div>
    )
}
