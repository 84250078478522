import React, { type FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary, CloseIcon, PlusIcon } from '@src/components'
import styles from './styles.module.scss'
import { type FetchCreateDashboardPayload } from '@src/store/dashboards/dashboards.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { fetchCreateDashboard } from '@src/store/dashboards/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const CreateNewDashboard: FC = () => {
    const { t } = useTranslation()

    const [createActive, setCreateActive] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const isAdmin = useAppSelector(({ user }) => user.isAdmin)
    const dashboardType = isAdmin ? 2 : 1
    const dashboardTypes = useAppSelector(({ dictionary }) => dictionary.dashboardTypes)

    const currentDBtype = dashboardTypes.find(type => type.id === dashboardType)

    const dispatch = useAppDispatch()

    const inputRef = useRef<HTMLInputElement>(null)

    const handleCreateActive = () => {
        setInputValue('')
        setCreateActive(!createActive)
        setIsFocused(!isFocused)
    }

    const handleCreateDashboard = () => {
        const onSuccess = () => {
            setInputValue('')
            handleCreateActive()
        }
        if (currentDBtype && inputValue) {
            const data: FetchCreateDashboardPayload['payload'] = {
                dashboardType: currentDBtype,
                name: inputValue,
                cellsX: 0,
                cellsY: 0,
                isHidden: false,
                autoUpdateInterval: 0,
                onSuccess,
            }
            dispatch(fetchCreateDashboard(data))
        }
    }

    return (
        <div className={styles.root}>
            {createActive ? (
                <div
                    className={`${styles.inputWrap} ${isFocused ? styles.focused : ''}`}
                    tabIndex={-1}
                    onBlur={() => setIsFocused(false)}
                    onClick={() => setIsFocused(true)}
                >
                    <input
                        className={styles.input}
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        type={'text'}
                        ref={inputRef}
                        autoFocus={isFocused}
                    />
                    <div className={styles.inputActions}>
                        <button title={t('buttons.save')} className={styles.btn} onClick={handleCreateDashboard}>
                            <ApplyIcon />
                        </button>
                        <button
                            title={t('buttons.cancel')}
                            className={`${styles.btn} ${styles.cancel}`}
                            onClick={handleCreateActive}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            ) : (
                <ButtonPrimary
                    className={styles.addBtn}
                    onClick={handleCreateActive}
                    title={t('buttons.add_board')}
                    type='transparent'
                    icon={<PlusIcon />}
                />
            )}
        </div>
    )
}
