import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    DashboardsListResponse,
    DashboardResponse,
    DeleteDashboardRequest,
    DashboardUsers,
} from '@src/api/dashboardApi/dashboard.types'
import { MINUT_IN_MS } from '@src/config/constants'
import type {
    DashboardUsersPermissions,
    FetchCreateCopyDashboardPayload,
    FetchCreateDashboardPayload,
    FetchDashboardByIdPayload,
    FetchDashboardUsersPermissionsPayload,
    FetchDeleteDashboardPayload,
    FetchFullUpdateDashboardPayload,
    FetchGrandAccessAndShareDashboard,
    FetchUpdateDashboardPayload,
    IDashboardsInitialState,
} from '@src/store/dashboards/dashboards.types'
import { createDashboardTimer } from './functions'

const initialState: IDashboardsInitialState = {
    autoUpdateDashboard: null,
    dashboardsList: [],
    currentDashboard: null,
    dashboardUsersPermissions: null,
}

export const dashboardsSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        fetchDashboardsList: state => {
            state.isLoadingDashboardList = true
            state.isErrorDashboardList = false
            state.dashboardListError = ''
        },
        setDashboardsList: (state, { payload }: PayloadAction<DashboardsListResponse>) => {
            state.isLoadingDashboardList = false
            state.dashboardsList = payload
        },
        errorDashboardsList: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingDashboardList = false
            state.isErrorDashboardList = true
            state.dashboardListError = payload
        },
        fetchDashboardById: (state, { payload }: PayloadAction<FetchDashboardByIdPayload['payload']>) => {
            state.isLoadingDashboardById = true
            state.isErrorDashboardById = false
            state.errorDashboardById = ''
        },
        setDashboardById: (state, { payload }: PayloadAction<DashboardResponse>) => {
            state.isLoadingDashboardById = false
            state.dashboardsList = state.dashboardsList.map(dashboard =>
                dashboard.id === payload.id ? payload : dashboard,
            )
        },
        errorDashboardById: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingDashboardById = false
            state.isErrorDashboardById = true
            state.errorDashboardById = payload
        },
        fetchCreateDashboard: (state, { payload }: PayloadAction<FetchCreateDashboardPayload['payload']>) => {
            state.isLoadingCreateDashboard = true
            state.isErrorCreateDashboard = false
            state.createDashboardError = ''
        },
        setCreateDashboard: (state, { payload }: PayloadAction<DashboardResponse>) => {
            state.isLoadingCreateDashboard = false
            state.dashboardsList.push(payload)
        },
        errorCreateDashboard: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingCreateDashboard = false
            state.isErrorCreateDashboard = true
            state.createDashboardError = payload
        },
        fetchCreateCopyDashboard: (state, { payload }: PayloadAction<FetchCreateCopyDashboardPayload['payload']>) => {
            state.isLoadingCreateDashboard = true
            state.isErrorCreateDashboard = false
            state.createDashboardError = ''
        },
        setCreateCopyDashboard: (state, { payload }: PayloadAction<DashboardResponse>) => {
            state.isLoadingCreateDashboard = false
            state.dashboardsList.push(payload)
        },
        errorCreateCopyDashboard: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingCreateDashboard = false
            state.isErrorCreateDashboard = true
            state.createDashboardError = payload
        },
        fetchUpdateDashboard: (state, { payload }: PayloadAction<FetchUpdateDashboardPayload['payload']>) => {
            state.isLoadingUpdateDashboard = true
            state.isErrorUpdateDashboard = false
            state.updateDashboardError = ''
        },
        fetchFullUpdateDashboard: (state, { payload }: PayloadAction<FetchFullUpdateDashboardPayload['payload']>) => {
            state.isLoadingUpdateDashboard = true
            state.isErrorUpdateDashboard = false
            state.updateDashboardError = ''
        },
        setUpdateDashboard: (state, { payload }: PayloadAction<DashboardResponse>) => {
            state.isLoadingUpdateDashboard = false

            state.dashboardsList = state.dashboardsList.map(elem => (elem.id === payload.id ? payload : elem))
            if (state.currentDashboard?.id === payload.id) {
                state.currentDashboard = { ...state.currentDashboard, ...payload }
                state.autoUpdateDashboard = state.autoUpdateDashboard && {
                    id: payload.id,
                    timerStartPoint: state.autoUpdateDashboard?.isAutoUpdate
                        ? state.autoUpdateDashboard?.timerStartPoint
                        : Date.now(),
                    timerEndPoint:
                        (state.autoUpdateDashboard?.isAutoUpdate
                            ? state.autoUpdateDashboard?.timerStartPoint
                            : Date.now()) +
                        payload.autoUpdateInterval * MINUT_IN_MS,
                    isAutoUpdate: payload.autoUpdateInterval > 0 && payload.autoUpdateInterval < 1_000_000,
                }
            }
        },
        errorUpdateDashboard: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingUpdateDashboard = false
            state.isErrorUpdateDashboard = true
            state.updateDashboardError = payload
        },
        fetchDeleteDashboard: (state, { payload }: PayloadAction<FetchDeleteDashboardPayload['payload']>) => {
            state.isLoadingDeleteDashboard = true
        },
        setDeleteDashboard: (state, { payload }: PayloadAction<DeleteDashboardRequest>) => {
            state.isLoadingDeleteDashboard = false
            state.dashboardsList = state.dashboardsList.filter(elem => elem.id !== payload.dashboardID)
            state.autoUpdateDashboard =
                state.currentDashboard?.id === payload.dashboardID ? null : state.autoUpdateDashboard
            state.currentDashboard = state.currentDashboard?.id === payload.dashboardID ? null : state.currentDashboard
        },
        errorDeleteDashboard: (state, { payload }: PayloadAction<string>) => {
            state.isLoadingDeleteDashboard = false
            state.isErrorDeleteDashboard = true
            state.deleteDashboardError = payload
        },
        setCurrentDashBoard: (state, { payload }: PayloadAction<DashboardResponse>) => {
            state.currentDashboard = payload
            state.autoUpdateDashboard = { id: payload.id, ...createDashboardTimer(payload.autoUpdateInterval) }
        },
        updateDashboardsTimer: state => {
            state.autoUpdateDashboard = state.autoUpdateDashboard && {
                ...state.autoUpdateDashboard,
                timerStartPoint: state.autoUpdateDashboard?.timerEndPoint,
                timerEndPoint:
                    state.autoUpdateDashboard?.timerEndPoint -
                    state.autoUpdateDashboard?.timerStartPoint +
                    state.autoUpdateDashboard?.timerEndPoint,
            }
        },
        fetchDashboardUsersPermissions: (
            state,
            { payload }: PayloadAction<FetchDashboardUsersPermissionsPayload['payload']>,
        ) => {
            state.isLoadingDashboardUsers = true
            state.isErrorDashboardUsers = false
        },
        setDashboardUsersPermissions: (state, { payload }: PayloadAction<DashboardUsersPermissions>) => {
            state.isLoadingDashboardUsers = false
            if (state.dashboardUsersPermissions) {
                const dashboardID = Object.keys(payload)[0]
                state.dashboardUsersPermissions = {
                    ...state.dashboardUsersPermissions,
                    [+dashboardID]:
                        state.dashboardUsersPermissions[+dashboardID]?.map(elem => {
                            const matchedResponseElem = payload[+dashboardID]?.find(item => item.id === elem.id)
                            return matchedResponseElem ?? elem
                        }) ?? payload[+dashboardID],
                }
            } else state.dashboardUsersPermissions = payload
        },
        errorDashboardUsersPermissins: state => {
            state.isLoadingDashboardUsers = false
            state.isErrorDashboardUsers = true
        },
        fetchGrandAccessAndShareDashboard: (
            state,
            { payload }: PayloadAction<FetchGrandAccessAndShareDashboard['payload']>,
        ) => {
            state.isLoadingUpdateDashboard = true
            state.isErrorUpdateDashboard = false
        },
        setGrandAccessAndShareDashboard: state => {
            state.isLoadingUpdateDashboard = false
        },
        errorGrandAccessAndShareDashboard: state => {
            state.isLoadingUpdateDashboard = false
            state.isErrorUpdateDashboard = true
        },
    },
})

export const {
    fetchDashboardsList,
    setDashboardsList,
    errorDashboardsList,
    fetchDashboardById,
    setDashboardById,
    errorDashboardById,
    fetchCreateDashboard,
    setCreateDashboard,
    errorCreateDashboard,
    fetchCreateCopyDashboard,
    setCreateCopyDashboard,
    errorCreateCopyDashboard,
    setCurrentDashBoard,
    fetchUpdateDashboard,
    setUpdateDashboard,
    errorUpdateDashboard,
    fetchFullUpdateDashboard,
    fetchDeleteDashboard,
    setDeleteDashboard,
    errorDeleteDashboard,
    updateDashboardsTimer,
    fetchDashboardUsersPermissions,
    setDashboardUsersPermissions,
    errorDashboardUsersPermissins,
    fetchGrandAccessAndShareDashboard,
    setGrandAccessAndShareDashboard,
    errorGrandAccessAndShareDashboard,
} = dashboardsSlice.actions

export default dashboardsSlice.reducer
