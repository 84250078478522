import React, { type FC, type ChangeEvent, type ReactNode } from 'react'
import { ApplyIcon } from '@src/components'
import styles from './styles.module.sass'

interface ICheckBox {
    label: ReactNode
    isChecked: boolean
    disabled?: boolean
    onChange: (value: boolean) => void
}

export const CheckBox: FC<ICheckBox> = ({ label, isChecked, onChange, disabled }) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            onChange(e.target.checked)
        }
    }
    return (
        <label className={styles.root}>
            <input type='checkbox' checked={isChecked} onChange={handleOnChange} />
            <span className={styles.icon}>
                <ApplyIcon />
            </span>
            {!!label && <span className={styles.label}>{label}</span>}
        </label>
    )
}
