import React, { useState, type FC } from 'react'
import type { WidgetFormsProps } from './WidgetCommands.types'
import commandStyles from './styles.module.scss'
import { ButtonIcon } from '@src/components'
import type { Form } from '@src/api/actions/actions.types'
import { IFrameFormModal } from '@src/components/Modals/IFrameFormModal/IFrameFormModal'

export const WidgetForms: FC<WidgetFormsProps> = ({ pinnedForms }) => {
    const [openedForm, setOpenedForm] = useState<Form | null>(null)
    const handleOpenForm = (form: Form) => {
        setOpenedForm(form)
    }
    return (
        <>
            {pinnedForms.map((elem, i) => (
                <ButtonIcon
                    key={i}
                    icon={
                        <img
                            src={`data:image/png;base64, ${elem.form.iconBytes}`}
                            className={commandStyles.actionIcon}
                        />
                    }
                    title={elem.form.name}
                    onClick={() => handleOpenForm(elem.form)}
                />
            ))}
            {!!openedForm && (
                <IFrameFormModal isOpen={!!openedForm} onCancel={() => setOpenedForm(null)} form={openedForm} />
            )}
        </>
    )
}
