import React, { useState, type FC } from 'react'
import { PieChart, Pie, ResponsiveContainer, Cell, Sector } from 'recharts'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import type { WidgetProps } from '../widgets.types'
import styles from './styles.module.scss'

export const PieChartWidget: FC<WidgetProps> = ({ dashboardID, widgetID }) => {
    const COLORS = [
        'var(--color-orange-pie-chart)',
        'var(--color-nightBlue-pie-chart)',
        'var(--color-green-pie-chart)',
        'var(--color-purple-pie-chart)',
        'var(--color-yellow-pie-chart)',
        'var(--color-pink-pie-chart)',
    ]

    const { pieChartWidget } = useWidget({ dashboardID, widgetID })
    const pieSectors =
        pieChartWidget?.widget?.data?.sectors?.map(({ caption, value }) => ({
            name: caption,
            value,
        })) ?? []
    const [activePie, setActivePie] = useState(-1)
    const customLabel = (props: any, active: boolean) => {
        const { cx, cy, midAngle, outerRadius, value, fill, name, startAngle, endAngle } = props
        const RADIAN = Math.PI / 180
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const mx = cx + (outerRadius + 7) * cos
        const my = cy + (outerRadius + 7) * sin
        const ex = mx + (cos >= 0 ? 1.5 : -1.5) * 5
        const ey = my
        const textAnchor = cos >= 0 ? 'start' : 'end'

        const rectangleHeight = 10

        return (
            <g>
                <path d={`M${cx},${cy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
                {active && (
                    <Sector
                        cx={cx}
                        cy={cy}
                        outerRadius={outerRadius + 2}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                )}
                <text
                    className={styles.pieLabel}
                    x={ex}
                    y={ey + rectangleHeight / 7}
                    textAnchor={textAnchor}
                    dominantBaseline='middle'
                    fontWeight='500'
                    fill={fill}
                    fontSize='13px'
                >
                    {`${name}: ${value}`}
                </text>
            </g>
        )
    }

    const renderActiveLabel = (props: any) => {
        return customLabel(props, true)
    }
    const renderLabel = (props: any) => {
        return customLabel(props, false)
    }

    return (
        <ResponsiveContainer debounce={20}>
            <PieChart>
                <Pie
                    data={pieSectors}
                    labelLine={false}
                    dataKey='value'
                    label={pieChartWidget?.widget?.isLabelsVisible && renderLabel}
                    activeIndex={activePie}
                    activeShape={!pieChartWidget?.widget?.isLabelsVisible && renderActiveLabel}
                    onMouseEnter={(_: any, index: number) => setActivePie(index)}
                    onMouseLeave={() => setActivePie(-1)}
                    outerRadius={'88%'}
                    stroke='none'
                >
                    {pieSectors.map((_: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}
