import { localStorageKeys } from '@src/config/localStorageKeys.enum'
import type { UserData } from '@src/store/user/user.types'
import axios, { type AxiosInstance } from 'axios'

const axiosInstance: AxiosInstance = axios.create({
    headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Credentials': 'true',
        // 'Access-Control-Allow-Origin': '*',
    },
    baseURL: 'https://dev-api-dashboard.plainandsimplesolution.com/v1',
})

axiosInstance.interceptors.request.use(
    async config => {
        const userData = localStorage.getItem(localStorageKeys.userData)
        const token = userData ? (JSON.parse(userData) as UserData)?.token : null
        if (config.headers && token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    async function (error) {
        return await Promise.reject(error)
    },
)

export default axiosInstance
