import React, { useEffect, useState } from 'react'
import { TextEditor } from '@src/components'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useDebounce } from '@src/hooks/useDebounce/useDebounce'
import type Editor from 'ckeditor5-custom-build/build/ckeditor'

export interface WrapTextEditorType {
    setValueText?: (value: string) => void
    autosend?: boolean
    dashboardID?: number | undefined
    widgetID?: number | undefined
}
export const WrapTextEditor = ({ setValueText, autosend, widgetID, dashboardID }: WrapTextEditorType) => {
    const [valueState, setValueState] = useState<string | undefined>('')
    const { textBlockWidget, updateWidget } = useWidget({
        widgetID,
        dashboardID,
    })
    const [textBlockValue, setTextBlockValue] = useState('')
    const debounceValue = useDebounce(textBlockValue)
    const onChange = (event: any, editor: Editor) => {
        const newData = (editor as any).getData()
        setTextBlockValue(newData)
        setValueText?.(newData)
    }
    useEffect(() => {
        if (textBlockWidget && !textBlockValue) {
            setValueState(textBlockWidget?.widget?.text)
        }
    }, [textBlockWidget?.widget?.text, textBlockValue])
    return (
        <>
            <TextEditor
                updateWidget={updateWidget}
                autosend={autosend}
                debounceValue={debounceValue}
                data={valueState || ''}
                onChange={onChange}
            />
        </>
    )
}
