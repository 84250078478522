import React, { type FC, type ReactNode, useLayoutEffect, useState, useEffect, useRef } from 'react'
import SplitPane from 'react-split-pane'
import type { SplitPaneProps } from 'react-split-pane'
import styles from './styles.module.scss'
import { CreateWidgetControlPanel } from '@src/components/CreateWidget/CreateWidgetControlPanel/CreateWidgetControlPanel'
import { CreateWidgetSettings } from '@src/components/CreateWidget/CreateWidgetSettings/CreateWidgetSettings'
import { WidgetLayout } from '../WidgetLayout/WidgetLayout'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useMediaQuery, useWindowSize } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import { useResizableDiv } from '@src/hooks'

//This solution for fix bug in types in library react-split-pane. Add children prop type to SplitPaneProps
interface ICustomSplitPane extends SplitPaneProps {
    children: ReactNode
}
// New SplitPane with extends interface
const CustomSplitPane: FC<ICustomSplitPane> = ({ children, ...rest }) => {
    return <SplitPane {...rest}>{children}</SplitPane>
}

export const CreateWidgetLayout: FC = props => {
    const [directionView, setDirectionView] = useState<'horizontal' | 'vertical'>('vertical')
    const { resizaHandlerRef, resizeElementRef, width: resizeWidth, height } = useResizableDiv()

    const { t } = useTranslation()
    const isMore1366px = useMediaQuery('(min-width: 1367px)')
    const { width } = useWindowSize()
    const halfScreen = width / 2
    const { setupWidgetPage } = useAppSelector(({ widgets }) => widgets)
    const { UISettings } = useAppSelector(({ appState }) => appState)
    const { widgetType, dashboardID, id: widgetID } = setupWidgetPage
    // State for set min and max size of SplitPanes. 300 and 1200 set by visual testing
    const [minSize, setMinSize] = useState(0)
    const [maxSize, setMaxSize] = useState(0)

    // This action for change primary SplitPane, max and min size if direction of SplitPane has changed
    useLayoutEffect(() => {
        if (directionView === 'horizontal') {
            setMinSize(isMore1366px ? 300 : 200)
            setMaxSize(450)
        } else {
            setMinSize(isMore1366px ? 790 : 710)
            setMaxSize(width - 350)
        }
    }, [directionView, width, isMore1366px])

    useEffect(() => {
        setDirectionView(UISettings?.createWidgetDirection ?? 'vertical')
    }, [UISettings?.createWidgetDirection])
    return (
        <>
            <CreateWidgetControlPanel />
            <div className={styles.root}>
                <CustomSplitPane
                    className={`${styles.splitPane} ${
                        directionView === 'horizontal' ? styles.horizontal : styles.vertical
                    }`}
                    resizerClassName={`${styles.resizer} ${directionView === 'vertical' ? styles.vertical : ''}`}
                    split={directionView}
                    defaultSize={halfScreen < minSize ? minSize : '50%'}
                    minSize={minSize}
                    maxSize={maxSize}
                >
                    <div className={`container ${styles.paneContent} ${!widgetID ? styles.notAllowed : ''}`}>
                        <div style={{ height: '100%' }} className={!widgetID ? styles.notAllowed : ''}>
                            <CreateWidgetSettings />
                        </div>
                    </div>
                    <div className={`container ${styles.paneContent} ${!widgetID ? styles.notAllowed : ''}`}>
                        <div
                            ref={resizeElementRef}
                            className={`${styles.panePreviewWrap} ${
                                // for table widget and horizontal position we need width on full screen
                                widgetType?.id === 2 ? styles.tableWidth : ''
                            }`}
                            style={{ width: resizeWidth, height }}
                        >
                            <div ref={resizaHandlerRef} className={styles.resizeHandler} />
                            {widgetID && dashboardID && widgetType ? (
                                <WidgetLayout
                                    simpleWidgetView
                                    widgetID={widgetID}
                                    dashboardID={dashboardID}
                                    widgetType={widgetType}
                                />
                            ) : (
                                <div className={styles.emptyData}>
                                    <p>{t('create_widget_first')}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </CustomSplitPane>
            </div>
        </>
    )
}
