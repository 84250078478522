import React, { useState, type FC } from 'react'
import type { WidgetMenuProps } from '../../WidgetLayout.types'
import { MenuLayout } from '../../../MenuLayout/MenuLayout'
import styles from '../../styles.module.scss'
import { ButtonIcon, SubMenuIcon } from '@src/components'
import { useTranslation } from 'react-i18next'
import { WidgetMenuCommands } from './WidgetMenuCommands'
import { WidgetMenuForms } from './WidgetMenuForms'

export const WidgetMenu: FC<WidgetMenuProps> = ({ list, access, widgetID, dashboardID, isLockedWidget }) => {
    const { t } = useTranslation()
    const [isMenuActive, setIsMenuActive] = useState(false)

    const handleMenuOpen = () => {
        setIsMenuActive(!isMenuActive)
    }
    return (
        <MenuLayout
            menuElement={
                <ButtonIcon
                    icon={<SubMenuIcon />}
                    title={t('widgets.counter.settings_btn')}
                    className={isMenuActive ? styles.hover : ''}
                    onClick={handleMenuOpen}
                    disabled={!access}
                />
            }
            horizontalPosition='right'
            onlyHorizontalPosition
            preventBubbling={false}
            setIsMenuActive={setIsMenuActive}
            className={styles.menu}
        >
            {list.map(({ icon, text, isShow, isDisabled, onClick }, i, arr) =>
                isShow ? (
                    <div
                        key={text}
                        className={`${isDisabled ? styles.disabled : ''} ${
                            i === arr.length - 1 ? styles.lastItem : ''
                        }`}
                        onClick={onClick}
                    >
                        {icon}
                        <p>{text}</p>
                    </div>
                ) : null,
            )}
            {!isLockedWidget && <WidgetMenuCommands widgetID={widgetID} dashboardID={dashboardID} />}
            {!isLockedWidget && <WidgetMenuForms widgetID={widgetID} dashboardID={dashboardID} />}
        </MenuLayout>
    )
}
