import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import styles from './styles.module.scss'
import React, { useEffect, type FC } from 'react'
import type { IFrameFormModalProps } from './IFrameFormModal.types'
import { Title } from '../PopupModal/Title/Title'
import { useTranslation } from 'react-i18next'
import { ButtonWithIcon, CancelIcon } from '@src/components/StyleGuide'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { actionsActions } from '@src/store/actions/slice'

export const IFrameFormModal: FC<IFrameFormModalProps> = ({ isOpen, onCancel, form }) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(actionsActions.fetchGetFormsList())
    }, [])
    const { t } = useTranslation()
    return (
        <AntModalLayout
            title={<Title title={t('form')} subtitle={form.name} />}
            isOpen={isOpen}
            onCancel={onCancel}
            footer={
                <div className={styles.footerContainer}>
                    <ButtonWithIcon
                        className={`${styles.buttonsMainStyles} ${styles.cancelBtn}`}
                        icon={<CancelIcon />}
                        text={t('buttons.close')}
                        onClick={onCancel}
                    />
                </div>
            }
        >
            <iframe className={styles.iframe} src={form.url} width={form.width} height={form.height} />
        </AntModalLayout>
    )
}
