import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../redux/useAppSelector'
import type { UseDashboardProps } from './useDashboard.types'
import type { Dashboard, DashboardId, DashboardUsers } from '@src/api/dashboardApi/dashboard.types'

export const useDashboard = (data?: UseDashboardProps) => {
    const userID = useAppSelector(state => state.user.userData?.accountID)
    const activeDashboard = useAppSelector(({ dashboards }) => dashboards.currentDashboard)
    const dashboardsList = useAppSelector(({ dashboards }) => dashboards.dashboardsList)
    const [currentDashboard, setCurrentDashboard] = useState<Dashboard | undefined>()
    const [dashboardID, setDashboardID] = useState<DashboardId['id'] | null>(activeDashboard?.id ?? data?.id ?? null)
    useEffect(() => {
        const findedDashboard = dashboardsList.find(dashboard => dashboard.id === data?.id)
        setCurrentDashboard(findedDashboard)
    }, [dashboardsList, data?.id])
    const dashboardListOptions = useMemo(() => {
        return dashboardsList.map(elem => ({
            label: elem.name,
            value: elem.id,
        }))
    }, [dashboardsList])
    useEffect(() => {
        setDashboardID(data?.id ?? activeDashboard?.id ?? null)
    }, [activeDashboard?.id, data?.id])

    const isUserAdminInDashboard = useCallback(
        (users?: DashboardUsers[]) => {
            const usersList = users ?? currentDashboard?.users
            return usersList?.some(user => user.id === userID && user.isAdmin) || (usersList?.length ?? 2) <= 1
        },
        [userID, currentDashboard?.users],
    )

    const notSharedDashboardsList = useMemo(() => {
        return dashboardsList.filter(dashboard => isUserAdminInDashboard(dashboard.users))
    }, [dashboardsList])
    const notSharedDashboardsOptions = useMemo(() => {
        return notSharedDashboardsList.map(elem => ({
            label: elem.name,
            value: elem.id,
        }))
    }, [notSharedDashboardsList])
    const isSharedDashboard = ((currentDashboard?.users?.length || activeDashboard?.users?.length) ?? 0) > 1
    return {
        dashboardID,
        dashboardsList,
        activeDashboard,
        currentDashboard,
        dashboardListOptions,
        notSharedDashboardsList,
        notSharedDashboardsOptions,
        isSharedDashboard,
        isUserAdminInDashboard,
    }
}
