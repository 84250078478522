import {
    ButtonIcon,
    ButtonPrimary,
    CheckIcon,
    CloseIcon,
    ExcelIcon,
    InfoIcon,
    PrintIcon,
    SearchInput,
} from '@src/components/StyleGuide'
import './styles.sass'
import React, { useRef, type FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { Modal, Table } from 'antd'
import { Title } from '../PopupModal/Title/Title'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import ReactDragListView from 'react-drag-listview'
import { useData } from './useData'
import type { WidgetDataModel } from './WidgetDataModel.types'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useReactToPrint } from 'react-to-print'
import { PopupCalendar } from '@src/components/PopupCalendar/PopupCalendar'

export const WidgetDataModal: FC<WidgetDataModel> = ({ isOpen, onCancel, widgetID, dashboardID }) => {
    const { t } = useTranslation()
    const componentRef = useRef<HTMLDivElement>(null)

    const {
        widgetName,
        rows,
        dragProps,
        cols,
        setSearchValue,
        onChangePeriod,
        downloadExcelFile,
        handleDatePeriodChange,
    } = useData({
        widgetID,
        dashboardID,
        isOpen,
    })
    const { periodOptions, getFormatedDate } = usePeriod()
    const { widgetTitle, currentWidget } = useWidget({ widgetID, dashboardID })

    const name = currentWidget?.listData?.name ?? ''
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${name}.pdf`,
    })

    return (
        <Modal
            rootClassName='WidgetDataModal'
            className={`${styles.modal} noDrag`}
            open={isOpen}
            onCancel={onCancel}
            title={<Title title={widgetTitle} subtitle={widgetName} />}
            closeIcon={<CloseIcon />}
            destroyOnClose={true}
            centered
            style={{ backgroundColor: 'rgba(4, 20, 38, 0.72)' }}
            footer={
                <div className={styles.buttonWrapper}>
                    <ButtonPrimary
                        className={styles.saveBtn}
                        icon={<CheckIcon stroke='white' />}
                        title={t('buttons.close')}
                        onClick={onCancel}
                    />
                </div>
            }
        >
            <div>
                <div className={styles.controllsWrapper}>
                    <div className={styles.hintWrapper}>
                        <InfoIcon />
                        <p className={styles.hint}>{t('widget_data_modal.info')}</p>
                    </div>
                    <div className={styles.controlls}>
                        <div className={styles.btnGroup}>
                            <ButtonIcon
                                isBoarded
                                icon={<ExcelIcon />}
                                title={t('widgets.counter.importExcel')}
                                onClick={downloadExcelFile}
                            />
                            <ButtonIcon
                                isBoarded
                                icon={<PrintIcon />}
                                title={t('widgets.counter.print')}
                                onClick={handlePrint}
                            />
                        </div>
                        <SearchInput onSearch={searchvalue => setSearchValue(searchvalue)} />
                        <div className={'divider'} />
                        <div className={styles.selectWrap}>
                            <SelectList
                                options={periodOptions}
                                selectedValue={currentWidget?.widget?.periodType?.id}
                                className={styles.select}
                                onChange={onChangePeriod}
                            />
                            {currentWidget?.widget?.periodType?.id === 1 ? (
                                <PopupCalendar
                                    values={
                                        currentWidget?.widget?.dateFrom && currentWidget?.widget?.dateTo
                                            ? {
                                                  dateFrom: currentWidget.widget.dateFrom,
                                                  dateTo: currentWidget.widget.dateTo,
                                              }
                                            : null
                                    }
                                    onChangePeriod={handleDatePeriodChange}
                                />
                            ) : (
                                <p className={styles.selectTime}>{`${getFormatedDate(
                                    currentWidget?.widget?.dateFrom,
                                )} - ${getFormatedDate(currentWidget?.widget?.dateTo)}`}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.tableWrapper}>
                    <ReactDragListView.DragColumn {...dragProps}>
                        <div ref={componentRef} className={styles.tableContainer}>
                            <Table
                                dataSource={rows}
                                columns={cols}
                                pagination={false}
                                sortDirections={['ascend', 'descend']}
                            />
                            {/* <table className={styles.tableContainerPrint}>
                                <thead>
                                    <tr>
                                        {colsForPrint.map(col => (
                                            <th key={col.key}>{col.title}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={index}>
                                            {colsForPrint.map(col => (
                                                <td key={col.key}>
                                                    {dayjs(row[col.key]).isValid()
                                                        ? dayjs(row[col.key]).format(DATE_FORMAT_FULL_WITH_TIME)
                                                        : row[col.key] ?? ''}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}
                        </div>
                    </ReactDragListView.DragColumn>
                </div>
            </div>
        </Modal>
    )
}
