import React from 'react'
import { AccessRightsPanel } from './AccessRightsPanel'
import { useAccessRightsData } from './useAccessRightsData'
import { AccessRightsTable } from './AccessRightsTable'

export const AccesRights = () => {
    const {
        setClientID,
        setDataSetId,
        setSearchInput,
        dataSetId,
        clientID,
        datasetsListOptions,
        adminClientsOptions,
        cols,
        tableData,
        handleSaveUsersPermissions,
    } = useAccessRightsData()
    return (
        <>
            <AccessRightsPanel
                dataSetId={dataSetId}
                clientID={clientID}
                setClientID={setClientID}
                setDataSetId={setDataSetId}
                datasetsListOptions={datasetsListOptions}
                clientsListOptions={adminClientsOptions}
                setSearchInput={setSearchInput}
                handleSaveUsersPermissions={handleSaveUsersPermissions}
            />
            <AccessRightsTable cols={cols} tableData={tableData} />
        </>
    )
}
