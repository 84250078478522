import { t } from 'i18next'

export const transformBigNumbersToshortString = (value: number) => {
    if (value > 9_999_999_999) return `${Math.round(value / 1_000_000_000)}${t('b')}`
    if (value > 999_999_999) return `${(value / 1_000_000_000).toFixed(1)}${t('b')}`
    if (value > 9_999_999) return `${Math.round(value / 1_000_000)}${t('m')}`
    if (value > 999_999) return `${(value / 1_000_000).toFixed(1)}${t('m')}`
    if (value > 9999) return `${Math.round(value / 1000)}${t('k')}`
    if (value > 999) return `${(value / 1000).toFixed(1)}${t('k')}`
    return value.toString()
}
