import React, { type FC, useEffect, useState } from 'react'
import styles from './style.module.scss'
import { ButtonIcon, ButtonWithIcon, ReturnIcon, SaveIcon, SearchInput } from '../StyleGuide'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SelectList } from '../StyleGuide/Ui/SelectList/SelectList'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { permissionsActions } from '@src/store/permissions/slice'
import type { AccessRightsPanelProps } from './AccesRights.types'
import { UnsavedUsersAccess } from '../Modals/UnsavedUsersAccess/UnsavedUsersAccess'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const AccessRightsPanel: FC<AccessRightsPanelProps> = ({
    setClientID,
    setDataSetId,
    setSearchInput,
    handleSaveUsersPermissions,
    dataSetId,
    clientID,
    datasetsListOptions,
    clientsListOptions,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [isOpenWarningUnsavedChanges, setIsOpenWarningUnsavedChanges] = useState(false)
    const [searchParams] = useSearchParams()
    const unsavedFields = useAppSelector(({ permissions }) => permissions.unsavedFields)
    const datasourceID = Number(searchParams.get('datasourceID'))

    useEffect(() => {
        if (dataSetId)
            dispatch(
                permissionsActions.fetchUsersDataPermissions({ datasourceTypeID: datasourceID, datasetID: dataSetId }),
            )
    }, [dataSetId, datasourceID])

    const handleBack = () => {
        if (unsavedFields.length) {
            setIsOpenWarningUnsavedChanges(true)
        } else navigate('/')
    }
    return (
        <div className={`${styles.root} ${styles.flexContainer} container`}>
            <div className={styles.flexContainer}>
                <ButtonIcon
                    className={styles.backBtn}
                    isBoarded
                    icon={<ReturnIcon />}
                    title={t('buttons.back')}
                    onClick={handleBack}
                />
                <div className={styles.divider} />
            </div>
            <div className={styles.dropdownWrapper}>
                <p className={styles.title}>{t('access_page.client')}</p>
                <SelectList
                    isTransparentBg
                    onChange={(value: string | number) => setClientID(value as number)}
                    options={clientsListOptions}
                    selectedValue={clientID}
                />
            </div>
            <div className={styles.dropdownWrapper}>
                <p className={styles.title}>{t('access_page.scenario')}</p>
                <SelectList
                    isTransparentBg
                    onChange={(value: string | number) => setDataSetId(value as string)}
                    options={datasetsListOptions}
                    selectedValue={dataSetId}
                />
            </div>
            <div className={`${styles.flexContainer} ${styles.dropdownWrapper}`}>
                <div className={styles.divider} />
                <SearchInput className={styles.searchInput} onSearch={value => setSearchInput(value)} />
                <ButtonIcon
                    title={t('buttons.save')}
                    onClick={() => handleSaveUsersPermissions()}
                    disabled={unsavedFields.length === 0}
                    className={`${styles.saveButton} ${unsavedFields.length > 0 ? styles.active : 'boarded'}`}
                    icon={<SaveIcon />}
                />
            </div>
            {isOpenWarningUnsavedChanges && (
                <UnsavedUsersAccess isOpen={isOpenWarningUnsavedChanges} setIsOpen={setIsOpenWarningUnsavedChanges} />
            )}
        </div>
    )
}
