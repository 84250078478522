import React, { type MouseEvent, useState } from 'react'
import styles from './styles.module.scss'
import { ButtonWithIcon } from '@src/components'
import { UnsavedUsersAccess } from '../Modals/UnsavedUsersAccess/UnsavedUsersAccess'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { NavLink } from 'react-router-dom'

export const MainMenu: React.FC = () => {
    const [isOpenWarningUnsavedChanges, setIsOpenWarningUnsavedChanges] = useState(false)
    const [url, setUrl] = useState('')
    const unsavedFields = useAppSelector(({ permissions }) => permissions.unsavedFields)
    const mainMenuList = useAppSelector(({ appState }) => appState.mainMenuList)

    const handleBack = (e: MouseEvent<HTMLAnchorElement>) => {
        if (unsavedFields.length) {
            e.preventDefault()
            setUrl(e.currentTarget.href)
            setIsOpenWarningUnsavedChanges(true)
        }
    }

    return (
        <>
            <nav className={styles.root}>
                <ul className={styles.list}>
                    {mainMenuList?.map(item => (
                        <li key={item.id} className={styles.item}>
                            <NavLink
                                to={item.url}
                                onClick={handleBack}
                                className={({ isActive }) => (isActive ? styles.active : '')}
                            >
                                <ButtonWithIcon
                                    text={item.name}
                                    icon={<img src={`data:image/png;base64, ${item.iconBytes}`} />}
                                />
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
            {isOpenWarningUnsavedChanges && (
                <UnsavedUsersAccess
                    isOpen={isOpenWarningUnsavedChanges}
                    setIsOpen={setIsOpenWarningUnsavedChanges}
                    url={url}
                />
            )}
        </>
    )
}
