import { type Layout } from 'react-grid-layout'
import type { ResizeDirections, UseResizeProps } from '../GridLayout.types'
import { useState } from 'react'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { getWidgetPositions } from '../GridLayout.functions'
import { fetchUpdateWidgetByIdAllRequests } from '@src/store/widgets/slice'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { useWidget } from '@src/hooks/useWidget/useWidget'

export const useResize = ({ emptyPlace, widgetsGrid, setWidgetsGrid, setActiveGridItemID }: UseResizeProps) => {
    const dispatch = useAppDispatch()
    const [resizeDirection, setResizeDirection] = useState<'left' | 'right' | ''>('')
    const [isResizing, setIsResizing] = useState(false)
    const { dashboardID } = useDashboard()
    const { findWidgetTypeByWidgetID } = useWidget({ dashboardID })

    const handleResizeDirection = (direction: ResizeDirections) => {
        if (direction === 'e' || direction === 'se') setResizeDirection('right')
        else if (direction === 'w' || direction === 'sw') setResizeDirection('left')
        else setResizeDirection('')
    }
    const handleResizeStart = (
        newLayout: Layout[],
        oldItem: Layout,
        newItem: Layout,
        placeholder: Layout,
        e: MouseEvent,
        element: HTMLElement,
    ) => {
        const direction = element.classList[1].split('-').at(-1) as ResizeDirections
        handleResizeDirection(direction)
        setIsResizing(true)
        setActiveGridItemID(Number(newItem.i))
    }

    const handleResize = (newLayout: Layout[], oldItem: Layout, newItem: Layout) => {
        const { x, y, w, h } = newItem
        const freeWidgetSpace = getWidgetPositions(oldItem.x, oldItem.y, oldItem.w, oldItem.h)
        const allowResizeRight = () => {
            if (resizeDirection === 'right') {
                const availableCells = [...emptyPlace, ...freeWidgetSpace].filter(([emptyY, emptyX]) => {
                    return x + w === emptyX && y <= emptyY && y + h - 1 >= emptyY
                })
                newItem.maxW = availableCells.length === newItem.h ? newItem.w + 1 : newItem.w
            }
        }
        allowResizeRight()
        const allowResizeLeft = () => {
            if (resizeDirection === 'left') {
                const availableCells = [...emptyPlace, ...freeWidgetSpace].filter(([emptyY, emptyX]) => {
                    return x - 1 === emptyX && y <= emptyY && y + h - 1 >= emptyY
                })
                newItem.maxW = availableCells.length === newItem.h ? newItem.w + 1 : newItem.w
            }
            const availableCells = [...emptyPlace, ...freeWidgetSpace].filter(([emptyY, emptyX]) => {
                return x <= emptyX && x + w - 1 >= emptyX && y + h === emptyY
            })
            newItem.maxH = availableCells.length === newItem.w ? newItem.h + 1 : newItem.h
        }
        allowResizeLeft()
    }

    const handleResizeStop = (newLayout: Layout[], oldItem: Layout, newItem: Layout) => {
        setResizeDirection('')
        setIsResizing(false)
        setActiveGridItemID(0)
        newItem.maxW = undefined
        newItem.maxH = undefined
        const { w, h, i, x, y } = newItem
        const currentWidgetType = findWidgetTypeByWidgetID(Number(i))
        setWidgetsGrid(
            widgetsGrid.map(elem =>
                elem.id.toString() === i ? { ...elem, x, y, w, h, positions: getWidgetPositions(x, y, w, h) } : elem,
            ),
        )
        if (dashboardID && currentWidgetType && (oldItem.h !== h || oldItem.w !== w)) {
            dispatch(
                fetchUpdateWidgetByIdAllRequests({
                    id: Number(i),
                    dashboardID,
                    widgetType: currentWidgetType,
                    positionX: x,
                    positionY: y,
                    cellsX: w,
                    cellsY: h,
                }),
            )
        }
    }
    return {
        isResizing,
        handleResizeStart,
        handleResize,
        handleResizeStop,
    }
}
