import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer'

export const getCurrentWidget = createSelector(
    [(state: RootState) => state, (state: RootState, IDs: { dashboardID: number; widgetID: number }) => IDs],
    (state, IDs) => state.widgets.widgetsByDashboards[IDs.dashboardID]?.widgets?.find(elem => elem.id === IDs.widgetID),
)
export const getCurrentDashboardWidgets = createSelector(
    [(state: RootState) => state, (state: RootState, dashboardID: number) => dashboardID],
    (state, dashboardID) => state.widgets.widgetsByDashboards[dashboardID]?.widgets,
)
export const getCurrentDashboardLoading = createSelector(
    [(state: RootState) => state, (state: RootState, dashboardID: number) => dashboardID],
    (state, dashboardID) => state.widgets.widgetsByDashboards[dashboardID]?.isLoadingDashboardWidgets,
)
