import React, { type Dispatch, type FC, type SetStateAction, useState } from 'react'
import styles from './styles.module.sass'
import { Modal } from 'antd'
import './styles.sass'
import { CheckBox, CloseIcon, DangerButton } from '@src/components/StyleGuide'
import { t } from 'i18next'
import { Title } from './Title/Title'
import { FooterButtons } from './FooterButtons/FooterButtons'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'

interface IConfirmChangeTypeModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    onConfirm: () => void
    onCancelChange: () => void
}

export const ConfirmChangeTypeModal: FC<IConfirmChangeTypeModalProps> = ({
    open,
    setOpen,
    onCancelChange,
    onConfirm,
}) => {
    const dispatch = useAppDispatch()
    const [checked, setChecked] = useState(false)

    const handleConfirm = () => {
        onConfirm()
        setOpen(false)
        dispatch(appStateActions.setLocalUISettings({ dontAskAgainChangeWidgetTypeModal: checked }))
    }

    const handleCheckChange = (check: boolean) => {
        setChecked(check)
    }

    return (
        <Modal
            rootClassName='ConfirmModal'
            className={`${styles.modal} noDrag`}
            open={open}
            title={<Title />}
            onCancel={onCancelChange}
            closeIcon={<CloseIcon />}
            centered
            footer={<FooterButtons onConfirm={handleConfirm} onCancel={onCancelChange} />}
        >
            <div className={styles.content}>
                <div className={styles.message}>
                    <div className={styles.dangerIcon}>
                        <DangerButton />
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('create_widget.confirmModal.text'),
                        }}
                    ></div>
                </div>
                <div className={styles.ban}>
                    <CheckBox
                        label={t('create_widget.confirmModal.banModal')}
                        isChecked={checked}
                        onChange={handleCheckChange}
                    />
                </div>
                <div className={styles.question}>
                    <p>{t('create_widget.confirmModal.question')}</p>
                </div>
            </div>
        </Modal>
    )
}
