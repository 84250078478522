import React from 'react'
import styles from './styles.module.scss'
import { BackButton } from './components/BackButton'
import { SetWidgetParameters } from './components/SetWidgetParameters'
import { WidgetTypesPicker } from './components/WidgetTypesPicker'
import { DirectionSwitch } from './components/DirectionSwitch'
import { DeleteButton } from './components/DeleteButton'

export const CreateWidgetControlPanel = () => {
    return (
        <div className={styles.root}>
            <div className={`container ${styles.content}`}>
                <div className={styles.contentWrap}>
                    <BackButton />
                    <div className={`divider ${styles.divider}`} />
                    <SetWidgetParameters />
                    <WidgetTypesPicker />
                    <DirectionSwitch />
                </div>
                <div className={`${styles.wrap} ${styles.actions}`}>
                    <div className={`divider ${styles.divider}`} />
                    <DeleteButton />
                </div>
            </div>
        </div>
    )
}
