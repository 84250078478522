import { type AllEffect, type ForkEffect, call, put, all, takeLatest } from 'redux-saga/effects'
import {
    errorAggregateFunctionTypes,
    errorAutoUpdateIntervals,
    errorCommandTypes,
    errorCompareFunctionTypes,
    errorDashboardTypes,
    errorDatasourceTypes,
    errorFieldTypes,
    errorFormTypes,
    errorPeriodTypes,
    errorSortTypes,
    errorTimeAxisTypes,
    errorWidgetTypes,
    fetchAggregateFunctionTypes,
    fetchAutoUpdateIntervals,
    fetchCommandTypes,
    fetchCompareFunctionTypes,
    fetchDashboardTypes,
    fetchDatasourceTypes,
    fetchFieldTypes,
    fetchFormTypes,
    fetchPeriodTypes,
    fetchSortTypes,
    fetchTimeAxisTypes,
    fetchWidgetTypes,
    setAggregateFunctionTypes,
    setAutoUpdateIntervals,
    setCommandTypes,
    setCompareFunctionTypes,
    setDashboardTypes,
    setDatasourceTypes,
    setFieldTypes,
    setFormTypes,
    setPeriodTypes,
    setSortTypes,
    setTimeAxisTypes,
    setWidgetTypes,
} from './slice'
import { dictionaryApi } from '@src/api/dictionary/dictionary.api'
import type {
    AggregateFunctionTypeListResponse,
    AutoUpdateIntervalsResponse,
    CommandTypeListResponse,
    CompareFunctionTypeListResponse,
    DashboardTypeListResponse,
    DatasourceTypeListResponse,
    FieldTypeListResponse,
    FormTypeListResponse,
    PeriodTypeListResponse,
    SortTypeListResponse,
    TimeAxisTypeListResponse,
    WidgetTypeListResponse,
} from '@src/api/dictionary/dictionary.types'
import type {} from './dictionary.types'
import { structureActions } from '../structures/slice'

function* fetchDashboardTypesWorker() {
    try {
        const res: DashboardTypeListResponse = yield call(dictionaryApi.getDashboardTypes)
        if (res) {
            yield put(setDashboardTypes(res))
        }
    } catch (err: any) {
        yield put(errorDashboardTypes())
    }
}

function* fetchWidgetTypesWorker() {
    try {
        const res: WidgetTypeListResponse = yield call(dictionaryApi.getWidgetTypes)
        if (res) {
            yield put(setWidgetTypes(res))
        }
    } catch (err: any) {
        yield put(errorWidgetTypes())
    }
}

function* fetchPeriodTypesWorker() {
    try {
        const res: PeriodTypeListResponse = yield call(dictionaryApi.getPeriodTypes)
        if (res) {
            yield put(setPeriodTypes(res))
        }
    } catch (err: any) {
        yield put(errorPeriodTypes())
    }
}
function* fetchAggregateFunctionTypesWorker() {
    try {
        const res: AggregateFunctionTypeListResponse = yield call(dictionaryApi.getAggregateFunctionTypes)
        if (res) {
            yield put(setAggregateFunctionTypes(res))
        }
    } catch (err: any) {
        yield put(errorAggregateFunctionTypes())
    }
}
function* fetchCompareFunctionTypesWorker() {
    try {
        const res: CompareFunctionTypeListResponse = yield call(dictionaryApi.getCompareFunctionTypes)
        if (res) {
            yield put(setCompareFunctionTypes(res))
        }
    } catch (err: any) {
        yield put(errorCompareFunctionTypes())
    }
}
function* fetchSortTypesWorker() {
    try {
        const res: SortTypeListResponse = yield call(dictionaryApi.getSortTypes)
        if (res) {
            yield put(setSortTypes(res))
        }
    } catch (err: any) {
        yield put(errorSortTypes())
    }
}
function* fetchTimeAxisTypesWorker() {
    try {
        const res: TimeAxisTypeListResponse = yield call(dictionaryApi.getTimeAxisTypes)
        if (res) {
            yield put(setTimeAxisTypes(res))
        }
    } catch (err: any) {
        yield put(errorTimeAxisTypes())
    }
}
function* fetchDatasourceTypesWorker() {
    try {
        const res: DatasourceTypeListResponse = yield call(dictionaryApi.getDatasourceTypes)
        if (res) {
            yield put(setDatasourceTypes(res))
            yield all(res.map(datasource => put(structureActions.fetchDatasource({ datasourceTypeID: datasource.id }))))
        }
    } catch (err: any) {
        yield put(errorDatasourceTypes())
    }
}
function* fetchFieldTypesWorker() {
    try {
        const res: FieldTypeListResponse = yield call(dictionaryApi.getFieldTypes)
        if (res) {
            yield put(setFieldTypes(res))
        }
    } catch (err: any) {
        yield put(errorFieldTypes())
    }
}
function* fetchCommandTypesWorker() {
    try {
        const res: CommandTypeListResponse = yield call(dictionaryApi.getCommandTypes)
        if (res) {
            yield put(setCommandTypes(res))
        }
    } catch (err: any) {
        yield put(errorCommandTypes())
    }
}
function* fetchFormTypesWorker() {
    try {
        const res: FormTypeListResponse = yield call(dictionaryApi.getFormTypes)
        if (res) {
            yield put(setFormTypes(res))
        }
    } catch (err: any) {
        yield put(errorFormTypes())
    }
}
function* fetchAutoUpdateIntervalsWorker() {
    try {
        const res: AutoUpdateIntervalsResponse = yield call(dictionaryApi.getAutoUpdateInterval)
        if (res) {
            yield put(setAutoUpdateIntervals(res))
        }
    } catch (err: any) {
        yield put(errorAutoUpdateIntervals())
    }
}

export default function* dictionarySaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(fetchDashboardTypes, fetchDashboardTypesWorker),
        takeLatest(fetchWidgetTypes, fetchWidgetTypesWorker),
        takeLatest(fetchPeriodTypes, fetchPeriodTypesWorker),
        takeLatest(fetchAggregateFunctionTypes, fetchAggregateFunctionTypesWorker),
        takeLatest(fetchCompareFunctionTypes, fetchCompareFunctionTypesWorker),
        takeLatest(fetchSortTypes, fetchSortTypesWorker),
        takeLatest(fetchTimeAxisTypes, fetchTimeAxisTypesWorker),
        takeLatest(fetchDatasourceTypes, fetchDatasourceTypesWorker),
        takeLatest(fetchFieldTypes, fetchFieldTypesWorker),
        takeLatest(fetchCommandTypes, fetchCommandTypesWorker),
        takeLatest(fetchFormTypes, fetchFormTypesWorker),
        takeLatest(fetchAutoUpdateIntervals, fetchAutoUpdateIntervalsWorker),
    ])
}
