import React, { useCallback, useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { t } from 'i18next'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { YAxisLineChartSetttings } from './YAxisLineChartSettings'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'

const dayGroupingID = [1]
const mounthGroupingID = [1, 2]
const yearGroupingID = [2, 3]
const yearsGroupingID = [3, 4]

export const LineChartSettings = () => {
    const timeAxisTypes = useAppSelector(({ dictionary }) => dictionary.timeAxisTypes)
    const timeAxisOptionsAll = timeAxisTypes.map(({ name, id }) => ({
        value: id,
        label: name,
    }))
    const [timeAxisOptions, setTimeAxisOptions] = useState(timeAxisOptionsAll)
    const { lineChartWidget, updateWidget } = useWidget()

    const { allowedUserDataOptions, findFieldByID } = useUserFields({
        datasourceTypeID: lineChartWidget?.listData.dataSourceType.id,
        datasetID: lineChartWidget?.listData.dataset.id,
    })

    const { getDateFromISOFormat } = usePeriod()
    const diffDays = Math.ceil(
        getDateFromISOFormat(lineChartWidget?.widget?.dateTo).diff(
            getDateFromISOFormat(lineChartWidget?.widget?.dateFrom),
            'days',
            true,
        ),
    )
    const filterGroupingOptions = useCallback(
        (arr: number[]) => {
            return timeAxisOptionsAll.filter(({ value }) => arr.includes(value))
        },
        [timeAxisTypes],
    )
    useEffect(() => {
        if (diffDays === 1) setTimeAxisOptions(filterGroupingOptions(dayGroupingID))
        if (diffDays > 1 && diffDays <= 31) setTimeAxisOptions(filterGroupingOptions(mounthGroupingID))
        if (diffDays > 31 && diffDays <= 365) setTimeAxisOptions(filterGroupingOptions(yearGroupingID))
        if (diffDays > 365) setTimeAxisOptions(filterGroupingOptions(yearsGroupingID))
    }, [filterGroupingOptions, diffDays])
    const handleXAxisData = (value: string | number) => {
        const matchedDataset = findFieldByID(value as string)
        if (matchedDataset)
            updateWidget({
                xAxisField: matchedDataset,
            })
    }

    const handleGroupByTime = (value: string | number) => {
        const matchedTimeAxisType = timeAxisTypes.find(({ id }) => id === (value as number))
        if (matchedTimeAxisType) updateWidget({ timeAxisType: matchedTimeAxisType })
    }
    return (
        <>
            <div className={styles.settingItem}>
                <p className={styles.settingItemTitle}>{t('create_widget.settings.axis_x')}</p>
                <div className={styles.settingItemWrap}>
                    <div className={styles.settingField}>
                        <span className={styles.settingFieldLabel}>{t('create_widget.settings.field_data_label')}</span>
                        <SelectList
                            isDisabled={
                                !lineChartWidget?.listData.dataSourceType.id || !lineChartWidget?.listData.dataset.id
                            }
                            options={allowedUserDataOptions}
                            onChange={handleXAxisData}
                            selectedValue={lineChartWidget?.widget?.xAxisField?.id ?? ''}
                        />
                    </div>
                    <div
                        className={`${styles.settingField} ${
                            lineChartWidget?.widget?.xAxisField?.fieldType?.id !== 5 ? styles.disabled : ''
                        }`}
                    >
                        <span className={styles.settingFieldLabel}>
                            {t('create_widget.settings.field_group_label')}
                        </span>
                        <SelectList
                            options={timeAxisOptions}
                            onChange={handleGroupByTime}
                            selectedValue={lineChartWidget?.widget?.timeAxisType?.id}
                        />
                    </div>
                </div>
            </div>
            <YAxisLineChartSetttings />
        </>
    )
}
