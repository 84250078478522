import React from 'react'
import { t } from 'i18next'
import styles from './styles.module.scss'
import { ButtonPrimary, ButtonWithIcon, CancelIcon, CheckIcon } from '@src/components/StyleGuide'

interface IFooterButtonsProps {
    onCancel?: () => void
}

export const FooterButtons: React.FC<IFooterButtonsProps> = ({ onCancel }) => (
    <ButtonPrimary
        onClick={onCancel}
        className={`${styles.btn}`}
        title={t('buttons.close')}
        icon={<CheckIcon stroke='white' />}
    />
)
