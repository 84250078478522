export enum dictionaryUrlConstants {
    DICTIONARY_URL = '/dictionary',
    LIST_URL = '/list',
    DASHBOARD_TYPE_URL = '/dashboardType',
    WIDGET_TYPE_URL = '/widgetType',
    PERIOD_TYPE_URL = '/periodType',
    AGGREGATE_FUNCTION_TYPE_URL = '/aggregateFunctionType',
    COMPARE_FUNCTION_TYPE_URL = '/compareFunctionType',
    SORT_TYPE_URL = '/sortType',
    TIME_AXIS_TYPE_URL = '/timeAxisType',
    DATASOURCE_TYPE_URL = '/datasourceType',
    FIELD_TYPE_URL = '/fieldType',
    COMMAND_TYPE_URL = '/commandType',
    FORM_TYPE_URL = '/formType',
    AUTO_UPDATE_INTERVAL = '/autoUpdateInterval',
}
