import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { PinIcon } from '@src/components'
import type { WidgetMenuActionsProps } from './WidgetMenuCommands.type'
import type { WidgetPinnedCommand, WidgetPinnedForm } from '@src/api/widgets/widgets.types'
import { useTranslation } from 'react-i18next'

export const WidgetMenuActions: FC<WidgetMenuActionsProps> = ({ title, list, pinnedList, onClick, onPin }) => {
    const isElemPinned = (id: number) => {
        return pinnedList.some(
            elem => (elem as WidgetPinnedCommand)?.command?.id === id || (elem as WidgetPinnedForm)?.form?.id === id,
        )
    }
    const { t } = useTranslation()
    return (
        <>
            {list.length ? (
                <div className={styles.form}>
                    <div className={styles.formDivider}>
                        <p className={styles.title}>{title}</p>
                        <div className={styles.divider}></div>
                    </div>
                    {list.map(elem => (
                        <div key={elem.id} className={styles.menuItem}>
                            <div className={styles.menuItemContant} onClick={() => onClick(elem.id)}>
                                <img src={`data:image/png;base64, ${elem.iconBytes}`} className={styles.menuItemIcon} />
                                <div className={styles.menuItemText}>
                                    <p>{elem.name}</p>
                                    <div className='divider'></div>
                                </div>
                            </div>
                            <div
                                role='button'
                                className={`${styles.menuItemPinIcon} ${isElemPinned(elem.id) ? styles.activePin : ''}`}
                                onClick={() => onPin(elem.id)}
                                title={
                                    isElemPinned(elem.id)
                                        ? t('dashboard_control_panel.options.unpin')
                                        : t('dashboard_control_panel.options.pin')
                                }
                            >
                                <PinIcon />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    )
}
