import { type AllEffect, type ForkEffect, all, fork } from 'redux-saga/effects'
import authorizationSaga from './user/saga'
import dashboardsSaga from './dashboards/saga'
import uiSettingsSaga from './appState/saga'
import dictionarySaga from './dictionary/saga'
import structuresSaga from './structures/saga'
import widgetsSaga from './widgets/saga'
import permissionsSaga from './permissions/saga'
import actionsSaga from './actions/saga'

export function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(authorizationSaga),
        fork(dashboardsSaga),
        fork(uiSettingsSaga),
        fork(dictionarySaga),
        fork(structuresSaga),
        fork(widgetsSaga),
        fork(permissionsSaga),
        fork(actionsSaga),
    ])
}
