import React from 'react'
import { t } from 'i18next'
import styles from './styles.module.sass'

interface ITitleProps {
    title: string | null | undefined
}

export const Title: React.FC<ITitleProps> = ({ title }) => {
    if (!title) <></>
    return (
        <div className={styles.modalTitle}>
            <span>{t('widgets.text.modal.title')}</span>
            <span className={styles.widgetTitle}> {title}</span>
            <p className={styles.widgetType}>{t('widgets.types.text')}</p>
        </div>
    )
}
