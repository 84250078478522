import { DeleteWidgetModal } from '@src/components/Modals/DeleteWidgetModal/DeleteWidgetModal'
import { ButtonIcon, DeleteIcon } from '@src/components/StyleGuide'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'
import { useDashboard } from '@src/hooks/useDashboard/useDashboard'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { deleteSetupWidgetPageData, fetchDeleteWidgetById } from '@src/store/widgets/slice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from '../styles.module.scss'
export const DeleteButton = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const { isSharedDashboard } = useDashboard()
    const { setupWidgetPage } = useWidget()
    const { dashboardID, id } = setupWidgetPage
    const { currentWidget } = useWidget({ dashboardID, widgetID: id })

    const handleBack = () => {
        navigate('/')
        dispatch(deleteSetupWidgetPageData())
    }

    const handleDeleteWidget = () => {
        if (id && dashboardID) {
            dispatch(fetchDeleteWidgetById({ dashboardID, widgetID: id }))
            dispatch(deleteSetupWidgetPageData())
            handleBack()
        }
    }
    return (
        <>
            <ButtonIcon
                className={styles.btnIcon}
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={!id || !dashboardID}
                icon={<DeleteIcon />}
                title={t('buttons.delete')}
            />
            <DeleteWidgetModal
                widgetName={currentWidget?.listData?.name ?? ''}
                isOpen={isDeleteModalOpen}
                onAccept={handleDeleteWidget}
                onClose={setIsDeleteModalOpen}
                isAccessShared={isSharedDashboard}
            />
        </>
    )
}
