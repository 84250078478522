import { Input } from '@src/components/StyleGuide'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import styles from '../styles.module.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDatasource } from '@src/hooks/useDatasource/useDatasource'
import { usePeriod } from '@src/hooks/usePeriod/usePeriod'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { fetchCreateWidgetByType } from '@src/store/widgets/slice'
import type { WidgetType } from '@src/api/dictionary/dictionary.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { permissionsActions } from '@src/store/permissions/slice'

export const SetWidgetParameters = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [widgetName, setWidgetName] = useState('')
    const [dataSourceId, setDataSourceId] = useState<number | null>(null)
    const [clientID, setClientID] = useState<number | null>(null)
    const [dataSetId, setDataSetId] = useState<string | null>(null)
    const { currentPeriod, fromToPeriod } = usePeriod(5)
    const { currentWidget, updateWidget, setupWidgetPage } = useWidget()
    const {
        widgetType,
        dashboardID,
        positionX,
        positionY,
        cellsX,
        cellsY,
        isLoadingCreateWidget,
        id: widgetID,
    } = setupWidgetPage
    const {
        datasourceType,
        datasourceList,
        dataSourceOptions,
        clientsListOptions,
        clientsList,
        datasetsListOptions,
        currentDatasetForRequest,
        findDatasetByID,
    } = useDatasource({
        datasourceID: dataSourceId,
        clientID,
        datasetID: dataSetId,
    })
    useEffect(() => {
        if (dataSourceId && dataSetId) {
            dispatch(
                permissionsActions.fetchUserDataPermissions({
                    datasourceTypeID: dataSourceId,
                    datasetID: dataSetId,
                }),
            )
        }
        if (!dataSourceId || !dataSetId) dispatch(permissionsActions.setUserDataPermissions([]))
    }, [dataSourceId, dataSetId])
    useEffect(() => {
        const pickedDatasource = dataSourceId ? datasourceList[dataSourceId] : null
        if (!pickedDatasource)
            setDataSourceId(currentWidget?.listData.dataSourceType.id ?? dataSourceOptions[0]?.value ?? null)
    }, [datasourceList, dataSourceOptions, currentWidget?.listData.dataSourceType])
    useEffect(() => {
        if (!clientID && dataSetId) {
            const matchedClient = clientsList.find(elem => !!elem.datasets.find(item => item.id === dataSetId))
            if (matchedClient) setClientID(matchedClient.id)
        }
    }, [dataSetId, clientsList])

    useEffect(() => {
        setDataSetId(currentWidget?.listData.dataset.id ?? null)
    }, [currentWidget?.listData.dataset])

    const handleSetWidgetName = (value: string) => {
        if (value !== widgetName) {
            setWidgetName(value)
            updateWidget({ name: value })
        }
    }

    const handleSetDatasource = (id: number | string) => {
        if (id !== dataSourceId) {
            setClientID(null)
            setDataSetId(null)
            setDataSourceId(id as number)
        }
    }
    const handleSetClient = (id: number | string) => {
        if (id !== clientID) {
            setDataSetId(null)
            setClientID(id as number)
        }
    }
    const handleSetDataset = (id: number | string) => {
        setDataSetId(id as string)
        const dataset = findDatasetByID(id as string)?.datasetForRequest
        const dataSourceType = dataSourceId ? datasourceList[dataSourceId]?.datasourceType : undefined
        if (dataset) updateWidget({ dataset, dataSourceType })
    }

    const createWidget = useCallback(
        (type?: WidgetType) => {
            if (
                widgetName &&
                datasourceType &&
                currentDatasetForRequest &&
                widgetType &&
                dashboardID &&
                typeof positionX === 'number' &&
                typeof positionY === 'number' &&
                cellsX &&
                cellsY &&
                !isLoadingCreateWidget
            ) {
                dispatch(
                    fetchCreateWidgetByType({
                        name: widgetName,
                        dataSourceType: datasourceType,
                        dataset: currentDatasetForRequest,
                        widgetType: type ?? widgetType,
                        dashboardID,
                        positionX,
                        positionY,
                        cellsX,
                        cellsY,
                        isDisabledFiltering: true,
                        isExtendedFiltering: false,
                        periodType: currentPeriod,
                        deniedFields: [],
                        ...fromToPeriod,
                    }),
                )
            }
        },
        [widgetName, datasourceType, currentDatasetForRequest, setupWidgetPage],
    )

    useEffect(() => {
        if (!widgetID) createWidget()
    }, [createWidget])
    return (
        <>
            {' '}
            <div className={`${styles.wrap} ${styles.fieldWrap}`}>
                <span className={styles.label}>{t('create_widget.labels.title')}</span>
                <Input
                    className={styles.input}
                    value={currentWidget?.listData?.name ?? ''}
                    setChangeOnFocus
                    setInputValue={handleSetWidgetName}
                />
            </div>
            <div className={`${styles.wrap} ${styles.fieldWrap}`}>
                <span className={styles.label}>{t('create_widget.labels.datasource')}</span>
                <SelectList options={dataSourceOptions} selectedValue={dataSourceId} onChange={handleSetDatasource} />
            </div>
            <div className={`${styles.wrap} ${styles.fieldWrap}`}>
                <span className={styles.label}>{t('create_widget.labels.client')}</span>
                <SelectList options={clientsListOptions} selectedValue={clientID} onChange={handleSetClient} />
            </div>
            <div className={`${styles.wrap} ${styles.fieldWrap}`}>
                <span className={styles.label}>{t('create_widget.labels.scenario')}</span>
                <SelectList
                    isDisabled={!clientID}
                    options={datasetsListOptions}
                    selectedValue={dataSetId}
                    onChange={handleSetDataset}
                />
            </div>
        </>
    )
}
