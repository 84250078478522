import React, { type FC } from 'react'
import styles from '../styles.module.scss'
import { t } from 'i18next'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { useWidget } from '@src/hooks/useWidget/useWidget'
import { useAggregateFunctions } from '@src/hooks/useAggregateFunctions/useAggregateFunctions'
import { useUserFields } from '@src/hooks/useUserFields/useUserFields'

export const PieChartSettings: FC = () => {
    const { pieChartWidget, updateWidget } = useWidget()
    const { aggregateFunctionOptions, getAggregateFunctionType } = useAggregateFunctions(
        pieChartWidget?.widget?.calcField?.fieldType?.id,
    )
    const { allowedUserDataOptions, findFieldByID } = useUserFields({
        datasourceTypeID: pieChartWidget?.listData.dataSourceType.id,
        datasetID: pieChartWidget?.listData.dataset.id,
    })

    const handleLabel = (value: string | number) => {
        const matchedDataset = findFieldByID(value as string)
        if (matchedDataset)
            updateWidget({
                groupField: { ...matchedDataset },
            })
    }

    const handleDataset = (value: string | number) => {
        const matchedDataset = findFieldByID(value as string)
        if (matchedDataset)
            updateWidget({
                calcField: { ...matchedDataset },
                aggregateFunctionType: { id: 2, name: 'Count' },
            })
    }

    const handleAggregateFunction = (value: string | number) => {
        const funcType = getAggregateFunctionType(value as number)
        if (funcType || value === 1) updateWidget({ aggregateFunctionType: funcType ?? { id: 1, name: 'None' } })
    }

    return (
        <>
            <div className={styles.settingItem}>
                <p className={styles.settingItemTitle}>{t('create_widget.settings.search_label')}</p>
                <div className={styles.settingItemWrap}>
                    <div className={styles.settingField}>
                        <span className={styles.settingFieldLabel}>
                            {t('create_widget.settings.field_legend_label')}
                        </span>
                        <SelectList
                            options={allowedUserDataOptions}
                            onChange={handleLabel}
                            selectedValue={pieChartWidget?.widget?.groupField?.id ?? ''}
                        />
                    </div>
                    <div className={styles.settingField}>
                        <span className={styles.settingFieldLabel}>{t('create_widget.settings.field_data_label')}</span>
                        <SelectList
                            options={allowedUserDataOptions}
                            onChange={handleDataset}
                            selectedValue={pieChartWidget?.widget?.calcField?.id ?? ''}
                        />
                    </div>
                    <div className={styles.settingField}>
                        <span className={styles.settingFieldLabel}>
                            {t('create_widget.settings.field_params_label')}
                        </span>
                        <SelectList
                            options={aggregateFunctionOptions}
                            onChange={handleAggregateFunction}
                            selectedValue={pieChartWidget?.widget?.aggregateFunctionType?.id ?? 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
