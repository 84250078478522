import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputCount, WidgetsIcon } from '@src/components'
import styles from '@src/components/SystemSettings/styles.module.scss'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { appStateActions } from '@src/store/appState/slice'
import { useAppSelector } from '@src/hooks/redux/useAppSelector'

export const WidgetSetting: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { UISettings } = useAppSelector(({ appState }) => appState)

    const fixedWidgets = UISettings?.fixedWigetsCount ?? 0
    const onChange = (value: number) => {
        dispatch(appStateActions.fetchUpdateUISettings({ fixedWigetsCount: value }))
    }

    return (
        <div className={styles.item}>
            <div className={styles.itemIcon}>
                <WidgetsIcon />
            </div>
            <div className={styles.itemWrap}>
                <p className={styles.itemTitle}>{t('system_settings.widgets_title')}</p>
                <p className={styles.itemValue}>{t('system_settings.widgets_subtitle')}</p>
            </div>
            <InputCount value={fixedWidgets} maxValue={4} onChange={onChange} />
        </div>
    )
}
