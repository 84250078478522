import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplyIcon, ButtonPrimary, CancelIcon, SettingsIcon } from '@src/components/StyleGuide'
import { Title } from './Title'
import styles from './styles.module.scss'
import { AntModalLayout } from '@src/layouts/AntModalLayout/AntModalLayout'
import type { AntModalProps } from '@src/layouts/AntModalLayout/AntModalLayout.types'
import { useNavigate } from 'react-router-dom'

export const CantPinMoreItemsModal: FC<AntModalProps> = ({ onCancel, isOpen }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const handleClose = () => {
        onCancel()
        navigate('/')
    }

    const handleNavigateToSettings = () => {
        navigate('/?isOpenSettings=true')
        onCancel()
    }

    return (
        <AntModalLayout isOpen={isOpen} onCancel={onCancel} title={<Title title={t('grid_cant_reduce_modal.title')} />}>
            <div className={styles.bodyWrapper}>
                <div className={styles.modalWarning}>
                    <p>{t('limit_pinned_element_modal.error_message')}</p>
                </div>
                <p className={styles.modalDescription}>
                    {t('limit_pinned_element_modal.change')}
                    <span> {t('limit_pinned_element_modal.settings')} </span>
                    {t('limit_pinned_element_modal.or_choose')}
                </p>
                <div className={styles.buttonsWrapper}>
                    <ButtonPrimary
                        className={styles.toSettings}
                        icon={<SettingsIcon />}
                        title={t('limit_pinned_element_modal.to_settings')}
                        type='primary'
                        onClick={handleNavigateToSettings}
                    />
                    <ButtonPrimary
                        className={styles.cancelBtn}
                        icon={<CancelIcon />}
                        title={t('limit_pinned_element_modal.close_window')}
                        type='transparent'
                        onClick={handleClose}
                    />
                </div>
            </div>
        </AntModalLayout>
    )
}
