import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.sass'
import { ArrowDropDownIcon, CloseIcon, SearchIcon } from '@src/components'
import { type SearchInputProps } from './SearchInput.types'

export const SearchInput: React.FC<SearchInputProps> = ({ onSearch, reset, className, ...props }) => {
    const { t } = useTranslation()

    const [localValue, setLocalValue] = React.useState<string>('')

    const handleSearch = (value: string) => {
        setLocalValue(value)
        onSearch?.(value)
    }

    const handleClear = () => {
        setLocalValue('')
        onSearch?.('')
        reset?.()
    }

    return (
        <div className={`${styles.root} ${className ?? ''}`}>
            <input
                className={`${styles.searchInput} ${className ?? ''}`}
                type={'text'}
                value={localValue}
                placeholder={t('search.placeholder')}
                onChange={e => handleSearch(e.target.value)}
                {...props}
            />
            <div className={styles.searchActions}>
                {!localValue ? (
                    <button title={t('search.placeholder')} className={styles.searchBtn}>
                        <SearchIcon />
                    </button>
                ) : (
                    <div className={styles.searchActionsWrap}>
                        {/*<div className={'divider'} />*/}
                        {/*<button title={t('search.down_btn')} className={`${styles.actionBtn} ${styles.down}`}>*/}
                        {/*    <ArrowDropDownIcon />*/}
                        {/*</button>*/}
                        {/*<button title={t('search.up_btn')} className={`${styles.actionBtn} ${styles.up}`}>*/}
                        {/*    <ArrowDropDownIcon />*/}
                        {/*</button>*/}
                        <button
                            title={t('search.clear_btn')}
                            onClick={handleClear}
                            className={`${styles.actionBtn} ${styles.close}`}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
