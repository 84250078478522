import React, { useState, type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles.module.scss'
import { SelectList } from '@src/components/StyleGuide/Ui/SelectList/SelectList'
import { FilterPanelItem } from '../FilterPanelItem/FilterPanelItem'
import type { FilterGroupProps, LogicOperators } from '../../FilterPanel.types'
import type { WidgetFilter } from '@src/api/widgets/widgets.types'
import { useLogicOperators } from '../../hooks/useLogicOperators'

export const FiltersGroup: FC<FilterGroupProps> = ({
    index,
    advancedMode,
    filterGroupItem,
    handleDeleteFilterGroup,
    onFilterUpdate,
    onFilterUpdateState,
    onAddFilterGroup,
}) => {
    const [filterGroup, setFilterGroup] = useState(filterGroupItem)
    const { t } = useTranslation()
    const logicOperatorsOptions = useLogicOperators()
    useEffect(() => {
        setFilterGroup(filterGroupItem)
    }, [filterGroupItem])
    const handleChangeLogicOperator = (value: string | number) => {
        if (((value as LogicOperators) === 'any') !== filterGroupItem.isUseOperatorOR)
            onFilterUpdate({ ...filterGroupItem, isUseOperatorOR: (value as LogicOperators) === 'any' })
    }
    const handleUpdateFilter = (filter: WidgetFilter, ind: number) => {
        const filters = filterGroup.filters
        const newFilters = filters.map((elem, i) =>
            i === ind
                ? {
                      ...filter,
                      widgetFilterGroupID: filterGroupItem.id,
                  }
                : elem,
        )
        onFilterUpdateState({ ...filterGroupItem, filters: newFilters })
        if (filter.field && filter.compareFunctionType) onFilterUpdate({ ...filterGroupItem, filters: newFilters })
    }

    const handleAddFilter = (i: number) => {
        const filtersCopy = [...filterGroup.filters]
        filtersCopy.splice(i + 1, 0, {
            field: null,
            compareFunctionType: null,
            sequenceNumber: i + 1,
            compareValue: null,
            isDisabled: true,
        })
        onFilterUpdateState({ ...filterGroupItem, filters: filtersCopy })
    }
    const handleDeleteFilter = (i: number) => {
        const filtersCopy = [...filterGroup.filters]
        const deletedFilter = filtersCopy.splice(i, 1)
        if (filtersCopy.length === 0 && filterGroupItem.id) handleDeleteFilterGroup(filterGroupItem.id)
        if (filtersCopy.length > 0 && deletedFilter[0].id) onFilterUpdate({ ...filterGroupItem, filters: filtersCopy })
        if (filtersCopy.length > 0 && !deletedFilter[0].id)
            onFilterUpdateState({ ...filterGroupItem, filters: filtersCopy })
    }
    const isPosibleDeleteRow = () => filterGroupItem.filters.length > 1
    const isPosibleAddRow = () => filterGroupItem.filters.every(elem => elem.field && elem.compareFunctionType)
    return (
        <div className={styles.filterContent}>
            {index === 0 &&
                filterGroup.filters?.map((elem, i) => (
                    <FilterPanelItem
                        key={elem.id}
                        advancedMode={advancedMode}
                        filterItem={elem}
                        isPosibleDeleteRow={isPosibleDeleteRow()}
                        isPosibleAddRow={isPosibleAddRow()}
                        onRowAdd={() => handleAddFilter(i)}
                        onAddFilterGroup={onAddFilterGroup}
                        onRowDelete={() => handleDeleteFilter(i)}
                        onChangeFilterItem={field => handleUpdateFilter(field, i)}
                    />
                ))}
            {index > 0 && advancedMode && (
                <div className={`${styles.filterContentRow} ${styles.group}`}>
                    <p className={styles.groupTitle}>{t('create_widget.settings.filter.group')}</p>
                    <div className={styles.groupCondition}>
                        <span className={styles.conditionTitle}>{t('create_widget.settings.filter.condition_1')}</span>
                        <SelectList
                            className={`filter-group__select ${styles.select}`}
                            options={logicOperatorsOptions}
                            onChange={handleChangeLogicOperator}
                            isFilter={true}
                            selectedValue={filterGroupItem.isUseOperatorOR ? 'any' : 'all'}
                        />
                        <span className={styles.conditionTitle}>{t('create_widget.settings.filter.condition_2')}</span>
                    </div>
                    <div className={styles.groupContent}>
                        {filterGroup.filters?.map((elem, i) => (
                            <FilterPanelItem
                                key={elem.id}
                                advancedMode={advancedMode}
                                filterItem={elem}
                                isPosibleAddRow={isPosibleAddRow()}
                                isPosibleDeleteRow
                                onRowAdd={() => handleAddFilter(i)}
                                onRowDelete={() => handleDeleteFilter(i)}
                                onAddFilterGroup={onAddFilterGroup}
                                onChangeFilterItem={field => handleUpdateFilter(field, i)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
