import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { DictionaryInitialState } from './structures.types'
import type {
    DatasetRequest,
    DatasetResponse,
    DatasetListRequest,
    DatasetsListResponse,
    DatasourceRequest,
    DatasourceResponse,
} from '@src/api/structures/structures.types'

const initialState: DictionaryInitialState = {
    datasourceList: {},
    isLoadingDatasource: false,
    isErrorDatasource: false,
    dataset: null,
    isLoadingDataset: false,
    isErrorDataset: false,
    datasetsList: [],
    isLoadingDatasetsList: false,
    isErrorDatasetsList: false,
}

export const structuresSlice = createSlice({
    name: 'structures',
    initialState,
    reducers: {
        fetchDatasource: (state, { payload }: PayloadAction<DatasourceRequest>) => {
            state.isLoadingDatasource = true
            state.isErrorDatasource = false
        },
        setDatasource: (state, { payload }: PayloadAction<DatasourceResponse>) => {
            state.isLoadingDatasource = false
            state.datasourceList[payload.datasourceType.id] = payload
        },
        errorDatasource: state => {
            state.isLoadingDatasource = false
            state.isErrorDatasource = true
        },
        fetchDataset: (state, { payload }: PayloadAction<DatasetRequest>) => {
            state.isLoadingDataset = true
        },
        setDataset: (state, { payload }: PayloadAction<DatasetResponse>) => {
            state.isLoadingDataset = false
            state.dataset = payload
        },
        errorDataset: state => {
            state.isLoadingDataset = false
            state.isErrorDataset = true
        },
        fetchDatasetsList: (state, { payload }: PayloadAction<DatasetListRequest>) => {
            state.isLoadingDatasetsList = true
        },
        setDatasetsList: (state, { payload }: PayloadAction<DatasetsListResponse>) => {
            state.isLoadingDatasetsList = false
            state.datasetsList = payload
        },
        errorDatasetsList: state => {
            state.isLoadingDatasetsList = false
            state.isErrorDatasetsList = true
        },
    },
})

export const { actions: structureActions, reducer: structures } = structuresSlice
