import React, { useState, type FC } from 'react'
import type { WidgetCommandsProps } from './WidgetCommands.types'
import commandStyles from './styles.module.scss'
import { ButtonIcon } from '@src/components'
import type { Command } from '@src/api/actions/actions.types'
import { useAppDispatch } from '@src/hooks/redux/useAppDispatch'
import { actionsActions } from '@src/store/actions/slice'
import { ActionExecuteResultModal } from '@src/components/Modals/ActionExecuteResultModal/ActionExecuteResultModal'
export const WidgetCommands: FC<WidgetCommandsProps> = ({ pinnedCommands }) => {
    const dispatch = useAppDispatch()
    const [isOpenModalAfterExecute, setIsOpenModalAfterExecute] = useState(false)
    const [executedCommand, setExecutedCommand] = useState('')
    const handleExecuteCommand = (command: Command) => {
        dispatch(
            actionsActions.fetchExecuteCommand({
                commandID: command.id,
                onSuccess: () => setIsOpenModalAfterExecute(true),
            }),
        )
        setExecutedCommand(command.name)
    }
    const handleCloseModal = () => {
        setIsOpenModalAfterExecute(false)
        setExecutedCommand('')
    }
    return (
        <>
            {pinnedCommands.map((elem, i) => (
                <ButtonIcon
                    key={i}
                    icon={
                        <img
                            src={`data:image/png;base64, ${elem.command.iconBytes}`}
                            className={commandStyles.actionIcon}
                        />
                    }
                    title={elem.command.name}
                    onClick={() => handleExecuteCommand(elem.command)}
                />
            ))}
            {isOpenModalAfterExecute && (
                <ActionExecuteResultModal
                    isOpen={isOpenModalAfterExecute}
                    onCancel={handleCloseModal}
                    commandName={executedCommand}
                />
            )}
        </>
    )
}
